// Chakra imports
import { Box, Heading, Button } from "@chakra-ui/react";
import Card from "components/card/Card.js";
import React from "react";
import { useHistory } from "react-router-dom";
import moment from "moment";

import './Banner.css';

export default function EvaluationBanner(props) {
  const { 
    userSurveyResponseId, 
    updateDate,
    type,
    status
  } = props;

  const history = useHistory();

  const STATUS = {
    IN_PROGRESS: 1,
    PENDING: 0
  }

  return (
    <Card mb={{ base: "0px", lg: "20px" }} className="banner-background" align='center'>
      <Heading size="1xl">{type} Survey</Heading>
      <Box style={ { marginTop: '30px', height: '60px'}}>
        {status === STATUS.IN_PROGRESS ? `You have an ongoing ${type} Evaluation` : `You have a pending ${type} Evaluation.`}
      </Box>
      <Box style={ { minHeight: '60px', color: 'green' } }>
        {status === STATUS.IN_PROGRESS ? `Last update ${moment(updateDate).format('MMM DD YYYY HH:mm')}` : null}
      </Box>
      <Box style={ { textAlign: 'center', marginTop: 40 } }>
        <Button
          sx={ { marginTop: '15px' } }
          variant='brand'
          backgroundColor="green.600"
          onClick={() => history.push(`/app/assessment/${userSurveyResponseId}`)}
          fontWeight='500'>
            {status === STATUS.PENDING ? "Start Evaluation" : "Continue" }
          </Button>
      </Box>
    </Card>
  );
}
