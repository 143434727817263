/*!
  _   _  ___  ____  ___ ________  _   _   _   _ ___   
 | | | |/ _ \|  _ \|_ _|__  / _ \| \ | | | | | |_ _| 
 | |_| | | | | |_) || |  / / | | |  \| | | | | || | 
 |  _  | |_| |  _ < | | / /| |_| | |\  | | |_| || |
 |_| |_|\___/|_| \_\___/____\___/|_| \_|  \___/|___|
                                                                                                                                                                                                                                                                                                                                       
=========================================================
* Horizon UI - v1.1.0
=========================================================

* Product Page: https://www.horizon-ui.com/
* Copyright 2022 Horizon UI (https://www.horizon-ui.com/)

* Designed and Coded by Simmmple

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// Chakra imports
import { Box, Grid, GridItem, Heading, SimpleGrid, useToast, Text, IconButton, Button } from "@chakra-ui/react";

import PropTypes from 'prop-types';

// Assets
import React, { useCallback, useEffect } from "react";
import { listOrganizationUsersAsync } from "features/user/slice";
import { getOrganizationEvaluations } from "features/surveys/slice";
import { connect } from "react-redux";
import Authenticated from "../authenticated";
import { loadSubscriptions } from "features/subscriptions/slice";
import { createPaymentSession } from "features/payments/slice";
import { setIsUpgradeModalOpen } from "features/payments/slice";
import { USER_ROLE } from "constants/userRoles.const";
import { updateOrganizationLogoAsync } from "features/user/slice";
import Card from "components/card/Card";
import { USER_ROLE_DESC } from "constants/userRoles.const";
import { EditIcon } from "@chakra-ui/icons";
import { COLORS } from "constants/colors";
import { getUserSurveys } from "features/userSurveys/slice";
import PendingEvaluationBanner from "./components/PendingEvaluationBanner";
import { SUBSCRIPTION_FREQUENCY_DESC } from "constants/subscriptionTypes.const";
import { useHistory } from "react-router-dom";

function Overview(props) {
  const { 
    onLoadTeamMembers,
    subscriptionType,
    subscriptionName,
    subscriptionFrequency,
    firstName, 
    lastName, 
    role, 
    isAuthenticated, 
    teamMembers,
    subscriptions,
    email,
    onLoadSubscriptions,
    setUpgradeModalOpen,
    numberOfEvaluations,
    numberOfEmployeeIQEvaluations,
    numberOfOrgIQEvaluations,
    numberOfBoardIQEvaluations,
    userId,
    onLoadUserSurveys,
    userSurveys
  } = props;
  
  const toast = useToast();
  const history = useHistory();

  const validateAuthentication = useCallback(() => {
    if (isAuthenticated === false){
      window.location.replace(`${process.env.REACT_APP_WELCOME_REDIRECT}`);
    }
  }, [isAuthenticated]);

  const onClickUpgrade = () => {
    history.push('/admin/subscription');
  }

  const location = props.location;

  useEffect(() => {
    validateAuthentication();
    onLoadTeamMembers();
    onLoadSubscriptions(() => { }, () => { });
    if(userId && userId !== ''){
      onLoadUserSurveys(userId, 'Pending', () => { }, () => { });
    }

    const query = new URLSearchParams(location);
    const status = query.get('status');

    if(status === "error") {
      toast({
        title: 'Payment error',
        description: "Unable to finish the payment. Please try again.",
        status: 'error',
        duration: 9000,
        isClosable: true
      });
      setUpgradeModalOpen(true);
    }
  }, []);

  return (
    <Authenticated automaticRedictToPay={false}>
      <Box minH="1000px" ml="45px" mt={8} mr="30px">
        <Box>
            <Heading size="md" pt="10px">My Profile</Heading>
        </Box>
        <Box>
          <Grid templateColumns={"1fr 1fr"} mt="25px" gap={0}>
            <GridItem>
              <Card height={"135px"}>
                <Grid templateColumns="2fr 1fr" mt="10px">
                  <GridItem width="60px" className="member-card" display={"flex"}>
                    <Box>
                      <div style={{ width: "69px", height: "69px", textAlign: 'center', backgroundColor: "#0095FF1A", color: "#0095ff", borderRadius: "50%" }} >
                        <Text fontSize="26px" fontWeight="bold" style={{ paddingTop: "14px" }}>
                          {`${firstName.substring(0, 1)} ${lastName.substring(0, 1)}`}
                        </Text>
                      </div>
                    </Box>
                    <Box width="275px" style={ { textWrap: "wrap" } } className="member-card">
                      <Box ml="25px" mt="0px">
                        <Heading size="md">{firstName} {lastName}</Heading>
                        <Box style={ { overflowWrap: 'wrap', width: '300px' } } mt="5px">
                          <Text className="evaluation-text-description" 
                            fontSize="md"
                            style={{ color: "#0095FF" }} 
                          > 
                            {USER_ROLE_DESC[role]}
                          </Text>
                          <Text>
                          </Text>
                          <Text fontSize="12px" color="#0000004D" mt="4px">
                            {email}
                          </Text>
                        </Box>
                      </Box>
                    </Box>
                  </GridItem>
                  <GridItem width={"100%"}>
                    <IconButton backgroundColor={COLORS.Primary} color={COLORS.PrimaryText}
                      onClick={() => history.push('/admin/settings')}
                      float="right" mr="20px" mt="10px">
                      <EditIcon />
                    </IconButton>
                  </GridItem>
                </Grid>
              </Card>
            </GridItem>
            <GridItem>
              <Box ml="20px">
                <Card height="60px">
                  <Grid templateColumns="1fr 1fr">
                    <GridItem>
                      <Text>Total Members</Text>
                    </GridItem>
                    <GridItem textAlign="right">
                      <Text color={COLORS.Primary} mr="10px">{teamMembers ? teamMembers.length : 0}</Text>
                    </GridItem>
                  </Grid>
                </Card>
                <Card height="60px" mt={"15px"}>
                  <Grid templateColumns="1fr 1fr">
                      <GridItem>
                        <Text>Total Evaluations</Text>
                      </GridItem>
                      <GridItem textAlign="right">
                        <Text color={COLORS.Primary} mr="10px">{numberOfEvaluations}</Text>
                      </GridItem>
                    </Grid>
                </Card>
              </Box>
            </GridItem>
          </Grid>
        </Box>
        <Box mt="20px">
            <Heading size="md" pt="10px">Pending Assessments</Heading>
        </Box>
        <Box>
          <SimpleGrid columns={{ sm: 1, md: 3, lg: 3 }} spacingX={"15px"} mt={10}>
              {userSurveys && userSurveys.length > 0 && role !== USER_ROLE.Owner ? userSurveys.map((s, i) => {
                  return <Box id="peding-ev-ban">
                      <PendingEvaluationBanner
                          userSurveyResponseId={s.id}
                          type={s.surveyType}
                          date={s.createDate}
                          updateDate={s.updateDate}
                          status={s.status}
                      />
                      </Box>;
              }) : <Box>
                  <Text>No Pending Assessments at the moment</Text>
              </Box>}
          </SimpleGrid>
        </Box>
        <Box mt="20px">
            <Grid templateColumns={"1fr 1fr 1fr"} columnGap={"20px"}>
              <GridItem>
                <Box>
                  <Heading size="md" pt="10px">Evaluations</Heading>
                </Box>
                <Box mt="20px">
                  <Card>
                    <Grid templateColumns={"1fr 1fr"} rowGap="10px">
                      <GridItem>
                        Employee IQ
                      </GridItem>
                      <GridItem textAlign="right">
                          <Text color={COLORS.Primary} fontWeight={"bold"} mr="10px">{numberOfEmployeeIQEvaluations}</Text>
                      </GridItem>
                      <GridItem>
                        Org IQ
                      </GridItem>
                      <GridItem textAlign="right">
                          <Text color={COLORS.Primary} fontWeight={"bold"} mr="10px">{numberOfOrgIQEvaluations}</Text>
                      </GridItem>
                      <GridItem>
                        Board IQ
                      </GridItem>
                      <GridItem textAlign="right">
                          <Text color={COLORS.Primary} fontWeight={"bold"} mr="10px">{numberOfBoardIQEvaluations}</Text>
                      </GridItem>
                      {/* <GridItem>
                        Career IQ
                      </GridItem>
                      <GridItem textAlign="right">
                          <Text color={COLORS.Primary} fontWeight={"bold"} mr="10px">{numberOfCareerIqEvaluations}</Text>
                      </GridItem> */}
                    </Grid>
                  </Card>
                </Box>
              </GridItem>
              {role === USER_ROLE.Admin ? <GridItem>
                <Box>
                  <Heading size="md" pt="10px">Subscription</Heading>
                </Box>
                <Box>
                  <Card height="110px" mt="20px">
                    <Grid templateColumns="1fr 1fr" ml="5px">
                      <GridItem>
                        <Box mt="5px">
                          <Text fontWeight="bold" fontSize="18px">
                            {subscriptionName}
                          </Text>
                        </Box>
                        <Box>
                          <Text fontSize="18px" color={COLORS.Primary}>
                            {subscriptions ? subscriptions.map((s, i) => {
                              if(s.type === subscriptionType){
                                return `$ ${subscriptions[i].price}/${SUBSCRIPTION_FREQUENCY_DESC[subscriptionFrequency]}`;
                              } else {
                                return null;
                              }
                            }) : null}
                          </Text>
                        </Box>
                      </GridItem>
                      <GridItem textAlign={"center"}>
                          <Button
                              w='140px'
                              mt="10px"
                              height={"50px"}
                              style={ { backgroundColor: COLORS.Primary, color: COLORS.PrimaryText } }
                              onClick={onClickUpgrade}
                              fontWeight='500'>
                              Upgrade
                          </Button>
                      </GridItem>
                    </Grid>
                  </Card>
                </Box>
              </GridItem> : null}
            </Grid>
        </Box>
        {/* Main Fields */}
{/*         
        {role === USER_ROLE.Admin ? <LogoUpload 
          organizationLogo={organizationLogo}
          onUploadLogo={onUploadLogo}
        /> : null} */}
      </Box>
    </Authenticated>
  );
}


Overview.propTypes = {
  subscriptions: PropTypes.array,
  isUpgradeModalOpen: PropTypes.bool,
  subscriptionType: PropTypes.number,
  subscriptionName: PropTypes.string,
  organizationId: PropTypes.string,
  organizationLogo: PropTypes.string,
  email: PropTypes.string,
  userId: PropTypes.string,
  userSurveys: PropTypes.array,
  onLoadSubscriptions: PropTypes.func,
  onLoadOrganizationEvaluations: PropTypes.func,
  onLoadTeamMembers: PropTypes.func,
  onCreatePaymentSession: PropTypes.func,
  setUpgradeModalOpen : PropTypes.func,
  numberOfTeamMembers: PropTypes.number,
  numberOfEvaluations: PropTypes.number,
  numberOfEmployeeIQEvaluations: PropTypes.number,
  numberOfOrgIQEvaluations: PropTypes.number,
  numberOfBoardIQEvaluations: PropTypes.number,
  numberOfPendingEvaluations: PropTypes.number,
  numberOfCompletedEvaluations: PropTypes.number,
  numberOfCareerIqEvaluations: PropTypes.number,
  onUploadLogo: PropTypes.func,
  onLoadUserSurveys: PropTypes.func
};

Overview.defaultProps = {
  subscriptions: [],
  isUpgradeModalOpen: false,
  subscriptionType: 0,
  subscriptionName: '',
  subscriptionFrequency: '',
  numberOfTeamMembers: 0,
  organizationId: '',
  organizationLogo: '',
  numberOfEvaluations: 0,
  numberOfEmployeeIQEvaluations: 0,
  numberOfOrgIQEvaluations: 0,
  numberOfBoardIQEvaluations: 0,
  numberOfPendingEvaluations: 0,
  numberOfCompletedEvaluations: 0,
  numberOfCareerIqEvaluations: 0,
  email: '',
  userId: '',
  userSurveys: [],
  onUploadLogo: () => { },
  onLoadTeamMembers: () => {},
  onLoadSubscriptions: () => {},
  onLoadOrganizationEvaluations: () => {},
  onCreatePaymentSession: () => {},
  setUpgradeModalOpen: () => {},
  onLoadUserSurveys: () => { }
}

function mapStateToProps(state) {

  const { 
    teamMembers, 
    firstName, 
    lastName, 
    role, 
    organizationName, 
    subscriptionType, 
    subscriptionName,
    subscriptionFrequency,
    numberOfTeamMembers,
    numberOfEvaluations,
    numberOfEmployeeIQEvaluations,
    numberOfOrgIQEvaluations,
    numberOfBoardIQEvaluations,
    numberOfPendingEvaluations,
    numberOfCompletedEvaluations,
    numberOfExjDiagnosticEvaluations,
    numberOfCareerIqEvaluations,
    email
  } = state.user;

  const { isAuthenticated, organizationId, organizationLogo, userId } = state.authentication;

  const { subscriptions } = state.subscriptions;

  const { isUpgradeModalOpen } = state.payments;

  const { userSurveys } = state.userSurveys;

  return {
    subscriptions,
    isUpgradeModalOpen,
    teamMembers,
    firstName,
    lastName,
    userSurveys,
    role,
    subscriptionType,
    subscriptionName,
    subscriptionFrequency,
    organizationId,
    organizationName,
    organizationLogo,
    isAuthenticated,
    numberOfTeamMembers,
    numberOfEvaluations,
    numberOfEmployeeIQEvaluations,
    numberOfOrgIQEvaluations,
    numberOfBoardIQEvaluations,
    numberOfPendingEvaluations,
    numberOfCompletedEvaluations,
    numberOfExjDiagnosticEvaluations,
    numberOfCareerIqEvaluations,
    userId,
    email
   };
};

function mapDispatchToProps (dispatch) {
  return {
      onLoadTeamMembers: () => dispatch(listOrganizationUsersAsync(null, () => { })),
      onLoadOrganizationEvaluations: (onSuccess, onError) => dispatch(getOrganizationEvaluations(null, onSuccess, onError)),
      onLoadSubscriptions: (onSuccess, onError) => dispatch(loadSubscriptions({ onSuccess, onError })),
      onCreatePaymentSession: (subscriptionType, subscriptionFrequency, onSuccess, onError) => dispatch(createPaymentSession({ subscriptionType, subscriptionFrequency, onSuccess, onError })),
      setUpgradeModalOpen: (open) => dispatch(setIsUpgradeModalOpen(open)),
      onUploadLogo: (file, onSuccess) => dispatch(updateOrganizationLogoAsync({ file, onSuccess })),
      onLoadUserSurveys: (userId, status, onSuccess, onError ) => dispatch(getUserSurveys({ userId, status, onSuccess, onError })),
      dispatch
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Overview);