import { combineReducers } from 'redux';
import userReducer from '../features/user/slice';
import languageReducer from '../features/language/slice';
import authenticationReducer from '../features/authentication/slice';
import loaderReducer from '../features/loader/slice';
import subscriptionsReducer from '../features/subscriptions/slice';
import paymentsReducer from '../features/payments/slice';
import surveysReducer from '../features/surveys/slice';
import metricsReducer from '../features/metrics/slice';
import userSurveys from '../features/userSurveys/slice';
import arrowsReducer from '../features/arrows/slice';
import reportsReducer from '../features/reports/slice';
import insightsReducer from '../features/insights/slice';
import { configureStore } from '@reduxjs/toolkit';

const rootReducer = combineReducers({
    authentication: authenticationReducer,
    loader: loaderReducer,
    user: userReducer,
    language: languageReducer,
    subscriptions: subscriptionsReducer,
    payments: paymentsReducer,
    surveys: surveysReducer,
    metrics: metricsReducer,
    userSurveys: userSurveys,
    arrows: arrowsReducer,
    reports: reportsReducer,
    insights: insightsReducer
  });

export const store = configureStore({
    reducer: rootReducer
});

export default store;
