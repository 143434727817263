const { SURVEY_TYPE } = require("./evaluation.const");

export const HarmonizationIndexLabels = {
    [SURVEY_TYPE.OrgIq]: {
        firstGrade: {
            title: "High Harmonization",
            range: "67.00 - 100.00",
            content: "All four behavior drivers are within the optimal range",
            action: "",
            color: "rgba(64, 148, 228, 0.1)"
        },
        secondGrade: {
            title: "Moderate Harmonization",
            range: "34.00 - 66.99",
            content: "One of the behavior drivers is out of the optimal range",
            action: "",
            color: "rgba(64, 148, 228, 0.25)"
        },
        thirdGrade: {
            title: "Low Harmonization",
            range: "0.00 - 33.99",
            content: "Two or more behavior drivers are out of the optimal range.",
            action: "",
            color: "rgba(64, 148, 228, 0.4)"
        }
    },
    [SURVEY_TYPE.BoardIq]: {
        firstGrade: {
            title: "High Harmonization",
            range: "67.00 - 100.00",
            content: "All four behavior drivers are within the optimal range",
            action: "",
            color: "rgba(64, 148, 228, 0.1)"
        },
        secondGrade: {
            title: "Moderate Harmonization",
            range: "34.00 - 66.99",
            content: "One of the behavior drivers is out of the optimal range",
            action: "",
            color: "rgba(64, 148, 228, 0.25)"
        },
        thirdGrade: {
            title: "Low Harmonization",
            range: "0.00 - 33.99",
            content: "Two or more behavior drivers are out of the optimal range.",
            action: "",
            color: "rgba(64, 148, 228, 0.4)"
        }
    },
    [SURVEY_TYPE.EmployeeIq]: {
        firstGrade: {
            title: "High Harmonization",
            range: "67.00 - 100.00",
            content: "All four behavior drivers are within the optimal range",
            action: "",
            color: "rgba(64, 148, 228, 0.1)"
        },
        secondGrade: {
            title: "Moderate Harmonization",
            range: "34.00 - 66.99",
            content: "One of the behavior drivers is out of the optimal range",
            action: "",
            color: "rgba(64, 148, 228, 0.25)"
        },
        thirdGrade: {
            title: "Low Harmonization",
            range: "0.00 - 33.99",
            content: "Two or more behavior drivers are out of the optimal range.",
            action: "",
            color: "rgba(64, 148, 228, 0.4)"
        }
    }
}