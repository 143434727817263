// Chakra imports
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalFooter,
    ModalBody,
    Button,
    Box,
    Text,
    Heading
  } from '@chakra-ui/react'
import Card from 'components/card/Card';
import { COLORS } from 'constants/colors';
import { TRAITS_DESC } from 'constants/definitions';
import React from "react";
  
export default function TraitsModal(props) {
    const { 
        isOpen, 
        onClose, 
        traits
    } = props;

    return (
        <Modal size='xl' isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalBody>
            <Box textAlign={"center"} fontSize={"14px"} mt="20px">
                <Text>
                The traits are patterns, the usual way an individual behaves and does something. Below are several out of 8 traits.
                </Text>
            </Box>
            {traits.map((a, i) => {
                return <Box mt="20px">
                <Card pr="80px" boxShadow="0px 18px 40px rgba(112, 144, 176, 0.12)" minH="120px">
                    <Box display={"flex"}>
                        <Box>
                            <Box width="60px" mt="10px">
                                <div style={{ width: "59px", height: "59px", textAlign: 'center', backgroundColor: "#0095FF1A", color: "#0095ff", borderRadius: "50%" }} >
                                    <Heading size="md" style={{ paddingTop: "16px" }}>
                                        {a.indicator}
                                    </Heading>
                                </div>
                            </Box>
                        </Box>
                        <Box width={"100%"} display={"flex"}>
                            <Box ml="15px" mt="5px" width={"400px"}>
                                <span style={ { color: COLORS.Primary, fontWeight: "bold" } }>{`${a.name}:`}</span> <Text fontSize="13px">{(a.name ? TRAITS_DESC[a.name.toLowerCase()] : null)}</Text> 
                            </Box>
                        </Box>
                    </Box>
                </Card>
            </Box>
            })}
          </ModalBody>
          <ModalFooter>
            <Button colorScheme='blue' style={ { backgrounColor: COLORS.Primary }} ml="auto" mr="auto" height={"60px"} width={"150px"} onClick={onClose}>Close</Button>
          </ModalFooter>
        </ModalContent>
    </Modal> 
    )
}