/* eslint-disable */
import  { 
  Modal, 
  ModalOverlay, 
  ModalContent, 
  ModalBody, 
  FormControl, 
  ModalHeader,
  ModalCloseButton,
  FormLabel,
  Input,
  ModalFooter,
  Button,
  Box,
  Heading,
  Text,
  Grid,
  GridItem,
  IconButton
} from "@chakra-ui/react";

// Custom components
import React from "react";
import PaymentLink from "./PaymentLink";
import { USER_ROLE } from "constants/userRoles.const";
import { AddIcon, DeleteIcon } from "@chakra-ui/icons";
import _ from "lodash";
import { v4 as uuidv4 } from 'uuid';

const MemberForm = ({ id, invitee, onChangeFirstName, onChangeLastName, onRemoveMember, onChangeEmail }) => {

  const [firstName, setFirstName] = React.useState(invitee.firstName);
  const [lastName, setLastName] = React.useState(invitee.lastName);
  const [email, setEmail] = React.useState(invitee.email);

  const changeFirstName = (val) => {
    onChangeFirstName(val, id);
    setFirstName(val);
  }

  const changeLastName = (val) => {
    onChangeLastName(val, id);
    setLastName(val);
  }

  const changeEmail = (val) => {
    onChangeEmail(val, id);
    setEmail(val);
  }

  return (<Grid templateColumns={"1fr 1fr 1fr 0.5fr"} columnGap={"5px"}>
    <GridItem>
      <FormControl>
          <FormLabel>First name</FormLabel>
          <Input value={firstName} onChange={(e) => changeFirstName(e.target.value, id)} required placeholder='First name' />
        </FormControl>
    </GridItem>
    <GridItem>
      <FormControl>
          <FormLabel>Last name</FormLabel>
          <Input value={lastName} onChange={(e) => changeLastName(e.target.value, id)} required placeholder='Last name' />
        </FormControl>
    </GridItem>
    <GridItem>
    <FormControl>
        <FormLabel>E-mail</FormLabel>
        <Input value={email} type="email" onChange={(e) => changeEmail(e.target.value, id)} required placeholder='E-mail' />
      </FormControl>
    </GridItem>
    <GridItem>
      <IconButton mt="30px" icon={<DeleteIcon />} onClick={() => onRemoveMember(id)} color="red"></IconButton>
    </GridItem>
  </Grid>)
}

export default function CreateMember({ organizationDomain, subscriptionType, role, toast, isOpen, onClose, onSave }) {
  
    const initialRef = React.useRef(null);
    const finalRef = React.useRef(null);

    const [invitees, setInvitees] = React.useState([{ id: uuidv4(), firstName: '', lastName: '', email: '' }]);

    const onClickClose = () => {
      setInvitees([{ id: uuidv4(), firstName: '', lastName: '', email: '' }]);
      onClose();
    }

    const onChangeFirstName = (value, id) => {
      let newInvitees = [...invitees];

      newInvitees[_.findIndex(newInvitees, (i) => i.id === id)].firstName = value;

      setInvitees(newInvitees);
    }

    const onChangeLastName = (value, id) => {
      let newInvitees = [...invitees];

      newInvitees[_.findIndex(newInvitees, (i) => i.id === id)].lastName = value;

      setInvitees(newInvitees);
    }

    const onChangeEmail = (value, id) => {
      let newInvitees = [...invitees];

      newInvitees[_.findIndex(newInvitees, (i) => i.id === id)].email = value;

      setInvitees(newInvitees);
    }

    const onClickAdd = (e) => {
      e.preventDefault();
      setInvitees([
        ...invitees,
        { id: uuidv4(), firstName: '', lastName: '', email: '' },
      ]);
    }
    
    const onRemoveMember = (id) => {
      let newInvitees = [...invitees];
    
      let list = _.filter(newInvitees, (p) => p.id !== id);

      if (list.length === 0) {
        list.push({ id: uuidv4(), firstName: '', lastName: '', email: '' });
      }

      setInvitees(list);
    }

    const onClickSave = (e) => {
      
      let list = _.filter(invitees, (i) => i.firstName !== '' && i.lastName !== '' && i.email !== '');

      onSave(list, () => 
        {
          setInvitees([{ id: uuidv4(), firstName: '', lastName: '', email: '' }]);
          onClose()
          toast({
            title: 'Success',
            description: `Invitation to new ${(role === USER_ROLE.TeamMember ? 'Team Member(s)' : 'Admin')} has been sent.`,
            status: 'success',
            duration: 9000,
            isClosable: true
          });
        }
      );
    }
    return (
        <Modal
          initialFocusRef={initialRef}
          finalFocusRef={finalRef}
          isOpen={isOpen}
          onClose={onClickClose}
          size="2xl"
        >
          <ModalOverlay />
          <ModalContent>
            <form onSubmit={(e) => onClickAdd(e)}>
              <ModalHeader>Add New Member</ModalHeader>
              <ModalCloseButton />
              <ModalBody pb={6}>
                {invitees.map((p, i) => {
                  return (<Box mb="20px">
                    <MemberForm
                      invitee={p}
                      id={p.id}
                      onRemoveMember={onRemoveMember}
                      onChangeEmail={onChangeEmail}
                      onChangeFirstName={onChangeFirstName}
                      onChangeLastName={onChangeLastName}
                    />
                  </Box>)
                })}
                
                <Box mt="5px">
                  <Button 
                    height={"25px"} 
                    width={"100px"} 
                    fontSize={"12px"} 
                    leftIcon={<AddIcon />} 
                    colorScheme='blue' 
                    type="submit"
                    ariant='solid'>
                    Add New
                  </Button>
                </Box>
                </ModalBody>
      
                <ModalFooter>
                  <Button colorScheme='blue' onClick={onClickSave} mr={3}>
                    Save
                  </Button>
                  <Button onClick={onClickClose}>Cancel</Button>
                </ModalFooter>
              </form>
              <Box style={ { height: '30px', backgroundColor: 'gray' } }/>
              {subscriptionType === 1 ? <Box mt="20px" minH="200px">
                  <Box ml="20px" mr="20px">
                    <Box>
                      <Heading size="md">Shareable invite Link</Heading>
                    </Box>
                    <Box>
                      <Text size="md">Anyone who joins a team using this link will join the team as a member</Text>
                    </Box>
                    <Box>
                      <PaymentLink organizationDomain={organizationDomain}/>
                    </Box>
                    <Box mt="15px" mb="40px">
                      <Button colorScheme='blue' float="right" type="submit" mr={3}>
                        Copy Link
                      </Button>
                    </Box>
                  </Box>
                </Box> : null}
          </ModalContent>
        </Modal>
    )
  }