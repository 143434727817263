import React from "react";

import { Icon } from "@chakra-ui/react";
import {
  MdMonetizationOn,
  MdLock,
  MdStarOutline,
  MdSettings,
  MdBadge,
  MdCorporateFare,
  MdGroup,
} from "react-icons/md";

// Admin Imports
import MainDashboard from "views/admin/default";
import Pricing from "views/admin/pricing";
import OrgIQ from "views/admin/evaluations/orgIQ";
import EmployeeIQ from "views/admin/evaluations/employeeIQ";
import BoardIQ from "views/admin/evaluations/boardIQ";
import Profile from "views/admin/profile/index";
import Settings from "views/admin/profile/Settings";
import Password from "views/admin/profile/Password";
import UpgradePlan from "views/admin/profile/UpgradePlan";
import PaymentSuccess from "views/admin/payment/index";
import SurveyTypes from "views/admin/surveyTypes";

// Auth Imports
import Session from "views/auth/session";
import RecoverPassword from "views/auth/recoverPassword";
import SignInCentered from "views/auth/signIn";
import SignUpCentered from "views/auth/signup";
import BuyAssessment from "views/buy/default";
import SignOutCentered from "views/auth/signOut";
import Definitions from "views/admin/definitions";
import Register from "views/auth/register";
import IndividualResults from "views/admin/dashboard/IndividualResults";
import Insights from "views/admin/insights";
import ReportCover from "views/report/Cover";
import ReportIntro from "views/report/Intro";
import ReportFirstPage from "views/report/FirstPage";
import ReportSecondPage from "views/report/SecondPage";
import ReportThirdPage from "views/report/ThirdPage";

import ExjReportCover from "views/exj/Cover";
import ExjReportFirstPage from "views/exj/First";
import ExjReportSecondPage from "views/exj/Second";
import ExjReportThirdPage from "views/exj/Third";
import ExjReportFourthPage from "views/exj/Fourth";
import ExjReportFifthPage from "views/exj/Fifth";
import ExjReportSixthPage from "views/exj/Sixth";
import ExjReportSeventhPage from "views/exj/Seventh";
import ExjReportEighthPage from "views/exj/Eighth";

import Assessment from "views/admin/evaluations/assessment";
import Onboarding from "views/admin/evaluations/onboarding";
import { USER_ROLE } from "constants/userRoles.const";
import { FaChartLine } from "react-icons/fa";

import MenuDashboardIcon from './assets/img/menu/menu_dashboard.svg';
import MenuAssessmentIcon from './assets/img/menu/menu_assessment.svg';
import MenuDefinitions from './assets/img/menu/menu_definitions.svg';
import MenuSignOut from './assets/img/menu/menu_sign_out.svg';

export const routes = [
  {
    name: "Dashboard",
    layout: "/admin",
    path: "/default",
    icon: MenuDashboardIcon,
    component: MainDashboard,
    role: [0, USER_ROLE.TeamMember, USER_ROLE.Admin, USER_ROLE.Owner]
  },
  {
    name: "Pricing",
    layout: "/admin",
    icon: <Icon as={MdMonetizationOn} width='20px' height='20px' color='inherit' />,
    path: "/pricing",
    component: Pricing,
    role: [0, USER_ROLE.TeamMember, USER_ROLE.Admin, USER_ROLE.Owner]
  },
  {
    name: "Create Account",
    layout: "/auth",
    path: "/sign-up",
    icon: <Icon as={MdStarOutline} width='20px' height='20px' color='inherit' />,
    component: SignUpCentered,
    role: [0, USER_ROLE.TeamMember, USER_ROLE.Admin, USER_ROLE.Owner]
  },
  {
    name: "Buy Assessment",
    layout: "/buy",
    path: "/",
    icon: <Icon as={FaChartLine} width='20px' height='20px' color='inherit' />,
    component: BuyAssessment,
    role: [0, USER_ROLE.TeamMember, USER_ROLE.Admin, USER_ROLE.Owner]
  },
  {
    name: "Register",
    layout: "/auth",
    path: "/confirm",
    icon: <Icon as={MdStarOutline} width='20px' height='20px' color='inherit' />,
    component: Register,
    hideFromMenu: true,
    role: [0, USER_ROLE.TeamMember, USER_ROLE.Admin, USER_ROLE.Owner]
  },
  {
    name: "Sign In",
    layout: "/auth",
    path: "/sign-in",
    icon: <Icon as={MdLock} width='20px' height='20px' color='inherit' />,
    component: SignInCentered,
    role: [0, USER_ROLE.TeamMember, USER_ROLE.Admin, USER_ROLE.Owner]
  },
  {
    name: "Forgot Password",
    layout: "/auth",
    path: "/forgot",
    icon: <Icon as={MdLock} width='20px' height='20px' color='inherit' />,
    component: RecoverPassword,
    hideFromMenu: true,
    role: [0, USER_ROLE.TeamMember, USER_ROLE.Admin, USER_ROLE.Owner]
  },
  {
    name: "Session",
    layout: "/auth",
    path: "/session",
    icon: <Icon as={MdLock} width='20px' height='20px' color='inherit' />,
    component: Session,
    hideFromMenu: true,
    role: [0, USER_ROLE.TeamMember, USER_ROLE.Admin, USER_ROLE.Owner]
  },
  {
    name: "Payment",
    layout: "/admin",
    path: "/payment/success",
    icon: <Icon as={MdSettings} width='20px' height='20px' color='inherit' />,
    component: PaymentSuccess,
    hideFromMenu: true,
    role: [0, USER_ROLE.TeamMember, USER_ROLE.Admin, USER_ROLE.Owner]
  }
];

export const authorizedRoutes = [
  {
    name: "Dashboard",
    layout: "/admin",
    path: "/default",
    icon: <img src={MenuDashboardIcon} alt="" style={{ height: "25px", width: '25px' }} />,
    component: MainDashboard,
    role: [0, USER_ROLE.TeamMember, USER_ROLE.Admin, USER_ROLE.Owner]
  }, 
  {
    name: "Assessment",
    layout: "/app",
    path: "/assessment",
    icon: <img src={MenuAssessmentIcon} alt="" style={{ height: "25px", width: '25px' }} />,
    component: Assessment,
    role: [USER_ROLE.Admin, USER_ROLE.TeamMember],
    hideFromMenu: false
  },
  {
    name: "Onboarding",
    layout: "/app",
    path: "/onboarding",
    icon: <Icon as={FaChartLine} width='20px' height='20px' color='inherit' />,
    component: Onboarding,
    role: [USER_ROLE.Admin, USER_ROLE.TeamMember],
    hideFromMenu: true
  },
  {
    name: "Employee IQ",
    layout: "/admin",
    path: "/employeeiq",
    icon: <Icon as={MdBadge} width='20px' height='20px' color='inherit' />,
    component: EmployeeIQ,
    hideFromMenu: true,
    role: [USER_ROLE.Admin]
  },
  {
    name: "Board IQ",
    layout: "/admin",
    path: "/boardiq",
    icon: <Icon as={MdGroup} width='20px' height='20px' color='inherit' />,
    component: BoardIQ,
    hideFromMenu: true,
    role: [USER_ROLE.Admin]
  },
  {
    name: "Org IQ",
    layout: "/admin",
    path: "/orgiq",
    icon: <Icon as={MdCorporateFare} width='20px' height='20px' color='inherit' />,
    component: OrgIQ,
    divider: true,
    hideFromMenu: true,
    role: [USER_ROLE.Admin]
  },
  {
    name: "Survey Admin",
    layout: "/admin",
    path: "/survey/types",
    icon: <Icon as={MdLock} width='20px' height='20px' color='inherit' />,
    component: SurveyTypes,
    divider: true,
    role: [USER_ROLE.Owner]
  },
  // {
  //   name: "Graphs",
  //   layout: "/admin",
  //   path: "/graphs",
  //   icon: <Icon as={MdPieChart} width='20px' height='20px' color='inherit' />,
  //   component: TeamMetrics,
  //   divider: true,
  //   role: [USER_ROLE.Admin]
  // },
  {
    name: "Results",
    layout: "/admin",
    path: "/assessment",
    icon: <img src={MenuAssessmentIcon} alt="" style={{ height: "25px", width: '25px' }} />,
    component: IndividualResults,
    role: [USER_ROLE.TeamMember, USER_ROLE.Admin, USER_ROLE.Owner]
  },
  {
    name: "Insights",
    layout: "/admin",
    path: "/insights",
    icon: <img src={MenuAssessmentIcon} alt="" style={{ height: "25px", width: '25px' }} />,
    component: Insights,
    role: [USER_ROLE.TeamMember, USER_ROLE.Admin, USER_ROLE.Owner]
  },
  {
    name: "Regular Report",
    layout: "/report",
    path: "/cover",
    icon: <img src={MenuAssessmentIcon} alt="" style={{ height: "25px", width: '25px' }} />,
    component: ReportCover,
    hideFromMenu: true,
    role: [USER_ROLE.TeamMember, USER_ROLE.Admin, USER_ROLE.Owner]
  },
  {
    name: "Regular Report Intro",
    layout: "/report",
    path: "/intro",
    icon: <img src={MenuAssessmentIcon} alt="" style={{ height: "25px", width: '25px' }} />,
    component: ReportIntro,
    hideFromMenu: true,
    role: [USER_ROLE.TeamMember, USER_ROLE.Admin, USER_ROLE.Owner]
  },
  {
    name: "Regular Report",
    layout: "/report",
    path: "/first",
    icon: <img src={MenuAssessmentIcon}  alt="" style={{ height: "25px", width: '25px' }} />,
    component: ReportFirstPage,
    hideFromMenu: true,
    role: [USER_ROLE.TeamMember, USER_ROLE.Admin, USER_ROLE.Owner]
  },
  {
    name: "Regular Report",
    layout: "/report",
    path: "/second",
    icon: <img src={MenuAssessmentIcon} alt="" style={{ height: "25px", width: '25px' }} />,
    component: ReportSecondPage,
    hideFromMenu: true,
    role: [USER_ROLE.TeamMember, USER_ROLE.Admin, USER_ROLE.Owner]
  },
  {
    name: "Regular Report",
    layout: "/report",
    path: "/third",
    icon: <img src={MenuAssessmentIcon} alt="" style={{ height: "25px", width: '25px' }} />,
    component: ReportThirdPage,
    hideFromMenu: true,
    role: [USER_ROLE.TeamMember, USER_ROLE.Admin, USER_ROLE.Owner]
  },
  {
    name: "Exj Report",
    layout: "/exj",
    path: "/cover",
    icon: <img src={MenuAssessmentIcon} alt="" style={{ height: "25px", width: '25px' }} />,
    component: ExjReportCover,
    hideFromMenu: true,
    role: [USER_ROLE.TeamMember, USER_ROLE.Admin, USER_ROLE.Owner]
  },
  {
    name: "Exj Report",
    layout: "/exj",
    path: "/first",
    icon: <img src={MenuAssessmentIcon} alt="" style={{ height: "25px", width: '25px' }} />,
    component: ExjReportFirstPage,
    hideFromMenu: true,
    role: [USER_ROLE.TeamMember, USER_ROLE.Admin, USER_ROLE.Owner]
  },
  {
    name: "Exj Report",
    layout: "/exj",
    path: "/second",
    icon: <img src={MenuAssessmentIcon} alt="" style={{ height: "25px", width: '25px' }} />,
    component: ExjReportSecondPage,
    hideFromMenu: true,
    role: [USER_ROLE.TeamMember, USER_ROLE.Admin, USER_ROLE.Owner]
  },
  {
    name: "Exj Report",
    layout: "/exj",
    path: "/third",
    icon: <img src={MenuAssessmentIcon} alt="" style={{ height: "25px", width: '25px' }} />,
    component: ExjReportThirdPage,
    hideFromMenu: true,
    role: [USER_ROLE.TeamMember, USER_ROLE.Admin, USER_ROLE.Owner]
  },
  {
    name: "Exj Report",
    layout: "/exj",
    path: "/fourth",
    icon: <img src={MenuAssessmentIcon} alt="" style={{ height: "25px", width: '25px' }} />,
    component: ExjReportFourthPage,
    hideFromMenu: true,
    role: [USER_ROLE.TeamMember, USER_ROLE.Admin, USER_ROLE.Owner]
  },
  {
    name: "Exj Report",
    layout: "/exj",
    path: "/fifth",
    icon: <img src={MenuAssessmentIcon} alt="" style={{ height: "25px", width: '25px' }} />,
    component: ExjReportFifthPage,
    hideFromMenu: true,
    role: [USER_ROLE.TeamMember, USER_ROLE.Admin, USER_ROLE.Owner]
  },
  {
    name: "Exj Report",
    layout: "/exj",
    path: "/sixth",
    icon: <img src={MenuAssessmentIcon} alt="" style={{ height: "25px", width: '25px' }} />,
    component: ExjReportSixthPage,
    hideFromMenu: true,
    role: [USER_ROLE.TeamMember, USER_ROLE.Admin, USER_ROLE.Owner]
  },
  {
    name: "Exj Report",
    layout: "/exj",
    path: "/seventh",
    icon: <img src={MenuAssessmentIcon} alt="" style={{ height: "25px", width: '25px' }} />,
    component: ExjReportSeventhPage,
    hideFromMenu: true,
    role: [USER_ROLE.TeamMember, USER_ROLE.Admin, USER_ROLE.Owner]
  },
  {
    name: "Exj Report",
    layout: "/exj",
    path: "/eighth",
    icon: <img src={MenuAssessmentIcon} alt="" style={{ height: "25px", width: '25px' }} />,
    component: ExjReportEighthPage,
    hideFromMenu: true,
    role: [USER_ROLE.TeamMember, USER_ROLE.Admin, USER_ROLE.Owner]
  },
  // {
  //   name: "Comparisons",
  //   layout: "/admin",
  //   path: "/member/comparison",
  //   icon: <Icon as={MdSearch} width='20px' height='20px' color='inherit' />,
  //   component: IndividualComparison,
  //   role: [USER_ROLE.TeamMember]
  // },
  {
    name: "Profile",
    layout: "/admin",
    path: "/profile",
    icon: <Icon as={MdSettings} width='20px' height='20px' color='inherit' />,
    component: Profile,
    hideFromMenu: true,
    role: [0, USER_ROLE.TeamMember, USER_ROLE.Admin, USER_ROLE.Owner]
  },
  {
    name: "Settings",
    layout: "/admin",
    path: "/settings",
    icon: <Icon as={MdSettings} width='20px' height='20px' color='inherit' />,
    component: Settings,
    hideFromMenu: true,
    role: [0, USER_ROLE.TeamMember, USER_ROLE.Admin, USER_ROLE.Owner]
  },
  {
    name: "Password",
    layout: "/admin",
    path: "/password",
    icon: <Icon as={MdSettings} width='20px' height='20px' color='inherit' />,
    component: Password,
    hideFromMenu: true,
    role: [0, USER_ROLE.TeamMember, USER_ROLE.Admin, USER_ROLE.Owner]
  },
  {
    name: "Profile",
    layout: "/admin",
    path: "/subscription",
    icon: <Icon as={MdSettings} width='20px' height='20px' color='inherit' />,
    component: UpgradePlan,
    hideFromMenu: true,
    role: [0, USER_ROLE.TeamMember, USER_ROLE.Admin, USER_ROLE.Owner]
  },
  {
    name: "Dashboard",
    layout: "/admin",
    path: "/payment/success",
    icon: <Icon as={MdSettings} width='20px' height='20px' color='inherit' />,
    component: PaymentSuccess,
    hideFromMenu: true,
    role: [0, USER_ROLE.TeamMember, USER_ROLE.Admin, USER_ROLE.Owner]
  },
  {
    name: "Definitions",
    layout: "/admin",
    path: "/definitions",
    icon: <img src={MenuDefinitions} alt="" style={{ height: "25px", width: '25px' }} />,
    component: Definitions,
    role: [0, USER_ROLE.TeamMember, USER_ROLE.Admin, USER_ROLE.Owner]
  },
  {
    name: "Sign Out",
    layout: "/admin",
    path: "/sign-out",
    icon: <img src={MenuSignOut} alt="" style={{ height: "25px", width: '25px' }} />,
    component: SignOutCentered,
    role: [0, USER_ROLE.TeamMember, USER_ROLE.Admin, USER_ROLE.Owner]
  }
];