/* eslint-disable */
import  { 
  Box,
  Button,
  Table,
  TableContainer, 
  TableCaption, Thead, Tr, Th,
  Td, Tbody, Checkbox, Flex
} from "@chakra-ui/react";
import _ from "lodash";

// Custom components
import React, { useEffect } from "react";

export default function AddParticipant({ 
    onClose, 
    onSave, 
    teamMembers, 
    participants, 
    onClickAddParticipant
  }) {
  
    const [stateParticipants, setStateParticipants] = React.useState(participants);

    const onSubmit = (e) => {
      e.preventDefault();

      onSave();
    }

    const isParticipanting = (userId) => {
      return stateParticipants.indexOf(userId) >= 0;
    }

    useEffect(() => {
      setStateParticipants(participants);
    }, [participants])
  
    return (
        <Box>
            <form onSubmit={onSubmit}>
            <TableContainer mt={10} maxHeight="200px" overflowY={true}>
                <Table variant='striped'>
                  <Thead>
                    <Tr>
                      <Th>Participanting?</Th>
                      <Th>Name</Th>
                      <Th>E-mail</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                  {teamMembers ? teamMembers.map((u, i) => {
                      return (
                        <Tr>
                          <Td align="center">
                            <Checkbox 
                              isChecked={isParticipanting(u.id)}
                              onChange={(e) => onClickAddParticipant(e.target.checked, u.id)}
                              />
                            </Td>
                          <Td>{u.firstName} {u.lastName}</Td>
                          <Td>{u.email}</Td>
                        </Tr>
                      )}) : null}
                  </Tbody>
                </Table>
              </TableContainer>
              <Box>
                <TableCaption>(
                  {teamMembers.length}) Members / ({stateParticipants.length}) Participants
                  </TableCaption>
              </Box>
              <Flex>
                <Box w='100%' style={ { textAlign: 'right' } } mt={5}>
                  <Button colorScheme='blue' type="submit" mr={3}>
                    Save
                  </Button>
                  <Button onClick={onClose}>Close</Button>
                </Box>
              </Flex>
            </form>
        </Box>
    )
  }