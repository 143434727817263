import React from "react";
import ReactDOM from "react-dom";
import "assets/css/App.css";
import { HashRouter, Route, Switch, Redirect } from "react-router-dom";
import store from './app/store';
import AuthLayout from "layouts/auth";
import AdminLayout from "layouts/admin";
import BuyLayout from "layouts/buy";
import AppLayout from "layouts/app";
import ReportLayout from "layouts/report";
import ExjLayout from "layouts/exj";
import { ChakraProvider } from "@chakra-ui/react";
import { Provider } from 'react-redux';
import theme from "theme/theme";
import * as Sentry from "@sentry/react";


Sentry.init({
  dsn: "https://d53469718a38e48d039b88f2f7fbc9d5@o4506791340277760.ingest.sentry.io/4506791344275456",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

ReactDOM.render(
  <ChakraProvider theme={theme}>
    <React.StrictMode>
      <Provider store={store}>
        <HashRouter>
          <Switch>
            <Route path={`/auth`} component={AuthLayout} />
            <Route path={`/admin`} component={AdminLayout} />
            <Route path={`/buy`} component={BuyLayout} />
            <Route path={`/app`} component={AppLayout} />
            <Route path={'/report'} component={ReportLayout} />
            <Route path={'/exj'} component={ExjLayout} />
            <Redirect from='/' to='/admin/default' />
          </Switch>
        </HashRouter>
      </Provider>
    </React.StrictMode>
  </ChakraProvider>,
  document.getElementById("root")
);

