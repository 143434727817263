// Custom components
import React from "react";
import { Heading, 
    Tabs, TabList, TabPanels, Tab, 
    TabPanel } from "@chakra-ui/react";
import Moment from 'moment';
import EvaluationParticipants from "./EvaluationParticipants";

export default function EvaluationBody(props) {

    const { 
        isAddParticipantModalOpen,
        isCreateMemberOpen,
        teamMembers,
        evaluations,
        evaluationParticipants,
        currentEvaluation,
        currentEvaluationId,
        survey, 
        handleAddTeamMembers, 
        handleCloseParticipantsModal,
        handleSaveParticipants,
        onClickAddParticipant,
        handleClickCreateTeamMember,
        handleCloseCreateMember,
        handleCreateNewTeamMember,
        tabIndex,
        handleTabsChange
    } = props;

    return (
        <div>
            <p style={ { marginTop: 10, marginLeft: 5 } }>
                <Heading color='tomato' size="md">{survey !== null && currentEvaluationId !== '' ? survey.title : '-'}</Heading>
            </p>
            <div style={ { marginTop: 10, marginLeft: 5 }  }>
                <p style={ { marginTop: 5 } }>{currentEvaluation ? `Create Date: ${Moment(currentEvaluation.createDate).format('MMM Do YYYY HH:mm')}` : null}</p>
                <p>Number of Participants: {evaluationParticipants.length}</p>
            </div>
            <Tabs mt={3} align='end' variant='enclosed' index={tabIndex} onChange={handleTabsChange}>
                <TabList>
                    <Tab>Participants</Tab>
                </TabList>
                <TabPanels align='start'>
                    <TabPanel>
                        <EvaluationParticipants 
                            isAddParticipantModalOpen={isAddParticipantModalOpen}
                            handleAddTeamMembers={handleAddTeamMembers}
                            handleCloseParticipantsModal={handleCloseParticipantsModal}
                            handleSaveParticipants={handleSaveParticipants}
                            teamMembers={teamMembers}
                            evaluationParticipants={evaluationParticipants}
                            handleClickCreateTeamMember={handleClickCreateTeamMember}
                            onClickAddParticipant={onClickAddParticipant}
                            isCreateMemberOpen={isCreateMemberOpen}
                            handleCloseCreateMember={handleCloseCreateMember}
                            handleCreateNewTeamMember={handleCreateNewTeamMember}
                            evaluations={evaluations}
                            currentEvaluationId={currentEvaluationId}
                        />
                    </TabPanel>
                </TabPanels>
                </Tabs>
      </div>
      )
}