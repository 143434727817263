/* eslint-disable */
/*!
  _   _  ___  ____  ___ ________  _   _   _   _ ___   
 | | | |/ _ \|  _ \|_ _|__  / _ \| \ | | | | | |_ _| 
 | |_| | | | | |_) || |  / / | | |  \| | | | | || | 
 |  _  | |_| |  _ < | | / /| |_| | |\  | | |_| || |
 |_| |_|\___/|_| \_\___/____\___/|_| \_|  \___/|___|
                                                                                                                                                                                                                                                                                                                                       
=========================================================
* Horizon UI - v1.1.0
=========================================================

* Product Page: https://www.horizon-ui.com/
* Copyright 2022 Horizon UI (https://www.horizon-ui.com/)

* Designed and Coded by Simmmple

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

import React, { useEffect } from "react";
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useHistory } from "react-router-dom";
// Custom components
import DefaultAuth from "layouts/auth/Default";
// Assets
import illustration from "assets/img/logo/logo.png";
// Chakra imports
import {
  Box,
  Flex
} from "@chakra-ui/react";
import { logoutAsync } from "features/authentication/slice";

function SignOut(props) {

  const { onSignOut, isAuthenticated, organizationLogo } = props;

  const logoImage = organizationLogo !== null && organizationLogo !== '' ? organizationLogo : illustration;

  const history = useHistory();

  const onSignOutSuccess = () => {
    history.push('/auth/sign-in');
  }
  useEffect(() => {
    if (isAuthenticated === false) {
      onSignOutSuccess()
    } else {
      onSignOut(onSignOutSuccess);
    }
  }, [])

  return (
   <DefaultAuth illustrationBackground={logoImage} image={logoImage}>
      <Flex
        maxW={{ base: "100%", md: "max-content" }}
        w='100%'
        mx={{ base: "auto", lg: "0px" }}
        me='auto'
        h='100%'
        alignItems='start'
        justifyContent='center'
        mb={{ base: "30px", md: "60px" }}
        px={{ base: "25px", md: "0px" }}
        mt={{ base: "40px", md: "14vh" }}
        flexDirection='column'>
          <Box me='auto'>
          </Box>
        </Flex>
    </DefaultAuth>
  );
}

SignOut.propTypes = {
  isAuthenticated: PropTypes.bool,
  onSignOut: PropTypes.func,
  organizationLogo: PropTypes.string
};

SignOut.defaultProps = {
  isAuthenticated: false,
  organizationLogo: null,
  onSignOut: () => {}
}

function mapStateToProps(state) {
  const { authentication } = state;

  const { isAuthenticated, organizationLogo } = authentication;
  
  return {
    isAuthenticated,
    organizationLogo
   };
};

function mapDispatchToProps (dispatch) {
  return {
      onSignOut: (onSignOutSuccess) => dispatch(logoutAsync(true, onSignOutSuccess)),
      dispatch
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SignOut);