import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Box, Grid, GridItem, Heading, Text, Tooltip } from '@chakra-ui/react';
import { SURVEY_TYPE } from 'constants/evaluation.const';
import ReactApexChart from 'react-apexcharts';
import Card from 'components/card/Card';
import { InfoIcon } from '@chakra-ui/icons';

function Trends(props){

    const { 
        trendsBySizeData,
        trendsByTimeInBusinessData,
        trendsByIndustryData,
        trendsBySpreadData,
        trendsByTypeData,
        trendsByHeadCountsData,
        myOrganizationTrendsBySizeData,
        myOrganizationTrendsByTimeInBusinessData,
        myOrganizationTrendsBySpreadData,
        myOrganizationTrendsByTypeData,
        myOrganizationTrendsByHeadCountsData,
        onGetTrends 
    } = props;

    useEffect(() => {
       onGetTrends(SURVEY_TYPE.EmployeeIq);
    }, []);

    const TrendChart = ({ title, data, myData, category }) => { 
        // Extracting unique names from the data array
        const uniqueNames = [...new Set(data.map(item => item.name))];

        // Extracting overOptimal, optimal, and subOptimal data for data
        const dataOverOptimal = data.map(item => parseFloat(item.overOptimal));
        const dataOptimal = data.map(item => parseFloat(item.optimal));
        const dataSubOptimal = data.map(item => parseFloat(item.subOptimal));

        const options = {
            title: {
                align: 'center'
            },
            chart: {
                type: 'line',
                stacked: false,
                height: 350,
                toolbar: {
                    show: false
                }
            },
            stroke: {
                width: [2, 2, 2],
                curve: 'smooth'
            },
            colors: ['#8884d8', '#82ca9d', '#ff0000'],
            fill: {
                opacity: [0.85, 0.85, 0.85],
                gradient: {
                    inverseColors: false,
                    shade: 'light',
                    type: 'vertical',
                    opacityFrom: 0.85,
                    opacityTo: 0.55,
                    stops: [0, 100, 100, 100]
                }
            },
            dataLabels: {
                enabled: false
            },
            labels: uniqueNames,
            markers: {
                size: 0
            },
            xaxis: {
                type: 'category',
                categories: uniqueNames
            },
            yaxis: [
                {
                    title: {
                        text: 'Values',
                    },
                    labels: {
                        formatter: function (val) {
                            return val.toFixed(2);
                        }
                    },
                    min: 0,
                    max: 10
                }
            ],
            tooltip: {
                shared: true,
                intersect: false,
                y: {
                    formatter: function (y) {
                        if (typeof y !== "undefined") {
                            return y.toFixed(2);
                        }
                        return y;
                    }
                }
            }
        };

        const series = [
            {
                name: 'Over Optimal',
                type: 'line',
                data: dataOverOptimal
            },
            {
                name: 'Optimal',
                type: 'line',
                data: dataOptimal
            },
            {
                name: 'Sub Optimal',
                type: 'line',
                data: dataSubOptimal
            }
        ];
        return (
            <div id="chart">
                <Box>
                    <Box>
                        <Card>
                            <Box pos="relative" display={"flex"}>
                                <Heading size={"sm"}>{title}</Heading>
                                <Box pos="absolute" right={0}>
                                    <Tooltip label={`Your organization's ${category} is '${myData && myData.length > 0 ? myData[0].name : null}'. These are the averages of your organization.`}>
                                        <InfoIcon />
                                    </Tooltip>
                                </Box>
                            </Box>
                            <Box>
                                <Text style={{ marginTop: '10px'}}>Category: {myData && myData.length > 0 ? myData[0].name : null}</Text>
                            </Box>
                            <Grid mt="10px" templateColumns={"1fr 1fr 1fr"} columnGap={"10px"} textAlign={"center"}>
                                <GridItem>
                                    <Box>
                                        <Card bgColor="#D9D9D9" minH="115px">
                                            <Text style={{ marginBottom: "3px"}} fontSize="12px">
                                                sub optimal
                                            </Text>
                                            <div style={{ borderBottom: '1px solid #000', marginLeft: "20%", marginRight: "20%"}}> </div>
                                            <Text style={{ marginTop: '10px'}}>{myData && myData.length > 0 ? myData[0].subOptimal : null}</Text>
                                        </Card>
                                    </Box>
                                </GridItem>
                                <GridItem>
                                    <Box>
                                        <Card bgColor="#D9D9D9" minH="115px">
                                            <Text style={{ marginBottom: "3px"}} fontSize="12px">
                                                optimal
                                            </Text>
                                            <div style={{ borderBottom: '1px solid #000', marginLeft: "20%", marginRight: "20%"}}> </div>
                                            <Text style={{ marginTop: '10px'}}>{myData && myData.length > 0 ? myData[0].optimal : null}</Text>
                                        </Card>
                                    </Box>
                                </GridItem>
                                <GridItem>
                                    <Card bgColor="#D9D9D9" minH="115px">
                                        <Text style={{ marginBottom: "3px"}} fontSize="12px">
                                            over optimal
                                        </Text>
                                        <div style={{ borderBottom: '1px solid #000', marginLeft: "20%", marginRight: "20%"}}> </div>
                                        <Text style={{ marginTop: '10px'}}>{myData && myData.length > 0 ? myData[0].overOptimal : null}</Text>
                                    </Card>
                                </GridItem>
                            </Grid>
                        </Card>
                    </Box>
                </Box>
                <Box mt="10px" pos={"relative"}> 
                    <Box>
                        <ReactApexChart options={options} series={series} type="line" height={350} />
                    </Box>
                    <Tooltip label={`These are the average of all other organizations in our database grouped by ${category}. Look at the '${myData && myData.length > 0 ? myData[0].name : null}' values of other organizations to compare how your organization compare to them.`}>
                        <InfoIcon mr={"18px"} top="25px" left="5px" pos={"absolute"} zIndex={"999"} />
                    </Tooltip>
                </Box>
            </div>
        );
    }
    return <Box minH="1000px">
            <Box>
                <Grid templateColumns={"1fr 1fr 1fr"} columnGap={"35px"} rowGap={"35px"}>
                    <GridItem>
                       <TrendChart title="Average by Size" category="Size" data={trendsBySizeData} myData={myOrganizationTrendsBySizeData} />
                    </GridItem>
                    <GridItem>
                       <TrendChart title="Average by Time in Business" category="Time in Business" data={trendsByTimeInBusinessData} myData={myOrganizationTrendsByTimeInBusinessData}/>
                    </GridItem>
                    <GridItem>
                       <TrendChart title="Average by Industry" category="Industry" data={trendsByIndustryData} myData={myOrganizationTrendsBySizeData} />
                    </GridItem>
                    <GridItem>
                       <TrendChart title="Average by Spread" category="Spread" data={trendsBySpreadData} myData={myOrganizationTrendsBySpreadData} />
                    </GridItem>
                    <GridItem>
                       <TrendChart title="Average by Type" category="Type" data={trendsByTypeData} myData={myOrganizationTrendsByTypeData} />
                    </GridItem>
                    <GridItem>
                       <TrendChart title="Average by Head Count" category="Head Count" data={trendsByHeadCountsData} myData={myOrganizationTrendsByHeadCountsData} />
                    </GridItem>
                </Grid>
            </Box>
        </Box>
}


Trends.propTypes = {
    title: PropTypes.string,
    description: PropTypes.string,
    trends: PropTypes.array,
    trendsByTimeInBusinessData: PropTypes.array,
    loading: PropTypes.bool,
    onGetTrends: PropTypes.func
};

Trends.defaultProps = {
    title: "Trends",
    description: "",
    trends: [],
    trendsBySizeData: [],
    trendsByTimeInBusinessData: [],
    trendsByIndustryData: [],
    trendsBySpreadData: [],
    trendsByTypeData: [],
    trendsByHeadCountsData: [],
    myOrganizationTrendsBySizeData: [],
    myOrganizationTrendsByTimeInBusinessData: [],
    myOrganizationTrendsByIndustryData: [],
    myOrganizationTrendsBySpreadData: [],
    myOrganizationTrendsByTypeData: [],
    myOrganizationTrendsByHeadCountsData: [],
    loading: false,
    onGetTrends: () => { }
}

export default Trends;