import { Box, Tabs, TabList, Tab } from "@chakra-ui/react";
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import React, { useCallback, useEffect } from "react";
import { getSurveyTypes } from "features/surveys/slice";
import { useState } from "react";
import Question from './components/Question';
import { setQuestion, deleteQuestion } from 'features/surveys/slice';
import { onSaveSurveyChanges } from 'features/surveys/slice';
import Authenticated from '../authenticated';
import { useHistory } from 'react-router-dom';

function SurveyTypes(props) {
  const { onLoadSurveyTypes, onQuestionChange, onRemoveQuestion, surveyTypes, onSaveChanges, role } = props;

  const [tabIndex, setTabIndex] = useState(0);
  const history = useHistory();

  const validateAccess = useCallback(() => {
    if(role !== null && role !== 3){
      history.push('/admin/default');
    }
  }, [role, history]);

  useEffect(() => {
    validateAccess();
    onLoadSurveyTypes(() => {
      setTabIndex(0);
      validateAccess();
    });
  }, []);

  const handleQuestionChange = (surveyId, questionId, title, description, order, availableForFreePlan, behaviorDriver) => {
    onQuestionChange(surveyId, questionId, title, description, order, availableForFreePlan, behaviorDriver);
    onSaveChanges(surveyId, () => {
      setTabIndex(tabIndex);
    }, () => {});
  }


  const handleQuestionDeletion = (surveyId, questionId) => {
    onRemoveQuestion(surveyId, questionId);
    onSaveChanges(surveyId, () => { 
      setTabIndex(tabIndex);
    }, () => {});
  }

  return (
    <Authenticated>
      <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
            <Tabs onChange={(index) => setTabIndex(index)}>
                {surveyTypes ? <TabList>
                    {surveyTypes.map((e, i) => {
                      return (
                          <Tab key={`tab_${i}`}>{e.title}</Tab>
                      )
                    })}
                </TabList> : null}
                <Box  style={ { backgroudColor: '#fff' } }>
                  {surveyTypes.length > 0 && surveyTypes[tabIndex] !== undefined ?
                        surveyTypes[tabIndex].questions.map((question, i) => {
                          return (<Box ml={2} mt={3} key={`s_${tabIndex}q_${i}`}>
                            <Question 
                              title={question.title} 
                              onChangeQuestion={(title, description, order, availableForFreePlan, behaviorDriver) => { 
                                handleQuestionChange(surveyTypes[tabIndex].id, question.id, title, description, order, availableForFreePlan, behaviorDriver);
                              }} 
                              order={question.order}
                              trait={question.trait}
                              availableForFreePlan={question.availableForFreePlan}
                              onRemoveQuestion={() => handleQuestionDeletion(surveyTypes[tabIndex].id, question.id)}
                              behaviorDriver={question.behaviorDriver}
                              description={question.description}
                              />
                          </Box>)}) : null}
                </Box>
              </Tabs>
              <Box>
              </Box>
          </Box>
    </Authenticated>
  );
}


SurveyTypes.propTypes = {
  surveyTypes: PropTypes.array,
  role: PropTypes.number,
  onLoadSurveyTypes: PropTypes.func,
  onQuestionChange: PropTypes.func,
  onSaveChanges: PropTypes.func,
  onRemoveQuestion: PropTypes.func
};

SurveyTypes.defaultProps = {
  subscriptions: [],
  role: null,
  onLoadSurveyTypes: () => {},
  onQuestionChange: () => {},
  onRemoveQuestion: () => {},
  surveyTypes: [],
  onSaveChanges: () => {} 
}

function mapStateToProps(state) {
  const { surveyTypes } = state.surveys;

  const { role } = state.user;

  return {
    surveyTypes,
    role
   };
};

function mapDispatchToProps (dispatch) {
  return {
      onLoadSurveyTypes: (onSuccess) => dispatch(getSurveyTypes(onSuccess, () => {})),
      onQuestionChange: (surveyId, questionId, title, description, order, availableForFreePlan, behaviorDriver) => dispatch(setQuestion({ surveyId, questionId, title, description, order, availableForFreePlan, behaviorDriver })),
      onRemoveQuestion: (surveyId, questionId) => dispatch(deleteQuestion({ surveyId, questionId })),
      onSaveChanges: (surveyId, onSuccess, onError) => dispatch(onSaveSurveyChanges({ surveyId, onSuccess, onError })),
      dispatch
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SurveyTypes);

