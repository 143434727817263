// Chakra imports
import {
    Box
  } from "@chakra-ui/react";
import Authenticated from "views/admin/authenticated";

import './index.css';

function First(props) {
    
    return (
      <Authenticated>
        <Box>
          Page 1
        </Box>
      </Authenticated>
    );
  }
  
  export default First;