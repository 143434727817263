/* eslint-disable */
/*!
  _   _  ___  ____  ___ ________  _   _   _   _ ___   
 | | | |/ _ \|  _ \|_ _|__  / _ \| \ | | | | | |_ _| 
 | |_| | | | | |_) || |  / / | | |  \| | | | | || | 
 |  _  | |_| |  _ < | | / /| |_| | |\  | | |_| || |
 |_| |_|\___/|_| \_\___/____\___/|_| \_|  \___/|___|
                                                                                                                                                                                                                                                                                                                                       
=========================================================
* Horizon UI - v1.1.0
=========================================================

* Product Page: https://www.horizon-ui.com/
* Copyright 2022 Horizon UI (https://www.horizon-ui.com/)

* Designed and Coded by Simmmple

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

import React, { useEffect } from "react";
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { NavLink } from "react-router-dom";
// Chakra imports
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  Heading,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  Select,
  Switch,
  Text,
  useColorModeValue,
  useToast,
} from "@chakra-ui/react";
// Custom components
import DefaultAuth from "layouts/auth/Default";
// Assets
import illustration from "assets/img/logo/logo.png";
import { MdOutlineRemoveRedEye } from "react-icons/md";
import { RiEyeCloseLine } from "react-icons/ri";
import { signUp } from "features/authentication/slice";
import { loadSubscriptions } from "features/subscriptions/slice";
import _ from "lodash";
import { createPaymentSession } from "features/payments/slice";
import { SUBSCRIPTION_TYPE } from "constants/subscriptionTypes.const";
import { COLORS } from "constants/colors";
import Card from "components/card/Card";

function SignUp(props) {
  
  const { onSignUp, onCreatePaymentSession, onLoadSubscriptions, subscriptions, location } = props;

  // Chakra color mode
  const textColor = useColorModeValue("navy.700", "white");
  const textColorSecondary = "gray.400";
  const textColorDetails = useColorModeValue("navy.700", "secondaryGray.600");
  const [show, setShow] = React.useState(false);
  const handleClick = () => setShow(!show);

  const [firstName, setFirstName] = React.useState('');
  const [lastName, setLastName] = React.useState('');
  const [email, setEmail] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [organization, setOrganization] = React.useState('');
  const [domain, setDomain] = React.useState('');
  const [subscriptionType, setSubscriptionType] = React.useState(1);
  const [billedYearly, setBilledYearly] = React.useState(false);

  const selectedSubscription = subscriptions && subscriptions !== null 
    ? _.find(subscriptions, function(i) { return i.type === subscriptionType }) :
     null;

  const toast = useToast();

  const SUBSCRIPTION_FREQUENCY = {
    yearly: 2,
    monthly: 1
  }

  useEffect(() => {

    const query = new URLSearchParams(location.search);
    const subscriptionType = query.get('subscriptionType');

    onLoadSubscriptions(() => {
      if(subscriptionType && subscriptionType !== null){
        setSubscriptionType(parseInt(subscriptionType));
      }
    }, () => {

    });
  }, []);

  const handleClickCreateAccount = (e) => {
    e.preventDefault();

    const subscriptionFrequency =  billedYearly === true ? SUBSCRIPTION_FREQUENCY.yearly : SUBSCRIPTION_FREQUENCY.monthly;

    const onSignUnSucceed = (redirectUrl) => {

      if(subscriptionType !== SUBSCRIPTION_TYPE.Enterprise) {
        onCreatePaymentSession(subscriptionType, subscriptionFrequency, (url) => {
          window.location.href = url;
          toast({
            title: 'Registration Succeed',
            description: "Welcome to Introgauge!",
            status: 'success',
            duration: 9000,
            isClosable: true
          });
        }, () => {
            toast({
              title: 'Payment error',
              description: "Unable to create the payment session.",
              status: 'error',
              duration: 9000,
              isClosable: true
            })
        });
      } else {
        window.location.replace(redirectUrl);
      }
    }

    const onSignUpError = (msg) => {
      toast({
        title: 'Sign Up Failed',
        description: msg,
        status: 'error',
        duration: 9000,
        isClosable: true
      })
    }
    if(selectedSubscription && selectedSubscription !== null){
      onSignUp(firstName, lastName, email, password, organization, subscriptionType, subscriptionFrequency, domain,
        onSignUnSucceed, onSignUpError);
    } else { 
      toast({
        title: 'Unable to Sign up',
        description: "Unable to determine the Subscription Type. Please refresh the page or try again later.",
        status: 'error',
        duration: 9000,
        isClosable: true
      })
    }
  }

  return (
   <DefaultAuth illustrationBackground={illustration} image={illustration}>
    <Card width={{ sm: "100%", md: "100%", lg: "70%", xl: "70%" }} marginLeft="auto" marginRight="auto" boxShadow="rgba(0, 0, 0, 0.16) 0px 1px 4px;" mb="80px">
        <Box pr="30px" pl="30px">
          <Box me='auto'
            mt={{ base: "0px", xl: "30px", md: "60px" }}
          >
            <Heading color={textColor} fontSize='36px' mb='10px'>
              Sign up
            </Heading>
            <Text
              mb='36px'
              ms='4px'
              color={textColorSecondary}
              fontWeight='400'
              fontSize='md'>
                  Complete the details to sign up!
            </Text>
          </Box>
          <Box >
            <form onSubmit={handleClickCreateAccount}>
              <FormControl>
                <Flex>
                  <Box mr='24px' width='100%'>
                    <Input
                      isRequired={true}
                      variant='auth'
                      fontSize='sm'
                      ms={{ base: "0px", md: "0px" }}
                      type='text'
                      placeholder='First Name'
                      mb='24px'
                      mr='30px'
                      fontWeight='500'
                      size='lg'
                      value={firstName}
                      onChange={(e) => setFirstName(e.target.value)}
                    />
                  </Box>
                  <Box width='100%'>
                    <Input 
                      isRequired={true}
                      variant='auth'
                      fontSize='sm'
                      ms={{ base: "0px", md: "0px" }}
                      type='text'
                      placeholder='Last Name'
                      mb='24px'
                      fontWeight='500'
                      size='lg'
                      value={lastName}
                      onChange={(e) => setLastName(e.target.value)}
                    />
                  </Box>
                </Flex>
                
                <Flex>
                  <Box width='100%' mr='24px'>
                    <Input
                      isRequired={true}
                      variant='auth'
                      fontSize='sm'
                      ms={{ base: "0px", md: "0px" }}
                      type='email'
                      placeholder='E-mail address'
                      mb='24px'
                      fontWeight='500'
                      size='lg'
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </Box>
                  <Box width='100%'>
                    <InputGroup size='md'>
                      <Input
                        isRequired={true}
                        fontSize='sm'
                        placeholder='Password'
                        mb='24px'
                        size='lg'
                        type={show ? "text" : "password"}
                        variant='auth'
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                      />
                      <InputRightElement display='flex' alignItems='center' mt='4px'>
                        <Icon
                          color={textColorSecondary}
                          _hover={{ cursor: "pointer" }}
                          as={show ? RiEyeCloseLine : MdOutlineRemoveRedEye}
                          onClick={handleClick}
                        />
                      </InputRightElement>
                    </InputGroup>
                  </Box>
                </Flex>
                <InputGroup size='md'>
                  <Input
                    isRequired={true}
                    fontSize='sm'
                    placeholder='Organization'
                    mb='24px'
                    size='lg'
                    type={"text"}
                    variant='auth'
                    value={organization}
                    onChange={(e) => setOrganization(e.target.value)}
                  />
                </InputGroup>
                {subscriptionType === 3 ? <FormControl mb={5}>
                  <Flex> 
                    <Input
                      isRequired={true}
                      fontSize='sm'
                      placeholder='Domain'
                      mb='24px'
                      size='lg'
                      type={"text"}
                      variant='auth'
                      width="80%"
                      value={domain}
                      onChange={(e) => setDomain(e.target.value)}
                    />
                    <Box mt={3} style={ { fontSize: 20 }}>
                      .introgauge.io
                    </Box>
                  </Flex>
                </FormControl> : null}
                <Grid templateColumns={{ md: "1fr 1fr" }}>
                  <GridItem>
                    <Box position="relative">
                      <label
                        style={{
                          position: 'absolute',
                          top: 0,
                          left: 2,
                          transform: 'translateY(-50%)',
                          background: 'white',
                          padding: '0 4px',
                          color: COLORS.Primary,
                          zIndex: 1,
                        }}
                      >
                        Subscription
                      </label>
                      <Select value={subscriptionType} height="50px" onChange={(e) => setSubscriptionType(parseInt(e.target.value))}>
                        {subscriptions && subscriptions !== null ? subscriptions.map((s, i) => {
                          return  s.type !== 0 ? <option value={s.type}>{s.name}</option> : null
                        }) : null}
                      </Select>
                    </Box>
                  </GridItem>
                  <GridItem pl={{ sm: "0px", md: "20px", lg: "20px", xl: "20px" }}>
                    <FormControl display='flex' alignItems='center' justifyContent="center" width="100%">
                      <FormLabel htmlFor='billing-type' mb='0' sx={ { fontWeight: billedYearly === false ? 'bold' : null } }>
                        Billed Monthly
                      </FormLabel>
                      <Switch id='billing-type' colorScheme="green" checked={billedYearly} onChange={() => setBilledYearly(!billedYearly)} />
                      <FormLabel ml={2} mt={2} sx={ { fontWeight: billedYearly === true ? 'bold' : null } }>
                        Billed yearly
                      </FormLabel>
                    </FormControl>
                  </GridItem>
                </Grid>
                <Box width={"100%"} mt="30px">
                  <Box width="100%" sx={ { textAlign: 'center' } }>
                      {selectedSubscription && selectedSubscription !== null 
                        ? <span>${billedYearly === true ? selectedSubscription.priceYear.toFixed(2) : selectedSubscription.price.toFixed(2)} / {billedYearly === true ? "Year" : "Month"}</span>
                        : null}
                  </Box>
                </Box>
                <Box w="100%" textAlign={"center"}>
                  <Button
                    fontSize='sm'
                    style={ { backgroundColor: COLORS.Primary, color: COLORS.PrimaryText } }
                    fontWeight='500'
                    w='160px'
                    h='50'
                    type="submit"
                    mt="30px"
                    mb='24px'>
                    Create Account
                  </Button>
                </Box>
              </FormControl>
            </form>
            <Box width={"100%"} textAlign={"center"} mb="20px">
              <Text color={textColorDetails} fontWeight='400' fontSize='14px'>
                Already have an account?
                <NavLink to='/auth/sign-in'>
                  <Text
                    color={COLORS.Primary}
                    as='span'
                    ms='5px'
                    fontWeight='500'>
                    Sign in
                  </Text>
                </NavLink>
              </Text>
            </Box>
          </Box>
        </Box>
      </Card>
    </DefaultAuth>
  );
}


SignUp.propTypes = {
  isAuthenticated: PropTypes.bool,
  subscriptions: PropTypes.array,
  onSignUp: PropTypes.func,
  onLoadSubscriptions: PropTypes.func
};

SignUp.defaultProps = {
  isAuthenticated: false,
  subscriptions: [],
  onSignUp: () => {},
  onLoadSubscriptions: PropTypes.func
}

function mapStateToProps(state) {
  const { authentication } = state;

  const { isAuthenticated } = authentication;

  const { subscriptions } = state.subscriptions;
  
  return {
    isAuthenticated,
    subscriptions
   };
};

function mapDispatchToProps (dispatch) {
  return {
      onSignUp: (firstName, lastName, email, password, organization, subscriptionType, subscriptionFrequency, domain, onSignUpSucceed, onSignUpError) => dispatch(signUp({ firstName, lastName, email, password, organization, subscriptionType, subscriptionFrequency, domain, onSignUpSucceed, onSignUpError })),
      onLoadSubscriptions: (onSuccess, onError) => dispatch(loadSubscriptions({ onSuccess, onError })),
      onCreatePaymentSession: (subscriptionType, subscriptionFrequency, onSuccess, onError) => dispatch(createPaymentSession({ subscriptionType, subscriptionFrequency, onSuccess, onError })),
      dispatch,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SignUp);