/* eslint-disable */
/*!
  _   _  ___  ____  ___ ________  _   _   _   _ ___   
 | | | |/ _ \|  _ \|_ _|__  / _ \| \ | | | | | |_ _| 
 | |_| | | | | |_) || |  / / | | |  \| | | | | || | 
 |  _  | |_| |  _ < | | / /| |_| | |\  | | |_| || |
 |_| |_|\___/|_| \_\___/____\___/|_| \_|  \___/|___|
                                                                                                                                                                                                                                                                                                                                       
=========================================================
* Horizon UI - v1.1.0
=========================================================

* Product Page: https://www.horizon-ui.com/
* Copyright 2022 Horizon UI (https://www.horizon-ui.com/)

* Designed and Coded by Simmmple

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

import React from "react";
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { NavLink, useHistory } from "react-router-dom";
// Chakra imports
import {
  Box,
  Button,
  Flex,
  FormControl,
  Heading,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  Text,
  useColorModeValue,
  useToast,
} from "@chakra-ui/react";
// Custom components
import DefaultAuth from "layouts/auth/Default";
// Assets
import illustration from "assets/img/logo/logo.png";
import { MdOutlineRemoveRedEye } from "react-icons/md";
import { RiEyeCloseLine } from "react-icons/ri";
import { getRegistrationInformation } from "features/authentication/slice";
import { useEffect } from "react";
import { confirmRegistration } from "features/authentication/slice";
import Card from "components/card/Card";
import { COLORS } from "constants/colors";

function Register(props) {
  
  const { onConfirm, onLoadRegistration, location } = props;

  const history = useHistory();
  const toast = useToast();

  const registrationId = new URLSearchParams(location.search).get("id");

  const [firstName, setFirstName] = React.useState('');
  const [lastName, setLastName] = React.useState('');
  const [email, setEmail] = React.useState('');
  const [password, setPassword] = React.useState('');

  useEffect(() => {

    onLoadRegistration(registrationId, (registration) => {

      setFirstName(registration.firstName);
      setLastName(registration.lastName);
      setEmail(registration.email);

    }, (msg) => {
      toast({
        title: 'Registration Error',
        description: msg,
        status: 'error',
        duration: 9000,
        isClosable: true
      })
    })
  }, []);

  // Chakra color mode
  const textColor = useColorModeValue("navy.700", "white");
  const textColorSecondary = "gray.400";
  const textColorDetails = useColorModeValue("navy.700", "secondaryGray.600");
  const textColorBrand = useColorModeValue("brand.500", "white");
  const brandStars = useColorModeValue("brand.500", "brand.400");
  const [show, setShow] = React.useState(false);
  const handleClick = () => setShow(!show);

  const handleClickCreateAccount = (e) => {
    e.preventDefault();

    const onSignUnSucceed = () => {
      history.push('/admin/default');
      toast({
        title: 'Registration Succeed',
        description: "Welcome to Introgauge!",
        status: 'success',
        duration: 9000,
        isClosable: true
      })
    }

    const onSignUpError = (msg) => {
      toast({
        title: 'Confirmation Failed',
        description: msg,
        status: 'error',
        duration: 9000,
        isClosable: true
      })
    }

    onConfirm(firstName, lastName, email, password, registrationId,
      onSignUnSucceed, onSignUpError);
  }

  return (
   <DefaultAuth illustrationBackground={illustration} image={illustration}>
      <Card width={{ sm: "100%", md: "100%", lg: "50%", xl: "50%" }} marginLeft="auto" marginRight="auto" boxShadow="rgba(0, 0, 0, 0.16) 0px 1px 4px;" mb="80px">
        <Box me='auto' pl="30px" pr="30px" mt="30px">
          <Heading color={textColor} fontSize='32px' mb='10px'>
            Confirm your registration
          </Heading>
          <Text
            mb='36px'
            ms='4px'
            color={textColorSecondary}
            fontWeight='400'
            fontSize='md'>
            Confirm your information and setup a new password
          </Text>
        </Box>
        <Box pl="30px" pr="30px">
          <form onSubmit={handleClickCreateAccount}>
            <FormControl>
              <Flex>
                <Box mr='24px' width='100%'>
                  <Input
                    isRequired={true}
                    variant='auth'
                    fontSize='sm'
                    ms={{ base: "0px", md: "0px" }}
                    type='text'
                    placeholder='First name'
                    mb='24px'
                    mr='30px'
                    fontWeight='500'
                    size='lg'
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                  />
                </Box>
                <Box width='100%'>
                  <Input 
                    isRequired={true}
                    variant='auth'
                    fontSize='sm'
                    ms={{ base: "0px", md: "0px" }}
                    type='text'
                    placeholder='Last Name'
                    mb='24px'
                    fontWeight='500'
                    size='lg'
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                  />
                </Box>
              </Flex>
              
              <Flex>
                <Box mr='24px' width='100%'>
                  <Input
                    isRequired={true}
                    variant='auth'
                    fontSize='sm'
                    ms={{ base: "0px", md: "0px" }}
                    type='email'
                    placeholder='E-mail Address'
                    mb='24px'
                    fontWeight='500'
                    size='lg'
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </Box>
                <Box width='100%'>
                  <InputGroup size='md'>
                    <Input
                      isRequired={true}
                      fontSize='sm'
                      placeholder='Password'
                      mb='24px'
                      size='lg'
                      type={show ? "text" : "password"}
                      variant='auth'
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                    <InputRightElement display='flex' alignItems='center' mt='4px'>
                      <Icon
                        color={textColorSecondary}
                        _hover={{ cursor: "pointer" }}
                        as={show ? RiEyeCloseLine : MdOutlineRemoveRedEye}
                        onClick={handleClick}
                      />
                    </InputRightElement>
                  </InputGroup>
                </Box>
              </Flex>
              <Box w="100%" textAlign={"center"}>
                <Button
                  fontSize='sm'
                  style={ { backgroundColor: COLORS.Primary, color: COLORS.PrimaryText } }
                  fontWeight='500'
                  w='150px'
                  h='50'
                  mb='24px'
                  type="submit">
                  Confirm
                </Button>
              </Box>
            </FormControl>
          </form>
          <Box textAlign={"center"}
            mb="40px"
            mt='0px'>
            <Text color={textColorDetails} fontWeight='400' fontSize='14px'>
              Already have an account?
              <NavLink to='/auth/sign-in'>
                <Text
                  color={textColorBrand}
                  as='span'
                  ms='5px'
                  fontWeight='500'>
                  Sign in
                </Text>
              </NavLink>
            </Text>
          </Box>
        </Box>
      </Card>
    </DefaultAuth>
  );
}


Register.propTypes = {
  isAuthenticated: PropTypes.bool,
  onConfirm: PropTypes.func
};

Register.defaultProps = {
  isAuthenticated: false,
  onConfirm: () => {}
}

function mapStateToProps(state) {
  const { authentication } = state;

  const { isAuthenticated } = authentication;
  
  return {
    isAuthenticated
   };
};

function mapDispatchToProps (dispatch) {
  return {
      onConfirm: (firstName, lastName, email, password, registrationId, onSignUpSucceed, onSignUpError) => dispatch(confirmRegistration({ firstName, lastName, email, password, registrationId, onSignUpSucceed, onSignUpError })),
      onLoadRegistration: (registrationId, onSucceed, onError) => dispatch(getRegistrationInformation({ registrationId, onSucceed, onError })),
      dispatch,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Register);