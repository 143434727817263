import React from 'react';
import { connect } from 'react-redux'
import ReactLoader from 'react-loaders';
import './loader.scss';
import './loader.css';

class Loader extends React.Component {

    render() {
        const { isLoading, location } = this.props;

        if(location && location.pathname === '/admin/insights') {
            return null;
        }
        
        return isLoading === true ? 
            <div className="loading">
                <ReactLoader active={true} color="white" type="ball-grid-pulse" />
            </div> : null;
  }
}

Loader.propTypes = {
};

Loader.defaultProps = {
}

function mapStateToProps(state) {
    const { loader } = state;

    const { isLoading } = loader;

    return { 
        isLoading: isLoading
    };
};

export default connect(mapStateToProps)(Loader);
