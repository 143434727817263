import { Box, Card, Tab, TabIndicator, TabList, TabPanel, TabPanels, Tabs, Text } from "@chakra-ui/react";
import MembersTable from "./MembersTable";
import PendingMembersTable from "./PendingMembersTable";

export default function MembersOverview({ 
    teamMembers, 
    inactiveTeamMembers, 
    loadingTeamMembers, 
    onClickDeleteMember,
    onClickEditMember,
    onClickTeamMember,
    userReponseSummary,
    showExplanation
}) {
    return <Card style={{ marginLeft: '20px', marginRight: '20px'}}>
      {showExplanation === true ? <Box>
              <Box padding="20px">
                  <Text fontWeight={"bold"}>You may have “confirmed” and “pending” team members and they may have “completed” and “pending” assessments.</Text>
                  <Text>This frame displays both confirmed and pending team members. Additionally, hovering the mouse over the colored rectangle next to a member’s name will show their progress in completing assessments.</Text>
              </Box>
      </Box> : null}
      <Box pl="20px" backgroundColor={"#EDF0F6"}>
          <Tabs position="relative" variant="unstyled" pt="10px">
            <TabList>
              <Tab>Confirmed ({teamMembers ? teamMembers.length : 0})</Tab>
              <Tab>
                Pending (
                {inactiveTeamMembers ? inactiveTeamMembers.length : 0})
              </Tab>
            </TabList>
            <TabIndicator
              mt="-1.5px"
              height="2px"
              bg="blue.500"
              borderRadius="1px"
            />
            <TabPanels>
              <TabPanel>
                {loadingTeamMembers === false ? <MembersTable
                  teamMembers={teamMembers}
                  onClickDelete={onClickDeleteMember}
                  onClickEdit={onClickEditMember}
                  onClickTeamMember={onClickTeamMember}
                  userReponseSummary={userReponseSummary}
                /> : null}
              </TabPanel>
              <TabPanel>
                <PendingMembersTable
                  teamMembers={inactiveTeamMembers}
                  onClickTeamMember={onClickTeamMember}
                />
              </TabPanel>
            </TabPanels>
          </Tabs>
        </Box>
    </Card>
}