// Chakra imports
import {
    Box
  } from "@chakra-ui/react";
import Authenticated from "views/admin/authenticated";

import './index.css';

function Sixth(props) {
    
    return (
      <Authenticated>
        <Box>
          Page 6
        </Box>
      </Authenticated>
    );
  }
  
  export default Sixth;