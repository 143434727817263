// Chakra imports
import { Box, Text, Button } from "@chakra-ui/react";
import Card from "components/card/Card.js";
import React from "react";

export default function InvitationBanner(props) {
  const { onClickInvite } = props;

  return (
    <Card mb={{ base: "0px", lg: "20px" }} minH="355px">
      <Box ml={"17px"} mt={"16px"}>
        <Box>
          <Text fontWeight="bold" textAlign="start" fontSize="xl">
            Your whole organization is treated as one team
          </Text>
        </Box>
        <Box mt={"15px"}>
          <Text fontSize="lg">
            Thus, you may invite to the team:
          </Text>
          <Box ml={"25px"} mt={"10px"}>
            <Text fontSize="lg">
              <ul style={{ lineHeight: "30px" }}>
                <li>Any employee, </li>
                <li>Any manager,</li>
                <li>
                  Any board member (applicable if your org includes a Board of
                  Directors)
                </li>
              </ul>
            </Text>
          </Box>
          <Box mt="10px">
            <Text>
              Just click on “Invite member” button and follow the on-screen instructions.
            </Text>
          </Box>

          <Box mt={"12px"}>
            <Button
              w="170px"
              minW="170px"
              h="50px"
              colorScheme="yellow"
              onClick={onClickInvite}
            >
              Invite Member
            </Button>
          </Box>
        </Box>
      </Box>
    </Card>
  );
}
