import { COLORS } from "constants/colors";

import { Box, Grid, GridItem, Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, ModalFooter, Button, Heading, Text } from "@chakra-ui/react";

import PropTypes from 'prop-types';

import { SURVEY_TYPE } from "constants/evaluation.const";
import Card from "components/card/Card";
import { SURVEY_TYPE_DESCRIPTION } from "constants/surveyTypes.const";

import employeeIqImg from '../../../../assets/img/surveys/EmployeeIQ.svg';
import careerIQImg from '../../../../assets/img/surveys/CareerIQ.svg';
import orgIqImg from '../../../../assets/img/surveys/OrgIQ.svg';
import boardIqImg from '../../../../assets/img/surveys/BoardIQ.svg';

import clockImg from '../../../../assets/img/modal/clock.svg';
import pagesImg from '../../../../assets/img/modal/pages.svg';

import { SURVEY_TYPE_SUMMARY } from "constants/evaluation.const";

import './OnboardingStepTwo.css';
import { useEffect, useState } from "react";
import { SURVEY_TYPE_EXPLANATION } from "constants/evaluation.const";
import { getUserSurveys } from "features/userSurveys/slice";
import { connect } from "react-redux";

const survey_types_imgs = {
    [SURVEY_TYPE.EmployeeIq]: employeeIqImg,
    [SURVEY_TYPE.BoardIq]: boardIqImg,
    [SURVEY_TYPE.OrgIq]: orgIqImg,
    [SURVEY_TYPE.CareerIQ]: careerIQImg,
};

const SurveyType = ({ selected, surveyType, onClickImage }) => {

    return (
        <Card className={selected !== null && selected.surveyType === surveyType ? "evaluation-type-card-clicked" : "evaluation-type-card"} onClick={onClickImage}>
            <Box display={"flex"}>
                <Box h={"100%"} className="evaluation-type-image">
                    <img style={ { marginTop: '10%' } } alt="" className="survey-type-img" src={survey_types_imgs[surveyType]} onClick={onClickImage} width={"60px"}/>
                </Box>
                <Box>
                    <Box ml="20px">
                        <Heading size="sm">{SURVEY_TYPE_DESCRIPTION[surveyType]}</Heading>
                        <Box style={ { overflowWrap: 'break-word' } }>
                            <Text className="evaluation-type-description" 
                                style={{ color: COLORS.Primary }} 
                                fontSize="12px"
                                onClick={() => { onClickImage(); } }
                            >
                            {SURVEY_TYPE_SUMMARY[surveyType]}
                            </Text>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Card>
    );
}

function OnboardingStepTwo({ 
    userId,
    userSurveys,
    isModalOpen, 
    setModalOpen, 
    onClickSkip, 
    onClickNext, 
    onFinishOnboarding,
    onLoadUserSurveys
}) {

    const [selectedEvaluation, setSelectedEvaluation] = useState(null);

    useEffect(() => {
        if(userId && userId !== ''){
          onLoadUserSurveys(userId, 'Pending', (evaluations) => {
            if(!evaluations || evaluations === null || evaluations.length === 0) {
              //  history.push('/admin/default');
            }
           }, () => { });
        }
      }, []);

    const handleProceedButton = () => {
        if(selectedEvaluation !== null){
            onFinishOnboarding(selectedEvaluation);
        }
    }

    const handleNextButton = () => {
        if(selectedEvaluation !== null){
            onClickNext();
        }
    }

    return ( <Box>
            <Heading size="md">
                Step 2. Take Assessments            
            </Heading>
            <Text mt="10px">
                It is highly recommended that you complete all four evaluations before requesting participation from others. Afterward, you can make informed decisions about inviting members of the organization to join your team and recommending specific products to them.
            </Text>
            <Grid templateColumns="1fr 1fr" gap={4} mt="20px">
                {userSurveys && userSurveys.length > 0 ? userSurveys.map((s, i) => { 
                    return ( <GridItem>
                    <SurveyType selected={selectedEvaluation} surveyType={s.surveyType} onClick={() => setSelectedEvaluation(s)}
                        onClickImage={() => {
                            setSelectedEvaluation(s);
                            handleNextButton();
                        }} />
                </GridItem>)
                 }) : null}
            </Grid>
            <Grid templateColumns={"1fr 1fr"} mt="40px">
                <GridItem>
                    <Button w='170px'
                        minW='170px'
                        height={"50px"}
                        style={ { backgroundColor: COLORS.Primary, color: COLORS.PrimaryText } } 
                        ml="20px" 
                        className="skip-button" 
                        onClick={() => onClickSkip()}>
                            Skip this step
                    </Button>
                </GridItem>
                <GridItem textAlign={"right"}>
                    <Button
                        w='170px'
                        minW='170px'
                        height={"50px"}
                        style={ { backgroundColor: COLORS.Primary, color: COLORS.PrimaryText } }
                        onClick={handleNextButton}
                        fontWeight='500'>
                        Next
                    </Button>

                    <Modal isOpen={isModalOpen} onClose={() => setModalOpen(false)}>
                        <ModalOverlay />
                        <ModalContent>
                            <ModalHeader>
                                <Box display={"flex"} justifyContent={"center"}>
                                    <Box lassName="evaluation-type-image">
                                        <img style={ { marginTop: '10%' } } alt="" src={selectedEvaluation !== null ? survey_types_imgs[selectedEvaluation.surveyType] : null} width={"50px"}/>
                                    </Box>   
                                    <Box ml="10px">
                                        <Heading size="md" mt="18px">{selectedEvaluation !== null ? SURVEY_TYPE_DESCRIPTION[selectedEvaluation.surveyType] : null}</Heading>
                                    </Box>
                                </Box>
                                <Box display={"flex"} justifyContent={"center"} mt="20px">
                                    <Box display={"flex"}>
                                        <img style={ { marginTop: '10%' } } alt="" src={clockImg} width={"20px"}/>
                                        <Text fontSize={"sm"} mt="10px" ml="8px">
                                            10 mins
                                        </Text>
                                    </Box>
                                    <Box display={"flex"} ml="25px">
                                        <img style={ { marginTop: '10%' } } alt="" src={pagesImg} width={"20px"}/>
                                        <Text fontSize={"sm"} mt="10px" ml="8px">
                                            24 Pages
                                        </Text>
                                    </Box>
                                </Box>                         
                            </ModalHeader>
                            <ModalBody textAlign={"center"}>
                                <Text mt="10px">{selectedEvaluation !== null ? SURVEY_TYPE_EXPLANATION[selectedEvaluation.surveyType] : null}</Text>
                            </ModalBody>

                            <ModalFooter justifyContent={"center"} mb="20px">
                                <Button 
                                    mr={3} 
                                    style={ { borderColor: COLORS.Primary } } 
                                    onClick={() => setModalOpen(false)}
                                    color={COLORS.Primary}
                                    width={"160px"}
                                    height={"60px"}
                                    >
                                    Cancel
                                </Button>
                                <Button 
                                    style={ { backgroundColor: COLORS.Primary, color: COLORS.PrimaryText } } 
                                    mr={3} 
                                    width={"160px"}
                                    height={"60px"}
                                    onClick={handleProceedButton}>
                                    Proceed
                                </Button>
                            </ModalFooter>
                        </ModalContent>
                    </Modal>
                </GridItem>
            </Grid>
        </Box>
    )
}


  
OnboardingStepTwo.propTypes = {
    userSurveys: PropTypes.array,
};
  
OnboardingStepTwo.defaultProps = {
    userSurveys: [],
}

function mapStateToProps(state) {
    const { userId } = state.authentication;
  
    const { userSurveys } = state.userSurveys;
  
    return {
      userId,
      userSurveys
     };
  };
  
  function mapDispatchToProps (dispatch) {
    return {
        onLoadUserSurveys: (userId, status, onSuccess, onError ) => dispatch(getUserSurveys({ userId, status, onSuccess, onError })),
        dispatch
    }
  }
  
export default connect(mapStateToProps, mapDispatchToProps)(OnboardingStepTwo);