// Custom components
import React from "react";
import { TableContainer} from "@chakra-ui/react";
import EvaluationParticipantsBody from "./EvaluationParticipantsBody";

function EvaluationParticipants(props){

    const { 
        isAddParticipantModalOpen,
        handleCloseParticipantsModal,
        handleSaveParticipants,
        teamMembers,
        evaluationParticipants,
        handleClickCreateTeamMember,
        onClickAddParticipant
    } = props;

    return (
        <div>
            <TableContainer>
                <EvaluationParticipantsBody 
                    isOpen={isAddParticipantModalOpen} 
                    onClose={() => handleCloseParticipantsModal()}
                    onSave={(userIds) => handleSaveParticipants()}
                    teamMembers={teamMembers}
                    participants={evaluationParticipants}
                    onClickCreateMember={handleClickCreateTeamMember}
                    onClickAddParticipant={onClickAddParticipant}
                    />
                {evaluationParticipants && evaluationParticipants.length === 0 ? <div style={ { textAlign: 'center' } }>
                    Your evaluation still has no Participants, go back to the Dashboard and Add New Members before proceeding.
                </div> : null}
            </TableContainer>
        </div>
    )
}

export default EvaluationParticipants;