import { Box, Grid, GridItem, IconButton, Text } from "@chakra-ui/react";
import { CloseIcon } from "@chakra-ui/icons";
import { PersonalityTraitsLabels } from "constants/personalityTraitsDetails.const";

function PersonalityTraitsDetails({ 
    onClose, 
    surveyType, 
    trait, 
    color1,
    color2,
    color3
}) {

    return (<Box>
        <Box textAlign={"center"} mb="10px">
            <IconButton
                isRound={true}
                variant='solid'
                backgroundColor={color1}
                aria-label='Done'
                fontSize='10px'
                height={"20px"}
                color={color3}
                onClick={() => onClose()}
                icon={<CloseIcon />}
            /> 
        </Box>
        <Box textAlign={"center"} mb="5px">
            <Text fontSize={"12px"}>Specifics of the Trait <span style={{ fontWeight: 'bold' }}>[{trait}]</span></Text>
        </Box>
            <Box borderTopColor={color1} borderRadius={"5px"} borderTopWidth={"1px"} backgroundColor={color1}>
                <Grid templateColumns={"0.5fr 1fr"} columnGap={0} padding="5px" paddingRight={"10px"}>
                    <GridItem>
                        <Box textAlign={"center"} pt="3px">
                            <Text fontSize={"11px"} fontWeight={"bold"}>{PersonalityTraitsLabels[surveyType][trait].firstGrade.range}</Text>
                        </Box>
                    </GridItem>
                    <GridItem>
                        <Box>
                            <Text fontWeight={"bold"}>{PersonalityTraitsLabels[surveyType][trait].firstGrade.title}</Text>
                        </Box>
                        <Box pb="20px">
                            <Text fontSize={"12px"}>{PersonalityTraitsLabels[surveyType][trait].firstGrade.content}</Text>
                            {/* <Text fontSize={"12px"}>{PersonalityTraitsLabels[surveyType].firstGrade.action}</Text> */}
                        </Box>
                    </GridItem>
                </Grid>
            </Box>
            <Box borderTopColor={color2} borderRadius={"5px"} paddingRight={"10px"} borderTopWidth={"1px"} backgroundColor={color2}>
                <Grid templateColumns={"0.5fr 1fr"} padding="5px">
                    <GridItem>
                        <Box textAlign={"center"} pt="3px">
                            <Text fontSize={"11px"} fontWeight={"bold"}>{PersonalityTraitsLabels[surveyType][trait].secondGrade.range}</Text>
                        </Box>
                    </GridItem>
                    <GridItem>
                        <Box>
                            <Box>
                                <Text fontWeight={"bold"}>{PersonalityTraitsLabels[surveyType][trait].secondGrade.title}</Text>
                            </Box>
                            <Box>
                                <Text fontSize={"12px"}>{PersonalityTraitsLabels[surveyType][trait].secondGrade.content}</Text>
                                {/* <Text fontSize={"12px"}>{PersonalityTraitsLabels[surveyType].secondGrade.action}</Text> */}
                            </Box>
                        </Box> 
                    </GridItem>
                </Grid>
            </Box>
            <Box borderTopColor={color3} borderRadius={"5px"} paddingRight={"10px"} borderTopWidth={"1px"} backgroundColor={color3}>
                <Grid templateColumns={"0.5fr 1fr"} padding="5px">
                    <GridItem>
                        <Box textAlign={"center"} pt="3px">
                            <Text fontSize={"11px"} fontWeight={"bold"}>{PersonalityTraitsLabels[surveyType][trait].thirdGrade.range}</Text>
                        </Box>
                    </GridItem>
                    <GridItem>
                        <Box>
                            <Text fontWeight={"bold"}>{PersonalityTraitsLabels[surveyType][trait].thirdGrade.title}</Text>
                        </Box>
                        <Box minH="80px">
                            <Text fontSize={"12px"}>{PersonalityTraitsLabels[surveyType][trait].thirdGrade.content}</Text>
                            {/* <Text fontSize={"12px"}>{PersonalityTraitsLabels[surveyType].thirdGrade.action}</Text> */}
                        </Box>
                    </GridItem>
                </Grid>
            </Box>
        </Box>
    )
}

export default PersonalityTraitsDetails;