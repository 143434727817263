import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Box } from '@chakra-ui/react';
import { getIndividualMetrics } from 'features/metrics/slice';
import Authenticated from '../authenticated';
import React from 'react';
import DefaultIndividualResults from './components/DefaultIndividualResults';
import { createEvaluation } from 'features/surveys/slice';
import { generateReport } from 'features/reports/slice';

function IndividualResults(props){

    const { 
        onLoadIndividualMetrics, 
        onDownloadReport, 
        onCreateNewEvaluation, 
        userId, 
        employeeIQ, 
        boardIQ, 
        orgIQ,
        careerIQ, 
        exjDiagnostic, 
        firstName, 
        lastName, 
        subscriptionName, 
        subscriptionType, 
        role, 
        isDownloading,
        userIdSelectedOnNavBar,
        userNameSelectedOnNavBar,
        isSelectingUserOnNavBar
    } = props;

    const query = new URLSearchParams(props.location.search);
    const surveyType = query.get('surveyType');
    const evaluationId = query.get('evaluationId');
    const report = query.get('report') !== null ? query.get('report').toLowerCase() === "true" : false;

    React.useEffect(() => {
        if (userId !== null) {
            onLoadIndividualMetrics(userId, () => {});
        }
    }, []);

    return <Authenticated >
        <Box minH="1000px">
                <DefaultIndividualResults 
                    onLoadIndividualMetrics={onLoadIndividualMetrics} 
                    onDownloadReport={onDownloadReport}
                    onCreateNewEvaluation={onCreateNewEvaluation}
                    isDownloading={isDownloading}
                    userId={userId} 
                    employeeIQ={employeeIQ}
                    boardIQ={boardIQ}
                    orgIQ={orgIQ}
                    careerIQ={careerIQ}
                    exjDiagnostic={exjDiagnostic}
                    firstName={firstName}
                    lastName={lastName}
                    subscriptionName={subscriptionName}
                    subscriptionType={subscriptionType}
                    surveyType={surveyType}
                    role={role}
                    report={report}
                    evaluationId={evaluationId}
                    userIdSelectedOnNavBar={userIdSelectedOnNavBar}
                    userNameSelectedOnNavBar={userNameSelectedOnNavBar}
                    isSelectingUserOnNavBar={isSelectingUserOnNavBar}
                />
        </Box>
            
    </Authenticated>
}


IndividualResults.propTypes = {
    onLoadOrganizationMetrics: PropTypes.func,
    onCreateNewEvaluation: PropTypes.func,
    userId: PropTypes.string,
    employeeIQ: PropTypes.array,
    boardIq: PropTypes.array,
    orgIQ: PropTypes.array,
    exjDiagnostic: PropTypes.array,
    careerIQ: PropTypes.array,
    subscriptionType: PropTypes.number,
    userIdSelectedOnNavBar: PropTypes.string,
    userNameSelectedOnNavbar: PropTypes.string,
    isSelectingUserOnNavBar: PropTypes.bool
};

IndividualResults.defaultProps = {
    onLoadOrganizationMetrics: () => { },
    onCreateNewEvaluation: () => { },
    userId: '',
    employeeIQ: [],
    boardIQ: [],
    orgIQ: [],
    exjDiagnostic: [],
    careerIQ: [],
    subscriptionType: -1,
    userIdSelectedOnNavBar: '',
    userNameSelectedOnNavbar: '',
    isSelectingUserOnNavBar: false
}

function mapStateToProps(state) {

    const { employeeIQ, boardIQ, orgIQ, exjDiagnostic, careerIQ } = state.metrics.individual;
    const { userId } = state.authentication;
    const { firstName, lastName, subscriptionName, subscriptionType, role } = state.user;
    const { isDownloading } = state.reports;
    const { userIdSelectedOnNavBar, userNameSelectedOnNavBar, isSelectingUserOnNavBar } = state.metrics;

    return {
        userId,
        employeeIQ,
        boardIQ,
        orgIQ,
        exjDiagnostic,
        careerIQ,
        firstName,
        lastName,
        subscriptionName,
        subscriptionType,
        role,
        isDownloading,
        userIdSelectedOnNavBar,
        userNameSelectedOnNavBar,
        isSelectingUserOnNavBar
    };
};

function mapDispatchToProps (dispatch) {
    return {
        onLoadIndividualMetrics: (userId, onSuccess) => dispatch(getIndividualMetrics({ userId, onSuccess} )),
        onDownloadReport: (reportOnly, evaluationId, onSuccess) => dispatch(generateReport({ reportOnly, evaluationId, onSuccess })),
        onCreateNewEvaluation: (surveyType, onSuccess, onError) => dispatch(createEvaluation(surveyType, onSuccess, onError)),
        dispatch
    }
}
  
export default connect(mapStateToProps, mapDispatchToProps)(IndividualResults);