export function isUserAuthenticated(){
    const user = localStorage.getItem('user');
    
    return user && user !== null ? true : false;
}

export const extractOrganizationId = () => {
    let organizationId;
  
    var user = localStorage.getItem("user");
    if(user && user !== null){
      var userInfo = JSON.parse(user);
  
      organizationId = userInfo.organizationId;
    }
  
    return organizationId;
}


export const extractJwtToken = () => {
  let jwtToken;

  var user = localStorage.getItem("user");
  if(user && user !== null){
    var userInfo = JSON.parse(user);

    jwtToken = userInfo.jwtToken;
  }

  return jwtToken;
}