import { createSlice } from '@reduxjs/toolkit';
import { getAnonymousClient, getAnonymousClientForm, getAuthenticatedClient } from '../../api';
import { loading, finishLoading } from '../loader/slice';
import { loadUserProfileAsync } from '../user/slice';

const extractUserId = () => {
  let userId;

  var user = localStorage.getItem("user");
  if(user && user !== null){
    var userInfo = JSON.parse(user);

    userId = userInfo.id;
  }

  return userId;
}

export const authenticationSlice = createSlice({
    name: 'authentication',
    initialState: {
      isAuthenticated: localStorage.getItem("user") !== undefined && localStorage.getItem("user") !== null,
      userId: extractUserId(),
      successfullyRegistered: false,
      mustRelogin: false,
      organizationId: ''
    },
    reducers: {
      setUserAuthenticated: (state, action) => {
          state.isAuthenticated = true;
          state.userId = action.payload;
      },
      setOrganizationId: (state, action) => {
        state.organizationId = action.payload;
      },
      setOrganizationLogo: (state, action) => {
        state.organizationLogo = action.payload;
      },
      logout: (state, action) => {
        state.isAuthenticated = false;
        state.userId = null;
        localStorage.removeItem("user");
        localStorage.removeItem("x-access-token");
        localStorage.removeItem("x-refresh-token");
      },
      setSucessfullyRegistered: (state, action) => {
        state.successfullyRegistered = action.payload;
      }
  }
});

export const { 
  setUserAuthenticated, 
  setOrganizationId,
  logout, 
  setSucessfullyRegistered,
  setOrganizationLogo
} = authenticationSlice.actions;

export const logoutAsync = (logoutViaApi, onSignOutSuccess) => async dispatch => {
  dispatch(loading());
  var client = await getAuthenticatedClient(dispatch)
  client.post(`v1/users/logout`, {
    headers: {
        'Content-Type': 'application/json'
    }
  }).then(res => {
    dispatch(logout());
    dispatch(finishLoading());
    onSignOutSuccess();
  }).catch((error) => {
    dispatch(finishLoading());
    dispatch(logout());
  });
}

export function login(action) {
  const { email, password, onLogginSucceed, onLoginError } = action;
  
  return async function loginAsync(dispatch, getState) {
      dispatch(loading());
      
      var client = await getAnonymousClientForm(dispatch);

      const params = new URLSearchParams();

      params.append('email', email);
      params.append('password', password);

      client.post(`v1/users/authenticate`, params, {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        }
      }).then(res => {
          let response = res.data;

          setUserToStorage(response);
          
          dispatch(setUserAuthenticated(response.id));
          dispatch(setOrganizationId(response.organizationId));
          dispatch(setOrganizationLogo(response.organizationLogo));
          dispatch(loadUserProfileAsync(response.id));

          if(onLogginSucceed){
            onLogginSucceed(res.data.returnUrl, res.data.mustPerformEvaluationOnboarding);
          }

          dispatch(finishLoading());
      }).catch((error) => {
          dispatch(finishLoading());
          console.log(error);
          if(onLoginError && error.response && error.response.data && error.response.data.description) {
            onLoginError(error.response.data.description);
          }
      });
  }
}


export function loginWithSession(action) {
  const { session, onLogginSucceed, onLoginError } = action;
  
  return async function loginWithSessionAsync(dispatch, getState) {
      dispatch(loading());
      
      var client = await getAnonymousClient(dispatch);

      client.post(`v1/users/session?id=${session}`).then(res => {
          let response = res.data;

          dispatch(setUserAuthenticated(response.id));
          if(onLogginSucceed){
            onLogginSucceed(res.data.returnUrl, res.data.mustPerformEvaluationOnboarding);
          }

          setUserToStorage(response);
          
          dispatch(setOrganizationId(response.organizationId));
          dispatch(setOrganizationLogo(response.organizationLogo));
          dispatch(loadUserProfileAsync(response.id));


          dispatch(finishLoading());
      }).catch((error) => {
          dispatch(finishLoading());
          if(onLoginError && error.response.data && error.response.data.description) {
            onLoginError(error.response.data.description);
          }
      });
  }
}

const setUserToStorage = (user) => {
  localStorage.setItem("user", JSON.stringify(user));
  localStorage.setItem("x-access-token", user.jwtToken);
  localStorage.setItem("x-refresh-token", user.refreshToken);
}

export function signUp(action) {
  const { firstName, lastName, password, email, organization, subscriptionType, subscriptionFrequency, domain, onSignUpSucceed, onSignUpError } = action;
  
  return async function signUpAsync(dispatch, getState) {
      dispatch(loading());
      var client = await getAnonymousClientForm(dispatch);

      const params = new URLSearchParams();

      params.append('firstname', firstName);
      params.append('lastname', lastName);
      params.append('password', password);
      params.append('email', email);
      params.append('organizationName', organization);
      params.append('subscriptionType', subscriptionType);
      params.append('subscriptionFrequency', subscriptionFrequency);
      params.append('domain', domain);

      client.post(`v1/users/signup/admin`, params, {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        }
      }).then(res => {
              dispatch(setSucessfullyRegistered(true));
              dispatch(finishLoading());
              dispatch(login({ email, password, onLogginSucceed: onSignUpSucceed, onLoginError: onSignUpError }));
          }).catch((error) => {
              dispatch(finishLoading());
              console.log(error);
              if(onSignUpError && error.response && error.response.data && error.response.data.description) {
                onSignUpError(error.response.data.description);
              }
          });
  }
}

export function forgotPassword(action) {
  const { email, onSuccess, onError } = action;
  
  return async function forgotPasswordAsync(dispatch, getState) {
      dispatch(loading());
      var client = await getAnonymousClientForm(dispatch);

      const params = new URLSearchParams();

      params.append('email', email);

      client.post(`v1/users/password`, params, {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        }
      }).then(res => {
              dispatch(finishLoading());
              onSuccess();
          }).catch((error) => {
              dispatch(finishLoading());
              console.log(error);
              if(onError && error.response && error.response.data && error.response.data.description) {
                onError(error.response.data.description);
              }
          });
  }
}

export function recoverPassword(action) {
  const { token, currentPassword, password, onSuccess, onError } = action;
  
  return async function recoverPasswordAsync(dispatch, getState) {
      dispatch(loading());
      var client = await getAnonymousClientForm(dispatch);

      const params = new URLSearchParams();

      params.append('token', token);
      params.append('currentPassword', currentPassword);
      params.append('password', password);

      client.put(`v1/users/password`, params, {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        }
      }).then(res => {
              dispatch(finishLoading());
              onSuccess(res.data.returnUrl);
          }).catch((error) => {
              dispatch(finishLoading());
              console.log(error);
              if(onError && error.response && error.response.data && error.response.data.description) {
                onError(error.response.data.description);
              }
          });
  }
}



export function enterpriseSignUp(action) {
  const { firstName, lastName, password, email, domain, onSignUpSucceed, onSignUpError } = action;
  
  return async function enterpriseSignUpAsync(dispatch, getState) {
      dispatch(loading());
      var client = await getAnonymousClientForm(dispatch);

      const params = new URLSearchParams();

      params.append('firstname', firstName);
      params.append('lastname', lastName);
      params.append('password', password);
      params.append('email', email);
      params.append('domain', domain);

      client.post(`v1/users/signup/enterprise`, params, {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        }
      }).then(res => {
              dispatch(setSucessfullyRegistered(true));
              dispatch(finishLoading());
              dispatch(login({ email, password, onLogginSucceed: onSignUpSucceed, onLoginError: onSignUpError }));
          }).catch((error) => {
              dispatch(finishLoading());
              console.log(error);
              if(onSignUpError && error.response && error.response.data && error.response.data.description) {
                onSignUpError(error.response.data.description);
              }
          });
  }
}

export function confirmRegistration(action) {
  const { firstName, lastName, password, email, registrationId, onSignUpSucceed, onSignUpError } = action;
  
  return async function signUpAsync(dispatch, getState) {
      dispatch(loading());
      var client = await getAnonymousClientForm(dispatch);

      const params = new URLSearchParams();

      params.append('firstname', firstName);
      params.append('lastname', lastName);
      params.append('password', password);
      params.append('email', email);
      params.append('invitationId', registrationId);

      client.post(`/v1/users/signup/member`, params, {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        }
      }).then(res => {
              dispatch(setSucessfullyRegistered(true));
              dispatch(finishLoading());
              dispatch(login({ email, password, onLogginSucceed: onSignUpSucceed, onLoginError: onSignUpError }));
          }).catch((error) => {
              dispatch(finishLoading());
              console.log(error);
              if(onSignUpError &&  error.response && error.response.data && error.response.data.description) {
                onSignUpError(error.response.data.description);
              }
          });
  }
}

export function getRegistrationInformation(action) {
  const { registrationId, onSucceed, onError } = action;
  
  return async function signUpAsync(dispatch, getState) {
      dispatch(loading());
      var client = await getAnonymousClientForm(dispatch);

      client.get(`/v1/users/invite/${registrationId}`).then(res => {
              let response = res.data;
              onSucceed(response);
              dispatch(finishLoading());
          }).catch((error) => {
              dispatch(finishLoading());
              if(onError && error.response.data && error.response.data.description) {
                onError(error.response.data.description);
              }
          });
  }
}

export default authenticationSlice.reducer;
