// Custom components
import React from "react";
import { 
    Tabs, TabPanels,
    TabPanel } from "@chakra-ui/react";
import EvaluationParticipants from "./EvaluationParticipants";

export default function  EvaluationBody(props) {

    const { 
        isAddParticipantModalOpen,
        isCreateMemberOpen,
        teamMembers,
        evaluations,
        evaluationParticipants,
        currentEvaluationId,
        handleAddTeamMembers, 
        handleCloseParticipantsModal,
        handleSaveParticipants,
        onClickAddParticipant,
        handleClickCreateTeamMember,
        handleCloseCreateMember,
        handleCreateNewTeamMember,
        tabIndex,
        handleTabsChange
    } = props;

    return (
        <div>
            <Tabs align='end' variant='enclosed' index={tabIndex} onChange={handleTabsChange}>
                <TabPanels align='start'>
                    <TabPanel>
                        <EvaluationParticipants 
                            isAddParticipantModalOpen={isAddParticipantModalOpen}
                            handleAddTeamMembers={handleAddTeamMembers}
                            handleCloseParticipantsModal={handleCloseParticipantsModal}
                            handleSaveParticipants={handleSaveParticipants}
                            teamMembers={teamMembers}
                            evaluationParticipants={evaluationParticipants}
                            handleClickCreateTeamMember={handleClickCreateTeamMember}
                            onClickAddParticipant={onClickAddParticipant}
                            isCreateMemberOpen={isCreateMemberOpen}
                            handleCloseCreateMember={handleCloseCreateMember}
                            handleCreateNewTeamMember={handleCreateNewTeamMember}
                            evaluations={evaluations}
                            currentEvaluationId={currentEvaluationId}
                        />
                    </TabPanel>
                </TabPanels>
                </Tabs>
      </div>
      )
}