/*!
  _   _  ___  ____  ___ ________  _   _   _   _ ___   
 | | | |/ _ \|  _ \|_ _|__  / _ \| \ | | | | | |_ _| 
 | |_| | | | | |_) || |  / / | | |  \| | | | | || | 
 |  _  | |_| |  _ < | | / /| |_| | |\  | | |_| || |
 |_| |_|\___/|_| \_\___/____\___/|_| \_|  \___/|___|
                                                                                                                                                                                                                                                                                                                                       
=========================================================
* Horizon UI - v1.1.0
=========================================================

* Product Page: https://www.horizon-ui.com/
* Copyright 2022 Horizon UI (https://www.horizon-ui.com/)

* Designed and Coded by Simmmple

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// Chakra imports

// Chakra imports
import {
    Box,
    useDisclosure,
    useToast,
  } from "@chakra-ui/react";
  import PropTypes from "prop-types";
  import { connect } from "react-redux";
  import React, { useEffect } from "react";
  import {
    listOrganizationUsersAsync,
    addNewTeamMemberAsync,
    onDeleteTeamMemberAsync,
  } from "features/user/slice";
  import CreateMember from "./CreateMember";
  import DeleteMember from "./DeleteMember";
  import EditMember from "./EditMember";
  import { onUpdateUserProfile } from "features/user/slice";
  import { getTeamMetrics } from "features/metrics/slice";
  import { USER_ROLE } from "constants/userRoles.const";
  import { listInnactiveUsersAsync } from "features/user/slice";
  import MembersOverview from "./MembersOverview";
  
function MembersManagementDefaultView(props) {
    const {
      onLoadTeamMembers,
      onLoadPendingTeamMember,
      onCreateTeamMembers,
      onDeleteTeamMember,
      userReponseSummary,
      inactiveTeamMembers,
      teamMembers,
      onUpdateTeamMember,
      onClickTeamMember,
      onLoadTeamMetrics,
      showExplanation,
      loadingTeamMembers
    } = props;
  
  
    const [isDeleteMemberOpen, setDeleteMemberOpen] = React.useState(false);
    const [idToDelete, setIdToDelete] = React.useState(0);
    const [userToUpdate, setUserToUpdate] = React.useState(null);
    const [isUpdateMemberOpen, setIsUpgradeModalOpen] = React.useState(false);
    const teamMemberRoleToBeCreated = USER_ROLE.TeamMember;
  
    const createMemberModal = useDisclosure();
  
    const toast = useToast();
  
    useEffect(() => {
      onLoadTeamMembers(() => {
        onLoadPendingTeamMember();
        onLoadTeamMetrics();
      });
    }, []);
  
    const onSaveMember = (firstName, lastName, email, role, onSuccess) => {
      onCreateTeamMembers(
        firstName,
        lastName,
        email,
        role,
        (invitation) => {
          onLoadTeamMembers();
          onSuccess();
        },
        (msg) => {
          toast({
            title: "Failed to add user",
            description: msg,
            status: "error",
            duration: 9000,
            isClosable: true,
          });
        }
      );
    };
  
    const onClickDeleteMember = (id) => {
      setDeleteMemberOpen(true);
      setIdToDelete(id);
    };
  
    const onClickEditMember = (user) => {
      setUserToUpdate(user);
      setIsUpgradeModalOpen(true);
    };
  
    const onCloseEditDialog = () => {
      setIsUpgradeModalOpen(false);
      setUserToUpdate(null);
    };
  
    const handleMemberUpdate = (firstName, lastName, email) => {
      onUpdateTeamMember(
        userToUpdate.id,
        firstName,
        lastName,
        email,
        () => {
          toast({
            title: "User updated",
            description: "User profile successfully updated",
            status: "success",
            duration: 9000,
            isClosable: true,
          });
          setIsUpgradeModalOpen(false);
          onLoadTeamMembers();
        },
        (msg) => {
          toast({
            title: "Failed to update user",
            description: msg,
            status: "error",
            duration: 9000,
            isClosable: true,
          });
        }
      );
    };
  
    const onDeleteError = (msg) => {
      toast({
        title: "Failed to remove user",
        description: msg,
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    };
  
    const onProceedWithDeletion = () => {
      if (idToDelete !== 0) {
        onDeleteTeamMember(
          idToDelete,
          () => {
            onLoadTeamMembers();
            setDeleteMemberOpen(false);
            setIdToDelete(0);
          },
          onDeleteError
        );
      }
    };
  
    return (
      <Box>
          <Box pr="20px">
            <Box mt="20px" mr="40px">
              <MembersOverview
                showExplanation={showExplanation}
                teamMembers={teamMembers}
                inactiveTeamMembers={inactiveTeamMembers}
                loadingTeamMembers={loadingTeamMembers}
                onClickDeleteMember={onClickDeleteMember}
                onClickEditMember={onClickEditMember}
                onClickTeamMember={onClickTeamMember}
                userReponseSummary={userReponseSummary}
              />
            </Box>
            <CreateMember
              role={teamMemberRoleToBeCreated}
              isOpen={createMemberModal.isOpen}
              onClose={createMemberModal.onClose}
              toast={toast}
              onSuccess={() => {
                onLoadTeamMembers();
                onLoadPendingTeamMember();
              }}
              onSave={onSaveMember}
            />
            <DeleteMember
              id={idToDelete}
              onDelete={onProceedWithDeletion}
              isOpen={isDeleteMemberOpen}
              onClose={() => setDeleteMemberOpen(false)}
            />
            {userToUpdate !== null ? (
              <EditMember
                isOpen={isUpdateMemberOpen}
                id={userToUpdate.id}
                firstName={userToUpdate.firstName}
                lastName={userToUpdate.lastName}
                email={userToUpdate.email}
                onClose={onCloseEditDialog}
                onUpdateMember={handleMemberUpdate}
              />
            ) : null}
          </Box>
      </Box>
    );
  }
  
  MembersManagementDefaultView.propTypes = {
    subscriptions: PropTypes.array,
    organizationName: PropTypes.string,
    onLoadSubscriptions: PropTypes.func,
    onCreateTeamMembers: PropTypes.func,
    onUpdateTeamMember: PropTypes.func,
    onClickTeamMember: PropTypes.func,
    onLoadOrganizationMetrics: PropTypes.func,
    onLoadPendingTeamMember: PropTypes.func,
    firstName: PropTypes.func,
    lastname: PropTypes.func,
    loadingTeamMembers: PropTypes.bool
  };
  
  MembersManagementDefaultView.defaultProps = {
    subscriptions: [],
    organizationName: "",
    onLoadSubscriptions: () => {},
    onCreateTeamMembers: () => {},
    onUpdateTeamMember: () => {},
    onClickTeamMember: () => {},
    onLoadOrganizationMetrics: () => {},
    onLoadPendingTeamMember: () => {},
    firstName: "",
    lastName: "",
    loadingTeamMembers: false
  };
  
  function mapStateToProps(state) {
    const {
      teamMembers,
      inactiveTeamMembers,
      organizationName,
      firstName,
      lastName,
      subscriptionType,
      loadingTeamMembers
    } = state.user;
  
    const { employeeIQ, boardIQ, orgIQ, careerIQ, exjDiagnostic } =
      state.metrics.team;
  
    return {
      subscriptionType,
      teamMembers,
      inactiveTeamMembers,
      organizationName,
      employeeIQ,
      boardIQ,
      orgIQ,
      careerIQ,
      exjDiagnostic,
      firstName,
      lastName,
      loadingTeamMembers
    };
  }
  
  function mapDispatchToProps(dispatch) {
    return {
      onLoadTeamMembers: (onSuccess) =>
        dispatch(listOrganizationUsersAsync("active", onSuccess)),
      onLoadPendingTeamMember: () => dispatch(listInnactiveUsersAsync(() => {})),
      onCreateTeamMembers: (
        firstName,
        lastName,
        email,
        role,
        onSuccess,
        onError
      ) =>
        dispatch(
          addNewTeamMemberAsync({
            firstName,
            lastName,
            email,
            role,
            onSuccess,
            onError,
          })
        ),
      onDeleteTeamMember: (id, onSuccess, onError) =>
        dispatch(onDeleteTeamMemberAsync({ id, onSuccess, onError })),
      onUpdateTeamMember: (id, firstName, lastName, email, onSuccess, onError) =>
        dispatch(
          onUpdateUserProfile({
            id,
            firstName,
            lastName,
            email,
            organizationName: "",
            onSuccess,
            onError,
          })
        ),
      onLoadTeamMetrics: (onSuccess) => dispatch(getTeamMetrics(onSuccess)),
      dispatch,
    };
  }
  
  export default connect(mapStateToProps, mapDispatchToProps)(MembersManagementDefaultView);
  