// Chakra imports
import {
    Box, CircularProgress, CircularProgressLabel, Divider, Grid, GridItem, Heading, Text
  } from "@chakra-ui/react";
import BehaviorDriversChart from "components/report/BehaviorDriversChart";
import { useEffect, useState } from "react";
import Authenticated from "views/admin/authenticated";
  
import './index.css';
import TraitItem from "components/report/TraitItem";

function FirstPage({ location }) {
    
    const [behaviorDrivers, setBehaviorDrivers] = useState({ action: 0, people: 0, strategy: 0, process: 0 });
    const [name, setName] = useState('');
    const [title, setTitle] = useState('');
    const [traitsAverage, setTraitsAverage] = useState(parseFloat(0));
    const [traitMetrics, setTraitMetrics] = useState([]);
    const traitColors = ["#4094E4", "#4094E4", "#23CE6B", "#23CE6B", "#F6AE2D", "#F6AE2D", "#FF5154", "#FF5154"];

    useEffect(() => {
        const query = new URLSearchParams(location.search);
    
        setBehaviorDrivers({
            action: parseFloat(query.get('action')),
            people: parseFloat(query.get('people')),
            strategy: parseFloat(query.get('strategy')),
            process: parseFloat(query.get('process'))
        });
        setName(query.get('name'));
        setTitle(query.get('title'));
        setTraitsAverage(parseFloat(query.get('traitsAverage')));

        let traits = [];
        (query.get('traits') || '').split('|').map((item) => {
            const [trait, avgScore] = item.split(',');
            traits.push({ trait: trait.toString(), avgScore: parseFloat(avgScore) });
            return item;
          });
        setTraitMetrics(traits);
    }, []);


    const OverallHarmonizationIndex = ({ value, mt, ml, mb, mr }) => {

        const color = "#4094E4";
        const trackColor = "#0095FF33";

        return (
        <Box style={ { justifyContent: "center" } } ml={ml} mt={mt} mb={mb} mr={mr} >
            <CircularProgress value={value} trackColor={trackColor} size='150px' thickness="12px" color={color}>
            <CircularProgressLabel>
                <Heading size="md">{value}%</Heading>
            </CircularProgressLabel>
            </CircularProgress>
        </Box>
        )
    }

    return (
      <Authenticated>
        <Box pl="40px" pr="40px">
            <Box mt="5px">
                <Box width={"100%"} textAlign={"center"} mb="5px">
                    <Box display={"flex"} justifyContent={"center"}>
                        <Text fontWeight={"bold"}>{title}</Text>, Individual Report for {name}
                    </Box>
                </Box>
                <Divider width={"100%"} borderWidth="1px"/>
            </Box>
            <Box justifyContent={"center"}>
                <Box textAlign={"center"} mt="30px">
                    <Heading size="md">EXECUTIVE SUMMARY</Heading>
                </Box>
                <Grid className='content' ml="auto" mr="auto" mt="20px" width="800px" columnGap={"20px"} templateColumns={"1fr 1fr"} gap={3} height={"100%"}>
                    <GridItem>
                        <Text fontSize={"12px"}>Behavior Drivers. We find that four underlying motivators or Behavior
                        Drivers determine how you behave in various situations at your workplace.</Text>
                        <Text mt="5px" fontSize={"12px"}>The chart provides your scores for the drivers.</Text>
                        <Text mt="5px" fontSize={"12px"}>The scale of 0 to 10 is divided into three ranges:</Text>
                        <Box ml="20px" fontSize={"12px"}>
                            <ul>
                                <li>Sub-optimal, 0 to 4.4 points</li>
                                <li>Optimal, 4.5 to 6.3 points, and</li>
                                <li>Above optimal, 6.4 to 10.0</li>
                            </ul>
                        </Box>
                    </GridItem>
                    <GridItem height={"200px"}>
                        <Box minHeight="250px" height="100%" pb="0px" textAlign={"center"}> 
                            <Box height={"70%"} width="80%" pl="20px">
                                <BehaviorDriversChart 
                                    ml={3}
                                    behaviorDrivers={behaviorDrivers}
                                    barWidth={"60%"}
                                    columnGap="0px"
                                    fontSize="12px"
                                    />
                            </Box>
                        </Box>
                    </GridItem>
                </Grid>
            </Box>
        </Box>
        <Box width={"100%"} alignContent={"center"}>
            <Box width="800px" ml="auto" mr="auto" backgroundColor="#D3D3D3" textAlign={"center"} mt="15px">
                <Text>The Behavior Drivers are malleable and subject to change through various training programs.</Text>
            </Box>
        </Box>
        <Box justifyContent={"center"}>
            <Grid className='content' ml="auto" mr="auto" mt="20px" width="800px" columnGap={"20px"} templateColumns={"1fr 1fr"} gap={3} height={"100%"}>
                <GridItem>
                    <Text fontSize={"12px"}>Personality Traits. Regardless of job position or work experience, individuals
                    in any organization commonly share 8 traits. The graph displays
                    those traits and your scores on a scale from 0 to 10, divided into three
                    ranges:</Text>
                    <Box ml="20px" mt="10px" fontSize={"12px"}>
                        <ul>
                            <li>Low, 0 to 3.99 points</li>
                            <li>Moderate, 4.00 to 6.99 points, and</li>
                            <li>High 7.00 to 10.0</li>
                        </ul>
                    </Box>
                </GridItem>
                <GridItem>
                    <Box  textAlign={"center"}> 
                        <Box height={"100%"}>
                            <Grid templateColumns={"1fr 1fr"} gap={1} mt="10px">
                                {traitMetrics.map((t, i) => {
                                    return (<GridItem>
                                        <TraitItem height="25px" textMt="3px" fontSize="10px" text={t.trait} value={t.avgScore.toFixed(2)} color={traitColors[i]} />
                                    </GridItem>)
                                })}
                                
                            </Grid>
                        </Box>
                    </Box>
                </GridItem>
            </Grid>
        </Box>
        <Box width={"100%"} mt="20px" alignContent={"center"}>
            <Box width="800px" ml="auto" mr="auto" backgroundColor="#D3D3D3" textAlign={"center"}>
                <Text>The Personality Traits are relatively stable over time and not easy to change.</Text>
            </Box>
        </Box>
        <Box justifyContent={"center"}>
            <Grid className='content' ml="auto" mr="auto" mt="20px" width="800px" columnGap={"20px"} templateColumns={"1fr 1fr"} gap={3} height={"100%"}>
                <GridItem>
                    <Text fontSize={"12px"}>
                        Harmonization Index. Your alignment with the organization’s vision
                        in achieving expected results, adhering to standards, and
                        fostering effective collaboration with colleagues is expressed
                        through a statistical measure called Harmonization Index. The
                        index is presented on a scale of 0 to 100 points, divided into
                        three ranges:
                    </Text>
                    <Box ml="20px" mt="10px" fontSize={"12px"}>
                        <ul>
                            <li>Low Harmonization, 0 to 33.99</li>
                            <li>Moderate Harmonization, 34.00 to 66.99, and</li>
                            <li>High harmonization, 67.00 to 100.</li>
                        </ul>
                    </Box>
                </GridItem>
                <GridItem>
                    <Box height="100%" textAlign={"center"}> 
                        <Box height={"100%"}>
                            <OverallHarmonizationIndex value={traitsAverage.toFixed(2)} ml="auto" mt="auto" mr="auto" mb="auto" /> 
                        </Box>
                    </Box>
                </GridItem>
            </Grid>
        </Box>
        <Box mt="20px" width={"100%"} alignContent={"center"}>
            <Box width="800px" ml="auto" mr="auto" backgroundColor="#D3D3D3" textAlign={"center"}>
                <Text>The Harmonization Index is highly changeable and depends on both Behavior Drivers and Personality Traits.</Text>
            </Box>
        </Box>
        <Box justifyContent={"center"}>
            <Grid className='content' ml="auto" mr="auto" mt="50px" width="800px" columnGap={"20px"} templateColumns={"1fr 1fr"} gap={3} height={"100%"}>
                <GridItem>
                    <Text fontSize={"12px"}>
                        Goals. Our platform generates clear and actionable goals for improvement
                        of each Behavior Driver and those personalized goals
                        are shown in the table. In this static (printable) report we can’t
                        show more details about the goals and how you progress toward
                        achieving them.
                    </Text>
                </GridItem>
                <GridItem>
                    <Box height="100%" pb="40px"> 
                        <Box height={"100%"}>
                          <table style={ { border: "1px #000 solid", fontSize: '12px' } }>
                            <tbody >
                                <tr style={ { border: "1px #000 solid"}}><td style={{ padding: "5px" } }>Goal 1, Increase the “Action” driver for 10%</td></tr>
                                <tr style={ { border: "1px #000 solid"}}><td style={{ padding: "5px" } }>Goal 2, Decrease the “Process” for 15%</td></tr>
                                <tr style={ { border: "1px #000 solid"}}><td style={{ padding: "5px" } }>Goal 3, Maintain the current value of “Strategy”</td></tr>
                                <tr style={ { border: "1px #000 solid"}}><td style={{ padding: "5px" } }>Goal 4, Maintain the current value of “People”</td></tr>
                            </tbody>
                          </table>
                        </Box>
                    </Box>
                </GridItem>
            </Grid>
        </Box>
        <Box mt="10px" width={"100%"} alignContent={"center"}>
            <Box width="800px" ml="auto" mr="auto" backgroundColor="#D3D3D3" textAlign={"center"}>
                <Text>Your online report contains a whole section about your personal improvement goals and actions.</Text>
            </Box>
        </Box>
        <Box pos={"absolute"} left="0" right="0" mt="60px">
            <Divider width={"100%"} borderWidth="1px"/>
            <Box textAlign={"right"} mt="10px">
                <Text fontWeight={"bold"} mr="50px">3</Text>
            </Box>
        </Box>
      </Authenticated>
    );
  }
  
  export default FirstPage;