import { createSlice } from '@reduxjs/toolkit';
import _ from 'lodash';
import { getAuthenticatedClient } from '../../api';
import { loading, finishLoading } from '../loader/slice';
import { extractOrganizationId } from 'helpers/storageHelper';


export const surveySlice = createSlice({
  name: 'surveys',
  initialState: {
      survey: null,
      evaluations: [],
      surveyTypes: [],
      organizationUserSurveys: [],
      hasChanges: false
  },
  reducers: {
    setSurvey: (state, action) => {
        state.survey = action.payload;
    },
    setSurveyTypes: (state, action) => {
        state.surveyTypes = action.payload;
    },
    setEvaluations: (state, action) => {
        state.evaluations = action.payload;
    },
    setOrganizationUserSurveys: (state, action) => {
        state.organizationUserSurveys = action.payload;
    },
    deleteQuestion: (state, action) => {
        const { surveyId, questionId } = action.payload;

        const surveyIndex = _.findIndex(state.surveyTypes, function (i) { return i.id === surveyId; });
        const questionIndex = _.findIndex(state.surveyTypes[surveyIndex].questions, function(i){ return i.id === questionId; });

        state.surveyTypes[surveyIndex].questions.splice(questionIndex, 1);

        state.hasChanges = true;
    },
    setQuestion: (state, action) => {
        const { title, description, order, availableForFreePlan, behaviorDriver, questionId, surveyId } = action.payload;

        const surveyIndex = _.findIndex(state.surveyTypes, function (i) { return i.id === surveyId; });
        const questionIndex = _.findIndex(state.surveyTypes[surveyIndex].questions, function(i){ return i.id === questionId; });
        const differentOrder = state.surveyTypes[surveyIndex].questions[questionIndex].order !== order;
        const differentTitle = state.surveyTypes[surveyIndex].questions[questionIndex].title !== title;
        const differentDescription = state.surveyTypes[surveyIndex].questions[questionIndex].description !== description;
        const differentBehaviorDriver = state.surveyTypes[surveyIndex].questions[questionIndex].behaviorDriver !== behaviorDriver;
        const differentAvailability = state.surveyTypes[surveyIndex].questions[questionIndex].availableForFreePlan !== availableForFreePlan;
        
        state.surveyTypes[surveyIndex].questions[questionIndex].title = title;
        state.surveyTypes[surveyIndex].questions[questionIndex].description = description;
        state.surveyTypes[surveyIndex].questions[questionIndex].order = order;
        state.surveyTypes[surveyIndex].questions[questionIndex].availableForFreePlan = availableForFreePlan;
        state.surveyTypes[surveyIndex].questions[questionIndex].behaviorDriver = behaviorDriver;

        if(differentOrder === true){
            state.surveyTypes[surveyIndex].questions = _.orderBy(state.surveyTypes[surveyIndex].questions, ['order'], ['asc']);
        }

        if(differentTitle === true || differentOrder === true || differentAvailability === true || differentBehaviorDriver === true
                || differentDescription === true){
            state.hasChanges = true;
        }
    },
    setHasChanges: (state, action) => {
        state.hasChanges = action.payload;
    }
  }
});

export const { 
    setSurvey,
    setEvaluations,
    setSurveyTypes,
    setQuestion,
    setHasChanges,
    deleteQuestion,
    setOrganizationUserSurveys
 } = surveySlice.actions;

export const getOrganizationSurvey = (evaluationId, onSuccess) => async dispatch => {
    dispatch(setSurvey(null));
    dispatch(loading());
    const client = await getAuthenticatedClient(dispatch);
    client.get(`v1/organization/survey?evaluationId=${evaluationId}`)
        .then(res => {
            let survey = res.data;
            dispatch(setSurvey(survey));
            onSuccess();
            dispatch(finishLoading());
        })
        .catch((error) => {
            dispatch(finishLoading());
        });
};

export const getSurveyTypes = (onSuccess, onError) => async dispatch => {

    dispatch(loading());
    const client = await getAuthenticatedClient(dispatch);
    client.get(`v1/surveyTypes`)
        .then(res => {
            let surveyTypes = res.data;
            dispatch(setSurveyTypes(surveyTypes));
            onSuccess();
            dispatch(finishLoading());
        })
        .catch((error) => {
            dispatch(finishLoading());
            onError(error);
        });
};

export const onSaveSurveyChanges = ({ surveyId, onSuccess, onError }) => async (dispatch, getState) => {

    dispatch(loading());
    const client = await getAuthenticatedClient(dispatch);


    var surveyState = getState().surveys;

    if(surveyState.hasChanges === true) {
        const surveyTypes = getState().surveys.surveyTypes;
        const survey = _.find(surveyTypes, function (s) { return s.id === surveyId});
    
        client.put(`v1/surveyTypes/${surveyId}`, survey)
            .then(res => {
                onSuccess();
                dispatch(setHasChanges(false));
                dispatch(finishLoading());
            })
            .catch((error) => {
                dispatch(finishLoading());
                onError(error);
            });
    }else {
        dispatch(finishLoading());
    }
};

export const getOrganizationEvaluations = (surveyType, onSuccess, onError) => async dispatch => {

    dispatch(loading());
    const client = await getAuthenticatedClient(dispatch);
    client.get(`v1/organizations/${extractOrganizationId()}/evaluations?type=${surveyType}`)
        .then(res => {
            let evaluations = res.data;
            dispatch(setEvaluations(evaluations));
            onSuccess(evaluations);
            dispatch(finishLoading());
        })
        .catch((error) => {
            dispatch(finishLoading());
            onError(error);
        });
};


export const getOrganizationUserSurveys = ({ status, onSuccess, onError }) => async dispatch => {
    dispatch(loading());
    const client = await getAuthenticatedClient(dispatch);
    client.get(`v1/organization/${extractOrganizationId()}/usersurveys?status=${status}`)
        .then(res => {
            let userSurveys = res.data;
            dispatch(setOrganizationUserSurveys(userSurveys));
            onSuccess();
            dispatch(finishLoading());
        })
        .catch((error) => {
            dispatch(finishLoading());
            onError(error);
        });
};

export const saveOrganizationEvaluation = (organizationId, evaluationId, userIds, onSuccess, onFailure) => async dispatch => {

    dispatch(loading());
    const client = await getAuthenticatedClient(dispatch);
    client.put(`v1/organizations/${organizationId}/evaluations/${evaluationId}`, {
        userIds: userIds
    }).then(res => {
        onSuccess();
        dispatch(finishLoading());
    })
    .catch((error) => {
        dispatch(finishLoading());
        onFailure(error);
    });
};

export const createEvaluation = (evaluationType, onSuccess, onFailure) => async dispatch => {
    dispatch(loading());
    const client = await getAuthenticatedClient(dispatch);
    client.post(`v1/organizations/${extractOrganizationId()}/evaluations`, {
        evaluationType: evaluationType
    }).then(res => {
        onSuccess(res.data);
        dispatch(finishLoading());
    })
    .catch((error) => {
        dispatch(finishLoading());
        onFailure(error);
    });
};

export const getSurvey = (surveyId, onSuccess) => async dispatch => {

    dispatch(loading());
    const client = await getAuthenticatedClient(dispatch);
    client.get(`v1/survey/${surveyId}`)
        .then(res => {
            let survey = res.data;
            dispatch(setSurvey(survey));
            onSuccess();
            dispatch(finishLoading());

        })
        .catch((error) => {
            dispatch(finishLoading());
        });
};

export default surveySlice.reducer;
