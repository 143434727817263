import { Box, Grid, GridItem, Text } from "@chakra-ui/react";

import DriverActionImg from "../../assets/img/drivers/action.svg";
import DriverProcessImg from "../../assets/img/drivers/process.svg";
import DriverStrategyImg from "../../assets/img/drivers/target.svg";
import DriverPeopleImg from "../../assets/img/drivers/people.svg";

const VerticalProgressBar = ({ progress, text, color, bgColor, img, barWidth, fontSize }) => {  
  
  const missing = 100 - progress;
  const theBarWidth = barWidth ? barWidth : "100%";
  const theFontSize = fontSize ? fontSize : "18px";

  return (
    <Box
      position="relative"
      h={"100%"}
      bg={bgColor}
      borderRadius="md"
      width={theBarWidth}
    >
    <Box width={"100%"} maxWidth={"100%"} height={"70px"} position={"absolute"}>
      <img src={img} alt="" style={ { 
        position: "absolute",
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        maxWidth: '100%',
        maxHeight: '100%'
      } } />
    </Box>
    <Box
      w="100%"
      position="relative"
      borderTopLeftRadius="md"
      borderTopRightRadius="md"
      overflow="hidden"
      transition="height 0.3s"
      minH={`${missing}%`}
    />
      <Box
        minH={`${progress}%`}
        bg={color}
        borderRadius="md"
        transition="height 0.3s"
      >
      <Box 
        position={"absolute"}
        width={"100%"}
        bottom={"0px"}
        textAlign={"center"}
      >
        <Text left="auto" rigth="auto" fontSize={"18px"} mb="20px">{(progress / 10).toFixed(2)}</Text>
      </Box>
    </Box>
    <Box
        textAlign={"center"}
        mt="5px"
        fontSize={theFontSize}
      >
      {text}
    </Box>
  </Box>
  );
};

function BehaviorDriversChart(props) {
  const { behaviorDrivers, barWidth, columnGap, fontSize } = props;
  const calculatePercentage = (val) => {
    return (val / 10) * 100
  }

  const theColumnGap = columnGap ? columnGap : "20px";
  
  return (<>
    {behaviorDrivers !== null ? <Grid templateColumns="1fr 1fr 1fr 1fr" columnGap={theColumnGap} height={"100%"}>
    <GridItem>
          <VerticalProgressBar 
            progress={calculatePercentage(behaviorDrivers.action)} 
            text="Action"
            color="#4094E4"
            bgColor="rgba(64, 148, 228, 0.5)"
            img={DriverActionImg}
            barWidth={barWidth}
            fontSize={fontSize}
          />
        </GridItem>
        <GridItem>
          <VerticalProgressBar 
            progress={calculatePercentage(behaviorDrivers.process)}
            text="Process"  
            img={DriverProcessImg}
            color="#23CE6B"
            bgColor="rgba(35, 206, 107, 0.5);"
            barWidth={barWidth}
            fontSize={fontSize}
          />
        </GridItem>
        <GridItem>
          <VerticalProgressBar 
            progress={calculatePercentage(behaviorDrivers.strategy)} 
            text="Strategy"  
            color="#F6AE2D"
            bgColor="rgba(246, 174, 45, 0.5)"
            img={DriverStrategyImg}
            barWidth={barWidth}
            fontSize={fontSize}
          />
        </GridItem>
        <GridItem>
          <VerticalProgressBar 
            progress={calculatePercentage(behaviorDrivers.people)} 
            text="People"  
            img={DriverPeopleImg}
            color="#FF5154"
            bgColor="rgba(255, 81, 84, 0.5)"
            barWidth={barWidth}  
            fontSize={fontSize}
          />
        </GridItem>
      </Grid> : null}
    </>
  );
}

export default BehaviorDriversChart;
