// Chakra imports
import { Box, SimpleGrid, Text } from "@chakra-ui/react";
import Card from "components/card/Card.js";
import { COLORS } from "constants/colors";
import React from "react";
import './MembersIndicators.css';

export default function MembersIndicators({ onClickToToManagePendingAssessments, numberOfPendingEvaluations, numberOfPendingRegistrations }) {
    return (
        <Box maxW="550px">
            <Card onClick={onClickToToManagePendingAssessments}>
                <Box>
                    <SimpleGrid columns={2}>
                        <Box className="member-indicator-banner">
                            <Text onClick={onClickToToManagePendingAssessments}>Pending Members</Text>
                        </Box>
                        <Box marginRight={"25px"} className="member-indicator-banner">
                            <Text float="right" onClick={onClickToToManagePendingAssessments} color={COLORS.Primary}>{numberOfPendingRegistrations}</Text>
                        </Box>
                    </SimpleGrid>
                </Box>
                <Box>
                    <SimpleGrid columns={2}>
                        <Box className="member-indicator-banner">
                            <Text onClick={onClickToToManagePendingAssessments}>Pending Assessments</Text>
                        </Box>
                        <Box marginRight={"25px"} className="member-indicator-banner">
                            <Text float="right" onClick={onClickToToManagePendingAssessments} color={COLORS.Primary}>{numberOfPendingEvaluations}</Text>
                        </Box>

                    </SimpleGrid>
                </Box>
        </Card>
        </Box>
       
    );
}
