import React from 'react';
import { Box, Select } from '@chakra-ui/react';
import { ORGANIZATION_SPREAD } from 'constants/organizationInformation';
import { ORGANIZATION_TIME_IN_BUSINESS } from 'constants/organizationInformation';
import { ORGANIZATION_SIZE } from 'constants/organizationInformation';
import { ORGANIZATION_TYPE } from 'constants/organizationInformation';
import { ORGANIZATION_AUTHORIZATION_LEVELS } from 'constants/organizationInformation';
import { ORGANIZATION_MATURITY } from 'constants/organizationInformation';
import { ORGANIZATION_DOMINANT_INDUSTRY } from 'constants/organizationInformation';
import { ORGANIZATION_SIZE_IN_HEADCOUNTS } from 'constants/organizationInformation';

import './OnboardingStepOneSelectOptions.css';

const EvaluationSelect = ({ title, options, selectedOption, handleChange, error, textMl, textMt }) => {
    return (
        <Box position="relative">
            {selectedOption === null ? <Box position="absolute" ml={textMl} mt={textMt}><span style={{ color: 'red' } }>*</span></Box> : null}
            <Select placeholder={title} 
                value={selectedOption} 
                onChange={handleChange} 
                className={error === true ? "select-error mandatory-select" : ""}>
                {options.map((option) => (
                    <option key={option.value} value={option.value}>
                        {option.label}
                    </option>
                ))}
            </Select> 
        </Box>
    )
}

export const SelectSpread = ({ selectedOption, setSelectedOption, error }) => {

    const options = [
        { label: 'Local', value: ORGANIZATION_SPREAD.Local },
        { label: 'Mixed Global and Local', value: ORGANIZATION_SPREAD.Mixed },
        { label: 'Global', value: ORGANIZATION_SPREAD.Global },
    ];

    const handleChange = (event) => {
        setSelectedOption(event.target.value);
    };

    return (
        <EvaluationSelect 
            title="Spread"
            textMl="72px"
            textMt="10px"
            options={options}
            handleChange={handleChange}
            selectedOption={selectedOption}
            error={error}
        />
    )
};


export const SelectTimeInBusiness = ({ selectedOption, setSelectedOption, error }) => {

    const options = [
        { label: 'Short (Less than 10 years)', value: ORGANIZATION_TIME_IN_BUSINESS.Short },
        { label: 'Medium (10 - 20 years)', value: ORGANIZATION_TIME_IN_BUSINESS.Medium },
        { label: 'Long (over 20 years)', value: ORGANIZATION_TIME_IN_BUSINESS.Long },
      ];

    const handleChange = (event) => {
        setSelectedOption(event.target.value);
    };

    return (
        <EvaluationSelect 
            title="Time in Business"
            textMl="142px"
            textMt="10px"
            options={options}
            handleChange={handleChange}
            selectedOption={selectedOption}
            error={error}
        />
    )
};

export const SelectDominantIndustry = ({ selectedOption, setSelectedOption, error }) => {

    const options = [
        // { label: 'Academia', value: ORGANIZATION_DOMINANT_INDUSTRY.Academia },
        // { label: 'Construction', value: ORGANIZATION_DOMINANT_INDUSTRY.Construction },
        // { label: 'Electronics', value: ORGANIZATION_DOMINANT_INDUSTRY.Electronics },
        // { label: 'Energy & Utilities', value: ORGANIZATION_DOMINANT_INDUSTRY.EnergyAndUtilities },
        // { label: 'Financial Services', value: ORGANIZATION_DOMINANT_INDUSTRY.FinancialServices },
        // { label: 'Food & Beverage', value: ORGANIZATION_DOMINANT_INDUSTRY.FoodAndBeverage },
        // { label: 'Government', value: ORGANIZATION_DOMINANT_INDUSTRY.Government },
        // { label: 'Healthcare', value: ORGANIZATION_DOMINANT_INDUSTRY.Healthcare },
        // { label: 'Hospitality', value: ORGANIZATION_DOMINANT_INDUSTRY.Hospitality },
        // { label: 'Information Technology', value: ORGANIZATION_DOMINANT_INDUSTRY.InformationTechnology },
        // { label: 'Professional Services', value: ORGANIZATION_DOMINANT_INDUSTRY.ProfessionalServices },
        // { label: 'Manufacturing', value: ORGANIZATION_DOMINANT_INDUSTRY.Manufacturing },
        // { label: 'Media', value: ORGANIZATION_DOMINANT_INDUSTRY.Media },
        // { label: 'Medical Products', value: ORGANIZATION_DOMINANT_INDUSTRY.MedicalProducts },
        // { label: 'Non-Profit', value: ORGANIZATION_DOMINANT_INDUSTRY.NonProfit },
        // { label: 'Pharmaceuticals', value: ORGANIZATION_DOMINANT_INDUSTRY.Pharmaceuticals },
        // { label: 'Real Estate', valßue: ORGANIZATION_DOMINANT_INDUSTRY.RealEstate },
        // { label: 'Retail', value: ORGANIZATION_DOMINANT_INDUSTRY.Retail },
        // { label: 'Transport & Logistics', value: ORGANIZATION_DOMINANT_INDUSTRY.TransportAndLogistics },

        { label: 'Services: Accounting, Financial Services, Legal, Insurance,  Hospitality, and Real Estate.', value: ORGANIZATION_DOMINANT_INDUSTRY.Services },
        { label: 'Life Sciences: Medical Products, Healthcare, and Pharmaceuticals.', value: ORGANIZATION_DOMINANT_INDUSTRY.LifeSciences },
        { label: 'Consumer: Food and beverage, Other consumer, Retail, Transport & Logistics.', value: ORGANIZATION_DOMINANT_INDUSTRY.Consumer },
        { label: 'Technology: IT Services, Software, Telecommunications, Electronics, Media.', value: ORGANIZATION_DOMINANT_INDUSTRY.Technology },
        { label: 'Heavy Industry and Natural Resources: Manufacturing, Construction, Energy & Utilities, Natural Resources.', value: ORGANIZATION_DOMINANT_INDUSTRY.HeavyIndustriesAndNaturalResources },
        { label: 'Public  Sector: Academia, Government, Non-profit.', value: ORGANIZATION_DOMINANT_INDUSTRY.PublicSector },
    ];
      
    const handleChange = (event) => {
        setSelectedOption(event.target.value);
    };

    return (
        <EvaluationSelect 
            title="Dominant Industry"
            textMl="155px"
            textMt="10px"
            options={options}
            handleChange={handleChange}
            selectedOption={selectedOption}
            error={error}
        />
    )
};

export const SelectSize = ({ selectedOption, setSelectedOption, error }) => {

    const options = [
        { label: 'Small (less than $50 million)', value: ORGANIZATION_SIZE.Small },
        { label: 'Medium ($50 to $500Million)', value: ORGANIZATION_SIZE.Midsize },
        { label: 'Large (over $500 Million)', value: ORGANIZATION_SIZE.Large },
    ];

    const handleChange = (event) => {
        setSelectedOption(event.target.value);
    };

    return (
        <EvaluationSelect 
            title="Size"
            textMl="50px"
            textMt="10px"
            options={options}
            handleChange={handleChange}
            selectedOption={selectedOption}
            error={error}
        />
    )
};

export const SelectSizeInHeadcounts = ({ selectedOption, setSelectedOption, error }) => {

    const options = [
        { label: 'Small (1 - 100)', value: ORGANIZATION_SIZE_IN_HEADCOUNTS.Small },
        { label: 'Midsize (100 - 999)', value: ORGANIZATION_SIZE_IN_HEADCOUNTS.Midsize },
        { label: 'Large (over 1,000)', value: ORGANIZATION_SIZE_IN_HEADCOUNTS.Large },
    ];

    const handleChange = (event) => {
        setSelectedOption(event.target.value);
    };

    return (
        <EvaluationSelect 
            title="Size in headcounts"
            textMl="157px"
            textMt="10px"
            options={options}
            handleChange={handleChange}
            selectedOption={selectedOption}
            error={error}
        />
    )
};

export const SelectType = ({ selectedOption, setSelectedOption, error }) => {

    const options = [
        { label: 'Public for Profit', value: ORGANIZATION_TYPE.PublicForProfit },
        { label: 'Private for Profit', value: ORGANIZATION_TYPE.PrivateForProfit },
        { label: 'Non-profit', value: ORGANIZATION_TYPE.NonProfit },
        { label: 'Government', value: ORGANIZATION_TYPE.Government }
    ];

    const handleChange = (event) => {
        setSelectedOption(event.target.value);
    };

    return (
        <EvaluationSelect 
            title="Type"
            textMl="55px"
            textMt="10px"
            options={options}
            handleChange={handleChange}
            selectedOption={selectedOption}
            error={error}
        />
    )
};

export const SelectNumberOfAuthority = ({ selectedOption, setSelectedOption, error }) => {

    const options = [
        { label: 'Few (less than 5)', value: ORGANIZATION_AUTHORIZATION_LEVELS.Few },
        { label: 'Medium (5-10)', value: ORGANIZATION_AUTHORIZATION_LEVELS.Medium },
        { label: 'Many (more than 10)', value: ORGANIZATION_AUTHORIZATION_LEVELS.Many },
    ];

    const handleChange = (event) => {
        setSelectedOption(event.target.value);
    };

    return (
        <EvaluationSelect 
            title="Authority Levels"
            textMl="137px"
            textMt="10px"
            options={options}
            handleChange={handleChange}
            selectedOption={selectedOption}
            error={error}
        />
    )
};

export const SelectMaturity = ({ selectedOption, setSelectedOption, error }) => {

    const options = [
        { label: 'Beginner', value: ORGANIZATION_MATURITY.Beginner },
        { label: 'Developing', value: ORGANIZATION_MATURITY.Developing },
        { label: 'Mature', value: ORGANIZATION_MATURITY.Mature },
    ];
    
    const handleChange = (event) => {
        setSelectedOption(event.target.value);
    };

    return (
        <EvaluationSelect 
            title="Maturity"
            textMl="80px"
            textMt="10px"
            options={options}
            handleChange={handleChange}
            selectedOption={selectedOption}
            error={error}
        />
    )
};