/* eslint-disable */
/*!
  _   _  ___  ____  ___ ________  _   _   _   _ ___   
 | | | |/ _ \|  _ \|_ _|__  / _ \| \ | | | | | |_ _| 
 | |_| | | | | |_) || |  / / | | |  \| | | | | || | 
 |  _  | |_| |  _ < | | / /| |_| | |\  | | |_| || |
 |_| |_|\___/|_| \_\___/____\___/|_| \_|  \___/|___|
                                                                                                                                                                                                                                                                                                                                       
=========================================================
* Horizon UI - v1.1.0
=========================================================

* Product Page: https://www.horizon-ui.com/
* Copyright 2022 Horizon UI (https://www.horizon-ui.com/)

* Designed and Coded by Simmmple

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

import React, { useEffect } from "react";
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { NavLink } from "react-router-dom";
// Chakra imports
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  Text,
  useColorModeValue,
  useToast,
} from "@chakra-ui/react";
// Custom components
import DefaultAuth from "layouts/auth/Default";
// Assets
import illustration from "assets/img/logo/logo.png";
import { MdOutlineRemoveRedEye } from "react-icons/md";
import { RiEyeCloseLine } from "react-icons/ri";
import _ from "lodash";
import { getOrganizationLogoAsync } from "features/user/slice";
import { enterpriseSignUp } from "features/authentication/slice";

function Buy(props) {
  
  const { onSignUp, organizationLogo, onGetCompanyLogo } = props;

  // Chakra color mode
  const textColor = useColorModeValue("navy.700", "white");
  const textColorSecondary = "gray.400";
  const textColorDetails = useColorModeValue("navy.700", "secondaryGray.600");
  const brandStars = useColorModeValue("brand.500", "brand.400");
  const [show, setShow] = React.useState(false);
  const handleClick = () => setShow(!show);

  const [firstName, setFirstName] = React.useState('');
  const [lastName, setLastName] = React.useState('');
  const [email, setEmail] = React.useState('');
  const [password, setPassword] = React.useState('');
  const toast = useToast();

  var splitUrl = window.location.hostname.split('.');
  var subdomain = window.location.hostname.split('.')[0];
  const logoImage = organizationLogo !== null && organizationLogo !== '' ? organizationLogo : illustration;

  useEffect(() => {

    if (splitUrl.length > 2 && subdomain !== 'www') {
      onGetCompanyLogo(subdomain, () => { });
    }

  }, []);

  const handleClickCreateAccount = (e) => {
    e.preventDefault();

    const onSignUnSucceed = (redirectUrl) => {
      window.location.replace(redirectUrl);
    }

    const onSignUpError = (msg) => {
      toast({
        title: 'Sign Up Failed',
        description: msg,
        status: 'error',
        duration: 9000,
        isClosable: true
      })
    }

    onSignUp(firstName, lastName, email, password, subdomain,
      onSignUnSucceed, onSignUpError);
  }

  return (
   <DefaultAuth illustrationBackground={logoImage} image={logoImage}>
      <Flex
        maxW={{ base: "100%", md: "max-content" }}
        w='100%'
        mx={{ base: "auto", lg: "0px" }}
        me='auto'
        h='100%'
        alignItems='start'
        justifyContent='center'
        mb={{ base: "30px", md: "60px" }}
        px={{ base: "25px", md: "0px" }}
        flexDirection='column'>
        <Box me='auto'
          mt={{ base: "0px", xl: "30px", md: "60px" }}
        >
          <Heading color={textColor} fontSize='36px' mb='10px'>
            Create an Account
          </Heading>
        </Box>
        <Flex
          zIndex='2'
          direction='column'
          w={{ base: "100%", md: "420px" }}
          maxW='100%'
          background='transparent'
          borderRadius='15px'
          mx={{ base: "auto", lg: "unset" }}
          me='auto'
          mb={{ base: "20px", md: "auto" }}>
          {/* <Button
            fontSize='sm'
            me='0px'
            mb='26px'
            py='15px'
            h='50px'
            borderRadius='16px'
            bg={googleBg}
            color={googleText}
            fontWeight='500'
            _hover={googleHover}
            _active={googleActive}
            _focus={googleActive}>
            <Icon as={FcGoogle} w='20px' h='20px' me='10px' />
            Sign in with Google
          </Button>
          <Flex align='center' mb='25px'>
            <HSeparator />
            <Text color='gray.400' mx='14px'>
              or
            </Text>
            <HSeparator />
          </Flex> */}
          <form onSubmit={handleClickCreateAccount}>
          <FormControl>
            <Flex>
              <Box mr='24px' width='200px'>
                <FormLabel
                  display='flex'
                  ms='4px'
                  fontSize='sm'
                  fontWeight='500'
                  color={textColor}
                  mb='8px'>
                  First Name<Text color={brandStars}>*</Text>
                </FormLabel>
                <Input
                  isRequired={true}
                  variant='auth'
                  fontSize='sm'
                  ms={{ base: "0px", md: "0px" }}
                  type='text'
                  placeholder='First name'
                  mb='24px'
                  mr='30px'
                  fontWeight='500'
                  size='lg'
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                />
              </Box>
              <Box width='200px'>
                <FormLabel
                  display='flex'
                  ms='4px'
                  fontSize='sm'
                  fontWeight='500'
                  color={textColor}
                  mb='8px'>
                  Last Name<Text color={brandStars}>*</Text>
                </FormLabel>
                <Input 
                  isRequired={true}
                  variant='auth'
                  fontSize='sm'
                  ms={{ base: "0px", md: "0px" }}
                  type='text'
                  placeholder='Last Name'
                  mb='24px'
                  fontWeight='500'
                  size='lg'
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                />
              </Box>
            </Flex>
            
            <Flex>
              <Box mr='24px' width='200px'>
                <FormLabel
                  display='flex'
                  ms='4px'
                  fontSize='sm'
                  fontWeight='500'
                  color={textColor}
                  mb='8px'>
                  Email<Text color={brandStars}>*</Text>
                </FormLabel>
                <Input
                  isRequired={true}
                  variant='auth'
                  fontSize='sm'
                  ms={{ base: "0px", md: "0px" }}
                  type='email'
                  placeholder='mail@simmmple.com'
                  mb='24px'
                  fontWeight='500'
                  size='lg'
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </Box>
              <Box width='200px'>
                <FormLabel
                  ms='4px'
                  fontSize='sm'
                  fontWeight='500'
                  color={textColor}
                  display='flex'>
                  Password<Text color={brandStars}>*</Text>
                </FormLabel>
                <InputGroup size='md'>
                  <Input
                    isRequired={true}
                    fontSize='sm'
                    placeholder='Min. 8 characters'
                    mb='24px'
                    size='lg'
                    type={show ? "text" : "password"}
                    variant='auth'
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                  <InputRightElement display='flex' alignItems='center' mt='4px'>
                    <Icon
                      color={textColorSecondary}
                      _hover={{ cursor: "pointer" }}
                      as={show ? RiEyeCloseLine : MdOutlineRemoveRedEye}
                      onClick={handleClick}
                    />
                  </InputRightElement>
                </InputGroup>
              </Box>
            </Flex>
            <Button
              fontSize='sm'
              colorScheme={"green"}
              fontWeight='500'
              w='100%'
              h='50'
              type="submit"
              mb='24px'>
              Proceed to Payment
            </Button>
          </FormControl>
          </form>
          <Flex
            flexDirection='column'
            justifyContent='center'
            alignItems='start'
            maxW='100%'
            mt='0px'>
            <Text color={textColorDetails} fontWeight='400' fontSize='14px'>
              Already have an account?
              <NavLink to='/auth/sign-in'>
                <Text
                  color="green"
                  as='span'
                  ms='5px'
                  fontWeight='500'>
                  Sign in
                </Text>
              </NavLink>
            </Text>
          </Flex>
        </Flex>
      </Flex>
    </DefaultAuth>
  );
}


Buy.propTypes = {
  isAuthenticated: PropTypes.bool,
  onSignUp: PropTypes.func,
  organizationLogo: PropTypes.string,
  onGetCompanyLogo: PropTypes.func
};

Buy.defaultProps = {
  isAuthenticated: false,
  organizationLogo: '',
  onSignUp: () => {},
  onGetCompanyLogo: () => {}
}

function mapStateToProps(state) {
  const { authentication } = state;

  const { isAuthenticated, organizationLogo } = authentication;

  return {
    isAuthenticated,
    organizationLogo
   };
};

function mapDispatchToProps (dispatch) {
  return {
      onSignUp: (firstName, lastName, email, password, domain, onSignUpSucceed, onSignUpError) => dispatch(enterpriseSignUp({ firstName, lastName, email, password, domain, onSignUpSucceed, onSignUpError })),
      onGetCompanyLogo: (domain, onSuccess ) => dispatch(getOrganizationLogoAsync({ domain, onSuccess })),
      dispatch,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Buy);