/* eslint-disable */
import  { 
  Box,
  Grid,
  GridItem,
  Text,
  Checkbox,
  Heading, 
  SimpleGrid
} from "@chakra-ui/react";
import Card from "components/card/Card";
import _ from "lodash";

// Custom components
import React, { useEffect } from "react";

export default function EvaluationParticipantsBody({ 
    onSave, 
    teamMembers, 
    participants, 
    onClickAddParticipant
  }) {
  
    const [stateParticipants, setStateParticipants] = React.useState(participants);

    const isParticipanting = (userId) => {
      return stateParticipants.indexOf(userId) >= 0;
    }

    useEffect(() => {
      setStateParticipants(participants);
    }, [participants])
  
    return (
        <Box width={"100%"} style={{ overflowY: 'auto'}} height={"500px"}>
          <SimpleGrid columns={{ sm: 1, md: 1, lg: 3, xl: 3 }} spacingY={4} spacingX={4}>
              {teamMembers ? teamMembers.map((u, i) => {
                return (
                  <Card>
                    <Grid templateColumns={{ sm: "1fr 1fr 1fr", md: "1fr 1fr 1fr" }}>
                      <GridItem>
                        <Box onClick={() => onClickTeamMember(u)} className="member-card">
                          <div style={{ width: "49px", height: "49px", textAlign: 'center', backgroundColor: "#0095FF1A", color: "#0095ff", borderRadius: "50%" }} >
                              <Heading size="md" style={{ paddingTop: "10px" }}>
                                {`${u.firstName.substring(0, 1)} ${u.lastName.substring(0, 1)}`}
                              </Heading>
                            </div>
                        </Box>
                      </GridItem>
                      <GridItem>
                        <Box onClick={() => onClickTeamMember(u)} className="member-card">
                          <Box ml="10px" mt="7px">
                            <Heading size="xs">{u.firstName} {u.lastName}</Heading>
                            <Box>
                              <Text className="evaluation-text-description" 
                                fontSize="8px"
                                style={{ color: "#0000004D", wordBreak: 'break-all', width: '20px' }} 
                              >
                                  {u.email}
                              </Text>
                            </Box>
                          </Box>
                        </Box>
                      </GridItem>
                      <GridItem>
                          <Box height={"8px"} mt="8px" textAlign={"center"}>
                            <Checkbox 
                                isChecked={isParticipanting(u.id)}
                                onChange={(e) => onClickAddParticipant(e.target.checked, u.id)}
                                marginTop="5px"
                                ml="auto"
                                mr="auto"
                              />
                          </Box>
                      </GridItem>
                    </Grid>
                  </Card>)}) : null}
            </SimpleGrid>
        </Box>
    )
  }