export const USER_ROLE = { 
    Undetermined: 0,
    TeamMember: 1,
    Admin: 2,
    Owner: 3
}

export const USER_ROLE_DESC = {
    [USER_ROLE.Undetermined]: 'Undetermined',
    [USER_ROLE.TeamMember]: 'Team Member',
    [USER_ROLE.Admin]: 'CEO/admin',
    [USER_ROLE.Owner]: 'Super Admin'
}