import { createSlice } from '@reduxjs/toolkit';
import { getAuthenticatedClient } from '../../api';
import { INSIGHT_VIEW } from 'constants/insightsViews.const';

function transformIntoChartData(payload, key) {
  const result = [];

  for (const category in payload[key]) {
    if (Object.hasOwnProperty.call(payload[key], category)) {
      const data = {
        name: category,
        overOptimal: parseFloat(payload[key][category].overOptimal).toFixed(2),
        optimal: parseFloat(payload[key][category].optimal).toFixed(2),
        subOptimal: parseFloat(payload[key][category].subOptimal).toFixed(2),
      };
      result.push(data);
    }
  }

  return result;
}

function transformIntoMyChartData(payload, key) {
  const result = [];

  for (const category in payload[key]) {
    if (Object.hasOwnProperty.call(payload[key], category)) {
      const overOptimal = parseFloat(payload[key][category].overOptimal);
      const optimal = parseFloat(payload[key][category].optimal);
      const subOptimal = parseFloat(payload[key][category].subOptimal);
      if (overOptimal !== 0 || optimal !== 0 || subOptimal !== 0) {
        const data = {
          name: category,
          overOptimal: overOptimal.toFixed(2),
          optimal: optimal.toFixed(2),
          subOptimal: subOptimal.toFixed(2),
        };
        result.push(data);
      }
    }
  }

  return result;
}


export const insightsSlice = createSlice({
  name: 'insights',
  initialState: {
    title: 'Trends',
    selected: 'trends',
    description: 'Each WorkIQ client-organization categorizes themselves based on six criteria: industry, financial size, headcount, organizational type, complexity, and years in operation. Our WorkIQ suite comprises three products, each containing 96 questions, termed "indicators." The "Trends" page displays crosstabulations of these indicators with the criteria, revealing average score patterns across our dataset of organizations and individuals. Charts below utilize a scale from zero to ten, divided into sub-optimal, optimal, and over-optimal segments.',
    insightsTabIndex: 0,
    trends: [],
    view: INSIGHT_VIEW.Trends,
    trendsBySizeData: [],
    trendsByTimeInBusinessData: [],
    trendsByIndustryData: [],
    trendsBySpreadData: [],
    trendsByTypeData: [],
    trendsByHeadCountsData: [],
    myOrganizationtrendsBySizeData: [],
    myOrganizationtrendsByTimeInBusinessData: [],
    myOrganizationtrendsByIndustryData: [],
    myOrganizationtrendsBySpreadData: [],
    myOrganizationtrendsByTypeData: [],
    myOrganizationtrendsByHeadCountsData: [],
    loading: false,
    videoDialogOpen: false
  },
  reducers: {
    setInsightsModule: (state, action) => {
      state.title = action.payload.title;
      state.selected = action.payload.selected;
      state.description = action.payload.description;
    },
    setInsightsTab: (state, action) => {
      state.insightsTabIndex = action.payload;
    },
    setView: (state, action) => {
      state.view = action.payload;
    },
    setTrendsResult: (state, action) => {
      state.trends = action.payload;
      
      try
      {
        state.trendsBySizeData = transformIntoChartData(action.payload, 'averageBySize');
        state.trendsByTimeInBusinessData = transformIntoChartData(action.payload, 'averageByTimeInBusiness');
        state.trendsByIndustryData = transformIntoChartData(action.payload, 'averagesByIndustry');
        state.trendsBySpreadData = transformIntoChartData(action.payload, 'averageBySpread');
        state.trendsByTypeData = transformIntoChartData(action.payload, 'averageByType');
        state.trendsByHeadCountsData = transformIntoChartData(action.payload, 'averagesByHeadCounts');

        state.myOrganizationTrendsBySizeData = transformIntoMyChartData(action.payload, 'myOrganizationAverageBySize');
        state.myOrganizationTrendsByTimeInBusinessData = transformIntoMyChartData(action.payload, 'myOrganizationAverageByTimeInBusiness');
        state.myOrganizationTrendsByIndustryData = transformIntoMyChartData(action.payload, 'myOrganizationAveragesByIndustry');
        state.myOrganizationTrendsBySpreadData = transformIntoMyChartData(action.payload, 'myOrganizationAverageBySpread');
        state.myOrganizationTrendsByTypeData = transformIntoMyChartData(action.payload, 'myOrganizationAverageByType');
        state.myOrganizationTrendsByHeadCountsData = transformIntoMyChartData(action.payload, 'myOrganizationAveragesByHeadCounts');
      }
      catch(e){
        console.log('charts error', e);
      }
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setVideoDialogOpen: (state, action) => {
      state.videoDialogOpen = action.payload;
    }
  }
});

export const { 
    setInsightsModule,
    setInsightsTab,
    setTrendsResult,
    setLoading,
    setView,
    setVideoDialogOpen
 } = insightsSlice.actions;


 export const getInsightTrends = (surveyType, onSuccess, onError) => async dispatch => {

  dispatch(setLoading(true));
  dispatch(setVideoDialogOpen(true));
  const client = await getAuthenticatedClient(dispatch);
  client.get(`v1/metrics/trends?surveyType=${surveyType}`)
      .then(res => {
          const metrics = res.data;
          dispatch(setTrendsResult(metrics));
          onSuccess();
          dispatch(setLoading(false));
          dispatch(setVideoDialogOpen(false));
      })
      .catch((error) => {
        onError();
        dispatch(setLoading(false));
        dispatch(setVideoDialogOpen(false));
      });
};

export default insightsSlice.reducer;
