import { Box, Grid, GridItem, IconButton, Text } from "@chakra-ui/react";
import { CloseIcon } from "@chakra-ui/icons";
import { HarmonizationIndexLabels } from "constants/harmonizationIndexDetails.const";

function HarmonizationIndexDetails({ onClose, surveyType }) {

    if (!HarmonizationIndexLabels[surveyType]){
        return <Box>
        </Box>;
    }

    return (<Box>
        <Box textAlign={"center"} mb="10px">
            <IconButton
                isRound={true}
                variant='solid'
                backgroundColor={HarmonizationIndexLabels[surveyType].firstGrade.color}
                aria-label='Done'
                fontSize='10px'
                color={HarmonizationIndexLabels[surveyType].thirdGrade.color}
                height={"20px"}
                onClick={() => onClose()}
                icon={<CloseIcon />}
            /> 
        </Box>
        <Box textAlign={"center"} mb="5px">
            <Text fontSize={"12px"}>Specifics of the Harmonization Index</Text>
        </Box>
            <Box borderTopColor={HarmonizationIndexLabels[surveyType].firstGrade.color} borderRadius={"5px"} borderTopWidth={"1px"} backgroundColor={HarmonizationIndexLabels[surveyType].firstGrade.color}>
                <Grid templateColumns={"0.5fr 1fr"} columnGap={0} padding="5px" paddingRight={"10px"}>
                    <GridItem>
                        <Box textAlign={"center"} pt="3px">
                            <Text fontSize={"11px"} fontWeight={"bold"}>{HarmonizationIndexLabels[surveyType].firstGrade.range}</Text>
                        </Box>
                    </GridItem>
                    <GridItem>
                        <Box>
                            <Text fontWeight={"bold"}>{HarmonizationIndexLabels[surveyType].firstGrade.title}</Text>
                        </Box>
                        <Box pb="20px">
                            <Text fontSize={"12px"}>{HarmonizationIndexLabels[surveyType].firstGrade.content}</Text>
                            {/* <Text fontSize={"12px"}>{HarmonizationIndexLabels[surveyType].firstGrade.action}</Text> */}
                        </Box>
                    </GridItem>
                </Grid>
            </Box>
            <Box borderTopColor={HarmonizationIndexLabels[surveyType].secondGrade.color} borderRadius={"5px"} paddingRight={"10px"} borderTopWidth={"1px"} backgroundColor={HarmonizationIndexLabels[surveyType].secondGrade.color}>
                <Grid templateColumns={"0.5fr 1fr"} padding="5px">
                    <GridItem>
                        <Box textAlign={"center"} pt="3px">
                            <Text fontSize={"11px"} fontWeight={"bold"}>{HarmonizationIndexLabels[surveyType].secondGrade.range}</Text>
                        </Box>
                    </GridItem>
                    <GridItem>
                        <Box>
                            <Box>
                                <Text fontWeight={"bold"}>{HarmonizationIndexLabels[surveyType].secondGrade.title}</Text>
                            </Box>
                            <Box>
                                <Text fontSize={"12px"}>{HarmonizationIndexLabels[surveyType].secondGrade.content}</Text>
                                {/* <Text fontSize={"12px"}>{HarmonizationIndexLabels[surveyType].secondGrade.action}</Text> */}
                            </Box>
                        </Box> 
                    </GridItem>
                </Grid>
            </Box>
            <Box borderTopColor={HarmonizationIndexLabels[surveyType].thirdGrade.color} borderRadius={"5px"} paddingRight={"10px"} borderTopWidth={"1px"} backgroundColor={HarmonizationIndexLabels[surveyType].thirdGrade.color}>
                <Grid templateColumns={"0.5fr 1fr"} padding="5px">
                    <GridItem>
                        <Box textAlign={"center"} pt="3px">
                            <Text fontSize={"11px"} fontWeight={"bold"}>{HarmonizationIndexLabels[surveyType].thirdGrade.range}</Text>
                        </Box>
                    </GridItem>
                    <GridItem>
                        <Box>
                            <Text fontWeight={"bold"}>{HarmonizationIndexLabels[surveyType].thirdGrade.title}</Text>
                        </Box>
                        <Box minH="80px">
                            <Text fontSize={"12px"}>{HarmonizationIndexLabels[surveyType].thirdGrade.content}</Text>
                            {/* <Text fontSize={"12px"}>{HarmonizationIndexLabels[surveyType].thirdGrade.action}</Text> */}
                        </Box>
                    </GridItem>
                </Grid>
            </Box>
        </Box>
    )
}

export default HarmonizationIndexDetails;