// Chakra Imports
import {
  Box,
  Button,
  Grid,
  GridItem,
  Heading,
  Hide,
  IconButton,
  Select,
  SimpleGrid,
  Tab,
  TabList,
  Tabs,
  Text,
} from "@chakra-ui/react";
import PropTypes from "prop-types";
import React, { useState, useEffect, useCallback } from "react";
import { PersonIcon } from "components/icons/Icons";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";

import './NavbarAdmin.css';
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { USER_ROLE } from "constants/userRoles.const";
import { listOrganizationUsersAsync } from "features/user/slice";
import { getIndividualMetrics } from "features/metrics/slice";
import { setUserIdSelectedOnNavBar } from "features/metrics/slice";
import { resetIsSelectingUserOnNavBar } from "features/metrics/slice";
import { setInsightsModule } from "features/insights/slice";
import { INSIGHTS_DESC } from "constants/insights.const";
import { setInsightsTab } from "features/insights/slice";
import { getInsightTrends } from "features/insights/slice";
import { SURVEY_TYPE } from "constants/evaluation.const";
import { INSIGHT_VIEW } from "constants/insightsViews.const";
import { setView } from "features/insights/slice";

function NavbarAdmin(props) {
  const { 
    userId, 
    secondary, 
    message, 
    firstName, 
    lastName, 
    role, 
    teamMembers, 
    onLoadTeamMembers, 
    onLoadIndividualMetrics,
    onSelectUserInFilter,
    userIdSelectedOnNavBar,
    onResetSelectingUsersInFilter,
    onChangeInsightsModule,
    insightsTabIndex,
    onChangeInsightsTabIndex,
    onGetTrends,
    onChangeInsightsView,
    insightView
  } = props;

  const history = useHistory();
  const location = useLocation();
  const [hasSelectedFilters, setSetSelectedFilters] = useState(false);

  const defaultGuid = "00000000-0000-0000-0000-000000000000";
  

  const IsResultPageAndAdmin = useCallback(() => {
    return location.pathname === '/admin/assessment' && role === USER_ROLE.Admin;
  }, [location, role]);

  const IsInsightsPageAndAdmin = () => {
    return location.pathname === '/admin/insights' && role === USER_ROLE.Admin;
  }

  useEffect(() => {
    if(IsResultPageAndAdmin()){
      onLoadTeamMembers();
    }
  }, []);

  const surveyTypeMapping = {
    0: SURVEY_TYPE.EmployeeIq,
    1: SURVEY_TYPE.BoardIq,
    2: SURVEY_TYPE.OrgIq
  }

  const onChangeSelectedMember = (e) => {
    const userIdAnName = e.target.value.split(';');
    onSelectUserInFilter(userIdAnName[0], userIdAnName[1]);
    setSetSelectedFilters(true);
  }

  const onClickGo = () => {
    setSetSelectedFilters(false);
    onLoadIndividualMetrics(userIdSelectedOnNavBar, () => { 
      onResetSelectingUsersInFilter();
    });
  }

  const onChangeSelectedInsightsModule = (e) => {
    const value = e.target.value;

    if (value) {
      const module = INSIGHTS_DESC[value];

      onChangeInsightsView(parseInt(value));
  
      onChangeInsightsModule(module.title, module.description, value);
    }
  }

  const handleInsightsTabsChange = (index) => {
    onChangeInsightsTabIndex(index);
    onGetTrends(surveyTypeMapping[index], () => { });
  }

  return (
    <Box id="main-nav" style={{ height: "61px", paddingLeft: '7px' }} width={"100%"}>
      <SimpleGrid columns={2} bg="#EDF0F6" width={"100%"} >
        <Box ml={"30px"} mt={"15px"} mb="15px">
        {IsResultPageAndAdmin() ? <Grid templateColumns={"1.5fr 0.5fr"} columnGap={"15px"}>
            <GridItem>
               <Select placeholder='Select Team Member' onChange={(e) => onChangeSelectedMember(e)} backgroundColor={"#fff"}>
                    <option value={`${defaultGuid};ALL`}><Text>All</Text></option>
                    <option value={`${userId};${firstName} ${lastName}`}><Text>Myself ({firstName} {lastName})</Text></option>
                    {teamMembers.map((m, i) => {
                      return ( m.id !== userId ? <option value={`${m.id};${m.firstName} ${m.lastName} (${m.email})`}>
                        <Text>{m.firstName} {m.lastName} ({m.email})</Text></option> : null)
                    })}
                </Select>
            </GridItem>   
            <GridItem>
              {hasSelectedFilters === true ? <Button 
                  colorScheme='blue' 
                  ml="auto"
                  mr="auto" 
                  onClick={onClickGo}>
                      Go
              </Button> : null}
            </GridItem>
          </Grid> : null}
          {IsInsightsPageAndAdmin() ? <Grid templateColumns={"1.5fr 0.5fr"} columnGap={"15px"}>
            <GridItem>
               <Select placeholder='Select Module' value={insightView} onChange={(e) => onChangeSelectedInsightsModule(e)} backgroundColor={"#fff"}>
                    <option value={INSIGHT_VIEW.Trends}>Trends</option>
                    <option value={INSIGHT_VIEW.Analytics}>Analytics</option>
                    <option value={INSIGHT_VIEW.DataDriveDecisions}>Data-drive decisions</option>
                </Select>
            </GridItem> 
            <GridItem style={{ position: 'relative'}}>
              <Tabs variant='enclosed' index={insightsTabIndex} onChange={handleInsightsTabsChange}>
                <TabList>
                  <Tab w={"140px"}>Employee IQ</Tab>
                  <Tab w={"140px"}>Board IQ</Tab>
                  <Tab w={"140px"}>Org IQ</Tab>
                </TabList>
            </Tabs>
            </GridItem> 
          </Grid> : null}
        </Box>
        <Box>
          <Box paddingTop="15px" pb="5px" marginRight={{ sm: "0px", md: "0px", lg: "80px", xl: "80px" } } position={"relative"} display={"flex"} justifyContent={"flex-end"} >
              <IconButton float="right" marginRight={"20px"}onClick={() => history.push('/admin/profile')}>
                <PersonIcon />
              </IconButton>
              <Hide below="md">
                <Box float="right" marginRight={"20px"} mt="8px" className="nav-username-section" onClick={() => history.push('/admin/profile')}>
                  <Heading size="md">{`${firstName} ${lastName}`}</Heading>
                </Box>
              </Hide>
          </Box>
            </Box>
      </SimpleGrid>
      {secondary ? <Text color='white'>{message}</Text> : null}
    </Box>
  );
}

NavbarAdmin.propTypes = {
  userId: PropTypes.string,
  brandText: PropTypes.string,
  variant: PropTypes.string,
  secondary: PropTypes.bool,
  fixed: PropTypes.bool,
  onOpen: PropTypes.func,
  routes: PropTypes.array,
  firstName: PropTypes.string,
  lastname: PropTypes.string,
  teamMembers: PropTypes.array,
  onLoadTeamMembers: PropTypes.func,
  userIdSelectedOnNavBar: PropTypes.string,
  onResetSelectingUsersInFilter: PropTypes.func,
  onChangeInsightsModule: PropTypes.func,
  selectedInsights: PropTypes.string,
  insightsTabIndex: PropTypes.number,
  onChangeInsightsTabIndex: PropTypes.func,
  onGetTrends: PropTypes.func,
  onChangeInsightsView: PropTypes.func,
  insightView: PropTypes.number
};


function mapStateToProps(state) {

  const { 
    userId,
    firstName, 
    lastName, 
    role,
    teamMembers
  } = state.user;

  const {
    userIdSelectedOnNavBar
  } = state.metrics;

  const {
    selected,
    insightsTabIndex,
    view
  } = state.insights;

  return {
    userId,
    firstName,
    lastName,
    role,
    teamMembers,
    userIdSelectedOnNavBar,
    selectedInsights: selected,
    insightsTabIndex,
    insightView: view
   };
};

function mapDispatchToProps (dispatch) {
  return {
      dispatch,
      onLoadTeamMembers: () => dispatch(listOrganizationUsersAsync(null, () => {})),
      onLoadIndividualMetrics: (userId, onSuccess) => dispatch(getIndividualMetrics({ userId, onSuccess })),
      onSelectUserInFilter: (userId, userName) => dispatch(setUserIdSelectedOnNavBar({ userId, userName })),
      onResetSelectingUsersInFilter: () => dispatch(resetIsSelectingUserOnNavBar()),
      onChangeInsightsModule: (title, description, selected) => dispatch(setInsightsModule({ title, description, selected })),
      onChangeInsightsTabIndex: (index) => dispatch(setInsightsTab(index)),
      onGetTrends: (surveyType, onSuccess) => dispatch(getInsightTrends(surveyType, onSuccess)),
      onChangeInsightsView: (view) => dispatch(setView(view))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(NavbarAdmin);