export const BEHAVIORS_DESC = {
    'action': 'Focus on "what", on achieving results.',
    'people' :  'Focus on "how", on rules, regulations.',
    'process': 'Focus on "who", on on enriching the human capital',
    'strategy': 'Focus on "why", on coping with change.' 
}

export const TRAITS_DESC = {
    //Employee IQ and Career IQ
    'activity level': 'The “Activity Level” refers to the internal motivation or impulse that compels individuals to engage in purposeful behavior towards achieving desired outcomes. It is the psychological force that propels people to overcome obstacles, make decisions, and execute plans.',
    'initiative': 'The initiative refers to the proactive and self-driven behavior of taking the lead, making decisions, and taking action without being prompted or instructed by others. It involves a sense of personal responsibility, motivation, and a willingness to go above and beyond what is expected.',
    'goal achievement': 'Goal achievement refers to the successful attainment of a specific objective or target. It involves the process of setting clear and measurable goals and then taking deliberate actions to reach those goals. Goal achievement often requires planning, effort, perseverance, and the ability to overcome obstacles.',
    'persistence': 'Persistence refers to the quality of steadfastly and tenaciously continuing one’s efforts, actions, or pursuit of a goal or objective despite facing obstacles, setbacks, or challenges. It involves the ability to maintain determination, resilience, and a long-term focus in the face of adversity or difficulties.',
    'competitiveness': 'Competitiveness refers to the inclination, drive, or desire to outperform others, excel in one’s endeavors, and achieve success relative to others. It is the characteristic or mindset of being actively engaged in competition and striving to be better, stronger, or more successful than competitors or peers.',
    'perception': 'Perception refers to the process by which individuals interpret and make sense of sensory information from the environment. It involves the organization, interpretation, and understanding of sensory stimuli, to form meaningful mental representations of the world around us.',
    'listening': 'Listening capability refers to the skill and capacity to effectively receive, interpret, and understand spoken or auditory information. It involves actively engaging with what is being communicated and processing the message accurately and comprehensively.',
    'flexibility': 'A flexible personality refers to the ability and willingness to adapt, adjust, and respond effectively to changing circumstances, situations, or challenges. It involves having open-mindedness and a capacity to modify one’s thoughts, behaviors, and attitudes based on the demands of different situations.',
    
    //Org IQ
    'organizational culture': `Organizational culture is the set of shared values, beliefs, and assumptions that shape the behavior and interactions of people within an organization. It is the unique feature of an organization that distinguishes it from other organizations`,
    'market perception': `A market encompasses the entirety of potential customers who share a specific desire or requirement and possess the willingness and ability to engage in exchange to fulfill that need or want.`,
    'money management': `The availability of funds, the cost of borrowing, the profitability of investments, and the flow of money within the organization.`,
    'setting goals': `The process by which an organization establishes its own objectives and those of its members, along with the mechanisms employed to track progress towards achieving those goals.`,
    'organizational structure': `How are roles, responsibilities, and decision-making authority distributed across the organization`,
    'organization members': `All individuals who are affiliated with or belong to a particular organization. This can include employees, volunteers, board members, stakeholders, and any other individuals who are formally or informally connected to the organization.`,
    'reward system': `How does an organization motivate and empower its members to pursue both personal and organizational goals.`,
    'information flow': `The organization's approach to gathering, structuring, analyzing, and preserving business-critical information.`,

    //Board IQ
    'meetings': `Formal gatherings of a board of directors, typically held at regular intervals, to discuss and make decisions on matters related to the organization's governance, strategy, and performance.`,
    'composition': `It refers to the makeup and characteristics of an organization's board of directors. It encompasses the number, qualifications, experience, diversity, and background of the individuals who serve on the board.`,
    'accountability': `It refers to the obligation of a board of directors to be responsible for its actions, decisions, and the overall performance of the organization it governs.`,
    'board-management relations': `It refers to the dynamic and collaborative interaction between the board of directors and the organization's management team.`,
    'standards of conduct': `Standards of conduct for a board of directors are a set of principles and guidelines that outline the expected behavior and ethical responsibilities of board members.`,
    'board motivation': `The motivation for boardwork is the directors' willingness to contribute to the corporate governance because of personal fullfilment, making adifference, or financial rewards.`,
    'board interaction': `What is the strategic alignment between the Board's direction and Management's actions, and how they work together effectively to achieve the organization's goals.`,
    'open-mindedeness': `The willingness and ability of the board of directors to share information and engage with management and stakeholders.`,
}   