import { Modal, ModalBody, ModalCloseButton, ModalHeader, ModalContent, ModalOverlay } from "@chakra-ui/react";
import { SURVEY_TYPE } from "constants/evaluation.const";
import React from "react";
import Evaluation from "./components/Evaluation";

function BoardIQ(props) {
  return (
    <Modal onClose={props.onClose} size='xl' isOpen={props.isOpen}>
    <ModalOverlay />
    <ModalContent>
      <ModalHeader>BoardIQ</ModalHeader>
      <ModalCloseButton />
      <ModalBody>
        <Evaluation 
              surveyType={SURVEY_TYPE.BoardIq}
              surveyTypeText={"Board IQ"}
              onCloseDialog={props.onClose}
            />
      </ModalBody>
      </ModalContent>
    </Modal>
  );
}

export default BoardIQ;