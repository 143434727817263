/*!
  _   _  ___  ____  ___ ________  _   _   _   _ ___   
 | | | |/ _ \|  _ \|_ _|__  / _ \| \ | | | | | |_ _| 
 | |_| | | | | |_) || |  / / | | |  \| | | | | || | 
 |  _  | |_| |  _ < | | / /| |_| | |\  | | |_| || |
 |_| |_|\___/|_| \_\___/____\___/|_| \_|  \___/|___|
                                                                                                                                                                                                                                                                                                                                       
=========================================================
* Horizon UI - v1.1.0
=========================================================

* Product Page: https://www.horizon-ui.com/
* Copyright 2022 Horizon UI (https://www.horizon-ui.com/)

* Designed and Coded by Simmmple

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// Chakra imports
import {
    Box,
    SimpleGrid,
  } from "@chakra-ui/react";
  import PropTypes from 'prop-types';
  import { connect } from 'react-redux';
  import Banner from "views/admin/default/components/Banner";
  import Evaluation from "views/admin/default/components/Evaluation";
  import React, { useCallback, useEffect } from "react";
  import Authenticated from "../authenticated";
  import EvaluationBanner from "./components/EvaluationBanner";
  import { getUserSurveys } from "features/userSurveys/slice";
  import { SURVEY_TYPE_DESCRIPTION } from "constants/surveyTypes.const";
import { USER_ROLE } from "constants/userRoles.const";
  
  function PaymentConfirmation(props) {
    const { 
      userId,
      userSurveys,
      numberOfTeamMembers,
      numberOfEvaluations,
      numberOfEmployeeIQEvaluations,
      numberOfOrgIQEvaluations,
      numberOfCareerIQEvaluations,
      numberOfBoardIQEvaluations,
      numberOfPendingEvaluations,
      firstName, 
      lastName, 
      role, 
      isAuthenticated, 
      organizationName,
      onLoadUserSurveys
    } = props;
  
    const validateAuthentication = useCallback(() => {
      if (isAuthenticated === false){
        window.location.replace(`${process.env.REACT_APP_WELCOME_REDIRECT}`);
      }
    }, [isAuthenticated]);
  
    useEffect(() => {
      validateAuthentication();
      if(userId && userId !== ''){
        onLoadUserSurveys(userId, 'Pending', () => { }, () => { });
      }
    }, []);
  
    const roleName = role === USER_ROLE.Admin 
      ? 'Company Representative / Admin' 
      : role === USER_ROLE.Owner
        ? "Introgauge Staff"
        : "Team Member";
  
    return (
      <Authenticated>
        <Box pt={{ base: "130px", md: "80px", xl: "80px" }} mt={10}>
          {isAuthenticated === true ? 
          <Box>
            <SimpleGrid columns={{sm: 1, md: 2, lg: 2}} spacingX={3} mt={10}>
                  <Banner
                    name={`${firstName} ${lastName}`}
                    job={roleName}
                    organizationName={organizationName}
                    teamMembers={numberOfTeamMembers}
                    surveyEvaluations={numberOfEvaluations}
                    numberOfBoardIqEvaluations={numberOfBoardIQEvaluations}
                    numberOfEmployeeIqEvaluations={numberOfEmployeeIQEvaluations}
                    numberOfOrgIqEvaluations={numberOfOrgIQEvaluations}
                    numberOfCareerIQEvaluations={numberOfCareerIQEvaluations}
                    pendingToBeAnswered={numberOfPendingEvaluations}
                  />
                  {role === USER_ROLE.Admin ? <Evaluation
                    mt={10}
                    pb={30}
                    ml={4}
                  /> : null}
            <SimpleGrid columns={{sm: 1, md: 3, lg: 3}} spacingX={2} spacingY={3} mt={10}>
              {userSurveys && userSurveys.length > 0 ? userSurveys.map((s, i) => {
                  return <EvaluationBanner
                    userSurveyResponseId={s.id}
                    type={SURVEY_TYPE_DESCRIPTION[s.surveyType]}
                    date={s.createDate}
                    updateDate={s.updateDate}
                    status={s.status}
                  />
              }) : null}
            </SimpleGrid>
          </SimpleGrid>
        </Box>
         : null}
        </Box>
      </Authenticated>
    );
  }
  
  PaymentConfirmation.propTypes = {
    userId: PropTypes.string,
    subscriptions: PropTypes.array,
    userSurveys: PropTypes.array,
    numberOfTeamMembers: PropTypes.number,
    numberOfEvaluations: PropTypes.number,
    numberOfEmployeeIQEvaluations: PropTypes.number,
    numberOfOrgIQEvaluations: PropTypes.number,
    numberOfBoardIQEvaluations: PropTypes.number,
    numberOfPendingEvaluations: PropTypes.number,
    numberOfCompletedEvaluations: PropTypes.number,
    onLoadUserSurveys: PropTypes.func
  };
  
  PaymentConfirmation.defaultProps = {
    userId: '',
    subscriptions: [],
    userSurveys: [],
    numberOfTeamMembers: 0,
    numberOfEvaluations: 0,
    numberOfEmployeeIQEvaluations: 0,
    numberOfOrgIQEvaluations: 0,
    numberOfBoardIQEvaluations: 0,
    numberOfPendingEvaluations: 0,
    numberOfCompletedEvaluations: 0,
    onLoadUserSurveys: () => { }
  }
  
  function mapStateToProps(state) {
  
    const { 
      teamMembers, 
      firstName, 
      lastName, 
      role, 
      organizationName, 
      numberOfTeamMembers,
      numberOfEvaluations,
      numberOfEmployeeIQEvaluations,
      numberOfOrgIQEvaluations,
      numberOfBoardIQEvaluations,
      numberOfPendingEvaluations,
      numberOfCareerIQEvaluations,
      numberOfCompletedEvaluations
    } = state.user;
  
    const { isAuthenticated, userId } = state.authentication;
  
    const { userSurveys } = state.userSurveys;
  
    return {
      userId,
      teamMembers,
      firstName,
      lastName,
      role,
      organizationName,
      isAuthenticated,
      numberOfTeamMembers,
      numberOfEvaluations,
      numberOfEmployeeIQEvaluations,
      numberOfOrgIQEvaluations,
      numberOfCareerIQEvaluations,
      numberOfBoardIQEvaluations,
      numberOfPendingEvaluations,
      numberOfCompletedEvaluations,
      userSurveys
     };
  };
  
  function mapDispatchToProps (dispatch) {
    return {
        onLoadUserSurveys: (userId, status, onSuccess, onError ) => dispatch(getUserSurveys({ userId, status, onSuccess, onError })),
        dispatch
    }
  }
  
  export default connect(mapStateToProps, mapDispatchToProps)(PaymentConfirmation);