// Chakra imports
import {
    Box, Text
  } from "@chakra-ui/react";
import Authenticated from "views/admin/authenticated";

import './index.css';

function Intro(props) {
    
    return (
      <Authenticated>
        <Box height={"300px"} >
          <Box p="40px" pos={"absolute"} bottom="60px" backgroundColor="#D3D3D3" ml="40px" mr="40px">
            <Box>
              <Text>
                Your organization has subscribed for our suite of products called Work IQ, which is an eco-system of three interconnected products: Employee IQ, Board IQ, and Org IQ.
              </Text>
            </Box>
            <Box mt="10px">
              <Text>
                This report has been compiled based on your responses on the Employee IQ. While it offers a comprehensive and robust overview, this report alone may not provide a complete understanding of your organization’s dynamics. Therefore, it is strongly advised to also take the Board IQ and the Org IQ and review their reports to gain complete insight.
              </Text>
            </Box>
            <Box mt="10px">
              <Text>
                Furthermore, your responses on the Employee IQ have been used to prepare an aggregate team report and you may ask the team leader about obtaining access to that document.
              </Text>
            </Box>
            <Box mt="10px">
              <Text>
                Lastly, this is not a verbatim version of your report available online - the Results and Definitions pages in the online portal offer more content and visual presentations. Thus, we recommend frequent visits to your portal where we post updates and useful information.
              </Text>
            </Box>
          </Box>
        </Box>
      </Authenticated>
    );
  }
  
  export default Intro;