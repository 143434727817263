import { extendTheme } from "@chakra-ui/react";
import { CardComponent } from "./additions/card/card";
import { buttonStyles } from "./components/button";
import { badgeStyles } from "./components/badge";
import { inputStyles } from "./components/input";
import { progressStyles } from "./components/progress";
import { sliderStyles } from "./components/slider";
import { textareaStyles } from "./components/textarea";
import { switchStyles } from "./components/switch";
import { linkStyles } from "./components/link";
import { breakpoints } from "./foundations/breakpoints";
import { globalStyles } from "./styles";

export default extendTheme(
  {
    colors: {
      brand: {
        50: "#eef2ff", // Lightest
        100: "#e0e7ff",
        200: "#c7d2fe",
        300: "#a5b4fc",
        400: "#818cf8", // Light
        500: "#6366f1", // Base or Default
        600: "#4f46e5",
        700: "#4338ca",
        800: "#3730a3", // Dark
        900: "#312e81", // Darkest
      },
      intro: {
        base: "#6366f1", // Base color for buttons
        hover: "#4f46e5", // Hover state color for buttons
        active: "#3730a3", // Active state color for buttons
      },
    },
  },
  { breakpoints }, // Breakpoints
  globalStyles,
  badgeStyles, // badge styles
  buttonStyles, // button styles
  linkStyles, // link styles
  progressStyles, // progress styles
  sliderStyles, // slider styles
  inputStyles, // input styles
  textareaStyles, // textarea styles
  switchStyles, // switch styles
  CardComponent // card component,
);
