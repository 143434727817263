/* eslint-disable */
/*!
  _   _  ___  ____  ___ ________  _   _   _   _ ___   
 | | | |/ _ \|  _ \|_ _|__  / _ \| \ | | | | | |_ _| 
 | |_| | | | | |_) || |  / / | | |  \| | | | | || | 
 |  _  | |_| |  _ < | | / /| |_| | |\  | | |_| || |
 |_| |_|\___/|_| \_\___/____\___/|_| \_|  \___/|___|
                                                                                                                                                                                                                                                                                                                                       
=========================================================
* Horizon UI - v1.1.0
=========================================================

* Product Page: https://www.horizon-ui.com/
* Copyright 2022 Horizon UI (https://www.horizon-ui.com/)

* Designed and Coded by Simmmple

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

import React, { useEffect } from "react";
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { NavLink, useHistory } from "react-router-dom";
// Chakra imports
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  Text,
  useColorModeValue,
  useToast,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Link,
  Heading,
  Flex,
} from "@chakra-ui/react";
// Custom components
import DefaultAuth from "layouts/auth/Default";
// Assets
import illustration from "assets/img/logo/logo.png";
import { FcGoogle } from "react-icons/fc";
import { MdOutlineRemoveRedEye } from "react-icons/md";
import { RiEyeCloseLine } from "react-icons/ri";
import { login } from "features/authentication/slice";
import { getOrganizationLogoAsync } from "features/user/slice";
import { forgotPassword } from "features/authentication/slice";
import { COLORS } from "constants/colors";
import Card from "components/card/Card";

function SignIn(props) {

  const { onSignIn, isAuthenticated, organizationLogo, onGetCompanyLogo, onForgotPassword } = props;

  // Chakra color mode
  const textColor = useColorModeValue("navy.700", "white");
  const textColorSecondary = "gray.400";
  const textColorDetails = useColorModeValue("navy.700", "secondaryGray.600");
  const [show, setShow] = React.useState(false);
  const handleClick = () => setShow(!show);

  const [email, setEmail] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [forgotEmailPwd, setForgotEmailPwd] = React.useState('');

  const { isOpen, onOpen, onClose } = useDisclosure();

  const history = useHistory();

  const toast = useToast();

  var splitUrl = window.location.hostname.split('.');
  var subdomain = window.location.hostname.split('.')[0];
  const logoImage = organizationLogo !== null && organizationLogo !== '' ? organizationLogo : illustration;

  useEffect(() => {
    if (splitUrl.length > 2 && subdomain !== 'www') {
      onGetCompanyLogo(subdomain, () => { });
    }
  }, []);

  const handleClickSignIn = (e) => {
    e.preventDefault();

    let hasErrors = false;

    if(email === '' || email === null) {
      hasErrors = true;
      setEmailError(true);
    }

    if(password === '' || password === null) {
      hasErrors = true;
      setPasswordError(true);
    }

    if(hasErrors === false) {

      const onLogginSucceed = (url, mustPerformEvaluationOnboarding) => {
        if(mustPerformEvaluationOnboarding === true) {
          history.push('/app/onboarding');
        } else {
          window.location.replace(url);
        }
      }

      const onLoginError = (msg) => {
        toast({
          title: 'Login Failed',
          description: msg,
          status: 'error',
          duration: 9000,
          isClosable: true
        })
      }

      onSignIn(
        email, 
        password, 
        onLogginSucceed,
        onLoginError,
        onLoginError);
    }
  }
  
  const handleForgotPwdEmailChange = (event) => {
    setForgotEmailPwd(event.target.value);
  };

  const handlePasswordRecovery = (event) => {
    event.preventDefault();

    if(forgotEmailPwd !== '') {
      onForgotPassword(forgotEmailPwd, () => {
        toast({
          title: 'Success',
          description: 'Forgot password e-mail sucessfully sent!',
          status: 'success',
          duration: 9000,
          isClosable: true
        });
        onClose();
      }, (msg) => {
        toast({
          title: 'Forgot Password request Failed',
          description: msg,
          status: 'error',
          duration: 9000,
          isClosable: true
        })
      })
    }
  }


  return (
   <DefaultAuth illustrationBackground={logoImage} image={logoImage}>
      <Card width={{ sm: "100%", md: "100%", lg: "50%", xl: "50%" }} marginLeft="auto" marginRight="auto" boxShadow="rgba(0, 0, 0, 0.16) 0px 1px 4px;" mb="80px">
          <Box me='auto'
            mt={{ base: "0px", xl: "30px", md: "60px" }}
            pr={{ sm: "0px", md: "0px", lg: "0px", xl: "30px" }} 
            pl="30px"
          >
            <Heading color={textColor} fontSize='32px' mb='10px'>
              Sign In
            </Heading>
            <Text
              mb='36px'
              ms='4px'
              color={textColorSecondary}
              fontWeight='400'
              fontSize='md'>
              Enter your email and password to sign in!
            </Text>
          </Box>
          <Box>
            <form onSubmit={handleClickSignIn}>
              <FormControl pr="30px" pl="30px">
                <Input
                  isRequired={true}
                  variant='auth'
                  fontSize='sm'
                  ms={{ base: "0px", md: "0px" }}
                  type='email'
                  placeholder='E-mail address'
                  mb='24px'
                  fontWeight='500'
                  size='lg'
                  errorBorderColor='red.300'
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                <InputGroup size='md'>
                  <Input
                    isRequired={true}
                    fontSize='sm'
                    placeholder='Password'
                    mb='24px'
                    size='lg'
                    type={show ? "text" : "password"}
                    variant='auth'
                    errorBorderColor='red.300'
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                  <InputRightElement display='flex' alignItems='center' mt='4px'>
                    <Icon
                      color={textColorSecondary}
                      _hover={{ cursor: "pointer" }}
                      as={show ? RiEyeCloseLine : MdOutlineRemoveRedEye}
                      onClick={handleClick}
                    />
                  </InputRightElement>
                </InputGroup>
                <Flex justifyContent='space-between' align='center' mb='24px'>
                  <Flex
                    flexDirection='column'
                    justifyContent='center'
                    alignItems='start'
                    maxW='100%'
                    mt='0px'>
                    <Text color={textColorDetails} fontWeight='400' fontSize='14px'>
                      Not registered yet?
                      <NavLink to='/auth/sign-up'>
                        <Text
                          color={COLORS.Primary}
                          as='span'
                          ms='5px'
                          fontWeight='500'>
                          Create an Account
                        </Text>
                      </NavLink>
                    </Text>
                  </Flex>
                  <Link onClick={() => onOpen()}>
                    <Text
                      color={COLORS.Primary}
                      fontSize='sm'
                      w='124px'
                      fontWeight='500'>
                      Forgot password?
                    </Text>
                  </Link>
                </Flex>
                <Box textAlign={"center"} width={"100%"}>
                  <Button
                    fontSize='sm'
                    style={ { backgroundColor: COLORS.Primary, color: COLORS.PrimaryText } }
                    fontWeight='500'
                    w='150px'
                    h='50'
                    mb='24px'
                    type="submit">
                    Sign In
                  </Button>
                </Box>
              </FormControl>
            </form>
          </Box>
          <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>Forgot Password</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                  <form onSubmit={handlePasswordRecovery}>
                  <FormControl>
                    <FormLabel>Email</FormLabel>
                    <Input 
                      type="email" 
                      isRequired={true} 
                      placeholder='mail@simmmple.com'
                      errorBorderColor='red.300' 
                      value={forgotEmailPwd} 
                      onChange={handleForgotPwdEmailChange} />
                  </FormControl>
                </form>
                </ModalBody>

                <ModalFooter>
                  <Button colorScheme="blue" mr={3} onClick={handlePasswordRecovery}>
                    Recover Password
                  </Button>
                  <Button onClick={onClose}>Cancel</Button>
                </ModalFooter>
            </ModalContent>
          </Modal>
      </Card>
    </DefaultAuth>
  );
}

SignIn.propTypes = {
  isAuthenticated: PropTypes.bool,
  organizationLogo: PropTypes.string,
  onSignIn: PropTypes.func,
  onGetCompanyLogo: PropTypes.func,
  onForgotPassword: PropTypes.func
};

SignIn.defaultProps = {
  isAuthenticated: false,
  organizationLogo: null,
  onSignIn: () => { },
  onGetCompanyLogo: () => { },
  onForgotPassword: () => { }
}

function mapStateToProps(state) {
  const { authentication } = state;

  const { isAuthenticated, organizationLogo } = authentication;
  
  return {
    isAuthenticated,
    organizationLogo
   };
};

function mapDispatchToProps (dispatch) {
  return {
      onSignIn: (email, password, onLogginSucceed, onLoginError) => dispatch(login({ email, password, onLogginSucceed, onLoginError })),
      onGetCompanyLogo: (domain, onSuccess ) => dispatch(getOrganizationLogoAsync({ domain, onSuccess })),
      onForgotPassword: (email, onSuccess, onError) => dispatch(forgotPassword({ email, onSuccess, onError })),
      dispatch
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SignIn);