// Chakra imports
import { Box, useDisclosure, Grid, GridItem } from "@chakra-ui/react";
import PropTypes from 'prop-types';
import Footer from "components/footer/FooterAdmin.js";
// Layout components
import NavbarAdmin from "components/navbar/NavbarAdmin.js";
import Sidebar from "components/sidebar/Sidebar.js";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Route } from "react-router-dom";
import { routes, authorizedRoutes } from "routes.js";
import Loader from "../../features/loader";
import { Hide } from '@chakra-ui/react'

// Custom Chakra theme
function Dashboard(props) {

  const [isAuthenticated, setIsAuthenticated] = useState();
  
  useEffect(() => {
    setIsAuthenticated(props.isAuthenticated);
  }, []);

  // states and functions
  const [fixed] = useState(false);

  // functions for changing the states from components
  const getRoute = () => {
    return window.location.pathname !== "/admin/full-screen-maps";
  };

  const getActiveRoute = (routes) => {
    let activeRoute = "Default Brand Text";
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse) {
        let collapseActiveRoute = getActiveRoute(routes[i].items);
        if (collapseActiveRoute !== activeRoute) {
          return collapseActiveRoute;
        }
      } else if (routes[i].category) {
        let categoryActiveRoute = getActiveRoute(routes[i].items);
        if (categoryActiveRoute !== activeRoute) {
          return categoryActiveRoute;
        }
      } else {
        if (
          window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1
        ) {
          return routes[i].name;
        }
      }
    }
    return activeRoute;
  };

  const getActiveNavbar = (routes) => {
    let activeNavbar = false;
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse) {
        let collapseActiveNavbar = getActiveNavbar(routes[i].items);
        if (collapseActiveNavbar !== activeNavbar) {
          return collapseActiveNavbar;
        }
      } else if (routes[i].category) {
        let categoryActiveNavbar = getActiveNavbar(routes[i].items);
        if (categoryActiveNavbar !== activeNavbar) {
          return categoryActiveNavbar;
        }
      } else {
        if (
          window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1
        ) {
          return routes[i].secondary;
        }
      }
    }
    return activeNavbar;
  };

  const getActiveNavbarText = (routes) => {
    let activeNavbar = false;
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse) {
        let collapseActiveNavbar = getActiveNavbarText(routes[i].items);
        if (collapseActiveNavbar !== activeNavbar) {
          return collapseActiveNavbar;
        }
      } else if (routes[i].category) {
        let categoryActiveNavbar = getActiveNavbarText(routes[i].items);
        if (categoryActiveNavbar !== activeNavbar) {
          return categoryActiveNavbar;
        }
      } else {
        if (
          window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1
        ) {
          return routes[i].messageNavbar;
        }
      }
    }
    return activeNavbar;
  };

  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.layout === "/admin") {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      }
      if (prop.collapse) {
        return getRoutes(prop.items);
      }
      if (prop.category) {
        return getRoutes(prop.items);
      } else {
        return null;
      }
    });
  };
  document.documentElement.dir = "ltr";
  
  const { onOpen } = useDisclosure();

  const routesToBeUsed = isAuthenticated === true 
    ? authorizedRoutes 
    : routes; 

  return (
    <Grid templateColumns={"260px 1fr"}>
      <Hide below='sm'>
        <GridItem height={"100%"}>
            <Sidebar routes={routesToBeUsed} />
        </GridItem>
      </Hide>
      <GridItem>
        <Box width="100%">
            <Box ml={{ sm: "0px", md: "0px", lg: "33px", xl: "33px" }}>
              <NavbarAdmin
                onOpen={onOpen}
                logoText={"Introgauge"}
                brandText={getActiveRoute(routesToBeUsed)}
                secondary={getActiveNavbar(routesToBeUsed)}
                message={getActiveNavbarText(routesToBeUsed)}
                fixed={fixed}
                routes={routesToBeUsed}
              />
            </Box>

            {getRoute() ? (
              <Box ml={{ sm: "0px", md: "32px", lg: "32px", xl: "32px" }} >
                  {getRoutes(routesToBeUsed)}
              </Box>
            ) : null}
          <Box>
            <Footer />
          </Box>
        </Box>
      </GridItem>
      <Loader location={props.location} />
    </Grid>
  );
}


Dashboard.propTypes = {
  isAuthenticated: PropTypes.bool
};

Dashboard.defaultProps = {
  isAuthenticated: false
}

function mapStateToProps(state) {
  const { authentication } = state;

  const { isAuthenticated } = authentication;

  return {
    isAuthenticated 
   };
};

function mapDispatchToProps (dispatch) {
  return { }
}

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);