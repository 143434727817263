import { Box, Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay } from "@chakra-ui/react";
import { useCallback, useEffect, useState } from "react";
import ReactLoader from 'react-loaders';
import './VideoDialog.scss';

export default function VideoDialog(props) {
  
    const waitingTexts = ['Please wait until we calculate your data.', 'Hang on, we are still busy with some calculations', 'We are almost there', 'We are aggregating data from our database to compare against your organization.'];
    const [waitingTextIndex, setWaitingTextIndex] = useState(0);

    const shuffle = useCallback(() => {
      let newWaitingTextIndex = waitingTextIndex + 1;
      if(newWaitingTextIndex > 3) {
          newWaitingTextIndex = 0;
      }
      setWaitingTextIndex(newWaitingTextIndex);
  }, [setWaitingTextIndex, waitingTextIndex]);

    useEffect(() => {
      const intervalID = setInterval(shuffle, 5000);
        return () => clearInterval(intervalID);
    }, [])

    const { 
        isOpen
    } = props;

    return (
      <Modal isOpen={isOpen} onClose={() => { }} size="2xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Organization Insights</ModalHeader>
          <ModalCloseButton />
          <ModalBody size="xl">
            <Box textAlign={"center"} minHeight={"200px"}>
              <Box mt="20px">
                {waitingTexts[waitingTextIndex]}
              </Box>
              <Box className="insights-loading" mt="30px"> 
                <ReactLoader active={true} color="black" type="cube-transition" />
              </Box>
            </Box>
          </ModalBody>
        </ModalContent>
    </Modal>
    )
}