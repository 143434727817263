import React from "react";
import { Box } from "@chakra-ui/react";
// Chakra imports
import { Flex } from "@chakra-ui/react";

// Custom components
import { HSeparator } from "components/separator/Separator";
import { useSelector } from "react-redux";

export function SidebarBrand({ align, separator }) {
  const organizationLogo = useSelector(state => state.authentication.organizationLogo);

  const logo = organizationLogo !== null && organizationLogo ? organizationLogo : require("../../../assets/img/logo/logo.png");

  return (
    <Flex align={align ? align : "center"} direction='column'>
      <Box
        mx={align === "left" ? null : 'auto'}
        p={{ base: "10px", md: "10px" }}>
        <img src={logo} alt="" width="160px" style={ { marginBottom: 10 } }/>
      </Box>
      {separator === false ? null : <HSeparator mb='20px' />}
    </Flex>
  );
}

export default SidebarBrand;
