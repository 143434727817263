/*!
  _   _  ___  ____  ___ ________  _   _   _   _ ___   
 | | | |/ _ \|  _ \|_ _|__  / _ \| \ | | | | | |_ _| 
 | |_| | | | | |_) || |  / / | | |  \| | | | | || | 
 |  _  | |_| |  _ < | | / /| |_| | |\  | | |_| || |
 |_| |_|\___/|_| \_\___/____\___/|_| \_|  \___/|___|
                                                                                                                                                                                                                                                                                                                                       
=========================================================
* Horizon UI - v1.1.0
=========================================================

* Product Page: https://www.horizon-ui.com/
* Copyright 2022 Horizon UI (https://www.horizon-ui.com/)

* Designed and Coded by Simmmple

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// Chakra imports
import { SearchIcon } from '@chakra-ui/icons';
import { Box, Button, Grid, GridItem, Heading, Input, InputGroup, InputLeftElement, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Tab, TabIndicator, TabList, TabPanel, TabPanels, Tabs, Text, useToast } from '@chakra-ui/react';
import { COLORS } from 'constants/colors';
import PropTypes from 'prop-types';
import MembersTable from '../../../../components/members/MembersTable';
import { connect } from 'react-redux';
import { listOrganizationUsersAsync } from 'features/user/slice';
import { useEffect, useState } from 'react';
import modalImgCheck from '../../../../assets/img/modal/check.svg';
import './PendingAssessmentsView.css';
import { getOrganizationUserSurveys } from 'features/surveys/slice';
import _ from 'lodash';
import { SURVEY_TYPE } from 'constants/evaluation.const';
import { resendMultipleUserInvitations } from 'features/userSurveys/slice';

function PendingAssessmentsView({ 
    teamMembers, 
    onLoadTeamMembers, 
    organizationUserSurveys, 
    onLoadOrganizationUserSurveys, 
    onClose,
    onResendInvitations
  }) {

  const [isModalOpen, setModalOpen] = useState(false);
  const [employeeIqSelectedUsers, setEmployeeIqSelectedUsers] = useState([]);
  const [boarIqSelectedUsers, setBoardIqSelectedUsers] = useState([]);
  const [orgIqSelectedUsers, setOrgIqSelectedUsers] = useState([]);
  const careerIqSelectedUsers = [];

  const toast = useToast();
  
  useEffect(() => {
    onLoadTeamMembers(() => {
      onLoadOrganizationUserSurveys('Pending', () => { }, () => { });
    }, () => { });
  }, []);

    // Function to handle checkbox change
  const handleCheckboxChange = (event, userId, selectedMembers, setSelectedMembers) => {
      const isChecked = event.target.checked;

      if (isChecked) {
        setSelectedMembers([...selectedMembers, userId]);
      } else {
        setSelectedMembers(selectedMembers.filter(id => id !== userId));
      }
  };

  const PendingMembers = ({ surveys, selectedMembers, setSelectedMembers }) => {
    const userIds = surveys.map((s, i) => s.userId);
    return (
      <Box>
         <Box>
          <InputGroup>
            <InputLeftElement>
              <SearchIcon color='#0095FF' />
            </InputLeftElement>
            <Input placeholder='Search member' size='md' bg={"#fff"}/>
          </InputGroup>
        </Box>
        <Box mt="15px">
          <MembersTable 
            teamMembers={_.filter(teamMembers, (m) => userIds.indexOf(m.id) >= 0)}
            onClickDelete={() => {}}
            onClickEdit={() => {}}
            onClickTeamMember={() => {}}
            onSelectTeamMember={(e, id) => handleCheckboxChange(e, id, selectedMembers, setSelectedMembers)}
            selectedMembers={selectedMembers}
          />
        </Box>
      </Box>
    )
  }

  const mergeSelectedUsers = (arr1, arr2, arr3, arr4) => {
    const mergedArray = [];
  
    function addToMergedArray(id) {
      if (!mergedArray.includes(id)) {
        mergedArray.push(id);
      }
    }
  
    arr1.forEach((id) => addToMergedArray(id));
    arr2.forEach((id) => addToMergedArray(id));
    arr3.forEach((id) => addToMergedArray(id));
    arr4.forEach((id) => addToMergedArray(id));
  
    return mergedArray;
  }

  const handleResendInvitations = (selected) => {
    if(selected.length > 0) {
      onResendInvitations(selected, () => {
        toast({
          title: 'Success',
          description: "Invitation(s) successfully sent.",
          status: 'success',
          duration: 9000,
          isClosable: true
        });
      }, () => {
        toast({
          title: 'Error',
          description: "Unable to resend the invitation(s), please contact support.",
          status: 'error',
          duration: 9000,
          isClosable: true
        });
      });
    } else {
      toast({
        title: 'Error',
        description: "Select at least one team member to re-send the invitation.",
        status: 'error',
        duration: 9000,
        isClosable: true
      });
    }
  }
  const onClickSend = () => {
    const arr = mergeSelectedUsers(employeeIqSelectedUsers, boarIqSelectedUsers, 
      orgIqSelectedUsers, careerIqSelectedUsers);

    handleResendInvitations(arr);
  }

  const onClickSendAll = () =>{
    const arr = organizationUserSurveys.map((s, i) => s.userId);
    
    handleResendInvitations(arr);
  }


  return (
    <Box paddingLeft="40px" paddingRight="40px">
        <Box mt="40px">
          <Grid templateColumns={"70% 30%"}>
            <GridItem display={"flex"}>
              <Heading size="lg">Pending Assesment of Members</Heading>
              <Text fontSize="md" color={COLORS.Primary} onClick={onClose} ml="10px" mt="10px" className="pending-assessment-sub-menu">Dashboard</Text>
            </GridItem>
            <GridItem>
              <Box position={"relative"}>
                <Button
                    w='170px'
                    minW='170px'
                    height={"50px"}
                    position="absolute"
                    right={0}
                    mr={"20px"}
                    mt={"20px"}
                    style={ { backgroundColor: COLORS.Primary, color: COLORS.PrimaryText } }
                    onClick={onClickSendAll}
                    fontWeight='500'>
                    Send All
                </Button>
              </Box>
            </GridItem>
          </Grid>
        </Box>
        <Box>
          <Tabs position="relative" variant="unstyled" mt="15px">
              <TabList>
                  <Tab>Employee IQ</Tab>
                  <Tab>Board IQ</Tab>
                  <Tab>Org IQ</Tab>
                  {/* <Tab>Career IQ</Tab> */}
              </TabList>
              <TabIndicator
                  mt="-1.5px"
                  height="2px"
                  bg="blue.500"
                  borderRadius="1px"
              />
              <TabPanels>
                <TabPanel>
                    <PendingMembers selectedMembers={employeeIqSelectedUsers} setSelectedMembers={setEmployeeIqSelectedUsers} setOrgIqSelectedUsers={setOrgIqSelectedUsers} surveys={_.filter(organizationUserSurveys, (i) => i.surveyType === SURVEY_TYPE.EmployeeIq)} />
                </TabPanel>
                <TabPanel>
                    <PendingMembers selectedMembers={boarIqSelectedUsers} setSelectedMembers={setBoardIqSelectedUsers} surveys={_.filter(organizationUserSurveys, (i) => i.surveyType === SURVEY_TYPE.BoardIq)} />
                </TabPanel>
                <TabPanel>
                    <PendingMembers selectedMembers={orgIqSelectedUsers} setSelectedMembers={setOrgIqSelectedUsers} surveys={_.filter(organizationUserSurveys, (i) => i.surveyType === SURVEY_TYPE.OrgIq)} />
                </TabPanel>
                {/* <TabPanel>
                    <PendingMembers selectedMembers={careerIqSelectedUsers} setSelectedMembers={setCareerIqSelectedUsers} surveys={_.filter(organizationUserSurveys, (i) => i.surveyType === SURVEY_TYPE.CareerIQ)} />
                </TabPanel> */}
              </TabPanels>
          </Tabs>
          <Button
              w='170px'
              minW='170px'
              height={"50px"}
              mt="20px"
              ml="20px"
              style={ { backgroundColor: COLORS.Primary, color: COLORS.PrimaryText } }
              onClick={onClickSend}
              fontWeight='500'>
              Send
          </Button>

          <Modal isOpen={isModalOpen} onClose={() => setModalOpen(false)}>
              <ModalOverlay />
              <ModalContent>
                <ModalHeader>
                    <img src={modalImgCheck} alt="" style={ { marginLeft: 'auto', marginRight: 'auto' } } />
                </ModalHeader>
                  <ModalBody textAlign={"center"}>
                      <Heading size="sm">Assessment has been resent</Heading>
                      <Text mt="10px">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor</Text>
                  </ModalBody>

                  <ModalFooter justifyContent={"center"}>
                      <Button 
                          style={ { backgroundColor: COLORS.Primary, color: COLORS.PrimaryText } } 
                          mr={3} 
                          onClick={() => setModalOpen(false)}>
                          Close
                      </Button>
                  </ModalFooter>
              </ModalContent>
          </Modal>
        </Box>
    </Box>
  );
}
PendingAssessmentsView.propTypes = {
  organizationName: PropTypes.array,
  organizationUserSurveys: PropTypes.array,
  onLoadTeamMembers: PropTypes.func,
  onLoadOrganizationUserSurveys: PropTypes.func,
  onResendInvitations: PropTypes.func
};

PendingAssessmentsView.defaultProps = {
  teamMembers: [],
  organizationUserSurveys: [],
  onLoadTeamMembers: () => {},
  onLoadOrganizationUserSurveys: () => { },
  onResendInvitations: () => { }
}

function mapStateToProps(state) {

  const { teamMembers } = state.user;

  const { organizationUserSurveys } = state.surveys;
  
  return {
    teamMembers,
    organizationUserSurveys
   };
};

function mapDispatchToProps (dispatch) {
  return {
      onLoadTeamMembers: (onSuccess, onError) => dispatch(listOrganizationUsersAsync(null, onSuccess, onError)),
      onLoadOrganizationUserSurveys: (status, onSuccess, onError) => dispatch(getOrganizationUserSurveys({ status, onSuccess, onError })),
      onResendInvitations: (userIds, onSuccess, onError) => dispatch(resendMultipleUserInvitations({ userIds, onSuccess, onError })),
      dispatch
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PendingAssessmentsView);