/*!
  _   _  ___  ____  ___ ________  _   _   _   _ ___   
 | | | |/ _ \|  _ \|_ _|__  / _ \| \ | | | | | |_ _| 
 | |_| | | | | |_) || |  / / | | |  \| | | | | || | 
 |  _  | |_| |  _ < | | / /| |_| | |\  | | |_| || |
 |_| |_|\___/|_| \_\___/____\___/|_| \_|  \___/|___|
                                                                                                                                                                                                                                                                                                                                       
=========================================================
* Horizon UI - v1.1.0
=========================================================

* Product Page: https://www.horizon-ui.com/
* Copyright 2022 Horizon UI (https://www.horizon-ui.com/)

* Designed and Coded by Simmmple

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// Chakra imports
import { Box, Grid, GridItem, Heading, Tab, TabList, Tabs, Text } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import PropTypes from 'prop-types';
import Authenticated from "../authenticated";
import Arrows from "./components/Arrows";
import './default.css';
import Factor from "./components/Factor";
import { connect } from "react-redux";
import { setTraitLines } from "features/arrows/slice";
import { setBehaviorLines } from "features/arrows/slice";
import { cleanLines } from "features/arrows/slice";
import { getDefinitionMetrics } from "features/metrics/slice";

function Definitions({ definitions, behaviorLines, traitLines, onSetBehaviorLines, onLoadDefinitions, onSetTraitLines, onRemoveAllLines }) {

  const indexes = { EmployeeIQ: 0, BoardIQ: 1, OrgIQ: 2, CareerIQ: 3 };

  const [tabIndex, setTabIndex] = useState(indexes.EmployeeIQ);

  useEffect(() => {
    onLoadDefinitions(() => { });
  }, []);

  const getDefinitions = (def) => {
    return tabIndex === indexes.EmployeeIQ 
      ? def.employeeIQ
      : tabIndex === indexes.BoardIQ 
        ? def.boardIQ
        : tabIndex === indexes.OrgIQ 
          ? def.orgIQ
          : tabIndex === indexes.CareerIQ 
            ? def.careerIQ
            : null;
  }

  return (
    <Authenticated>
      <Box pt={{ base: "60px", md: "60px", xl: "60px" }} pl="50px" pr="20px" pb="40px" minH={"800px"}>
        <Grid templateColumns={"1fr 1fr"}>
          <GridItem>
            <Heading size="md">The WorkIQ Ecosystem</Heading>
            <Box mt="15px">
              <Text fontSiz="12px">
                All assessments in the WorkIQ suite are built in the same way: each consists of 96 indicators (or “measures”), where each indicator is partially influenced by 8 traits (“unique factors”) and 4 behavior drivers (“common factors”). That is the well known Factor Analysis and it is the binding agent of our WorkIQ Eco System.
              </Text>
            </Box>
          </GridItem>
          <GridItem>
            <Box textAlign={"center"}>
              <Box>
                <Heading size="md">Factor Analysis Model</Heading>
              </Box>
              <Box>
                <Factor />
              </Box>
            </Box>
          </GridItem>
        </Grid>
        <Box mt="20px">
          <Tabs value={tabIndex} onChange={(i) => setTabIndex(i)}>
            <TabList>
                <Tab>Employee IQ</Tab>
                <Tab>Board IQ</Tab>
                <Tab>Org IQ</Tab>
                {/* <Tab>Career IQ</Tab> */}
            </TabList>
            </Tabs>
            <Box>
                {definitions ? <Arrows 
                  definitions={getDefinitions(definitions)} 
                  onSetBehaviorLines={onSetBehaviorLines}
                  onSetTraitLines={onSetTraitLines}
                  traitLines={traitLines}
                  behaviorLines={behaviorLines}
                  onRemoveAllLines={onRemoveAllLines}
                  /> : null}
                {/* {definitions && tabIndex === indexes.BoardIQ ? <Arrows definitions={definitions.boardIQ} /> : null}
                {definitions && tabIndex === indexes.OrgIQ ? <Arrows definitions={definitions.orgIQ} /> : null}
                {definitions && tabIndex === indexes.CareerIQ ? <Arrows definitions={definitions.careerIQ} /> : null} */}
            </Box>
        </Box>
      </Box>
    </Authenticated>
  );
}

  
Definitions.propTypes = {
  definitions: PropTypes.object,
  onLoadDefinitions: PropTypes.func,
  onSetTraitLines: PropTypes.func,
  onSetBehaviorLines: PropTypes.func,
  onRemoveAllLines: PropTypes.func
};

Definitions.defaultProps = {
  onLoadDefinitions: () => { },
  onSetTraitLines: () => { },
  onSetBehaviorLines: () => { },
  onRemoveAllLines: () => { }
}

function mapStateToProps(state) {
  const { behaviorLines, traitLines } = state.arrows;
  const { definitions } = state.metrics;

  return {
    definitions,
    behaviorLines, 
    traitLines
   };
};

function mapDispatchToProps (dispatch) {
  return {
      onLoadDefinitions: (onSuccess) => dispatch(getDefinitionMetrics({ onSuccess })),
      onSetTraitLines: (traitLines) => dispatch(setTraitLines(traitLines)),
      onSetBehaviorLines: (behaviorLines) => dispatch(setBehaviorLines(behaviorLines)),
      onRemoveAllLines: () => dispatch(cleanLines()),
      dispatch
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Definitions);