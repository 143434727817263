import { Box, Grid, GridItem, Text }  from "@chakra-ui/react";
import { useEffect, useRef } from "react";
import LeaderLine from "react-leader-line";

import './Factor.css';

function Factor() {

    const commonRef = useRef();
    const measureRef = useRef();
    const uniqueRef = useRef();

    useEffect(() => {
        const lineOptions1 = {
            color: '#0095FF',
            startPlugColor: '#0095FF',
            endPlugColor: '#0095FF',
            startPlug: 'behind',
            size: 1,
            path: 'straight'
        };

        const line1 = new LeaderLine(commonRef.current, measureRef.current, lineOptions1);
        const line2 = new LeaderLine(uniqueRef.current, measureRef.current, lineOptions1);


        return () => {
            line1.remove();
            line2.remove();
        };
    }, []);

    return (
        <Box mt="20px">
            <Grid templateColumns={"1fr 1fr 1fr"} pr="120px" pl="120px">
                <GridItem>
                    <Box bgColor={"#fff"} mt="5px" border="1.5px solid #0095FF" borderRadius={"10px"} width={"160px"} pt="10px" ml="auto" mr="auto" height={"50px"} ref={commonRef}>
                        <Text pt="3px">Common Factor</Text>
                    </Box>
                </GridItem>
                <GridItem>
                    <Box bgColor={"#fff"} className="measure-background" borderRadius={"60px"} width={"60px"} ml="auto" mr="auto" pt="8px" height={"60px"} ref={measureRef}>
                        <Text fontSize="14px" mt="12px">Measure</Text>
                    </Box>
                </GridItem>
                <GridItem>
                    <Box mt="5px" bgColor={"#fff"} border="1.5px solid #0095FF" borderRadius={"30px"} width={"120px"} ml="auto" mr="auto" height={"50px"} ref={uniqueRef}>
                        <Text pt="15px" fontSize="14px">Unique Factor</Text>
                    </Box>
                </GridItem>
            </Grid>
        </Box>
    );
}

export default Factor;