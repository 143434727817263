/*!
  _   _  ___  ____  ___ ________  _   _   _   _ ___   
 | | | |/ _ \|  _ \|_ _|__  / _ \| \ | | | | | |_ _| 
 | |_| | | | | |_) || |  / / | | |  \| | | | | || | 
 |  _  | |_| |  _ < | | / /| |_| | |\  | | |_| || |
 |_| |_|\___/|_| \_\___/____\___/|_| \_|  \___/|___|
                                                                                                                                                                                                                                                                                                                                       
=========================================================
* Horizon UI - v1.1.0
=========================================================

* Product Page: https://www.horizon-ui.com/
* Copyright 2022 Horizon UI (https://www.horizon-ui.com/)

* Designed and Coded by Simmmple

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// Chakra imports
import {
  Box,
  Grid,
  SimpleGrid,
  Text,
  GridItem,
  useToast
} from "@chakra-ui/react";
import PropTypes from "prop-types";
import Evaluation from "../components/Evaluation";
import React, { useState } from "react";
import PendingEvaluationBanner from "../components/PendingEvaluationBanner";
import CreateMember from "../components/CreateMember";
import { USER_ROLE } from "constants/userRoles.const";
import { SUBSCRIPTION_TYPE } from "constants/subscriptionTypes.const";
import InvitationBanner from "../components/InvitationBanner";
import MembersIndicators from "../components/MembersIndicators";
import MembersManagement from "components/members/MembersManagement";
import MembersManagementMemberView from "components/members/MembersManagementMemberView";

function DefaultView(props) {
  const {
    userId,
    userSurveys,
    teamMembers,
    firstName,
    lastName,
    role,
    subscriptionType,
    organizationDomain,
    mustPerformOnboarding,
    mustPayEnterprise,
    numberOfPendingEvaluations,
    numberOfPendingRegistrations,
    onClickToManageEvaluation,
    onClickToToManagePendingAssessments,
    onSaveMember,
    onCreateTeamMemberDialog,
    location,
  } = props;

  const toast = useToast();

  const [isVisualizingUser, setIsVisualizingUser] = useState(false);

  return (
    <Grid
      templateColumns={"1fr"}
      spacingX={3}
      ml={{ sm: "0px", md: "0px", lg: "33px", xl: "33px" }}
      mt={8}
    >
      {isVisualizingUser === false &&
        role === USER_ROLE.Admin &&
        [SUBSCRIPTION_TYPE.Team, SUBSCRIPTION_TYPE.Enterprise].indexOf(
          subscriptionType
        ) >= 0 ? (
          <GridItem>
            <SimpleGrid columns={{ sm: 1, md: 1, lg: 2, xl: 2 }}>
              <Box
                maxW="3xl"
                ml={{ sm: "10px", md: "10px", lg: "0px", xl: "0px" }}
                mr={{ sm: "10px", md: "10px", lg: "0px", xl: "0px" }}
                mb={{ sm: "10px", md: "10px", lg: "0px", xl: "0px" }}
              >
                <Box mb="20px" ml="10px">
                  <Text fontSize="2xl" fontWeight={"bold"}>{`Managing Your Team`}</Text>
                </Box>
                <InvitationBanner
                  username={`${firstName} ${lastName}`}
                  onClickInvite={onCreateTeamMemberDialog.onOpen}
                />
                <CreateMember
                  isOpen={onCreateTeamMemberDialog.isOpen}
                  onClose={onCreateTeamMemberDialog.onClose}
                  onSave={onSaveMember}
                  subscriptionType={subscriptionType}
                  role={USER_ROLE.TeamMember}
                  organizationDomain={organizationDomain}
                  toast={toast}
                />
              </Box>
              <Box>
                <Box
                  ml={{ sm: "10px", md: "10px", lg: "20px", xl: "20px" }}
                  mr={{ sm: "10px", md: "10px", lg: "20px", xl: "20px" }}
                >
                  <Box mb="20px" ml="10px">
                    <Text fontSize="2xl" fontWeight={"bold"}>
                      Managing Assessments
                    </Text>
                  </Box>
                  {role === USER_ROLE.Admin ? (
                    <Box>
                      <Evaluation
                        mt={10}
                        pb={30}
                        ml={4}
                        subscriptionType={subscriptionType}
                        teamMembers={teamMembers}
                        onClickToManageEvaluation={onClickToManageEvaluation}
                      />
                      <MembersIndicators
                        onClickToToManagePendingAssessments={
                          onClickToToManagePendingAssessments
                        }
                        numberOfPendingEvaluations={numberOfPendingEvaluations}
                        numberOfPendingRegistrations={
                          numberOfPendingRegistrations
                        }
                      />
                    </Box>
                  ) : null}
                </Box>
              </Box>
            </SimpleGrid>
          </GridItem>
      ) : null}

      {isVisualizingUser === false && userSurveys && userSurveys.length > 0 ? (
        <Box mt={{ sm: "0px", md: "15px", lg: "20px", xl: "20px" }}>
          <Box mb="20px" ml="10px">
            <Text fontSize="2xl">Assessments</Text>
          </Box>
          <Box maxW="7xl">
            <SimpleGrid
              columns={{ sm: 1, md: 3, lg: 3 }}
              ml={role === USER_ROLE.Admin ? 5 : 0}
              spacingX={"15px"}
              mt={10}
            >
              {userSurveys &&
              userSurveys.length > 0 &&
              mustPayEnterprise === false &&
              role !== USER_ROLE.Owner ? (
                userSurveys.map((s, i) => {
                  return (
                    <Box id="peding-ev-ban">
                      <PendingEvaluationBanner
                        userSurveyResponseId={s.id}
                        type={s.surveyType}
                        date={s.createDate}
                        updateDate={s.updateDate}
                        status={s.status}
                        mustPerformOnboarding={mustPerformOnboarding}
                      />
                    </Box>
                  );
                })
              ) : (
                <Box>
                  <Text>No Pending Assessments at the moment</Text>
                </Box>
              )}
            </SimpleGrid>
          </Box>
        </Box>
      ) : null}

      <Box mt="20px" mr="40px" mb="20px">
        {role === USER_ROLE.Admin ? (
          <Box ml="5px" mr="5px">
            {isVisualizingUser === false ? (
              <Box pl="20px" pb="20px">
                <Text fontSize="2xl" fontWeight={"bold"}>
                  Current Status
                </Text>
              </Box>
            ) : null}
            <MembersManagement
              location={location}
              showExplanation={true}
              onVisualizeUser={(value) => setIsVisualizingUser(value)}
            />
          </Box>
        ) : null}

        {role === USER_ROLE.TeamMember ? <MembersManagementMemberView 
            member={{ id: userId, firstName: firstName, lastName: lastName }} 
            onClose={() => { }} 
            onReinviteUser={() => { }}
            adminMode={false}
          /> : null}

      </Box>


    </Grid>
  );
}

DefaultView.propTypes = {
  userId: PropTypes.string,
  userSurveys: PropTypes.array,
  userReponseSummary: PropTypes.array,
  teamMembers: PropTypes.array,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  role: PropTypes.string,
  subscriptionType: PropTypes.number,
  mustPayEnterprise: PropTypes.bool,
  organizationDomain: PropTypes.string,
  mustPerformOnboarding: PropTypes.bool,
  onCreateTeamMemberDialog: PropTypes.object,
  numberOfPendingRegistrations: PropTypes.number,
  numberOfPendingEvaluations: PropTypes.number,
  onSaveMember: PropTypes.func,
  onClickToManageEvaluation: PropTypes.func,
  onClickToManagePendingEvaluation: PropTypes.func,
  onClickDeleteMember: PropTypes.func,
  onClickEditMember: PropTypes.func,
  onClickTeamMember: PropTypes.func,
};

DefaultView.defaultProps = {
  userSurveys: [],
  userReponseSummary: [],
  teamMembers: [],
  firstName: "",
  lastName: "",
  role: "",
  numberOfPendingEvaluations: 0,
  numberOfPendingRegistrations: 0,
  subscriptionType: -1,
  mustPayEnterprise: false,
  organizationDomain: "",
  mustPerformOnboarding: true,
  onCreateTeamMemberDialog: {},
  onSaveMember: () => {},
  onClickToManageEvaluation: () => {},
  onClickToManagePendingEvaluation: () => {},
  onClickDeleteMember: () => {},
  onClickEditMember: () => {},
  onClickTeamMember: () => {},
  userId: ''
};

export default DefaultView;
