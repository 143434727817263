import {
  Flex,
  useColorModeValue,
} from "@chakra-ui/react";
import { setIsUpgradeModalOpen } from "features/payments/slice";
import React from "react";
import { connect } from "react-redux";

function SidebarCard({ onOpenUpgradeModal }) {
  const bgColor = "linear-gradient(135deg, #868CFF 0%, #4318FF 100%)";
  const borderColor = useColorModeValue("white", "navy.800");

  return (
    <Flex
      justify='center'
      direction='column'
      align='center'
      bg={bgColor}
      borderRadius='30px'
      me='20px'
      position='relative'>
      <Flex
        border='5px solid'
        borderColor={borderColor}
        bg='linear-gradient(135deg, #868CFF 0%, #4318FF 100%)'
        borderRadius='50%'
        w='94px'
        h='94px'
        align='center'
        justify='center'
        mx='auto'
        position='absolute'
        left='50%'
        top='-47px'
        transform='translate(-50%, 0%)'>
        <h3>Introgauge</h3>
      </Flex>
    </Flex>
  );
}

function mapStateToProps(state) {
  return {
  };
};

function mapDispatchToProps (dispatch) {
  return {
      onOpenUpgradeModal: () => dispatch(setIsUpgradeModalOpen(true)),
      dispatch
  }
}


export default connect(mapStateToProps, mapDispatchToProps)(SidebarCard);
