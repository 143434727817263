/*!
  _   _  ___  ____  ___ ________  _   _   _   _ ___   
 | | | |/ _ \|  _ \|_ _|__  / _ \| \ | | | | | |_ _| 
 | |_| | | | | |_) || |  / / | | |  \| | | | | || | 
 |  _  | |_| |  _ < | | / /| |_| | |\  | | |_| || |
 |_| |_|\___/|_| \_\___/____\___/|_| \_|  \___/|___|
                                                                                                                                                                                                                                                                                                                                       
=========================================================
* Horizon UI - v1.1.0
=========================================================

* Product Page: https://www.horizon-ui.com/
* Copyright 2022 Horizon UI (https://www.horizon-ui.com/)

* Designed and Coded by Simmmple

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// Chakra imports
import {
  Box,
  useToast,
  useDisclosure,
} from "@chakra-ui/react";
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import React, { useEffect, useState } from "react";
import { listOrganizationUsersAsync } from "features/user/slice";
import Authenticated from "../authenticated";
import { getUserSurveys } from "features/userSurveys/slice";
import DefaultView from "./views/DefaultView";
import EvaluationManagementView from "./components/EvaluationManagementView";
import { SURVEY_TYPE } from "constants/evaluation.const";
import PendingAssessmentsView from "./views/PendingAssessmentsView";
import { USER_ROLE } from "constants/userRoles.const";
import { resendUserInvitation } from "features/userSurveys/slice";
import { useHistory } from "react-router-dom";
import { addMultipleTeamMembersAsync } from "features/user/slice";

const VIEWS_TYPES = {
  Default: 1,
  EvaluationManagement: 2, 
  PendingAssessments: 3
}

function AdminDefault(props) {
  const { 
    userId,
    userSurveys,
    teamMembers,
    inactiveTeamMembers,
    loadingTeamMembers,
    numberOfPendingEvaluations,
    numberOfPendingRegistrations,
    mustPerformEvaluationOnboarding,
    hasSkipEvaluationOnboarding,
    firstName, 
    lastName, 
    role, 
    subscriptionType,
    isAuthenticated, 
    onLoadUserSurveys,
    onLoadTeamMembers,
    onCreateTeamMembers,
    organizationDomain,
    mustPayEnterprise,
    location
  } = props;

  const [currentViewType, setCurrentViewType] = useState(VIEWS_TYPES.Default);
  const [currentEvaluationBeingManaged, setCurrentEvaluationBeingManaged] = useState(SURVEY_TYPE.CareerIQ);
  const currentPendingEvaluationsTypeBeingManaged = SURVEY_TYPE.CareerIQ;

  const toast = useToast();
  const history = useHistory();
  const onCreateTeamMemberDialog = useDisclosure();

  useEffect(() => {
    onLoadTeamMembers();
    if(userId && userId !== ''){
      onLoadUserSurveys(userId, 'Pending', () => { }, () => { });
    }
  }, []);

  useEffect(() => {
    if(mustPerformEvaluationOnboarding === true && hasSkipEvaluationOnboarding === false){
      history.push('/app/onboarding');
    } 
  }, []);
  
  const onSaveMember = (invitees, onSuccess) => {
    onCreateTeamMembers(invitees, USER_ROLE.TeamMember, (invitation) => {
      onSuccess();
    }, (msg) => {
      toast({
        title: 'Failed to add user',
        description: msg,
        status: 'error',
        duration: 9000,
        isClosable: true
      })
    });
  }

  const onClickToManageEvaluation = (evaluationType) => {
    setCurrentEvaluationBeingManaged(evaluationType);
    setCurrentViewType(VIEWS_TYPES.EvaluationManagement);
  }

  const onClickToToManagePendingAssessments = () => {
    setCurrentViewType(VIEWS_TYPES.PendingAssessments);
  }

  return (
    <Authenticated>
      <Box minH="1000px">
        {isAuthenticated === true ? 
        <Box>
            {currentViewType === VIEWS_TYPES.Default ? <DefaultView 
               userSurveys={userSurveys}
               teamMembers={teamMembers}
               inactiveTeamMembers={inactiveTeamMembers}
               loadingTeamMembers={loadingTeamMembers}
               firstName={firstName}
               lastName={lastName}
               role={role}
               subscriptionType={subscriptionType}
               onSaveMember={onSaveMember}
               organizationDomain={organizationDomain}
               mustPayEnterprise={mustPayEnterprise}
               onCreateTeamMemberDialog={onCreateTeamMemberDialog}
               onClickToManageEvaluation={onClickToManageEvaluation}
               onClickToToManagePendingAssessments={onClickToToManagePendingAssessments}
               numberOfPendingEvaluations={numberOfPendingEvaluations}
               numberOfPendingRegistrations={numberOfPendingRegistrations}
               location={location}
               userId={userId}
            /> : currentViewType === VIEWS_TYPES.EvaluationManagement ? <EvaluationManagementView 
              surveyType={currentEvaluationBeingManaged}
              onCloseEvaluationManagement={() => { setCurrentViewType(VIEWS_TYPES.Default)}}
            /> : <PendingAssessmentsView 
              evaluationType={currentPendingEvaluationsTypeBeingManaged}
              onClose={() => { setCurrentViewType(VIEWS_TYPES.Default) }}
            /> }
      </Box>
       : null}
      </Box>
    </Authenticated>
  );
}

AdminDefault.propTypes = {
  userId: PropTypes.string,
  subscriptions: PropTypes.array,
  userSurveys: PropTypes.array,
  subscriptionType: PropTypes.number,
  numberOfEvaluations: PropTypes.number,
  numberOfTeamMembers: PropTypes.number,
  numberOfEmployeeIQEvaluations: PropTypes.number,
  numberOfOrgIQEvaluations: PropTypes.number,
  numberOfBoardIQEvaluations: PropTypes.number,
  numberOfPendingEvaluations: PropTypes.number,
  numberOfCompletedEvaluations: PropTypes.number,
  numberOfCareerIqEvaluations: PropTypes.number,
  organizationDomain: PropTypes.string,
  onLoadUserSurveys: PropTypes.func,
  onLoadTeamMembers: PropTypes.func,
  onCreateTeamMembers: PropTypes.func,
  mustPayEnterprise: PropTypes.bool, 
  hasSkipEvaluationOnboarding: PropTypes.bool,
  onReinviteUser: PropTypes.func
};

AdminDefault.defaultProps = {
  userId: '',
  subscriptions: [],
  userSurveys: [],
  subscriptionType: -1,
  numberOfTeamMembers: 0,
  numberOfEvaluations: 0,
  numberOfEmployeeIQEvaluations: 0,
  numberOfOrgIQEvaluations: 0,
  numberOfBoardIQEvaluations: 0,
  numberOfPendingEvaluations: 0,
  numberOfCompletedEvaluations: 0,
  numberOfCareerIqEvaluations: 0,
  organizationDomain: '',
  mustPayEnterprise: true,
  hasSkipEvaluationOnboarding: false,
  onLoadUserSurveys: () => { },
  onLoadTeamMembers: () => { },
  onCreateTeamMembers: () => { },
  onReinviteUser: () => { }
}

function mapStateToProps(state) {

  const { 
    teamMembers, 
    inactiveTeamMembers,
    loadingTeamMembers,
    firstName, 
    lastName, 
    role, 
    subscriptionType,
    organizationName, 
    numberOfTeamMembers,
    numberOfEvaluations,
    numberOfEmployeeIQEvaluations,
    numberOfOrgIQEvaluations,
    numberOfBoardIQEvaluations,
    numberOfPendingEvaluations,
    numberOfPendingRegistrations,
    numberOfCompletedEvaluations,
    numberOfCareerIqEvaluations, 
    organizationDomain,
    mustPayEnterprise,
    mustPerformOnboarding,
    mustPerformEvaluationOnboarding,
    hasSkipEvaluationOnboarding
  } = state.user;

  const { isAuthenticated, userId } = state.authentication;

  const { userSurveys } = state.userSurveys;

  return {
    userId,
    teamMembers,
    firstName,
    lastName,
    role,
    subscriptionType,
    organizationName,
    isAuthenticated,
    numberOfTeamMembers,
    numberOfEvaluations,
    numberOfEmployeeIQEvaluations,
    numberOfOrgIQEvaluations,
    numberOfBoardIQEvaluations,
    numberOfPendingEvaluations,
    numberOfPendingRegistrations,
    numberOfCompletedEvaluations,
    numberOfCareerIqEvaluations,
    userSurveys,
    organizationDomain,
    mustPayEnterprise,
    mustPerformOnboarding,
    mustPerformEvaluationOnboarding,
    hasSkipEvaluationOnboarding,
    inactiveTeamMembers,
    loadingTeamMembers
   };
};

function mapDispatchToProps (dispatch) {
  return {
      onLoadUserSurveys: (userId, status, onSuccess, onError ) => dispatch(getUserSurveys({ userId, status, onSuccess, onError })),
      onLoadTeamMembers: () => dispatch(listOrganizationUsersAsync(null, () => {})),
      onCreateTeamMembers: (invitees, role, onSuccess, onError) => dispatch(addMultipleTeamMembersAsync({ invitees, role, onSuccess, onError })),
      onReinviteUser: (userSurveyResponseId, onSuccess, onError) => dispatch(resendUserInvitation({ userSurveyResponseId, onSuccess, onError })),
      dispatch
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AdminDefault);