/* eslint-disable */
import  { 
  Modal, 
  ModalOverlay, 
  ModalContent, 
  ModalBody, 
  ModalHeader,
  ModalCloseButton,
  ModalFooter,
  Button,
  Text
} from "@chakra-ui/react";

// Custom components
import React from "react";

export default function DeleteMember({ isOpen, onClose, id, onDelete }) {
  
    const initialRef = React.useRef(null);
    const finalRef = React.useRef(null);

    const onSubmit = (e) => {
      e.preventDefault();

      onDelete(id);
    }
  
    return (
        <Modal
          initialFocusRef={initialRef}
          finalFocusRef={finalRef}
          isOpen={isOpen}
          onClose={onClose}
        >
          <ModalOverlay />
          <ModalContent>
            <form onSubmit={onSubmit}>
              <ModalHeader>Delete team member</ModalHeader>
              <ModalCloseButton />
                <ModalBody pb={6}>
                  <Text
                    mb='36px'
                    ms='4px'
                    fontWeight='400'
                    fontSize='md'>
                    Are you sure you want to delete this member?
                  </Text>
                </ModalBody>
                <ModalFooter>
                  <Button colorScheme='blue' type="submit" mr={3}>
                    Delete
                  </Button>
                  <Button onClick={onClose}>Cancel</Button>
                </ModalFooter>
              </form>
          </ModalContent>
        </Modal>
    )
  }