import { Box, Grid, GridItem, Text } from '@chakra-ui/react';
import React from 'react';

function Analytics(props){
    return <Box minH="1000px">
            <Box textAlign={"center"}>
                <Text>Soon, we will be able to offer clients predictive analytics as the ones listed below.</Text>
            </Box>
            <Box p="40px" backgroundColor={"#fff"} mt="20px">
                <Grid templateColumns={"1fr 1fr"} rowGap={"100px"} columnGap={"30px"}>
                    <GridItem>
                        <Text fontWeight={"bold"}>Churn vs Retention Prediction</Text>
                        <Text>Churn focuses on the loss or departure of employees while retention focuses on the ability to maintain and keep them.</Text>
                        <Text>In making prediction, we use the indicators that cover xxx, yyyt, zzz aspects</Text>
                        <Text>Statistical measures: central tendency, standard deviation </Text>
                    </GridItem>
                    <GridItem>
                        <Text fontWeight={"bold"}>Engagement Prediction</Text>
                        <Text>Engagement focuses on fostering a positive and productive work environment where employees are  committed, motivated, and invested in their work and the organization.</Text>
                        <Text>In making prediction, we use the indicators #xx, #xy, etc.</Text>
                    </GridItem>
                    <GridItem>
                        <Text fontWeight={"bold"}>Loyalty Prediction</Text>
                        <Text>Retention focuses on the ability to maintain and keep  employees engaged and loyal to the organization.</Text>
                        <Text>In making prediction, we use the indicators #xx, #xy, etc.</Text>
                    </GridItem>
                </Grid>
            </Box>
        </Box>
}


Analytics.propTypes = {

};

Analytics.defaultProps = {

}

export default Analytics;