import { createSlice } from '@reduxjs/toolkit';

export const loaderSlice = createSlice({
  name: 'loader',
  initialState: {
    isLoading: false
  },
  reducers: {
    loading: (state) => {
        state.isLoading = true;
    },
    finishLoading: (state) => {
        state.isLoading = false;
    }
  },
});

export const { 
    loading,
    finishLoading
 } = loaderSlice.actions;

export const selectIsLoading = state => state.loader.isLoading;

export default loaderSlice.reducer;
