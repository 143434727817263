import { Box, Button, Grid, GridItem, Heading, Progress, SimpleGrid, Slider, SliderFilledTrack, SliderMark, 
    SliderThumb, SliderTrack, Tooltip, useToast, Text } from "@chakra-ui/react";
import { SURVEY_TYPE } from "constants/evaluation.const";
import { getUserSurveysById } from "features/userSurveys/slice";
import React, { useEffect } from "react";
import Authenticated from "../../authenticated";
import PropTypes from 'prop-types';
import { connect } from "react-redux";
import Card from "components/card/Card";
import { setAnswer } from "features/userSurveys/slice";
import { saveUserSurveyChanges } from "features/userSurveys/slice";
import { useHistory } from "react-router-dom";
import { COLORS } from "constants/colors";
import employeeIqImg from '../../../../assets/img/surveys/EmployeeIQ.svg';
import careerIQImg from '../../../../assets/img/surveys/CareerIQ.svg';
import orgIqImg from '../../../../assets/img/surveys/OrgIQ.svg';
import boardIqImg from '../../../../assets/img/surveys/BoardIQ.svg';

const survey_types_imgs = {
    [SURVEY_TYPE.EmployeeIq]: employeeIqImg,
    [SURVEY_TYPE.BoardIq]: boardIqImg,
    [SURVEY_TYPE.OrgIq]: orgIqImg,
    [SURVEY_TYPE.CareerIQ]: careerIQImg,
};

function EvaluationFormView(props) {

  const { userId, userSurvey, onLoadUserSurveyById, onChangeQuestionScore, answers, onSaveSurveyChanges, location } = props;

  const [questionIndexes, setQuestionIndexes] = React.useState([]);
  const [showErrors, setShowErrors] = React.useState(false);
  const [pageIndex, setPageIndex] = React.useState(0);
  const [hasChanges, setHasChanges] = React.useState(false);
  const [numberOfPages, setNumberOfPages] = React.useState(0);
  const [progress, setProgress] = React.useState(0);

  const history = useHistory();

  const toast = useToast();

  useEffect(() => {
    const route = location.pathname.split('/');
    const userSurveyResponseId = route[route.length - 1];

    if(userSurveyResponseId && userSurveyResponseId !== null && userSurveyResponseId !== ''){
      onLoadUserSurveyById(userId, userSurveyResponseId, (us) => {

        const pages = us.survey.questions.length / 4;
        const index = us.currentPageIndex === 0 ? 0 : us.currentPageIndex;

        setQuestionIndexes([
          (index * 4),
          (index * 4) + 1,
          (index * 4) + 2,
          (index * 4) + 3
        ]);

        setProgress(pages > 0 && index > 0 ? ((index + 1) / pages) * 100 : 0);
        setNumberOfPages(pages);
        setPageIndex(index);
       }, () => { });
    }
  }, []);

  const anyUnansweredQuestion = () => {
    let result = false;
    for(let i = 0; i < questionIndexes.length; i++){
      if(questionIndexes[i] < userSurvey.survey.questions.length){
        let questionId = userSurvey.survey.questions[questionIndexes[i]].id;
        if(answers[questionId] < 0){
          result = true;
        }
      }
    }
    return result;
  }

  const onClickNext = () => {
    setShowErrors(false);
    if (anyUnansweredQuestion() === false) {
      if (pageIndex < numberOfPages){
        let arr = [];
        for(let i = 0; i < questionIndexes.length; i++){
          arr.push(questionIndexes[i] + 4);
        }
        setQuestionIndexes(arr);
        
        const newPageIndex = pageIndex + 1;

        setPageIndex(newPageIndex);

        setProgress(numberOfPages > 0 && newPageIndex > 0 ? (newPageIndex / numberOfPages) * 100 : 0);
        
        if(hasChanges === true){
          onSaveSurveyChanges(userSurvey.organizationId, userSurvey.evaluationId, pageIndex, answers, null /* enterprise questions */, () => { 
            setHasChanges(false);
            toast({
              description: `Assessment successfully updated.`,
              status: 'success',
              duration: 3000,
              isClosable: true
            });
          }, () => { } );
        }
      }
    } else {
      setShowErrors(true);
    }
  }

  const onClickPrevious = () => {
    if (pageIndex > 0) {
      let arr = [];
      for(let i = 0; i < questionIndexes.length; i++){
        const newIndex = questionIndexes[i] - 4;
        arr.push(newIndex);
      }
      setQuestionIndexes(arr);

      const newindex = pageIndex - 1;

      setPageIndex(newindex);

      setProgress(numberOfPages > 0 && pageIndex > 0 ? (newindex / numberOfPages) * 100 : 0);
    }
  }

  const onClickFinish = () => {
    setShowErrors(false);
    if (anyUnansweredQuestion() === false) {
        onSaveSurveyChanges(userSurvey.organizationId, userSurvey.evaluationId, pageIndex, answers, userSurvey.enterpriseQuestions,
          () => { 
            setHasChanges(false);
            toast({
              description: `Assessment successfully submited.`,
              status: 'success',
              duration: 9000,
              isClosable: true
            });
            history.push(`/admin/assessment?surveyType=${userSurvey.surveyType}&report=true&evaluationId=${userSurvey.evaluationId}`);
          },  () => { } );
    } else {
      setShowErrors(true);
    }
  }

  const changeQuestionScore = (question, index, score) => {

    onChangeQuestionScore(question.id, score);
    setHasChanges(true);
  }

  const getQuestionCardClass = (index, id) => {
    return answers[id] < 0 ? "shake" : "";
  }

  const onClickSaveAndContinueLater = () => {
      onSaveSurveyChanges(userSurvey.organizationId, userSurvey.evaluationId, pageIndex, answers, null /* enterprise questions */, () => { 
        setHasChanges(false);
        toast({
          description: `Assessment saved`,
          status: 'success',
          duration: 3000,
          isClosable: true
        });
        history.push('/admin/default');
      }, () => { } );
    }

  const lessThanZeroThenZero = (val) => {
    return val < 0 ? 0 : val;
  }

  return (
    <Authenticated>
      <Box pt={ { md: "10px", xl: "100px" }} pb="100px" pl={{ sm: "10px", md: "10%", lg: "10%" } } pr={{ sm: "10px", md: "10%", lg: "10%" } }>
        <Card margin="auto">
          <Box pl="30px" pr="40px">
            {userSurvey !== null ? <Box sx={ { width: '100%', textAlign: 'center' } }>
            <Box mt="5px">
              <Grid templateColumns="1fr 1fr">
                <GridItem>
                  <Box display={"flex"}>
                    <Box h={"100%"} className="evaluation-type-image">
                      <img alt="" style={ { marginTop: '10%' } } src={userSurvey !== null ? survey_types_imgs[userSurvey.surveyType] : null} width={"50px"}/>
                    </Box>
                    <Heading size="md" mt="15px" ml="5px">{userSurvey.survey.title}</Heading>         
                  </Box>      
                </GridItem>
                <GridItem textAlign={"right"}>
                  <Text fontSize="md" mt="10px">Page {pageIndex+1} of {numberOfPages}</Text>
                </GridItem>
              </Grid>
            </Box>
            
            <Grid templateColumns={"1fr 1fr"} columnGap={"10px"} mt={"5px"}>
              {userSurvey.survey.questions.map((q, qi) => {
                return questionIndexes.indexOf(qi) >= 0 ? <Card
                  className={showErrors === true ? getQuestionCardClass(qi, q.id) : "assessment-question"} 
                  style={ { margin: 10, border: '#E4E4E4 1px solid' }}>
                  <Box ml="5px" mr="5px" mb="10px">
                    <Box>
                      <Box sx={ { width: '100%', textAlign: 'center' } }>
                        <Text fontSize="14px" height="40px">
                          {process.env.REACT_APP_DEBUG_MODE === "true" ? `${q.order}. ` : null}{q.title}
                        </Text>
                        </Box>
                        {/* <Box w="100%">
                          <Text style={ { height: "20px" }} fontSize="10px">
                            {q.description !== null && q.description ? q.description : null}
                          </Text>
                        </Box> */}
                        <Box sx={ { marginTop: 5 } } position={"relative"}>
                          <Box style={ { textAlign: 'center' } }>
                            <Heading size="md">{answers[q.id] ? (answers[q.id] < 0 ? "-" : answers[q.id]) : '-'}</Heading>
                          </Box>
                          <Slider
                            id='slider'
                            defaultValue={answers[q.id] ? lessThanZeroThenZero(answers[q.id]) : 0}
                            min={0}
                            max={10}
                            mt={5}
                            mb={10}
                            onChange={(v) => changeQuestionScore(q, qi, v)}
                          >
                            {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((i) => {
                              return  <SliderMark value={i} mt='3' ml='-2.5' fontSize='12px'>
                                {i}
                              </SliderMark>
                            })}

                            <SliderTrack>
                              <SliderFilledTrack/>
                            </SliderTrack>
                            <Tooltip
                              bg={'#0095FF'}
                              colorScheme="blue"
                              style={{ backgroudColor: "#0095FF", zIndex: 999, position: 'absolute'}}
                              placement='bottom'
                              isOpen={true}
                              label={answers[q.id] ? lessThanZeroThenZero(answers[q.id]) : 0}
                            >
                              <SliderThumb>
                                <Box/>
                              </SliderThumb>
                            </Tooltip>
                          </Slider>
                          <SimpleGrid columns={2} >
                            <Box style={ { left: '0' } } position={"absolute"}>
                              Disagree
                            </Box>
                            <Box style={ { right: '0' } } position={"absolute"}>
                              Agree
                            </Box>
                          </SimpleGrid>
                        </Box>
                      </Box>
                  </Box>
                </Card> : null})}
            </Grid>
            <Box sx={ { textAlign: 'center '} } mt={"5px"}>
              <Heading sx={ { color: COLORS.Primary } } size="xs">{pageIndex > 0 ? `${parseInt(progress)}%` : null}</Heading>
            </Box>
            <Box sx={{ width: '100%', paddingLeft: '30%', mb: "10px", paddingRight: '30%', alignContent: 'middle', justifyContent: 'center', textAlign: 'center' } }>
              <Progress value={progress} size='xs' width={"100%"} colorScheme='intro' mt={2} mr={3} ml={3} />
            </Box>
            <Box style={ { textAlign: 'center' } }>
              <Grid templateColumns={"1fr 1fr 1fr"}>
                {pageIndex > 0 ? <GridItem>
                  <Button
                  color={COLORS.Primary}
                  onClick={onClickPrevious}
                  float="left"
                  width="180px"
                  height="50px"
                  fontWeight='500'>
                    Back
                  </Button>
                </GridItem>  : null}   
                <GridItem>
                    <Text fontSize="md" mt="15px" color="gray" ml="20px" className="skip-button" onClick={() => onClickSaveAndContinueLater()}>Save and Continue Later</Text>
                </GridItem>
                {pageIndex < numberOfPages - 1 ? <GridItem><Button
                  float="right"
                  sx={ { marginRight: '15px', marginLeft: '25px' } }
                  style={ { backgroundColor: COLORS.Primary, color: COLORS.PrimaryText } }
                  onClick={onClickNext}
                  width="180px"
                  height="50px"
                  fontWeight='500'>
                    Next
                  </Button> </GridItem> : null}
                  {pageIndex === numberOfPages - 1 ? <GridItem><Button
                  sx={ { float: 'right', marginRight: '10px' } }
                  style={ { backgroundColor: COLORS.Primary, color: COLORS.PrimaryText } }
                  width="180px"
                  height="50px"
                  onClick={onClickFinish}
                  fontWeight='500'>
                    Finish
                  </Button> </GridItem> : null}
              </Grid>
            </Box>
          </Box> : null}
          </Box>
        </Card>
      </Box>
    </Authenticated>
  );
}


EvaluationFormView.propTypes = {
  location: PropTypes.object,
  userId: PropTypes.string,
  userSurvey: PropTypes.object,
  answers: PropTypes.array,
  onLoadUserSurveyById: PropTypes.func,
  onChangeQuestionScore: PropTypes.func,
  onSaveSurveyChanges: PropTypes.func
};

EvaluationFormView.defaultProps = {
  location: { },
  userId: '',
  userSurvey: null,
  answers: [],
  onLoadUserSurveyById: () => { },
  onChangeQuestionScore: () => { },
  onSaveSurveyChanges: () => { }
}

function mapStateToProps(state) {

  const { userId } = state.authentication;

  const { userSurvey, answers } = state.userSurveys;

  return {
    userId,
    userSurvey,
    answers
   };
};

function mapDispatchToProps (dispatch) {
  return {
      onLoadUserSurveyById: (userId, userSurveyResponseId, onSuccess, onError ) => dispatch(getUserSurveysById({ userId, userSurveyResponseId, onSuccess, onError })),
      onChangeQuestionScore: (questionId, score) => dispatch(setAnswer({ questionId, score })),
      onSaveSurveyChanges: (organizationId, evaluationId, pageIndex, answers, enterpriseQuestions, onSuccess, onError) => dispatch(saveUserSurveyChanges( { organizationId, evaluationId, pageIndex, answers, enterpriseQuestions, onSuccess, onError } )),
      dispatch
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(EvaluationFormView);