/*!
  _   _  ___  ____  ___ ________  _   _   _   _ ___   
 | | | |/ _ \|  _ \|_ _|__  / _ \| \ | | | | | |_ _| 
 | |_| | | | | |_) || |  / / | | |  \| | | | | || | 
 |  _  | |_| |  _ < | | / /| |_| | |\  | | |_| || |
 |_| |_|\___/|_| \_\___/____\___/|_| \_|  \___/|___|
                                                                                                                                                                                                                                                                                                                                       
=========================================================
* Horizon UI - v1.1.0
=========================================================

* Product Page: https://www.horizon-ui.com/
* Copyright 2022 Horizon UI (https://www.horizon-ui.com/)

* Designed and Coded by Simmmple

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// Chakra imports
import {
  Box,
  Button,
  Tabs,
  TabList,
  Tab,
  useToast,
  SimpleGrid,
  Text,
  Tooltip,
  Heading,
  Link,
  InputGroup,
  InputLeftElement,
  Input,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "@chakra-ui/react";
import _ from "lodash";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Card from "components/card/Card.js";
import {
  getOrganizationSurvey,
  getOrganizationEvaluations,
} from "features/surveys/slice";
import React, { useEffect, useState } from "react";
import { listOrganizationUsersAsync } from "features/user/slice";
import { addNewTeamMemberAsync } from "features/user/slice";
import { saveOrganizationEvaluation } from "features/surveys/slice";
import { SURVEY_TYPE } from "constants/evaluation.const";
import EvaluationBody from "./EvaluationBody";
import ConfirmationDialog from "components/confirmDialog/ConfirmationDialog";
import { createEvaluation } from "features/surveys/slice";
import { setSurvey } from "features/surveys/slice";
import { AddIcon, SearchIcon } from "@chakra-ui/icons";
import { SURVEY_TYPE_DESCRIPTION } from "constants/surveyTypes.const";
import { COLORS } from "constants/colors";
import modalImgCheck from "../../../../assets/img/modal/check.svg";
import { updateOrganizationEvaluation } from "features/userSurveys/slice";

function Evaluation(props) {
  // Chakra Color Mode

  const {
    onLoadOrganizationSurvey,
    onLoadTeamMembers,
    onLoadOrganizationEvaluations,
    onCreateTeamMembers,
    onSaveEvaluation,
    onCreateNewEvaluation,
    survey,
    onClearSurvey,
    evaluations,
    teamMembers,
    surveyType,
    surveyTypeText,
    onCloseEvaluationManagement,
    onResendInvitations
  } = props;

  const [currentEvaluationId, setcurrentEvaluationId] = React.useState("");
  const [isAddParticipantModalOpen, setOpenParticipantModalOpen] =
    React.useState(false);
  const [isCreateMemberOpen, setCreateMemberOpen] = React.useState(false);
  const [evaluationParticipants, setEvaluationParticipants] = React.useState(
    []
  );
  const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] =
    React.useState(false);
  const [tabIndex, setTabIndex] = React.useState(0);
  const [isModalOpen, setModalOpen] = useState(false);

  const currentEvaluation =
    currentEvaluationId !== ""
      ? _.find(evaluations, { id: currentEvaluationId })
      : null;

  const toast = useToast();

  useEffect(() => {
    onLoadOrganizationEvaluations(
      surveyType,
      (evaluations) => {
        if (evaluations && evaluations.length > 0) {
          setcurrentEvaluationId(evaluations[0].id);
          setEvaluationParticipants(
            _.map(evaluations[0].participants, (i) => {
              return i.id;
            })
          );
          onLoadOrganizationSurvey(evaluations[0].id);
          setTabIndex(0);
        }
        setTabIndex(0);
      },
      (error) => {
        console.log(error);
      }
    );
  }, []);

  const handleEvaluationClick = (evaluationId) => {
    onClearSurvey();
    setcurrentEvaluationId("");
    setEvaluationParticipants([]);
    onLoadOrganizationEvaluations(
      surveyType,
      (evaluations) => {
        if (evaluations && evaluations.length > 0) {
          const evaluationIndex = _.findIndex(evaluations, (i) => {
            return i.id === evaluationId;
          });
          setcurrentEvaluationId(evaluationId);
          setEvaluationParticipants(
            _.map(evaluations[evaluationIndex].participants, (i) => {
              return i.id;
            })
          );
          onLoadOrganizationSurvey(evaluationId);
        }
        setTabIndex(0);
      },
      (error) => {
        console.log(error);
      }
    );
  };

  const handleCreateNewEvaluation = () => {
    onCreateNewEvaluation(
      surveyType,
      (evaluation) => {
        toast({
          title: "Evaluation updated",
          description: `${surveyTypeText} Evaluation successfully created`,
          status: "success",
          duration: 9000,
          isClosable: true,
        });
        setIsConfirmationDialogOpen(false);

        onLoadOrganizationEvaluations(
          surveyType,
          (evaluations) => {
            setcurrentEvaluationId(evaluation.id);
            setEvaluationParticipants(evaluation.userIds);
            setTabIndex(0);
          },
          (error) => {
            console.log(error);
          }
        );

        onLoadOrganizationSurvey(evaluation.id);
      },
      (error) => {
        let errorMsg = "Sorry, something went wrong! Try again later.";

        if (error.response.data && error.response.data.description) {
          errorMsg = error.response.data.description;
        }

        toast({
          title: `${surveyTypeText} Evaluation error`,
          description: errorMsg,
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      }
    );
  };

  const handleSaveParticipants = () => {
    const onSuccess = () => {
      toast({
        title: "Evaluation updated",
        description: "Evaluation successfully saved",
        status: "success",
        duration: 9000,
        isClosable: true,
      });
      const previousEvaluationId = currentEvaluationId;

      setOpenParticipantModalOpen(false);
      setCreateMemberOpen(false);
      onLoadOrganizationEvaluations(
        surveyType,
        (evaluations) => {
          if (evaluations && evaluations.length > 0) {
            const evaluation = _.find(evaluations, {
              id: previousEvaluationId,
            });
            setcurrentEvaluationId(evaluation.id);
            setEvaluationParticipants(
              _.map(evaluation.participants, (i) => {
                return i.id;
              })
            );
            setTabIndex(0);
          }
        },
        (error) => {
          console.log(error);
        }
      );
    };

    const onFailure = (error) => {
      let errorMsg = "Sorry, something went wrong! Try again later.";

      if (error.response.data && error.response.data.description) {
        errorMsg = error.response.data.description;
      }

      toast({
        title: "Evaluation error",
        description: errorMsg,
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    };

    onSaveEvaluation(
      currentEvaluation.organizationId,
      currentEvaluation.id,
      evaluationParticipants,
      onSuccess,
      onFailure
    );
  };

  const handleAddTeamMembers = () => {
    onLoadTeamMembers(() => {
      setOpenParticipantModalOpen(true);
    });
  };

  const handleClickCreateTeamMember = () => {
    setOpenParticipantModalOpen(false);
    setCreateMemberOpen(true);
  };

  const handleCloseCreateMember = () => {
    setCreateMemberOpen(false);
    setOpenParticipantModalOpen(true);
  };

  const onClickAddParticipant = (checked, userId) => {
    let lstParticipants = Object.assign([], evaluationParticipants);

    if (checked === true) {
      lstParticipants.push(userId);
    } else {
      lstParticipants = _.filter(evaluationParticipants, (i) => {
        return i !== userId;
      });
    }

    setEvaluationParticipants(lstParticipants);
  };

  const onClickSend = () => {
    handleResendInvitations(evaluationParticipants);
  };

  const handleResendInvitations = (selected) => {
    if (selected.length > 0) {
      onResendInvitations(
        currentEvaluationId,
        selected,
        () => {
          toast({
            title: "Success",
            description: "Invitation(s) successfully sent.",
            status: "success",
            duration: 9000,
            isClosable: true,
          });
        },
        () => {
          toast({
            title: "Error",
            description:
              "Unable to resend the invitation(s), please contact support.",
            status: "error",
            duration: 9000,
            isClosable: true,
          });
        }
      );
    } else {
      toast({
        title: "Error",
        description:
          "Select at least one team member to re-send the invitation.",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    }
  };

  const handleCreateNewTeamMember = (firstName, lastName, email, onSuccess) => {
    onCreateTeamMembers(
      firstName,
      lastName,
      email,
      (invitation) => {
        onLoadTeamMembers(() => {
          onClickAddParticipant(true, invitation.userId);
        });
        onSuccess();
        setOpenParticipantModalOpen(true);
      },
      (msg) => {
        toast({
          title: "Failed to add user",
          description: msg,
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      }
    );
  };
  return (
    <SimpleGrid
      columns={{ sm: 1, md: 1, lg: 1 }}
      spacingX={3}
      ml={{ sm: "0px", md: "0px", lg: "33px", xl: "33px" }}
      mt={"30px"}
    >
      <Box ml="20px" width={"90%"}>
        <Box display={"flex"}>
          <Box width={"50%"} display={"flex"}>
            <Box>
              <Heading fontSize="2xl">
                Assessment Type: {SURVEY_TYPE_DESCRIPTION[surveyType]}
              </Heading>
            </Box>
            <Box mt="6px" ml="20px">
              <Link
                href="#"
                color={COLORS.Primary}
                onClick={() => {
                  onCloseEvaluationManagement();
                }}
              >
                Dashboard
              </Link>
            </Box>
          </Box>
          <Box width={"50%"}>
            <Box float={"right"} mt="10px">
              <Button
                w="170px"
                minW="170px"
                height={"50px"}
                style={{
                  backgroundColor: COLORS.Primary,
                  color: COLORS.PrimaryText,
                }}
                onClick={() => {
                  setModalOpen(true);
                }}
                fontWeight="500"
              >
                Send All
              </Button>
            </Box>
          </Box>
        </Box>
        <Box>
          <Text fontSize="1xl">Manage Assessment Participants</Text>
        </Box>
        <Box>
          <Tabs mt="10px">
            <Box>
              {evaluations ? (
                <TabList>
                  {evaluations.map((e, i) => {
                    return (
                      <Tab onClick={() => handleEvaluationClick(e.id)}>
                        Evaluation {e.number}
                      </Tab>
                    );
                  })}
                  <Tab>
                    <Tooltip label="New Evaluation">
                      <AddIcon
                        onClick={() => setIsConfirmationDialogOpen(true)}
                      />
                    </Tooltip>
                  </Tab>
                </TabList>
              ) : null}
            </Box>
            <Box mt="10px">
              <Box>
                <InputGroup>
                  <InputLeftElement>
                    <SearchIcon color="#0095FF" />
                  </InputLeftElement>
                  <Input placeholder="Search member" size="md" bg={"#fff"} />
                </InputGroup>
              </Box>
              <Box>
                {!evaluations || evaluations.length <= 0 ? (
                  <div>
                    <Card
                      mb={{ base: "0px", lg: "20px" }}
                      mt={10}
                      style={{ textAlign: "center", height: 200 }}
                    >
                      <div style={{ marginTop: 30 }}>
                        You currently have no {surveyTypeText} evaluations.
                      </div>
                      <div style={{ marginTop: 20 }}>
                        <Button
                          variant="brand"
                          onClick={() => setIsConfirmationDialogOpen(true)}
                          mr={3}
                        >
                          Create {surveyTypeText} Evaluation
                        </Button>
                      </div>
                    </Card>
                  </div>
                ) : null}

                {evaluations && evaluations.length > 0 ? (
                  <EvaluationBody
                    evaluations={evaluations}
                    currentEvaluation={currentEvaluation}
                    currentEvaluationId={currentEvaluationId}
                    isAddParticipantModalOpen={isAddParticipantModalOpen}
                    isCreateMemberOpen={isCreateMemberOpen}
                    teamMembers={teamMembers}
                    evaluationParticipants={evaluationParticipants}
                    survey={survey}
                    handleAddTeamMembers={handleAddTeamMembers}
                    handleCloseParticipantsModal={onCloseEvaluationManagement}
                    handleSaveParticipants={handleSaveParticipants}
                    onClickAddParticipant={onClickAddParticipant}
                    handleClickCreateTeamMember={handleClickCreateTeamMember}
                    handleCloseCreateMember={handleCloseCreateMember}
                    handleCreateNewTeamMember={handleCreateNewTeamMember}
                    tabIndex={tabIndex}
                    handleTabsChange={(index) => setTabIndex(index)}
                  />
                ) : null}

                <ConfirmationDialog
                  title="Confirmation"
                  description={`You are about to create a ${surveyTypeText} evaluation.`}
                  confirmText="Proceed"
                  cancelText="Cancel"
                  isOpen={isConfirmationDialogOpen}
                  onClose={() => setIsConfirmationDialogOpen(false)}
                  onConfirm={() =>
                    handleCreateNewEvaluation(SURVEY_TYPE.EmployeeIq)
                  }
                />
              </Box>
              <Box>
                <Box mt="10px" ml="10px">
                  <Button
                    w="170px"
                    minW="170px"
                    height={"50px"}
                    style={{
                      backgroundColor: COLORS.Primary,
                      color: COLORS.PrimaryText,
                    }}
                    onClick={onClickSend}
                    fontWeight="500"
                  >
                    Send
                  </Button>
                  <Modal
                    isOpen={isModalOpen}
                    onClose={() => setModalOpen(false)}
                  >
                    <ModalOverlay />
                    <ModalContent>
                      <ModalHeader>
                        <img
                          alt=""
                          src={modalImgCheck}
                          style={{ marginLeft: "auto", marginRight: "auto" }}
                        />
                      </ModalHeader>
                      <ModalBody textAlign={"center"}>
                        <Heading size="sm">Invitation has been sent</Heading>
                        <Text mt="10px">
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit, sed do eiusmod tempor
                        </Text>
                      </ModalBody>

                      <ModalFooter justifyContent={"center"}>
                        <Button
                          style={{
                            backgroundColor: COLORS.Primary,
                            color: COLORS.PrimaryText,
                          }}
                          mr={3}
                          onClick={() => setModalOpen(false)}
                        >
                          Close
                        </Button>
                      </ModalFooter>
                    </ModalContent>
                  </Modal>
                </Box>
              </Box>
            </Box>
          </Tabs>
        </Box>
      </Box>
    </SimpleGrid>
  );
}

Evaluation.propTypes = {
  survey: PropTypes.object,
  onLoadOrganizationSurvey: PropTypes.func,
  onLoadOrganizationEvaluations: PropTypes.func,
  onLoadTeamMembers: PropTypes.func,
  onSaveEvaluation: PropTypes.func,
  evaluations: PropTypes.array,
  organizationId: PropTypes.string,
  teamMembers: PropTypes.array,
  onCreateTeamMembers: PropTypes.func,
  onClearSurvey: PropTypes.func,
  onCloseDialog: PropTypes.func,
  onResendInvitations: PropTypes.func,
};

Evaluation.defaultProps = {
  survey: null,
  onLoadOrganizationSurvey: () => {},
  onLoadOrganizationEvaluations: () => {},
  onLoadTeamMembers: () => {},
  onCreateTeamMembers: () => {},
  onSaveEvaluation: () => {},
  onClearSurvey: () => {},
  onCloseDialog: () => {},
  onResendInvitations: () => {},
  evaluations: [],
  organizationId: "",
  teamMembers: [],
};

function mapStateToProps(state) {
  const { survey, evaluations } = state.surveys;
  const { organizationId } = state.authentication;
  const { teamMembers } = state.user;

  return {
    survey,
    evaluations,
    organizationId,
    teamMembers
  };
}

function mapDispatchToProps(dispatch) {
  return {
    onLoadOrganizationSurvey: (evaluationId, onSuccess) =>
      dispatch(getOrganizationSurvey(evaluationId, onSuccess)),
    onLoadTeamMembers: (onSuccess) =>
      dispatch(listOrganizationUsersAsync(null, onSuccess)),
    onCreateTeamMembers: (firstName, lastName, email, onSuccess, onError) =>
      dispatch(
        addNewTeamMemberAsync({
          firstName,
          lastName,
          email,
          onSuccess,
          onError,
        })
      ),
    onLoadOrganizationEvaluations: (surveyType, onSuccess, onError) =>
      dispatch(getOrganizationEvaluations(surveyType, onSuccess, onError)),
    onSaveEvaluation: (
      organizationId,
      evaluationId,
      userIds,
      onSuccess,
      onFailure
    ) =>
      dispatch(
        saveOrganizationEvaluation(
          organizationId,
          evaluationId,
          userIds,
          onSuccess,
          onFailure
        )
      ),
    onCreateNewEvaluation: (surveyType, onSuccess, onError) =>
      dispatch(createEvaluation(surveyType, onSuccess, onError)),
    onClearSurvey: () => dispatch(setSurvey(null)),
    onResendInvitations: (evaluationId, userIds, onSuccess, onError) =>
      dispatch(
        updateOrganizationEvaluation({
          evaluationId,
          userIds,
          onSuccess,
          onError,
        })
      ),
    dispatch,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Evaluation);
