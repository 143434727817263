// OrganizationSpread
export const ORGANIZATION_SPREAD = {
    Global: 1,
    Mixed: 2,
    Local: 3,
 };
  
  // OrganizationTimeInBusiness
 export const ORGANIZATION_TIME_IN_BUSINESS = {
    Long: 1,
    Medium: 2,
    Short: 3,
 };
  
  // OrganizationDominantIndustry
 export const ORGANIZATION_DOMINANT_INDUSTRY = {
    Academia: 1,
    Construction: 2,
    Electronics: 3,
    EnergyAndUtilities: 4,
    FinancialServices: 5,
    FoodAndBeverage: 6,
    Government: 7,
    Healthcare: 8,
    Hospitality: 9,
    InformationTechnology: 10,
    ProfessionalServices: 11,
    Manufacturing: 12,
    Media: 13,
    MedicalProducts: 14,
    NonProfit: 15,
    Pharmaceuticals: 16,
    RealEstate: 17,
    Retail: 18,
    TransportAndLogistics: 19,

    Services: 20,
    LifeSciences: 21,
    Consumer: 22,
    Technology: 23,
    HeavyIndustriesAndNaturalResources: 24,
    PublicSector: 25
};
  
  // OrganizationSize
 export const ORGANIZATION_SIZE = {
    Large: 1,
    Midsize: 2,
    Small: 3,
};
  
  // OrganizationSizeInHeadcounts
 export const ORGANIZATION_SIZE_IN_HEADCOUNTS = {
    Large: 1,
    Midsize: 2,
    Small: 3,
  };
  
  // OrganizationType
 export const ORGANIZATION_TYPE = {
    PublicForProfit: 1,
    PrivateForProfit: 2,
    NonProfit: 3,
    Government: 4,
};
  
  // OrganizationAuthorizationLevels
 export const ORGANIZATION_AUTHORIZATION_LEVELS = {
    Many: 1,
    Medium: 2,
    Few: 3,
};
  
  // OrganizationMaturity
export const ORGANIZATION_MATURITY = {
    Mature: 1,
    Developing: 2,
    Beginner: 3,
};
  