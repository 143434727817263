// Chakra imports
import { Input, InputGroup } from "@chakra-ui/react";
import React from "react";
import './Banner.css';

export default function PaymentLink(props) {
  const { 
    organizationDomain
  } = props;
  const text = `https://${organizationDomain}.introgauge.io/#/buy`;

  return (
      <InputGroup mt={"15px"}>
        <Input value={text} />
      </InputGroup>
  );
}
