import { createSlice } from '@reduxjs/toolkit';
import _ from 'lodash';
import { getAuthenticatedClient } from '../../api';
import { loading, finishLoading } from '../loader/slice';
import { extractOrganizationId } from 'helpers/storageHelper';


export const userSurveys = createSlice({
  name: 'userSurveys',
  initialState: {
      userSurvey: null,
      userSurveys: [],
      answers: []
  },
  reducers: {
    setUserSurvey: (state, action) => {
        state.userSurvey = action.payload;
        let answers = {};
        for(let i = 0; i < action.payload.survey.questions.length; i++){
            const id = action.payload.survey.questions[i].id;
            let answer = _.find(action.payload.answers, function (i) { return i.questionId === id } );
            
            answers[action.payload.survey.questions[i].id] = answer && answer !== null ? answer.score : -1;
        }
        state.answers = answers;
    },
    setUserSurveys: (state, action) => {
        state.userSurveys = action.payload;
    },
    setAnswer: (state, action) => {
        const { questionId, score } = action.payload;

        state.answers[questionId] = score;
    },
  }
});

export const { 
    setUserSurvey,
    setUserSurveys,
    setAnswer
 } = userSurveys.actions;

export const getUserSurveys = ({ userId, status, onSuccess, onError }) => async dispatch => {

    dispatch(loading());
    const client = await getAuthenticatedClient(dispatch);
    client.get(`v1/users/${userId}/surveys?status=${status}`)
        .then(res => {
            let evaluations = res.data;
            dispatch(setUserSurveys(evaluations));
            onSuccess(evaluations);
            dispatch(finishLoading());
        })
        .catch((error) => {
            dispatch(finishLoading());
            onError(error);
        });
};


export const updateOrganizationEvaluation = ({ evaluationId, userIds, onSuccess, onError }) => async dispatch => {

    dispatch(loading());
    const client = await getAuthenticatedClient(dispatch);
    client.put(`v1/organizations/${extractOrganizationId()}/evaluation/${evaluationId}`, { userIds })
        .then(res => {
            onSuccess();
            dispatch(finishLoading());
        })
        .catch((error) => {
            dispatch(finishLoading());
            onError(error);
        });
};

export const getUserSurveysById = ({ userId, userSurveyResponseId, onSuccess, onError }) => async dispatch => {

    dispatch(loading());
    const client = await getAuthenticatedClient(dispatch);
    client.get(`v1/users/${userId}/surveys/${userSurveyResponseId}`)
        .then(res => {
            let evaluations = res.data;
            if(evaluations !== null && evaluations.length > 0){
                dispatch(setUserSurvey(evaluations[0]));
                onSuccess(evaluations[0]);
            }
            dispatch(finishLoading());
        })
        .catch((error) => {
            dispatch(finishLoading());
            onError(error);
        });
};

export const resendUserInvitation = ({ userSurveyResponseId, onSuccess, onError }) => async dispatch => {
    dispatch(loading());
    const client = await getAuthenticatedClient(dispatch);
    client.put(`v1/usersurvey/${userSurveyResponseId}/invitation`)
        .then(res => {
            onSuccess();
            dispatch(finishLoading());
        })
        .catch((error) => {
            dispatch(finishLoading());
            onError(error);
        });
}

export const resendMultipleUserInvitations = ({ userIds, onSuccess, onError }) => async dispatch => {
    dispatch(loading());
    const client = await getAuthenticatedClient(dispatch);
    client.put(`v1/organization/${extractOrganizationId()}/usersurveys/invitation`, { userIds })
        .then(res => {
            onSuccess();
            dispatch(finishLoading());
        })
        .catch((error) => {
            dispatch(finishLoading());
            onError(error);
        });
}

export const saveUserSurveyChanges = ( { organizationId, evaluationId, answers, enterpriseQuestions, pageIndex, onSuccess, onError }) => async dispatch => {

    dispatch(loading());
    const client = await getAuthenticatedClient(dispatch);
    let userAnswers = [];
    
    for (const question in answers) {
        userAnswers.push({ questionId: question, score: answers[question]});
    }

    client.post(`v1/organizations/${organizationId}/evaluations/${evaluationId}/answer`, { 
        answers: userAnswers,
        enterpriseQuestions: enterpriseQuestions,
        currentPageIndex: pageIndex
    })
        .then(res => {
            onSuccess(res.data);
            dispatch(finishLoading());
        })
        .catch((error) => {
            dispatch(finishLoading());
            onError(error);
        });
}


export default userSurveys.reducer;
