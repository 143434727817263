import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Box, Heading, Text } from '@chakra-ui/react';
import Authenticated from '../authenticated';
import { getInsightTrends } from 'features/insights/slice';
import Trends from './components/Trends';
import { INSIGHT_VIEW } from 'constants/insightsViews.const';
import Analytics from './components/Analytics';
import DataDriveDecisions from './components/DataDriveDecisions';
import VideoDialog from './components/VideoDialog';
import { setVideoDialogOpen } from 'features/insights/slice';

function Insights(props){

    const { 
        title, 
        description, 
        trends, 
        loading, 
        trendsBySizeData,
        trendsByTimeInBusinessData,
        trendsByIndustryData,
        trendsBySpreadData,
        trendsByTypeData,
        trendsByHeadCountsData,
        myOrganizationTrendsBySizeData,
        myOrganizationTrendsByTimeInBusinessData,
        myOrganizationTrendsByIndustryData,
        myOrganizationTrendsBySpreadData,
        myOrganizationTrendsByTypeData,
        myOrganizationTrendsByHeadCountsData,
        onGetTrends,
        view,
        videoDialogOpen
    } = props;


    const handleGetTrends = (surveyType, onSuccess) => {
        onGetTrends(surveyType, onSuccess, () => { });
    }

    return <Authenticated>
           <Box minH="1000px" width={"100%"} pr={"100px"}>
            <Box p="40px">
                    <Heading>Insights: {title}</Heading>
                    <Text>{description}</Text>
                        {videoDialogOpen === true ? <VideoDialog 
                            isOpen={videoDialogOpen}
                            isLoading={loading}
                            onClose={() => () => { }}
                        /> : null}
                </Box>
                <Box padding={"30px"}>
                    {view === INSIGHT_VIEW.Trends ? <Trends 
                        title={title}
                        description={description}
                        trends={trends}
                        loading={loading}
                        trendsBySizeData={trendsBySizeData}
                        trendsByTimeInBusinessData={trendsByTimeInBusinessData}
                        trendsByIndustryData={trendsByIndustryData}
                        trendsBySpreadData={trendsBySpreadData}
                        trendsByTypeData={trendsByTypeData}
                        trendsByHeadCountsData={trendsByHeadCountsData}
                        myOrganizationTrendsBySizeData={myOrganizationTrendsBySizeData}
                        myOrganizationTrendsByTimeInBusinessData={myOrganizationTrendsByTimeInBusinessData}
                        myOrganizationTrendsByIndustryData={myOrganizationTrendsByIndustryData}
                        myOrganizationTrendsBySpreadData={myOrganizationTrendsBySpreadData}
                        myOrganizationTrendsByTypeData={myOrganizationTrendsByTypeData}
                        myOrganizationTrendsByHeadCountsData={myOrganizationTrendsByHeadCountsData}
                        onGetTrends={handleGetTrends}
                    /> : null}

                    {view === INSIGHT_VIEW.Analytics ? <Analytics /> : null}

                    {view === INSIGHT_VIEW.DataDriveDecisions ? <DataDriveDecisions /> : null}
                </Box>
            </Box>
        </Authenticated>
}


Insights.propTypes = {
    title: PropTypes.string,
    description: PropTypes.string,
    trends: PropTypes.array,
    trendsByTimeInBusinessData: PropTypes.array,
    loading: PropTypes.bool,
    onGetTrends: PropTypes.func,
    view: PropTypes.number
};

Insights.defaultProps = {
    title: "Trends",
    description: "",
    trends: [],
    trendsBySizeData: [],
    trendsByTimeInBusinessData: [],
    trendsByIndustryData: [],
    trendsBySpreadData: [],
    trendsByTypeData: [],
    trendsByHeadCountsData: [],
    myOrganizationTrendsBySizeData: [],
    myOrganizationTrendsByTimeInBusinessData: [],
    myOrganizationTrendsByIndustryData: [],
    myOrganizationTrendsBySpreadData: [],
    myOrganizationTrendsByTypeData: [],
    myOrganizationTrendsByHeadCountsData: [],
    loading: false,
    onGetTrends: () => { },
    view: INSIGHT_VIEW.Trends
}

function mapStateToProps(state) {

    const {
        title,
        description,
        trends,
        trendsBySizeData,
        trendsByTimeInBusinessData,
        trendsByIndustryData,
        trendsBySpreadData,
        trendsByTypeData,
        trendsByHeadCountsData,
        myOrganizationTrendsBySizeData,
        myOrganizationTrendsByTimeInBusinessData,
        myOrganizationTrendsByIndustryData,
        myOrganizationTrendsBySpreadData,
        myOrganizationTrendsByTypeData,
        myOrganizationTrendsByHeadCountsData,
        loading,
        view,
        videoDialogOpen
      } = state.insights;

    return {
        title,
        description,
        trends,
        trendsBySizeData,
        trendsByTimeInBusinessData,
        trendsByIndustryData,
        trendsBySpreadData,
        trendsByTypeData,
        trendsByHeadCountsData,
        myOrganizationTrendsBySizeData,
        myOrganizationTrendsByTimeInBusinessData,
        myOrganizationTrendsByIndustryData,
        myOrganizationTrendsBySpreadData,
        myOrganizationTrendsByTypeData,
        myOrganizationTrendsByHeadCountsData,
        loading,
        view,
        videoDialogOpen
    };
};

function mapDispatchToProps (dispatch) {
    return {
        onGetTrends: (surveyType, onSuccess, onError) => dispatch(getInsightTrends(surveyType, onSuccess, onError)),
        onSetVideoDialogOpen: (val) => dispatch(setVideoDialogOpen(val)),
        dispatch
    }
}
  
export default connect(mapStateToProps, mapDispatchToProps)(Insights);