import { createSlice } from '@reduxjs/toolkit';
import { getAnonymousClient } from '../../api';
import { loading, finishLoading } from '../loader/slice';
import { localDictionary } from './dictionary';

export const languageSlice = createSlice({
  name: 'language',
  initialState: {
    language: 'en-US',
    dictionary: localDictionary
  },
  reducers: {
    setLanguage: (state, action) => {
        state.language = action.payload;
    },
    setDictionary: (state, action) => {
        state.dictionary = action.payload;
    }
  }
});

export const { 
    setDictionary
 } = languageSlice.actions;

export const loadDictionaryAsync = () => async dispatch => {
    dispatch(loading());

    const client = await getAnonymousClient(dispatch);
    client.get(`v1/planning/dictionary`)
        .then(res => {
            const dictionary = res.data.data[0];
            dispatch(setDictionary(dictionary));
            dispatch(finishLoading());
        })
        .catch((error) => {
            dispatch(finishLoading());
        });
};

export const selectDictionary = state => state.language.dictionary;

export default languageSlice.reducer;

