const { SURVEY_TYPE } = require("./evaluation.const");

export const BehaviorDriverLabels = {
    [SURVEY_TYPE.OrgIq]: {
        firstGrade: {
            "Action": {
                title: "Survival-centric",
                content: "Focus on conservative and easy to achieve goals.",
                action: 'Need to raise the bar.',
                level: "over-optimal",
                color: "rgba(64, 148, 228, 0.1)"
            },
            "Process": {
                title: "Anarchy-centrics",
                content: "Operate in a chaotic and decentralized manner.",
                action: 'Need formal structure, rules, and control.',
                level: "over-optimal",
                color: "rgba(35, 206, 107, 0.1)"
            
            },
            "Strategy": {
                title: "Complacency-centrics",
                content: "Sole focus on status quo, neglect growth and improvement.",
                action: 'Need to get out of the comfort zone.',
                level: "over-optimal",
                color: "rgba(246, 174, 45, 0.1)"
            },
            "People": {
                title: "Self-centrics",
                content: "Neglectful towards their workforce.",
                action: 'Need to develop a culture of work-life balance.',
                level: "over-optimal",
                color: "rgba(255, 81, 84, 0.1)"
            },
        },
        secondGrade: {
            "Action": {
                title: "Performance-centric",
                content: `Balance between "what" and "how" to produce and deliver.`,
                action: 'Need a culture that fine-tunes goals.',
                level: "optimal",
                color: "rgba(64, 148, 228, 0.25)"
            
            },
            "Process": {
                title: "Smoothness-centrics",
                content: "Streamlined business operations.",
                action: 'Need to learn how to spot and remove small obstacles.',
                level: "optimal",
                color: "rgba(35, 206, 107, 0.25)"
            
            },
            "Strategy": {
                title: "Caution-centrics",
                content: "Ready to capitalize on internal and external changes.",
                action: 'Need to maintain a culture of ongoing risk assessment.',
                level: "optimal",
                color: "rgba(246, 174, 45, 0.25)"
            
            },
            "People": {
                title: "Cohesion-centrics",
                content: "Focus on unity, collaboration, and interconnectedness.",
                action: "Need to continually assess the employee dynamics.",
                level: "optimal",
                color: "rgba(255, 81, 84, 0.25)"
            
            },
        },
        thirdGrade: {
            "Action": {
                title: "Outcome-centric",
                content: "Overly focused on achieving results at any price..",
                action: 'Need a culture of working smarter.',
                level: "sub-optimal",
                color: "rgba(64, 148, 228, 0.4)"
            
            },
            "Process": {
                title: "Red tape-centrics",
                content: "Continually develop new rules and procedures.",
                action: 'Need a culture of deregulation and process-optimization.',
                level: "sub-optimal",
                color: "rgba(35, 206, 107, 0.4)"
            
            },
            "Strategy": {
                title: "Rebirth-centrics",
                content: "Persistently beginning anew without drawing lessons from the past.",
                action: 'Need self-control and consistency.',
                level: "sub-optimal",
                color: "rgba(246, 174, 45, 0.4)"
            
            },
            "People": {
                title: "People-centrics",
                content: "Exclusive focus on pleasing people, often at high operating costs.",
                action: 'Need to move from a fun to a good place to work.',
                level: "sub-optimal",
                color: "rgba(255, 81, 84, 0.4)"
            
            }
        }
    },
    [SURVEY_TYPE.BoardIq]: {
        firstGrade: {
            "Action": {
                title: "Apathetic",
                content: "No enthusiasm, motivation, or interest to actively pursue goals.",
                action: 'Need infusion, new members.',
                level: "over-optimal",
                color: "rgba(64, 148, 228, 0.1)"
            },
            "Process": {
                title: "Chaotic",
                content: "Disorder, confusion, and a lack of organization.",
                action: 'Develop and stick to policies for efficient governance.',
                level: "over-optimal",
                color: "rgba(35, 206, 107, 0.1)"
            
            },
            "Strategy": {
                title: "Visionless",
                content: "Lack of a clear and inspiring vision for the organization's future.",
                action: 'Need more strategy sessions.',
                level: "over-optimal",
                color: "rgba(246, 174, 45, 0.1)"
            },
            "People": {
                title: "Detached",
                content: "Directors are disengaged from the organization and its stakeholders..",
                action: 'Need to work on human skills.',
                level: "over-optimal",
                color: "rgba(255, 81, 84, 0.1)"
            },
        },
        secondGrade: {
            "Action": {
                title: "Goal-centrics",
                content: `Clear governance goals and assessment metrics.`,
                action: 'Should monitor for discrepancies with management goals.',
                level: "optimal",
                color: "rgba(64, 148, 228, 0.25)"
            
            },
            "Process": {
                title: "Effective Cadence",
                content: "Clear duties and expectations, well organized.",
                action: 'Need to make small adjustments and stay on that path.',
                level: "optimal",
                color: "rgba(35, 206, 107, 0.25)"
            
            },
            "Strategy": {
                title: "Directional",
                content: "Provide the right level of effective direction to the company.",
                action: 'Stay aligned on the appetite for risk.',
                level: "optimal",
                color: "rgba(246, 174, 45, 0.25)"
            
            },
            "People": {
                title: "Balanced",
                content: "Balanced emphasis on both directors’ and organization members’ needs.",
                action: "Need to keep doing that.",
                level: "optimal",
                color: "rgba(255, 81, 84, 0.25)"
            
            },
        },
        thirdGrade: {
            "Action": {
                title: "Meeting-intensive",
                content: "Overly focused on frequent meetings without clear objectives.",
                action: 'Need to reduce the quantity for the sake of quality.',
                level: "sub-optimal",
                color: "rgba(64, 148, 228, 0.4)"
            
            },
            "Process": {
                title: "Paralyzed",
                content: "Paralysis by over-analysis, overlooking creative opportunities.",
                action: 'Build an environment for the free flow of ideas',
                level: "sub-optimal",
                color: "rgba(35, 206, 107, 0.4)"
            
            },
            "Strategy": {
                title: "Indecisive",
                content: "Go back and forth, revisiting decisions repeatedly.",
                action: 'Build a culture where a decision is better than no decision.',
                level: "sub-optimal",
                color: "rgba(246, 174, 45, 0.4)"
            
            },
            "People": {
                title: "Pleaser",
                content: "Exclusive interest in satisfying the directors’ needs..",
                action: 'Involve more organization members in board meetings.',
                level: "sub-optimal",
                color: "rgba(255, 81, 84, 0.4)"
            
            }
        }
    },
    [SURVEY_TYPE.EmployeeIq]: {
        firstGrade: {
            "Action": {
                title: "Workaholics",
                content: "Exclusive focus on achieving results, while neglecting all other aspects.",
                action: 'Need training to decrease.',
                title2: "",
                content2: "",
                action2: "",
                level: "over-optimal",
                color: "rgba(64, 148, 228, 0.1)"
            },
            "Process": {
                title: "Bureaucrats",
                content: "Exclusive focus on obeying rules, while neglecting all other aspects.",
                action: 'Need training to decrease.',
                level: "over-optimal",
                color: "rgba(35, 206, 107, 0.1)"
            
            },
            "Strategy": {
                title: "Chameleons",
                content: "Exclusive focus on change, while neglecting all other aspects.",
                action: 'Need training to decrease.',
                level: "over-optimal",
                color: "rgba(246, 174, 45, 0.1)"
            },
            "People": {
                title: "Compromisers",
                content: "Exclusive focus on people's needs, while neglecting all other aspects.",
                action: 'Need training to decrease.',
                level: "over-optimal",
                color: "rgba(255, 81, 84, 0.1)"
            },
        },
        secondGrade: {
            "Action": {
                title: "Acomplishers",
                content: "Balanced focus on achieving results, without neglecting the other aspects.",
                action: 'Need training to maintain.',
                level: "optimal",
                color: "rgba(64, 148, 228, 0.25)"
            
            },
            "Process": {
                title: "Regulators",
                content: "Balanced focus on rules and regulations, without neglecting the other aspects.",
                action: 'Need training to maintain.',
                level: "optimal",
                color: "rgba(35, 206, 107, 0.25)"
            
            },
            "Strategy": {
                title: "Creators",
                content: "Balanced focus on coping with change, without neglecting the other aspects.",
                action: 'Need training to maintain.',
                level: "optimal",
                color: "rgba(246, 174, 45, 0.25)"
            
            },
            "People": {
                title: "Uniters",
                content: "Balanced focus on people's needs, without neglecting the other aspects.",
                action: "Need training to maintain.",
                level: "optimal",
                color: "rgba(255, 81, 84, 0.25)"
            
            },
        },
        thirdGrade: {
            "Action": {
                title: "Under-achievers",
                content: "Lack of focus on achieving results.",
                action: 'Need traning to increase.',
                level: "sub-optimal",
                color: "rgba(64, 148, 228, 0.4)"
            
            },
            "Process": {
                title: "Non-compliants",
                content: "Lack of focus on obeying rules.",
                action: 'Need training to increase.',
                level: "sub-optimal",
                color: "rgba(35, 206, 107, 0.4)"
            
            },
            "Strategy": {
                title: "Change-haters",
                content: "Lack of interest to accept new things.",
                action: 'Need training to increase.',
                level: "sub-optimal",
                color: "rgba(246, 174, 45, 0.4)"
            
            },
            "People": {
                title: "Anti-socials",
                content: "Lack of focus on people.",
                action: 'Need traning to increase.',
                level: "sub-optimal",
                color: "rgba(255, 81, 84, 0.4)"
            
            }
        }
    }
}