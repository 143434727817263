import { Box } from "@chakra-ui/react";
import Card from "components/card/Card";
import SidebarBrand from "components/sidebar/components/Brand";
import { useState } from "react";
import { connect } from "react-redux";
import OnboardingStepOne from "./OnboardingStepOne";
import OnboardingStepTwo from "./OnboardingStepTwo";
import PropTypes from 'prop-types';
import { updateOrganizationInformationAsync } from "features/user/slice";
import { useHistory } from "react-router-dom";
import { setSkipEvaluationOnboarding } from "features/user/slice";

function OrganizationOnboardingView({ isInPaymentProcess, onFinishOnboarding, onSaveOrganizationInformation, onSkipEvaluationOnboarding }){

    const Steps = { 
        Step1: 1,
        Step2: 2
    }
    const logo = require("../../,,/../../../assets/img/logo/logo.png");

    const [currentStep, setCurrentStep] = useState(Steps.Step1);
    const [isModalOpen, setModalOpen] = useState(false);
    const history = useHistory();

    const onClickSkip = () => {
        setModalOpen(false);
        onSkipEvaluationOnboarding();
        history.push('/admin/default');
    }

    return (<Box paddingRight="25%" paddingLeft="25%" paddingTop="5%" >
        {isInPaymentProcess === false ? <Card size="sm" pb="10%" mb="200px">
            <SidebarBrand align="left" separator={false} />
            <Box mt="10px">
                <Box>
                    {currentStep === Steps.Step1 ? <OnboardingStepOne 
                        onClickNext={() => setCurrentStep(Steps.Step2)}
                        onSaveOrganizationInformation={onSaveOrganizationInformation}
                    />: <OnboardingStepTwo 
                        isModalOpen={isModalOpen}
                        setModalOpen={setModalOpen}
                        onClickNext={() => setModalOpen(true)}
                        onFinishOnboarding={onFinishOnboarding}
                        onClickSkip={onClickSkip}
                    />}
                </Box>
            </Box>
        </Card> : <Box minH="90vh">
                <Box textAlign={"center"} top="auto" mb="auto">
                    <img src={logo} width="180px" alt="" style={ { marginBottom: 10, marginTop: "80px", marginLeft: "auto", marginRight: "auto" } }/>
                </Box>
            </Box>}
    </Box>)
}


OrganizationOnboardingView.propTypes = {
    onSaveOrganizationInformation: PropTypes.func,
    onSkipEvaluationOnboarding: PropTypes.func,
    hasSkipEvaluationOnboarding: PropTypes.bool,
    isInPaymentProcess: PropTypes.bool
};
  
OrganizationOnboardingView.defaultProps = {
    onSaveOrganizationInformation: () => { },
    onSkipEvaluationOnboarding: () => { },
    hasSkipEvaluationOnboarding: false,
    isInPaymentProcess: true
}
  
function mapStateToProps(state) {
    const { hasSkipEvaluationOnboarding, isInPaymentProcess } = state.user;
    return {
        hasSkipEvaluationOnboarding,
        isInPaymentProcess
    };
};

function mapDispatchToProps (dispatch) {
  return {
      dispatch,
      onSaveOrganizationInformation: (organizationInformation, onSuccess, onError ) => dispatch(updateOrganizationInformationAsync({ organizationInformation, onSuccess, onError })),
      onSkipEvaluationOnboarding: () => dispatch(setSkipEvaluationOnboarding(true))
  }
}


export default connect(mapStateToProps, mapDispatchToProps)(OrganizationOnboardingView);