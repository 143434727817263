import { COLORS } from "constants/colors";
import { SelectDominantIndustry, SelectMaturity, SelectNumberOfAuthority, SelectSize, SelectSizeInHeadcounts, SelectSpread, SelectTimeInBusiness, SelectType } from "../components/OnboardingStepOneSelectOptions";
import { useState } from "react";

const { Grid, GridItem, Button, Box, Heading, Text } = require("@chakra-ui/react");

function OnboardingStepOne({ onClickNext, onSaveOrganizationInformation }) {

    const [selectedSpread, setSelectedSpread] = useState(null);
    const [selectedTimeInBusiness, setSelectedTimeInBusiness] = useState(null);
    const [selcetedDominantIndustry, setSelectedDominantIndustry] = useState(null);
    const [selecetedSize, setSelectedSize] = useState(null);
    const [selectedSizeInHeadcounts, setSelectedSizeInheadcounts] = useState(null);
    const [selectedType, setSelectedType] = useState(null);
    const [selectedAuthorityLevel, setSelectedAuthorityLevel] = useState(null);
    const [selectedMaturity, setSelectedMaturity] = useState(null);

    const [showErrors, setShowErrors] = useState(false);

    const handleSaveInformation = () => {

        if(selectedSpread === null || selectedTimeInBusiness === null || selcetedDominantIndustry === null
            || selecetedSize === null || selectedSizeInHeadcounts === null || selectedSizeInHeadcounts === null 
                || selectedType === null || selectedAuthorityLevel === null || selectedMaturity === null)
        {
            setShowErrors(true);
        }
        else {
            onSaveOrganizationInformation({
                spread: selectedSpread,
                timeInBusiness: selectedTimeInBusiness, 
                domainantIndustry: selcetedDominantIndustry,
                size: selecetedSize,
                sizeInHeadcounts: selectedSizeInHeadcounts,
                type: selectedType,
                authorizationLevels: selectedAuthorityLevel,
                maturity: selectedMaturity
            }, () => {
                onClickNext()
            }, () => {
                
            })
        }
    }

    return ( 
        <Box>
            <Heading size="md">
                Step 1. More information about your organization
            </Heading>
            <Text mt="10px">
                This is your first login to the WorkIQ and we need to make several steps together to make the things right. To begin, please choose options from the drop-down menus that best describe your organization.  
            </Text>
            <Grid templateColumns="1fr 1fr" gap={4} mt="20px">
                <GridItem>
                    <SelectSpread selectedOption={selectedSpread} setSelectedOption={setSelectedSpread} error={selectedSpread === null && showErrors === true} />
                </GridItem>
                <GridItem>
                    <SelectTimeInBusiness selectedOption={selectedTimeInBusiness} setSelectedOption={setSelectedTimeInBusiness} error={selectedTimeInBusiness === null && showErrors === true} />
                </GridItem>

                <GridItem>
                    <SelectDominantIndustry selectedOption={selcetedDominantIndustry} setSelectedOption={setSelectedDominantIndustry} error={selcetedDominantIndustry === null && showErrors === true} />
                </GridItem>
                <GridItem>
                    <SelectSize selectedOption={selecetedSize} setSelectedOption={setSelectedSize} error={selecetedSize === null && showErrors === true} />
                </GridItem>

                <GridItem>
                    <SelectSizeInHeadcounts selectedOption={selectedSizeInHeadcounts} setSelectedOption={setSelectedSizeInheadcounts} error={selectedSizeInHeadcounts === null && showErrors === true} />
                </GridItem>
                <GridItem>
                    <SelectType selectedOption={selectedType} setSelectedOption={setSelectedType} error={selectedType === null && showErrors === true} />
                </GridItem>

                <GridItem>
                    <SelectNumberOfAuthority selectedOption={selectedAuthorityLevel} setSelectedOption={setSelectedAuthorityLevel} error={selectedAuthorityLevel === null && showErrors === true} />
                </GridItem>
                <GridItem>
                    <SelectMaturity selectedOption={selectedMaturity} setSelectedOption={setSelectedMaturity} error={selectedMaturity === null && showErrors === true} />
                </GridItem>
            </Grid>
            <Grid templateColumns={"1fr 1fr"} mt="40px">
                <GridItem colSpan={2} textAlign={"right"}>
                    <Button
                        w='170px'
                        minW='170px'
                        height={"50px"}
                        style={ { backgroundColor: COLORS.Primary, color: COLORS.PrimaryText } }
                        onClick={handleSaveInformation}
                        fontWeight='500'>
                        Next
                    </Button>
                </GridItem>
            </Grid>
        </Box>
    )
}

export default OnboardingStepOne;