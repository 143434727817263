
import React, { useCallback } from "react";
import { loadUserProfileAsync } from "features/user/slice";
import { useEffect } from "react";
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { createPaymentSession } from "features/payments/slice";
import { useToast } from "@chakra-ui/react";
import { createEnterprisePaymentSession } from "features/payments/slice";
import { createPaymentOnboarding } from "features/payments/slice";
import { setPaymentProcessState } from "features/user/slice";
import { isUserAuthenticated } from "helpers/storageHelper";

function Authenticated(props) {
    const { 
        onChangePaymentProcessState, 
        onLoadUserProfile, 
        onCreateEnterprisePaymentSession, 
        onCreatePaymentOnboarding, 
        automaticRedictToPay, 
        onCreatePaymentSession, 
        style
    } = props;

    const toast = useToast();

    const checkIfIsAuthenticated = useCallback(() => {
        if(isUserAuthenticated() === false){
            window.location.replace(`${process.env.REACT_APP_WEBSITE_URL}/#/auth/sign-in/default`);
        }
    }, []);

    useEffect(() => {
        checkIfIsAuthenticated();
        window.scrollTo(0, 0)
    }, [checkIfIsAuthenticated]);

    useEffect(() => {
        onLoadUserProfile((user) => {
            if(user.mustChooseSubscription === true && automaticRedictToPay === true) {
                onCreatePaymentSession(user.subscriptionType, user.subscriptionFrequency, (url) => {
                    window.location.href = url;
                    }, () => {
                    toast({
                        title: 'Payment error',
                        description: "Unable to create the payment session.",
                        status: 'error',
                        duration: 9000,
                        isClosable: true
                    })
                });
            } else {
                if(user.mustPerformOnboarding === true) {
                    onCreatePaymentOnboarding((response) => {
                        window.location.replace(response.url);
                        }, () => {
                        toast({
                            title: 'Payment error',
                            description: "Unable to create the payment session.",
                            status: 'error',
                            duration: 9000,
                            isClosable: true
                        })
                        });
                } else if(user.mustPayEnterprise === true) {
                    //For customers of Enterprise accounts
                    onCreateEnterprisePaymentSession((response) => {
                        window.location.href = response.url;
                        }, () => {
                        toast({
                            title: 'Payment error',
                            description: "Unable to create the payment session.",
                            status: 'error',
                            duration: 9000,
                            isClosable: true
                        });
                    });
                } else {
                    onChangePaymentProcessState(false);
                }
            }
        });
    }, []);

    return <div id="authenticated" style={style}>
        {props.children}
    </div>
}

Authenticated.propTypes = {
    onLoadUserProfile: PropTypes.func,
    subscriptionFrequency: PropTypes.number,
    mustPay: PropTypes.bool,
    isInProcessOfPayment: PropTypes.bool,
    automaticRedictToPay: PropTypes.bool,
    onLoadUserSurveys: PropTypes.func,
    onCreatePaymentSession: PropTypes.func,
    onCreatePaymentOnboarding: PropTypes.func,
    onChangePaymentProcessState: PropTypes.func
  };
  
Authenticated.defaultProps = {
    subscriptionType: 0,
    subscriptionFrequency: 0,
    mustPay: false,
    automaticRedictToPay: true,
    isInProcessOfPayment: true,
    onLoadUserProfile: () => { }, 
    onCreatePaymentSession: () => { },
    onCreatePaymentOnboarding: () => { },
    onChangePaymentProcessState: () => { }
}
  
function mapStateToProps(state) {

    const { 
        subscriptionType,
        subscriptionFrequency,
        mustPay,
        lastUserProfileCall
    } = state.user;
  
    return {
        subscriptionType,
        subscriptionFrequency,
        mustPay,
        lastUserProfileCall
    };
};
  
function mapDispatchToProps (dispatch) {
    return {
        onLoadUserProfile: (onSuccess) => dispatch(loadUserProfileAsync(null, onSuccess)),
        onCreatePaymentSession: (subscriptionType, subscriptionFrequency, onSuccess, onError) => dispatch(createPaymentSession({ subscriptionType, subscriptionFrequency, onSuccess, onError })),
        onCreateEnterprisePaymentSession: (onSuccess, onError) => dispatch(createEnterprisePaymentSession({ onSuccess, onError })),
        onCreatePaymentOnboarding: (onSuccess, onError) => dispatch(createPaymentOnboarding( { onSuccess, onError })),
        onChangePaymentProcessState: (inProcess) => dispatch(setPaymentProcessState(inProcess))
    }
}
  
  export default connect(mapStateToProps, mapDispatchToProps)(Authenticated);