// Chakra imports
import { Box, Text, Heading, Button } from "@chakra-ui/react";
import Card from "components/card/Card.js";
import React from "react";
import { COLORS } from "constants/colors";

import employeeIqImg from '../../../assets/img/surveys/EmployeeIQ.svg';
import careerIQImg from '../../../assets/img/surveys/CareerIQ.svg';
import orgIqImg from '../../../assets/img/surveys/OrgIQ.svg';
import boardIqImg from '../../../assets/img/surveys/BoardIQ.svg';
import { SURVEY_TYPE } from "constants/evaluation.const";


import './PendingEvaluationBanner.css';
import { SURVEY_TYPE_DESCRIPTION } from "constants/surveyTypes.const";
import { SURVEY_TYPE_SUMMARY } from "constants/evaluation.const";

export default function PendingEvaluationBanner(props) {
  const { 
    type,
    onClickToManagePendingEvaluation,
    withButton, 
    buttonText, 
    onButtonClick
  } = props;

  const survey_types_imgs = {
    [SURVEY_TYPE.EmployeeIq]: employeeIqImg,
    [SURVEY_TYPE.BoardIq]: boardIqImg,
    [SURVEY_TYPE.OrgIq]: orgIqImg,
    [SURVEY_TYPE.CareerIQ]: careerIQImg,
   };
  return (
    <Box maxW="md">
      <Card mb={{ base: "0px", lg: "20px" }}>
            <Box display={"flex"}>
              <Box h={"100%"} className="evaluation-text-image" onClick={onClickToManagePendingEvaluation}>
                <img style={ { marginTop: '10%' } } alt="" src={survey_types_imgs[type]} width={"60px"}/>
              </Box>
              <Box>
                <Box ml="20px">
                  <Heading size="sm">{SURVEY_TYPE_DESCRIPTION[type]}</Heading>
                  <Box style={ { overflowWrap: 'break-word', width: '200px' } }>
                    <Text className="evaluation-text-description" 
                      style={{ color: COLORS.Primary }} 
                      size="md"
                      onClick={onClickToManagePendingEvaluation}
                    >
                      {SURVEY_TYPE_SUMMARY[type]}
                    </Text>
                  </Box>
                </Box>
              </Box>
            </Box>
              {withButton === true ? <Box ml="70px" mt="20px">
                <Button 
                      w='200px'
                      minW='140px'
                      mt={{ base: "20px", "2xl": "auto" }}
                      h="50px"
                      style={ { backgroundColor: COLORS.Primary, color: COLORS.PrimaryText } }
                      onClick={onButtonClick}
                      fontWeight='500'>
                      {buttonText}
                  </Button>
                </Box> : null}
            {/* <Box>
              {status === STATUS.IN_PROGRESS ? `You have an ongoing ${type} Evaluation` : `You have a pending ${type} Evaluation.`}
            </Box>
            <Box style={ { color: 'green' } }>
              {status === STATUS.IN_PROGRESS ? `Last update ${moment(updateDate).format('MMM DD YYYY HH:mm')}` : null}
            </Box> */}
          
          </Card>
    </Box>
   
  );
}
