import { Box, Grid, GridItem, IconButton, Text } from "@chakra-ui/react";
import { CloseIcon } from "@chakra-ui/icons";
import { BehaviorDriverLabels } from "constants/behaviorDriverDetails.const";

function BehaviorDriversDetails({ type, surveyType }) {

    if (!BehaviorDriverLabels[surveyType] || !BehaviorDriverLabels[surveyType].firstGrade[type]){
        return <Box>
        </Box>;
    }

    return (<Box className="behavior-graph-item">
        <Box textAlign={"center"} mb="10px">
            <IconButton
                isRound={true}
                variant='solid'
                backgroundColor={BehaviorDriverLabels[surveyType].firstGrade[type].color}
                aria-label='Done'
                fontSize='10px'
                color={BehaviorDriverLabels[surveyType].thirdGrade[type].color}
                height={"20px"}
                icon={<CloseIcon />}
            /> 
        </Box>
        <Box textAlign={"center"} mb="5px">
            <Text fontSize={"12px"}>Specifics of the Behavior Driver [{type}]</Text>
        </Box>
            <Box borderTopColor={BehaviorDriverLabels[surveyType].firstGrade[type].color} borderRadius={"5px"} borderTopWidth={"1px"} backgroundColor={BehaviorDriverLabels[surveyType].firstGrade[type].color}>
                <Grid templateColumns={"0.5fr 1fr"} columnGap={0} padding="5px" paddingRight={"10px"}>
                    <GridItem>
                        <Box textAlign={"center"} pt="3px">
                            <Text>6.3 - 10.0</Text>
                            <Text fontSize={"11px"} fontWeight={"bold"}>{BehaviorDriverLabels[surveyType].firstGrade[type].level}</Text>
                        </Box>
                    </GridItem>
                    <GridItem>
                        <Box>
                            <Text fontWeight={"bold"}>{BehaviorDriverLabels[surveyType].firstGrade[type].title}</Text>
                        </Box>
                        <Box pb="20px">
                            <Text fontSize={"12px"}>{BehaviorDriverLabels[surveyType].firstGrade[type].content}</Text>
                            <Text fontSize={"12px"}>{BehaviorDriverLabels[surveyType].firstGrade[type].action}</Text>
                        </Box>
                    </GridItem>
                </Grid>
            </Box>
            <Box borderTopColor={BehaviorDriverLabels[surveyType].secondGrade[type].color} borderRadius={"5px"} paddingRight={"10px"} borderTopWidth={"1px"} backgroundColor={BehaviorDriverLabels[surveyType].secondGrade[type].color}>
                <Grid templateColumns={"0.5fr 1fr"} padding="5px">
                    <GridItem>
                        <Box textAlign={"center"} pt="3px">
                            <Text>4.5 - 6.2</Text>
                            <Text fontSize={"11px"} fontWeight={"bold"}>{BehaviorDriverLabels[surveyType].secondGrade[type].level}</Text>
                        </Box>
                    </GridItem>
                    <GridItem>
                        <Box>
                            <Box>
                                <Text fontWeight={"bold"}>{BehaviorDriverLabels[surveyType].secondGrade[type].title}</Text>
                            </Box>
                            <Box>
                                <Text fontSize={"12px"}>{BehaviorDriverLabels[surveyType].secondGrade[type].content}</Text>
                                <Text fontSize={"12px"}>{BehaviorDriverLabels[surveyType].secondGrade[type].action}</Text>
                            </Box>
                        </Box> 
                    </GridItem>
                </Grid>
            </Box>
            <Box borderTopColor={BehaviorDriverLabels[surveyType].thirdGrade[type].color} borderRadius={"5px"} paddingRight={"10px"} borderTopWidth={"1px"} backgroundColor={BehaviorDriverLabels[surveyType].thirdGrade[type].color}>
                <Grid templateColumns={"0.5fr 1fr"} padding="5px">
                    <GridItem>
                        <Box textAlign={"center"} pt="3px">
                            <Text>0.0 - 4.4</Text>
                            <Text fontSize={"11px"} fontWeight={"bold"}>{BehaviorDriverLabels[surveyType].thirdGrade[type].level}</Text>
                        </Box>
                    </GridItem>
                    <GridItem>
                        <Box>
                            <Text fontWeight={"bold"}>{BehaviorDriverLabels[surveyType].thirdGrade[type].title}</Text>
                        </Box>
                        <Box minH="80px">
                            <Text fontSize={"12px"}>{BehaviorDriverLabels[surveyType].thirdGrade[type].content}</Text>
                            <Text fontSize={"12px"}>{BehaviorDriverLabels[surveyType].thirdGrade[type].action}</Text>
                        </Box>
                    </GridItem>
                </Grid>
            </Box>
        </Box>
    )
}

export default BehaviorDriversDetails;