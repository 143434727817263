import { Card, Grid, GridItem, Text } from "@chakra-ui/react"

export const GoalItem = ({ text, value, onClick, mb }) => {
    return (<Card backgroundColor={"#BABCBE"} height="55px" className="goals-item" padding={0} mb={mb} onClick={() => onClick()}>
            <Grid templateColumns={"1fr 1fr"}>
                <GridItem>
                    <Text ml="40px" mt="15px" fontSize="12px">{text}</Text>
                </GridItem>
            </Grid>
    </Card>)
}