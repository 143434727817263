// Chakra imports
import {
    Box, Card, Divider, Grid, GridItem, Heading, SimpleGrid, Slider, SliderFilledTrack, SliderMark, SliderThumb, SliderTrack, Text, Tooltip
  } from "@chakra-ui/react";
import { useEffect, useRef, useState } from "react";
import Authenticated from "views/admin/authenticated";
  
import './index.css';
import LeaderLine from "react-leader-line";
import DefinitionsReport from "./components/DefinitionsReport";

function SecondPage({ location }) {
    
    const [name, setName] = useState('');
    const [title, setTitle] = useState('');

    const refManagement = useRef();
    const refPeers = useRef();
    const refIndividualReports = useRef();
    const refGroupReports = useRef();

    useEffect(() => {
        const query = new URLSearchParams(location.search);

        setName(query.get('name'));
        setTitle(query.get('title'));
        
        new LeaderLine(refManagement.current, refIndividualReports.current, {
            color: "#000",
            startPlugColor: "#000",
            endPlugColor: "#000",
            startPlug: 'behind',
            size: 1
        });
        new LeaderLine(refManagement.current, refGroupReports.current, {
            color: "#000",
            startPlugColor: "#000",
            endPlugColor: "#000",
            startPlug: 'behind',
            size: 1
        });
        new LeaderLine(refPeers.current, refGroupReports.current, {
            color: "#000",
            startPlugColor: "#000",
            endPlugColor: "#000",
            startPlug: 'behind',
            size: 1
        });
    }, []);
    return (
      <Authenticated>
        <Box mt="5px" pl="40px" pr="40px">
            <Box width={"100%"} textAlign={"center"} mb="5px">
                <Box display={"flex"} justifyContent={"center"}>
                    <Text fontWeight={"bold"}>{title}</Text>, Individual Report for {name}
                </Box>
            </Box>
            <Divider width={"100%"} borderWidth="1px"/>
        </Box>
        <Box textAlign={"center"} mt="5px">
            <Heading size="md">ASSESSMENT</Heading>
        </Box>
        <Box pr="10%" pl="10%" mt="40px">
            <Grid className='content' ml="auto" mr="auto" mt="50px" width="100%" columnGap={"20px"} templateColumns={"1fr 1fr"} gap={3} height={"100%"}>
                <GridItem>
                    <Text fontSize="12px" mt="10px">The Survey. While taking the Employee IQ, you were presented
                    with 96 statements or indicators related to vital aspects of your
                    work life, with a sample statement displayed in the sidebar.
                    On a scale from zero to 10, you expressed your level of agreement
                    with those statements, where zero stands for “I completely
                    disagree with the statement” and 10 stands for “I completely
                    agree with the statement.” That is, in your opinion, how you generally
                    tend to behave in your organization.</Text>
                </GridItem>
                <GridItem height={"200px"}>
                    <Card
                        minH={"200px"}
                        className={"assessment-question"} 
                        style={ { margin: 10, border: '#E4E4E4 1px solid' }}>
                        <Box ml="5px" mr="5px" mb="10px" p="20px">
                            <Box>
                                <Box sx={ { marginTop: 5 } } position={"relative"}>
                                    <Box style={ { textAlign: 'center' } }>
                                        <Heading size="md">I am passionate only about the idea of starting something new.</Heading>
                                    </Box>
                                    <Slider
                                        id='slider'
                                        min={0}
                                        max={10}
                                        defaultValue={5}
                                        mt={5}
                                        mb={10}
                                        onChange={(v) => {}}
                                    >
                                        {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((i) => {
                                        return  <SliderMark value={i} mt='3' ml='-2.5' fontSize='12px'>
                                            {i}
                                        </SliderMark>
                                        })}

                                        <SliderTrack value={5}>
                                        <SliderFilledTrack/>
                                        </SliderTrack>
                                        <Tooltip
                                            bg={'#0095FF'}
                                                colorScheme="blue"
                                                style={{ backgroudColor: "#0095FF", zIndex: 999, position: 'absolute'}}
                                                placement='bottom'
                                                isOpen={true}
                                                label={5}
                                            >
                                        <SliderThumb>
                                            <Box/>
                                        </SliderThumb>
                                        </Tooltip>
                                    </Slider>
                                    <SimpleGrid columns={2} >
                                        <Box style={ { left: '0' } } position={"absolute"}>
                                        Disagree
                                        </Box>
                                        <Box style={ { right: '0' } } position={"absolute"}>
                                        Agree
                                        </Box>
                                    </SimpleGrid>
                                </Box>
                            </Box>
                        </Box>
                    </Card>
                </GridItem>
            </Grid>
        </Box>
        <Box pr="10%" pl="10%">
            <Grid className='content' ml="auto" mr="auto" mt="50px" columnGap={"20px"} templateColumns={"1fr 1fr"} gap={3} height={"100%"}>
                <GridItem>
                    <Text fontSize="12px">
                        Confidentiality. Before the survey, you were informed about the peers
                        purpose of Employee IQ, the result utilization, and the accessibility
                        to organization members. Our focus is to ensure a transparent
                        understanding of result use. The sidebar graph illustrates
                        who and how has access to your responses.
                    </Text>
                </GridItem>
                <GridItem>
                    <Box height="100%" textAlign={"center"}> 
                       <Grid templateColumns={"1fr 1fr"} rowGap={"40px"}>
                            <GridItem>
                                <Box ref={refManagement} border="1px #000 solid" ml="auto" mr="auto" width={"150px"} height={"30px"} borderRadius={"5px"} backgroundColor={"#D3D3D3"}>
                                   <Text mt="2px">Management</Text>
                                </Box>
                            </GridItem>
                            <GridItem>
                                <Box ref={refPeers} border="1px #000 solid" ml="auto" mr="auto" width={"150px"} height={"30px"} borderRadius={"5px"} backgroundColor={"#D3D3D3"}>
                                    <Text mt="2px">Peers</Text>
                                </Box>
                            </GridItem>
                            <GridItem>
                                <Box ref={refIndividualReports} border="1px #000 solid" ml="auto" mr="auto" width={"150px"} height={"30px"} borderRadius={"5px"} backgroundColor={"#D3D3D3"}>
                                    <Text mt="2px">Individual Reports</Text>
                                </Box>
                            </GridItem>
                            <GridItem>
                                <Box ref={refGroupReports} border="1px #000 solid" ml="auto" mr="auto" width={"150px"} height={"30px"} borderRadius={"5px"} backgroundColor={"#D3D3D3"}>
                                    <Text mt="2px">Group Report</Text>
                                </Box>
                            </GridItem>
                       </Grid>
                    </Box>
                </GridItem>
            </Grid>
        </Box>
        <Box pr="10%" pl="10%" mt="50px">
           <Box>
                <Text fontSize="12px">The Theory Behind. All assessments in the WorkIQ suite are constructed similarly: each comprises 96 indicators
                (referred to as ‘measures’). Each indicator is partially influenced by 8 traits (‘unique factors’) and 4 behavior drivers
                (‘common factors’). This design</Text>
           </Box>
           <Box textAlign={"center"} mt="50px" width={"850px"} ml="auto" mr="auto">
                <DefinitionsReport />
           </Box>
        </Box>
        <Box pos={"absolute"} left="0" right="0" mt="60px">
            <Divider width={"100%"} borderWidth="1px"/>
            <Box textAlign={"right"} mt="10px">
                <Text fontWeight={"bold"} mr="50px">4</Text>
            </Box>
        </Box>
      </Authenticated>
    );
  }
  
  export default SecondPage;