/*!
  _   _  ___  ____  ___ ________  _   _   _   _ ___   
 | | | |/ _ \|  _ \|_ _|__  / _ \| \ | | | | | |_ _| 
 | |_| | | | | |_) || |  / / | | |  \| | | | | || | 
 |  _  | |_| |  _ < | | / /| |_| | |\  | | |_| || |
 |_| |_|\___/|_| \_\___/____\___/|_| \_|  \___/|___|
                                                                                                                                                                                                                                                                                                                                       
=========================================================
* Horizon UI - v1.1.0
=========================================================

* Product Page: https://www.horizon-ui.com/
* Copyright 2022 Horizon UI (https://www.horizon-ui.com/)

* Designed and Coded by Simmmple

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// Chakra imports
import { Box, SimpleGrid } from "@chakra-ui/react";
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import PrincingTable from "views/admin/pricing/components/PrincingTable";
import {
  columnsDataDevelopment
} from "views/admin/pricing/variables/columnsData";
import { loadSubscriptions } from "features/subscriptions/slice";
import React, { useEffect } from "react";

function Settings(props) {
  const { onLoadSubscriptions, subscriptions } = props;

  useEffect(() => {
    onLoadSubscriptions();
  }, [onLoadSubscriptions])

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <SimpleGrid
        mb='20px'
        columns={{ sm: 1, md: 1 }}
        spacing={{ base: "20px", xl: "20px" }}>
        <PrincingTable
          columnsData={columnsDataDevelopment}
          tableData={subscriptions}
        />
      </SimpleGrid>
    </Box>
  );
}


Settings.propTypes = {
  subscriptions: PropTypes.array,
  onLoadSubscriptions: PropTypes.func
};

Settings.defaultProps = {
  subscriptions: [],
  onLoadSubscriptions: () => {}
}

function mapStateToProps(state) {

  const { subscriptions } = state.subscriptions;
  
  return {
    subscriptions 
   };
};

function mapDispatchToProps (dispatch) {
  return {
      onLoadSubscriptions: (onSuccess, onError) => dispatch(loadSubscriptions(() => { }, () => { })),
      dispatch
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Settings);