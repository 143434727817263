// Chakra imports
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalFooter,
    ModalBody,
    Button,
    Box,
    Text,
    Heading
  } from '@chakra-ui/react'
import Card from 'components/card/Card';
import { COLORS } from 'constants/colors';
import { BEHAVIORS_DESC } from 'constants/definitions';
import React from "react";
  
export default function BehaviorsModal(props) {
    const { 
        isOpen, 
        onClose, 
        behaviorDrivers
    } = props;

    return (
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalBody>
            {behaviorDrivers.map((a, i) => {
                return <Box mt="20px">
                    <Card pr="80px" boxShadow="0px 18px 40px rgba(112, 144, 176, 0.12)" h="100px">
                        <Box display={"flex"}>
                            <Box>
                                <Box width="60px">
                                    <div style={{ width: "49px", height: "49px", textAlign: 'center', backgroundColor: "#0095FF1A", color: "#0095ff", borderRadius: "50%" }} >
                                        <Heading size="md" style={{ paddingTop: "10px" }}>
                                            {a.indicator}
                                        </Heading>
                                    </div>
                                </Box>
                            </Box>
                            <Box>
                                <Text fontWeight={"bold"}>{a.name}</Text>
                                <Text color="#0000004D">{a.name ? BEHAVIORS_DESC[a.name.toLowerCase()] : null}</Text>
                            </Box>
                        </Box>
                    </Card>
                </Box>
            })}
          </ModalBody>
          <ModalFooter>
            <Button 
                style={ { backgroundColor: COLORS.Primary, color: COLORS.PrimaryText } } 
                ml="auto"
                mr="auto"
                height={"60px"}
                width={"150px"}
                onClick={onClose}>Close</Button>
          </ModalFooter>
        </ModalContent>
    </Modal>
    )
}