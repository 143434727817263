/* eslint-disable */
import  { 
  Box,
  Button,
  Text,
  Heading, 
  useToast, 
  Grid, 
  GridItem, 
  useDisclosure
} from "@chakra-ui/react";
import Card from "components/card/Card";
import _ from "lodash";
import './UpgradePlan.css';

import PropTypes from 'prop-types';
import { connect } from "react-redux";
// Custom components
import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import Authenticated from "../authenticated";
import { loadSubscriptions } from "features/subscriptions/slice";
import { COLORS } from "constants/colors";

import IconCheck from "../../../assets/img/icons/check.svg";
import IconClose from "../../../assets/img/icons/close.svg";
import ConfirmationDialog from "components/confirmDialog/ConfirmationDialog";
import { createPaymentSession } from "features/payments/slice";

function UpgradePlan({ 
    onClose, 
    onCreatePaymentSession, 
    subscriptions,
    subscriptionType,
    onLoadSubscriptions
  }) {
  
    useEffect(() => {
      onLoadSubscriptions(() => { }, () => { });
    }, []);

    const history = useHistory();
    const toast = useToast();
    const dialog = useDisclosure();

    const [selectedSubscriptionType, setSelectedSubscriptionType] = React.useState(0);
    const [selectedSubscriptionDuration, setSubscriptionDuration] = React.useState('1');

    const onSubmit = (e) => {
      e.preventDefault();
      if (selectedSubscriptionType !== subscriptionType) {
        onCreatePaymentSession(selectedSubscriptionType, selectedSubscriptionDuration, (url) => {
          window.location.href = url;
          toast({
            description: "Subscription sucessfully upgraded.",
            status: 'success',
            duration: 9000,
            isClosable: true
          })
        }, () => {
          toast({
            title: 'Payment error',
            description: "Unable to create the payment session.",
            status: 'error',
            duration: 9000,
            isClosable: true
          })
        });
      }
    }

    const handleSelectSubscription = (subscription) => {
      setSelectedSubscriptionType(subscription.type);
      setSelectedSubscription(subscription);
    }


    return (
        <Authenticated>
          <Box ml="20px" mr="30px" pb="200px" mt={8}> 
              <Box display={"flex"} ml="40px">
                <Heading size="md" pt="10px">Subscription</Heading>
                <Text fontSize="16px" ml="5px" pt="13px" color={COLORS.Primary}>Upgrade</Text>
              </Box>
              <Box pb={6} mt="20px">
                <Box pt={4} pl={2}>
                  <Grid 
                    templateColumns={"1fr 1fr 1fr"} 
                    gap='20px'
                    pl='30px'
                    pr="30px"
                  >
                    {subscriptions ? subscriptions.map((subscription, i) => {
                      if(subscription.type !== 0) {
                        return (<GridItem>
                        <Card 
                          minH="640px"
                          onClick={() => handleSelectSubscription(subscription)}
                        >
                        <Box mt="10px" ml="20px">
                          <Box>
                            <Text as='h5' fontSize='22px' fontWeight={"bold"}>{subscription.name}</Text>
                          </Box>
                          {subscription.type !== 0 ? <Box display="flex">
                              <Text fontSize="18px" fontWeight={"bold"}>$</Text>
                              <Box mt="4px" ml="5px"> 
                                {selectedSubscriptionDuration === '1' ? <Box display={"flex"} ml="3px">
                                  <Text as='h5' fontSize='32px' fontWeight="bold">{subscription.price}</Text>
                                  <Text mt="17px">/monthly</Text>
                                </Box> : <Box display={"flex"} ml="3px">
                                  <Text as='h5' fontSize='32px' fontWeight="bold">{subscription.priceYear}</Text>
                                  <Text mt="17px">/yearly</Text>
                                </Box>}
                              </Box>
                          </Box> : null}
                          <Box>
                            <Button
                                w='170px'
                                minW='170px'
                                height={"50px"}
                                right={0}
                                mr={"20px"}
                                mt={"10px"}
                                style={ { 
                                  backgroundColor: subscriptionType !== subscriptions[i].type ? COLORS.Primary : "#E2E8F0", 
                                  color: subscriptionType !== subscriptions[i].type ? COLORS.PrimaryText : COLORS.Primary
                                } }
                                onClick={() => {
                                  if(subscriptionType !== subscriptions[i].type){
                                    setSelectedSubscription(subscriptions[i]);
                                    dialog.onOpen();
                                  }
                                 }}
                                fontWeight='500'>
                                {subscriptions[i].type === subscriptionType ? "Current" : "Upgrade"}
                            </Button>
                          </Box>
                          <Box mt={"25px"}>
                            <Box ml={1}>
                              {subscriptions[i].advantages ? subscriptions[i].advantages.map((a, ai) => {
                                return <Box display={"flex"} mt="20px">
                                    <Box width={"80px"}>
                                      <img src={IconCheck} style={ { marginTop: '8px', height: "20px" } } />
                                    </Box>
                                    <Box width={"260px"}>
                                      {subscriptions[i].advantages[ai]}
                                    </Box>
                                  </Box>
                              }) : null}
                            </Box>
                            <Box ml={1} mt="20px">
                              {subscriptions[i].disadvantages ? subscriptions[i].disadvantages.map((a, ai) => {
                                return <Box display={"flex"} mt="20px">
                                    <Box width={"80px"}>
                                      <img src={IconClose} style={ { marginTop: '8px', height: "20px" } } />
                                    </Box>
                                    <Box width={"260px"}>
                                      {subscriptions[i].disadvantages[ai]}
                                    </Box>
                                  </Box>
                              }) : null}
                            </Box>
                          </Box>
                        </Box>
                      </Card>
                    </GridItem>)
                  }}) : null}
                  </Grid>
                </Box>
                <Box>
                  <ConfirmationDialog
                    isOpen={dialog.isOpen}
                    onClose={dialog.onClose}
                    onConfirm={onSubmit}
                    title="Confirmation"
                    description={"Are you sure you want to upgrade?"}
                    confirmText="Yes"
                    cancelText="No (Cancel)"
                  />
                </Box>
              </Box>
          </Box>
        </Authenticated>
    )
  }


UpgradePlan.propTypes = {
  subscriptions: PropTypes.array,
  subscriptionType: PropTypes.number,
  onLoadSubscriptions: PropTypes.func
};

UpgradePlan.defaultProps = {
  subscriptions: [],
  subscriptionType: 0,
  onLoadSubscriptions: () => {}
}

function mapStateToProps(state) {
  const { subscriptions } = state.subscriptions;

  const { 
    subscriptionType
  } = state.user;

  return {
    subscriptions,
    subscriptionType
   };
};

function mapDispatchToProps (dispatch) {
  return {
      onLoadSubscriptions: (onSuccess, onError) => dispatch(loadSubscriptions({ onSuccess, onError })),
      onCreatePaymentSession: (subscriptionType, subscriptionFrequency, onSuccess, onError) => dispatch(createPaymentSession({ subscriptionType, subscriptionFrequency, onSuccess, onError })),
      dispatch
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(UpgradePlan);