export const columnsDataDevelopment = [
  {
    Header: "Subscription",
    accessor: "name",
  },
  {
    Header: "Price",
    accessor: "price",
  },
  {
    Header: "Team Licenses",
    accessor: "numberOfLicenses",
  }
];