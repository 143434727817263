import { Box, CircularProgress, CircularProgressLabel, Heading } from "@chakra-ui/react";

export const OverallHarmonizationIndex = ({ value, mt, ml, mb, mr, onClickChart }) => {

    const color = "#4094E4";
    const trackColor = "#0095FF33";

    return (
    <Box className="harmonization-index" style={ { justifyContent: "center" } } ml={ml} mt={mt} mb={mb} mr={mr} onClick={onClickChart} >
        <CircularProgress value={value} trackColor={trackColor} size='200px' thickness="12px" color={color}>
        <CircularProgressLabel>
            <Heading size="xl">{value}%</Heading>
        </CircularProgressLabel>
        </CircularProgress>
    </Box>
    )
}