import { createSlice } from '@reduxjs/toolkit';
import { getAuthenticatedClient } from '../../api';
import { loading, finishLoading } from '../loader/slice';
import { extractOrganizationId } from 'helpers/storageHelper';

export const metricsSlice = createSlice({
  name: 'metrics',
  initialState: {
    userIdSelectedOnNavBar: null,
    userNameSelectedOnNavBar: null,
    isSelectingUserOnNavBar: false,
    userReponseSummary: null,
    employeeIQ: null,
    boardIQ: null,
    orgIQ: null,
    careerIQ: null,
    definitions: null,
    individual: {
      employeeIQ: null,
      boardIQ: null,
      orgIQ: null,
      exjDiagnostic: null,
      careerIQ: null
    },
    team: {
      employeeIQ: null,
      boardIQ: null,
      orgIQ: null,
      exjDiagnostic: null,
      careerIQ: null
    }
  },
  reducers: {
    setUserIdSelectedOnNavBar: (state, action) => {
      state.userIdSelectedOnNavBar = action.payload.userId;
      state.userNameSelectedOnNavBar = action.payload.userName;
      state.isSelectingUserOnNavBar = true;
    },
    resetIsSelectingUserOnNavBar: (state, action) => {
      state.isSelectingUserOnNavBar = false;
    },
    setEvaluationMetrics: (state, action) => {
        state.employeeIQ = action.payload.employeeIQ;
        state.boardIQ = action.payload.boardIQ;
        state.orgIQ = action.payload.orgIQ;
        state.careerIQ = action.payload.careerIQ;
    },
    setIndividualEvaluationMetrics: (state, action) => {
      state.individual.employeeIQ = action.payload.employeeIQ;
      state.individual.boardIQ = action.payload.boardIQ;
      state.individual.orgIQ = action.payload.orgIQ;
      state.individual.exjDiagnostic = action.payload.exjDiagnostic;
      state.individual.careerIQ = action.payload.careerIQ;
    },
    setTeamEvaluationMetrics: (state, action) => {
      state.team.employeeIQ = action.payload.employeeIQ;
      state.team.boardIQ = action.payload.boardIQ;
      state.team.orgIQ = action.payload.orgIQ;
      state.team.exjDiagnostic = action.payload.exjDiagnostic;
      state.team.careerIQ = action.payload.careerIQ;
    },
    setUserResponseSummary: (state, action) => {
      state.userReponseSummary = action.payload;
    },
    setDefinitions: (state, action) => {
      state.definitions = action.payload
    }
  }
});

export const { 
    setEvaluationMetrics,
    setIndividualEvaluationMetrics,
    setTeamEvaluationMetrics,
    setUserResponseSummary,
    setDefinitions,
    setUserIdSelectedOnNavBar,
    resetIsSelectingUserOnNavBar
 } = metricsSlice.actions;

export const getOrganizationMetrics = (onSuccess) => async dispatch => {

    dispatch(loading());
    const client = await getAuthenticatedClient(dispatch);
    client.get(`v1/metrics/evaluations?organizationId=${extractOrganizationId()}`)
        .then(res => {
            const metrics = res.data;
            dispatch(setEvaluationMetrics(metrics));
            onSuccess();
            dispatch(finishLoading());
        })
        .catch((error) => {
            dispatch(finishLoading());
        });
};

export const getOrganizationUserResponseSummary = (onSuccess) => async dispatch => {

  dispatch(loading());
  const client = await getAuthenticatedClient(dispatch);
  client.get(`v1/metrics/userresponses/summary?organizationId=${extractOrganizationId()}`)
      .then(res => {
          const metrics = res.data;
          dispatch(setUserResponseSummary(metrics));
          onSuccess();
          dispatch(finishLoading());
      })
      .catch((error) => {
          dispatch(finishLoading());
      });
};

export const getTeamMetrics = (onSuccess) => async dispatch => {

  dispatch(loading());
  const client = await getAuthenticatedClient(dispatch);
  client.get(`v1/metrics/evaluations/team?organizationId=${extractOrganizationId()}`)
      .then(res => {
          const metrics = res.data;
          dispatch(setTeamEvaluationMetrics(metrics));
          onSuccess();
          dispatch(finishLoading());
      })
      .catch((error) => {
          dispatch(finishLoading());
      });
};

export const getIndividualMetrics = ({ userId, onSuccess }) => async dispatch => {

    dispatch(loading());
    const client = await getAuthenticatedClient(dispatch);
    client.get(`v1/users/${userId}/metrics?organizationId=${extractOrganizationId()}`)
        .then(res => {
            let metrics = res.data;
            dispatch(setIndividualEvaluationMetrics(metrics));
            onSuccess(metrics);
            dispatch(finishLoading());
        })
        .catch((error) => {
            dispatch(finishLoading());
        });
};

export const getDefinitionMetrics = ({ onSuccess }) => async dispatch => {

    dispatch(loading());
    const client = await getAuthenticatedClient(dispatch);
    client.get(`v1/metrics/definitions`)
        .then(res => {
            let metrics = res.data;
            dispatch(setDefinitions(metrics));
            onSuccess();
            dispatch(finishLoading());
        })
        .catch((error) => {
            dispatch(finishLoading());
        });
};



export default metricsSlice.reducer;
