/* eslint-disable */
import  { 
  Modal, 
  ModalOverlay, 
  ModalContent, 
  ModalBody, 
  FormControl, 
  ModalHeader,
  ModalCloseButton,
  FormLabel,
  Input,
  ModalFooter,
  Button
} from "@chakra-ui/react";
import { USER_ROLE } from "constants/userRoles.const";

// Custom components
import React from "react";

export default function CreateMember({ isOpen, role, onClose, toast, onSuccess, onSave }) {
  
    const initialRef = React.useRef(null);
    const finalRef = React.useRef(null);

    const [firstName, onChangeFirstName] = React.useState('');
    const [lastName, onChangeLastName] = React.useState('');
    const [email, onChangeEmail] = React.useState('');

    const onSubmit = (e) => {
      e.preventDefault();

      onSave(firstName, lastName, email, role, () => {
        onClose();
        onSuccess();
        toast({
          title: 'Success',
          description: `Invitation to new ${(role === USER_ROLE.TeamMember ? 'Team Member' : 'Admin')} has been sent.`,
          status: 'success',
          duration: 9000,
          isClosable: true
        });
      });
    }
  
    return (
        <Modal
          initialFocusRef={initialRef}
          finalFocusRef={finalRef}
          isOpen={isOpen}
          onClose={onClose}
        >
          <ModalOverlay />
          <ModalContent>
            <form onSubmit={onSubmit}>
              <ModalHeader>Create a new account</ModalHeader>
              <ModalCloseButton />
              <ModalBody pb={6}>
                    <FormControl>
                      <FormLabel>First name</FormLabel>
                      <Input onChange={(e) => onChangeFirstName(e.target.value)} required ref={initialRef} placeholder='First name' />
                    </FormControl>
        
                    <FormControl mt={4}>
                      <FormLabel>Last name</FormLabel>
                      <Input onChange={(e) => onChangeLastName(e.target.value)} required placeholder='Last name' />
                    </FormControl>

                    <FormControl mt={4}>
                      <FormLabel>E-mail</FormLabel>
                      <Input onChange={(e) => onChangeEmail(e.target.value)} required placeholder='E-mail' />
                    </FormControl>
                  </ModalBody>
        
                  <ModalFooter>
                    <Button colorScheme='blue' type="submit" mr={3}>
                      Save
                    </Button>
                    <Button onClick={onClose}>Cancel</Button>
                  </ModalFooter>
              </form>
          </ModalContent>
        </Modal>
    )
  }