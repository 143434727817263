import { Grid, GridItem, Text, Tooltip } from "@chakra-ui/react";
import Card from "components/card/Card";
import { TRAITS_DESC } from "constants/definitions";

function TraitItem(props) {
  const { color, text, value, height, textMt, fontSize } = props;

  const theHeight = height ? height : "55px";
  const theTextMt = textMt ? textMt : "15px";
  const theFontSize = fontSize ? fontSize : "10px";

  return (<Card backgroundColor={color} height={theHeight} padding={0} gap={2}>
      <Tooltip label={(text ? TRAITS_DESC[text.toLowerCase()] : null)}>
          <Grid templateColumns={"1.5fr 0.5fr"}>
              <GridItem>
                  <Text ml="20px" mt={theTextMt} fontSize={theFontSize}>{text}</Text>
              </GridItem>
              <GridItem textAlign={"right"} fontSize={theFontSize} mr="30px" mt={theTextMt}>
                  {value}
              </GridItem>
          </Grid>
      </Tooltip>
  </Card>)
}

export default TraitItem;
