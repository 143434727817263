// Chakra imports
import { Box } from "@chakra-ui/react";
import PropTypes from 'prop-types';
// Layout components
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Route } from "react-router-dom";
import { routes, authorizedRoutes } from "routes.js";
import Loader from "../../features/loader";

// Custom Chakra theme
function Exj(props) {
  const [isAuthenticated, setIsAuthenticated] = useState();
  
  useEffect(() => {
    setIsAuthenticated(props.isAuthenticated);
  }, []);

  // functions for changing the states from components
  const getRoute = () => {
    return window.location.pathname !== "/admin/full-screen-maps";
  };

  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.layout === "/exj") {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      }
      if (prop.collapse) {
        return getRoutes(prop.items);
      }
      if (prop.category) {
        return getRoutes(prop.items);
      } else {
        return null;
      }
    });
  };
  document.documentElement.dir = "ltr";
  
  const routesToBeUsed = isAuthenticated === true 
    ? authorizedRoutes 
    : routes; 


  return (
    <Box className="report">
      <Box
        minHeight='100vh'
        height='100%'
        width={"100%"}
        position='relative'>

          {getRoute() ? (
            <Box ml={{ sm: "0px", md: "32px", lg: "32px", xl: "32px" }} >
                {getRoutes(routesToBeUsed)}
            </Box>
          ) : null}
      </Box>
    <Loader />
  </Box>
  );
}

Exj.propTypes = {
  isAuthenticated: PropTypes.bool
};

Exj.defaultProps = {
  isAuthenticated: false
}

function mapStateToProps(state) {
  const { authentication } = state;

  const { isAuthenticated } = authentication;

  return {
    isAuthenticated 
   };
};

function mapDispatchToProps (dispatch) {
  return { }
}

export default connect(mapStateToProps, mapDispatchToProps)(Exj);