import { Box, Heading, Text } from "@chakra-ui/react";
import Card from "components/card/Card";
import { COLORS } from "constants/colors";
import { SURVEY_TYPE_SUMMARY } from "constants/evaluation.const";
import { SURVEY_TYPE } from "constants/evaluation.const";
import { SURVEY_TYPE_DESCRIPTION } from "constants/surveyTypes.const";

import employeeIqImg from '../../../assets/img/surveys/EmployeeIQ.svg';
import careerIQImg from '../../../assets/img/surveys/CareerIQ.svg';
import orgIqImg from '../../../assets/img/surveys/OrgIQ.svg';
import boardIqImg from '../../../assets/img/surveys/BoardIQ.svg';

const survey_types_imgs = {
    [SURVEY_TYPE.EmployeeIq]: employeeIqImg,
    [SURVEY_TYPE.BoardIq]: boardIqImg,
    [SURVEY_TYPE.OrgIq]: orgIqImg,
    [SURVEY_TYPE.CareerIQ]: careerIQImg,
};

const SurveyType = ({ surveyType }) => {
    return (
            <Box>
                <Card mb={{ base: "0px", lg: "20px"}} w="350px" minH="110px">
                    <Box display={"flex"} w="500px">
                        <Box h={"100%"} className="evaluation-text-image">
                            <img style={ { marginTop: '10%' } } alt="" src={survey_types_imgs[surveyType]} width={"60px"}/>
                        </Box>
                        <Box>
                            <Box ml="20px">
                            <Heading size="sm">{SURVEY_TYPE_DESCRIPTION[surveyType]}</Heading>
                            <Box style={ { overflowWrap: 'break-word', width: '250px' } }>
                                <Text className="evaluation-text-description" 
                                style={{ color: COLORS.Primary }} 
                                size="md"
                                >
                                {SURVEY_TYPE_SUMMARY[surveyType]}
                                </Text>
                            </Box>
                            </Box>
                        </Box>
                    </Box>
                </Card>
            </Box>
    );
}

export default SurveyType;