import { createSlice } from '@reduxjs/toolkit';
import { getAuthenticatedClient } from '../../api';
import { extractJwtToken } from 'helpers/storageHelper';

export const reportSlice = createSlice({
  name: 'reports',
  initialState: {
    isDownloading: false
  },
  reducers: {
    downloading: (state, action) => {
        state.isDownloading = true;
    },
    downloadFinished:  (state, action) => {
        state.isDownloading = false;
    }
  }
});

export const { 
    downloading,
    downloadFinished
} = reportSlice.actions;

export const generateReport = ({ reportOnly, evaluationId, onSuccess }) => async dispatch => {

    dispatch(downloading());
    const client = await getAuthenticatedClient(dispatch);
    client.post(`v1/report?evaluationId=${evaluationId}&download=${extractJwtToken()}&reportOnly=${reportOnly}`, null,  {
        responseType: 'blob',
      }).then(res => {
            // Create a Blob from the response data
            const blob = new Blob([res.data], { type: 'application/pdf' });
            onSuccess(blob);
            dispatch(downloadFinished());
        })
        .catch((error) => {
            dispatch(downloadFinished());
        });
};

export default reportSlice.reducer;
