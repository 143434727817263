/* eslint-disable */
import { DeleteIcon, EditIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Grid,
  GridItem,
  Heading,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  SimpleGrid,
  Text,
  Tooltip,
} from "@chakra-ui/react";
// Custom components
import Card from "components/card/Card";
import { COLORS } from "constants/colors";
import React, { useState } from "react";

export default function PendingMembersTable(props) {
  const { teamMembers, userReponseSummary, onClickTeamMember } = props;

  const [isModalOpen, setIsModalOpen] = useState(false);
  const viewingUser = false;

  const onResendInvitation = () => {
    setIsModalOpen(false);
  }

  const onDeleteInvitation = () => {
    setIsModalOpen(false);
  }


  const hasAnsweredEmployeeIq = (responses, userId) => {
    return responses && responses.employeeIQ.indexOf(userId) >= 0;
  }
  const hasAnsweredBoardIq = (responses, userId) => {
    return responses && responses.boardIQ.indexOf(userId) >= 0;
  }
  const hasAnsweredCareerIq = (responses, userId) => {
    return responses && responses.careerIQ.indexOf(userId) >= 0;
  }
  const hasAnsweredOrgIq = (responses, userId) => {
    return responses && responses.orgIQ.indexOf(userId) >= 0;
  }
  const hasAnsweredExjDiagnostic = (responses, userId) => {
    return responses && responses.exjDiagnostic.indexOf(userId) >= 0;
  }

 
  return (
    <Box width={"100%"} style={{ overflowY: 'auto'}} height={"500px"}>
      <SimpleGrid columns={{ sm: 1, md: 1, lg: 3, xl: 3 }} spacingY={4} spacingX={4}>
      {teamMembers ? teamMembers.map((u, i) => {
            return (
              <Card>
                <Grid templateColumns={{ sm: "1fr 1fr 1fr", md: "1fr 1fr 1fr" }}>
                  <GridItem>
                    <Box onClick={() => onClickTeamMember(u)} className="member-card" height={"10px"} pos="relative" alignContent={"center"} justifyContent={"center"}>
                      <div style={{ width: "49px", height: "49px", textAlign: 'center', backgroundColor: "#0095FF1A", color: "#0095ff", borderRadius: "50%", position: 'absolute' }} >
                          <Heading size="md" style={{ paddingTop: "10px" }}>
                            {`${u.firstName.substring(0, 1)} ${u.lastName.substring(0, 1)}`}
                          </Heading>
                        </div>
                    </Box>
                  </GridItem>
                  <GridItem>
                    <Box style={ { textWrap: "wrap"}} onClick={() => onClickTeamMember(u)} className="member-card">
                      <Box ml="10px" mt="7px">
                        <Heading size="xs">{u.firstName} {u.lastName}</Heading>
                        <Box style={ { overflowWrap: 'wrap' } }>
                          <Text className="evaluation-text-description" 
                            fontSize="8px"
                            width={"90px"}
                            style={{ color: "#0000004D", wordBreak: "breakAll"}} 
                          >
                              {u.email}
                          </Text>
                        </Box>
                      </Box>
                    </Box>
                  </GridItem>
                  <GridItem pos={"relative"}>
                      <Box height={"8px"} top="50%" left="50%" pos={"absolute"} alignContent={"center"} justifyContent={"center"}>
                        <Tooltip label={<div>
                            <div style={ { marginTop: "2px" } }>Employee Iq: {hasAnsweredEmployeeIq(userReponseSummary, u.id) ? "Completed" : "Pending"}</div>
                            <div style={ { marginTop: "2px" } }>Board Iq: {hasAnsweredBoardIq(userReponseSummary, u.id) ? "Completed" : "Pending"}</div>
                            <div style={ { marginTop: "2px" } }>Org Iq: {hasAnsweredOrgIq(userReponseSummary, u.id) ? "Completed" : "Pending"}</div>
                            {/* <div style={ { marginTop: "2px" } }>Career Iq: {hasAnsweredCareerIq(userReponseSummary, u.id) ? "Completed" : "Pending"}</div> */}
                          </div>}>
                            <Box marginLeft="auto" marginRight="auto" w={"35px"}>
                              <Grid templateColumns="1fr 1fr" columnGap={"3px"} rowGap={"3px"}>
                                <GridItem bgColor={hasAnsweredEmployeeIq(userReponseSummary, u.id) ? "#FF5154" : "#E5F4FF"} borderTopLeftRadius={"10px"} minH={"15px"}>
                                </GridItem>
                                <GridItem bgColor={hasAnsweredOrgIq(userReponseSummary, u.id) ? "#23CE6B" : "#E5F4FF"} borderTopRightRadius={"10px"} minH={"15px"}>
                                </GridItem>
                                <GridItem bgColor={hasAnsweredBoardIq(userReponseSummary, u.id) ? "#F6AE2D" : "#E5F4FF"} borderBottomLeftRadius={"10px"} minH={"15px"}>
                                </GridItem>
                                <GridItem bgColor={hasAnsweredCareerIq(userReponseSummary, u.id) ? "#0095FF" : "#E5F4FF"} borderBottomRightRadius={"10px"} minH={"15px"}>
                                </GridItem>
                              </Grid>
                            </Box>
                        </Tooltip>
                      </Box>
                  </GridItem>
                </Grid>
              </Card>)}) : null}
            </SimpleGrid>
            <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
              <ModalOverlay />
              <ModalContent>
                  <ModalBody>
                      <Box width="100%">
                          <div style={{ width: "49px", height: "49px", position: 'relative', right: '45%', left: '45%', textAlign: 'center', backgroundColor: "#0095FF1A", color: "#0095ff", borderRadius: "50%" }} >
                            <Heading size="md" style={{ paddingTop: "10px" }}>
                              {viewingUser ? `${viewingUser.firstName.substring(0, 1)} ${viewingUser.lastName.substring(0, 1)}` : null}
                            </Heading>
                          </div>
                      </Box>
                      <Box width="100%" style={ { textWrap: "wrap", textAlign: "center" }} className="member-card">
                        <Box ml="10px" mt="7px">
                          <Heading size="xs">{viewingUser ? `${viewingUser.firstName} ${viewingUser.lastName}` : null}</Heading>
                          <Box style={ { overflowWrap: 'wrap', width: '100%' } }>
                            <Text className="evaluation-text-description" 
                              fontSize="xs"
                              style={{ color: "#0000004D" }} 
                            >
                              {viewingUser ? viewingUser.email : null}
                            </Text>
                          </Box>
                        </Box>
                      </Box>
                      <Box mt="10px" w="350px" ml="auto" mr="auto">
                        <Button 
                            style={ { backgroundColor: COLORS.Primary, color: COLORS.PrimaryText } } 
                            mr={3} 
                            onClick={onResendInvitation}>
                            Resend Invitation
                        </Button>
                        <Button 
                            color={COLORS.Primary}
                            style={ { borderColor: COLORS.Primary }}
                            onClick={onDeleteInvitation}>
                            Delete Invitation
                        </Button>
                      </Box>
                  </ModalBody>
                  <ModalFooter justifyContent={"center"}>
                      <Button 
                          style={ { backgroundColor: COLORS.Primary, color: COLORS.PrimaryText } } 
                          mr={3} 
                          onClick={() => setIsModalOpen(false)}>
                          Close
                      </Button>
                  </ModalFooter>
              </ModalContent>
            </Modal>

   </Box>
  );
}
