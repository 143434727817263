import PropTypes from 'prop-types';
import { Box, Grid, GridItem, Heading, Text, Button, Tooltip, IconButton } from '@chakra-ui/react';
import React from 'react';
import { useState } from 'react';
import Card from 'components/card/Card';
import { SURVEY_TYPE } from 'constants/evaluation.const';
import { USER_ROLE } from 'constants/userRoles.const';
import { SUBSCRIPTION_TYPE } from 'constants/subscriptionTypes.const';
import { SURVEY_TYPE_DESCRIPTION } from 'constants/surveyTypes.const';
import { DownloadIcon } from '@chakra-ui/icons';
import { TailSpin } from 'react-loader-spinner';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { ResultPanel } from 'components/results/ResultPanel';

const evaluationIndexMapping = {
    [SURVEY_TYPE.EmployeeIq]: 0,
    [SURVEY_TYPE.BoardIq]: 1,
    [SURVEY_TYPE.OrgIq]: 2,
    [SURVEY_TYPE.CareerIQ]: 3,
    [SURVEY_TYPE.ExjDiagnostic]: 4
}

function DefaultIndividualResults(props){
    const history = useHistory();

    const { 
        onLoadIndividualMetrics, 
        onDownloadReport,
        onCreateNewEvaluation,
        userId, 
        employeeIQ, 
        boardIQ, 
        orgIQ, 
        careerIQ, 
        exjDiagnostic, 
        isDownloading,
        subscriptionType,
        role,
        report,
        evaluationId,
        userIdSelectedOnNavBar,
        userNameSelectedOnNavBar,
        isSelectingUserOnNavBar
    } = props;

    const evaluationTypes = [];

    if (employeeIQ !== null && employeeIQ.length > 0) {
        evaluationTypes.push(employeeIQ);
    }

    if (boardIQ !== null && boardIQ.length > 0) {
        evaluationTypes.push(boardIQ);
    }

    if (orgIQ !== null && orgIQ.length > 0) {
        evaluationTypes.push(orgIQ);
    }

    if (careerIQ !== null && careerIQ.length > 0) {
        evaluationTypes.push(careerIQ);
    }

    if (subscriptionType === SUBSCRIPTION_TYPE.Enterprise && (exjDiagnostic !== null && exjDiagnostic.length > 0)) {
        evaluationTypes.push(exjDiagnostic);
    }

    const surveyTypeMapping = {
        0: SURVEY_TYPE.EmployeeIq,
        1: SURVEY_TYPE.BoardIq,
        2: SURVEY_TYPE.OrgIq,
        3: SURVEY_TYPE.CareerIQ,
        4: SURVEY_TYPE.ExjDiagnostic
    }

    const [evaluationTabIndex, setEvaluationTabIndex] = useState(0);
    const [evaluationTypeIndex, setEvaluationTypeIndex] = useState(0);

    const onClickDownload = (reportOnly, evaluationTypeIndex, evaluationTabIndex) => {

        onDownloadReport(reportOnly, evaluationTypes[evaluationTypeIndex][evaluationTabIndex].evaluationId, (blob) => {
            if(reportOnly === false) { 
                // Create a URL for the Blob
                const url = window.URL.createObjectURL(blob);
                // Create a link element and trigger a download
                const link = document.createElement('a');
                link.href = url;
                link.download = `${SURVEY_TYPE_DESCRIPTION[surveyTypeMapping[evaluationTypeIndex]]}.pdf`;
                document.body.appendChild(link);
                
                link.click();
            
                // Clean up
                document.body.removeChild(link);
                window.URL.revokeObjectURL(url);
            }
        });
    }
    React.useEffect(() => {
        if (userId !== null) {
            onLoadIndividualMetrics(userId, () => {
                if(report === true) {
                    onDownloadReport(true, evaluationId, 
                        (blob) => {});
                }
            });
        }

    }, [userId, evaluationId, onLoadIndividualMetrics, report, onDownloadReport]);

    React.useEffect(() => {
        if(props.surveyType !== null){
          setEvaluationTypeIndex(evaluationIndexMapping[props.surveyType]);
        }
      }, [props.surveyType, props.report]);

    const HasNotSelectedAllInTheNavBar = (selectedUser) => {
        return selectedUser !== "00000000-0000-0000-0000-000000000000";
    }

    const hasSelectedAllInTheNavBar = (selectedUser) => {
        return selectedUser === "00000000-0000-0000-0000-000000000000";
    }

    const onClickTakeAssessment = (userSurveyResponseId) => {
      history.push(`/app/assessment/${userSurveyResponseId}`);
    }

    return <Box id="results" className='results' pt={{ base: "50px", md: "50px", xl: "50px" }} ml={{ sm: '0px', md: '0px', lg: "33px", xl: "33px" }} pr="20px" pb="40px">
            <Box>
                <Grid templateColumns={"1fr 1fr"}>
                    <GridItem>
                        <Heading size="md">Overall Results</Heading>
                    </GridItem>
                    {evaluationTypes.length > 0 && evaluationTypes[evaluationTypeIndex][evaluationTabIndex] ? <GridItem textAlign="right">
                        {/* download function */}
                        {isDownloading ? <Box float="right" mr="30px"><TailSpin
                            height="30"
                            width="30"
                            color="#4094E4"
                            ariaLabel="tail-spin-loading"
                            radius="1"
                            float="right"
                            wrapperStyle={{}}
                            wrapperClass=""
                            visible={true}
                            /> </Box>: <Tooltip label="Download Report">
                            <IconButton 
                                icon={<DownloadIcon />}
                                aria-label='Download'
                                mr="30px"
                                colorScheme="blue"
                                onClick={() => onClickDownload(false, evaluationTypeIndex, evaluationTabIndex)}
                            />
                        </Tooltip>}
                        {/* end download function */}
                    </GridItem> : null}
                </Grid>
            </Box>
            <ResultPanel 
                employeeIQ={employeeIQ}
                boardIQ={boardIQ}
                orgIQ={orgIQ}
                careerIQ={careerIQ}
                exjDiagnostic={exjDiagnostic}
                subscriptionType={subscriptionType}
                userIdSelectedOnNavBar={userIdSelectedOnNavBar}
                isSelectingUserOnNavBar={isSelectingUserOnNavBar}
                hasSelectedAllInTheNavBar={hasSelectedAllInTheNavBar}
                onCreateNewEvaluation={onCreateNewEvaluation}
                onLoadIndividualMetrics={onLoadIndividualMetrics}
                userId={userId}
                role={role}
                showEvaluationsActionBar={true}
                onChangeEvaluationTypeIndex={(index) => setEvaluationTypeIndex(index)}
                onChangeEvaluationTabIndex={(index) => setEvaluationTabIndex(index)}
            />
            {isSelectingUserOnNavBar === false ? <Box>
                {evaluationTypes[evaluationTypeIndex] && evaluationTypes[evaluationTypeIndex].length > 0 && evaluationTypes[evaluationTypeIndex][evaluationTabIndex] && 
                    evaluationTypes[evaluationTypeIndex][evaluationTabIndex].behaviorDrivers !== null &&  evaluationTypes[evaluationTypeIndex][evaluationTabIndex].pendingUserSurveyResponseId !== null ? <Box mt="10px" height={"100%"}>
                        
                        {/* User has no results to be displayed */}
                        {role === USER_ROLE.Admin && (userIdSelectedOnNavBar !== userId && HasNotSelectedAllInTheNavBar(userIdSelectedOnNavBar) && userIdSelectedOnNavBar !== null) ? <Card minH="200px">
                            <Box ml="auto" mr="auto" mt="auto" mb="auto">
                                <Text>There are no results to be displayed. {userNameSelectedOnNavBar === "ALL" ? "Team Members have" : `${userNameSelectedOnNavBar} has`} not taken any <span style={{ fontWeight: "bold" }}>[{SURVEY_TYPE_DESCRIPTION[evaluationTypes[evaluationTypeIndex][evaluationTabIndex].evaluationType]}]</span> yet.</Text>
                            </Box>
                        </Card> : null}

                        {(role === USER_ROLE.Admin && (userIdSelectedOnNavBar === userId || userIdSelectedOnNavBar === null)) || (role === USER_ROLE.TeamMember) ? <Card minH="200px">
                            <Box ml="auto" mr="auto" mt="30px">
                               <Text>You don't have <span style={{ fontWeight: "bold" }}>[{SURVEY_TYPE_DESCRIPTION[evaluationTypes[evaluationTypeIndex][evaluationTabIndex].evaluationType]}]</span> results because still haven't taken the assessment yet.</Text>
                            </Box>
                            <Box ml="auto" mr="auto" mt="15px">
                                <Button 
                                    colorScheme='blue' 
                                    ml="auto"
                                    mr="auto" 
                                    onClick={() => onClickTakeAssessment(evaluationTypes[evaluationTypeIndex][evaluationTabIndex].pendingUserSurveyResponseId)}>
                                        Take Assessment
                                </Button>
                            </Box>
                        </Card> : null}

                </Box> : null}
            </Box> : <Box>
                <Card minH="200px" mt="10px">
                    <Box mt="auto" mb="auto" ml="auto" mr="auto">
                        <Text>Click <span style={{ fontWeight: 'bold' }}>"GO"</span> to load the results</Text>
                    </Box>
                </Card>
            </Box>}
        </Box>
}


DefaultIndividualResults.propTypes = {
    onLoadOrganizationMetrics: PropTypes.func,
    onDownloadReport: PropTypes.func,
    userId: PropTypes.string,
    employeeIQ: PropTypes.array,
    boardIq: PropTypes.array,
    orgIQ: PropTypes.array,
    careerIQ: PropTypes.array,
    exjDiagnostic: PropTypes.array,
    surveyType: PropTypes.number,
    userIdSelectedOnNavBar: PropTypes.string,
    userNameSelectedOnNavbar: PropTypes.string
};

DefaultIndividualResults.defaultProps = {
    onLoadOrganizationMetrics: () => { },
    onDownloadReport: () => {},
    userId: '',
    employeeIQ: [],
    boardIQ: [],
    orgIQ: [],
    exjDiagnostic: [],
    careerIQ: [],
    surveyType: null,
    userIdSelectedOnNavBar: '',
    userNameSelectedOnNavbar: ''
}
  
export default DefaultIndividualResults;