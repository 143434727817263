import { INSIGHT_VIEW } from "./insightsViews.const";

export const INSIGHTS_DESC = {
    [INSIGHT_VIEW.Trends]: {
        title: "Trends",
        description: `Each WorkIQ client-organization categorizes themselves based on six criteria: industry, financial size, headcount, organizational type, complexity, and years in operation. Our WorkIQ suite comprises three products, each containing 96 questions, termed "indicators." The "Trends" page displays crosstabulations of these indicators with the criteria, revealing average score patterns across our dataset of organizations and individuals. Charts below utilize a scale from zero to ten, divided into sub-optimal, optimal, and over-optimal segments.`,
    },
    [INSIGHT_VIEW.Analytics]: {
        title: "Predictive Analytics",
        description: `Predictive analytics involves using historical data to forecast future events or behaviors. It uses techniques such as statistical modeling, machine learning, and data mining to analyze patterns in datasets and make predictions about future outcomes. By identifying relationships and trends in the data, predictive analytics can help tam leaders anticipate to make informed decisions and optimize strategies in managing human resources.`,
    },
    [INSIGHT_VIEW.DataDriveDecisions]: {
        title: "Data-driven Executive Decision",
        description: "",
    }
}