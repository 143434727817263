import axios from 'axios';
import { logoutAsync, setUserAuthenticated } from '../features/authentication/slice';

const getApiClient = async (dispatch, authenticated, contentType) => {

  const jsonClient = axios.create({
    baseURL: process.env.REACT_APP_API_URL
  });

  jsonClient.interceptors.request.use(
      config => {    

        config.headers = { 
          'Accept': 'application/json',
          'Content-Type': contentType,
          'LanguageCode': localStorage.getItem('language'),
          'x-access-token': localStorage.getItem('x-access-token'),
          'Ocp-Apim-Subscription-Key': process.env.REACT_APP_API_KEY,
          'x-functions-key': process.env.REACT_APP_FUNC_KEY
        }
    
        return config;
      },
      error => {
        Promise.reject(error);
    });
  
  jsonClient.interceptors.response.use((response) => {
        return response;
    }, async (error) => {

        const originalConfig = error.config;

        const handleErrorMessage = (err) => {
          if (err.response && err.response.data && err.response.data.code !== undefined) {
              if(err.response.data.code === "USER_INVALID_TOKEN" ){
                window.location.href = process.env.REACT_APP_WELCOME_REDIRECT;
                dispatch(logoutAsync());
              }
          }
          return Promise.reject(err);
        }

        if (originalConfig.url !== "v1/users/refresh-token" && originalConfig.url !== "v1/users/session" && originalConfig.url !== "v1/users/authenticate" && error.response) {
          
          if (error.response.status === 401 && originalConfig._retry !== true) {
            originalConfig._retry = true;

            try {

              const rs = await jsonClient.post("v1/users/refresh-token", {
                refreshToken: localStorage.getItem('x-refresh-token'),
              });
    
              const { jwtToken, refreshToken, id } = rs.data;

              localStorage.setItem('x-access-token', jwtToken);
              localStorage.setItem('x-refresh-token', refreshToken);

              dispatch(setUserAuthenticated(id));
    
              return jsonClient(originalConfig);

            } catch (_error) {
              
              return Promise.reject(_error);

            }
          } else {
            handleErrorMessage(error);
          }
        } else {
          handleErrorMessage(error);
        }
        
        return Promise.reject(error);
    });

  return jsonClient;
}

export const getAnonymousClient = (dispatch) => getApiClient(dispatch, false, 'application/json');
export const getAnonymousClientForm = (dispatch) => getApiClient(dispatch, false, 'application/x-www-form-urlencoded');
export const getAuthenticatedClient = (dispatch) => getApiClient(dispatch, true, 'application/json');
export const getFileClient = (dispatch) => getApiClient(dispatch, true, 'multipart/form-data');
