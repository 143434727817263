import { Card, Grid, GridItem, Text, Tooltip } from "@chakra-ui/react"
import { TRAITS_DESC } from "constants/definitions"

export const TraitItem = ({ color, text, value, onClick }) => {
    return (<Card backgroundColor={"#DCE0E3"} height="55px" padding={0} gap={2} className="trait-item" onClick={() => onClick()}>
        <Tooltip label={(text ? TRAITS_DESC[text.toLowerCase()] : null)}>
            <Grid templateColumns={"1fr 1fr"}>
                <GridItem>
                    <Text ml="20px" mt="15px" fontSize="12px">{text}</Text>
                </GridItem>
                <GridItem textAlign={"right"} mr="30px" mt="15px">
                    {value}
                </GridItem>
            </Grid>
        </Tooltip>
    </Card>)
}