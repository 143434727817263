import { getUserSurveysById } from "features/userSurveys/slice";
import React from "react";
import Authenticated from "../authenticated";
import PropTypes from 'prop-types';
import { connect } from "react-redux";
import './assessment.css';
import { setAnswer } from "features/userSurveys/slice";
import { saveUserSurveyChanges } from "features/userSurveys/slice";
import EvaluationFormView from "./views/EvaluationFormView";

function Assessment(props) {

  return (
    <Authenticated>
      <EvaluationFormView 
          location={props.location}
      />
    </Authenticated>
  );
}


Assessment.propTypes = {
  mustPerformOnboarding: PropTypes.bool
};

Assessment.defaultProps = {
  mustPerformOnboarding: true,
}

function mapStateToProps(state) {

  const { userId } = state.authentication;

  const { userSurvey, answers } = state.userSurveys;

  const { hasSkipEvaluationOnboarding } = state.user;

  return {
    userId,
    userSurvey,
    answers,
    hasSkipEvaluationOnboarding
   };
};

function mapDispatchToProps (dispatch) {
  return {
      onLoadUserSurveyById: (userId, userSurveyResponseId, onSuccess, onError ) => dispatch(getUserSurveysById({ userId, userSurveyResponseId, onSuccess, onError })),
      onChangeQuestionScore: (questionId, score) => dispatch(setAnswer({ questionId, score })),
      onSaveSurveyChanges: (organizationId, evaluationId, pageIndex, answers, enterpriseQuestions, onSuccess, onError) => dispatch(saveUserSurveyChanges( { organizationId, evaluationId, pageIndex, answers, enterpriseQuestions, onSuccess, onError } )),
      dispatch
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Assessment);