import { COLORS } from "constants/colors";
import { useEffect, useRef } from "react";
import LeaderLine from "react-leader-line";
import BehaviorsModal from "./modals/BehaviorsModal";
import IndicatorsModal from "./modals/IndicatorsModal";
import TraitsModal from "./modals/TraitsModal";
import './Arrows.css';
import { TRAITS_DESC } from "constants/definitions";
import { BEHAVIORS_DESC } from "constants/definitions";

const { Box, Grid, GridItem, Heading, Text, useDisclosure, Tooltip } = require("@chakra-ui/react");

function Arrows({ definitions, behaviorLines, traitLines, onSetBehaviorLines, onSetTraitLines, onRemoveAllLines }){

    const behaviorColors = ['#B9B4B4', '#686666', '#2F2E2E','#686666'];
    const traitColors = ['#FB4D3D', '#0095FF', '#0095FF', '#F6AE2D', '#8B00FF', '#FFD700', '#00FF00', '#FF1493'];

    const behaviorsModal = useDisclosure();
    const indicatorsModal = useDisclosure();
    const traitsModal = useDisclosure();

    const behaviorRefs = [];
    const indicatorsRefs = [];
    const traitsRefs = [];

    const behaviorRef1 = useRef();
    const behaviorRef2 = useRef();
    const behaviorRef3 = useRef();
    const behaviorRef4 = useRef();

    behaviorRefs.push(behaviorRef1);
    behaviorRefs.push(behaviorRef2);
    behaviorRefs.push(behaviorRef3);
    behaviorRefs.push(behaviorRef4);
    
    const indicatorRef1 = useRef();
    const indicatorRef2 = useRef();
    const indicatorRef3 = useRef();

    indicatorsRefs.push(indicatorRef1);
    indicatorsRefs.push(indicatorRef2);
    indicatorsRefs.push(indicatorRef3);

    const traitsRef1 = useRef();
    const traitsRef2 = useRef();
    const traitsRef3 = useRef();
    const traitsRef4 = useRef();
    const traitsRef5 = useRef();
    const traitsRef6 = useRef();
    const traitsRef7 = useRef();
    const traitsRef8 = useRef();

    traitsRefs.push(traitsRef1);
    traitsRefs.push(traitsRef2);
    traitsRefs.push(traitsRef3);
    traitsRefs.push(traitsRef4);
    traitsRefs.push(traitsRef5);
    traitsRefs.push(traitsRef6);
    traitsRefs.push(traitsRef7);
    traitsRefs.push(traitsRef8);

    const createBehaviorLines = (defs, bRefs, iRefs, arr) => {
        for (var i = 0; i < defs.behaviors.length; i++) {
            for (var y = 0; y < defs.indicators.length; y++) {
                arr.push(new LeaderLine(bRefs[i].current, iRefs[y].current, {
                    color: behaviorColors[i],
                    startPlugColor: behaviorColors[i],
                    endPlugColor: behaviorColors[i],
                    startPlug: 'behind',
                    size: 1
                }));
            }
        }
    }

    const createTraitLines = (defs, tRefs, iRefs, arr) => {
        for (var i = 0; i < defs.traits.length; i++) {
            for (var y = 0; y < defs.indicators.length; y++) {
                arr.push(new LeaderLine(tRefs[i].current, iRefs[y].current, {
                    color: traitColors[i],
                    startPlugColor: traitColors[i],
                    endPlugColor: traitColors[i],
                    startPlug: 'behind',
                    size: 1
                }));
            }
        }
    }

    const removeBehaviorLines = (arr) => {
        for (let i = 0; i < arr.length; i++) {
            try {
                arr[i].remove();
            } catch(e){
                // Error
            }
        }

        onSetBehaviorLines([]);
    }  

    const removeTraitLines = (arr) => {
        for (let i = 0; i < arr.length; i++) {
            try {
                arr[i].remove();
            } catch(e){
                // Error
            }
        }
        onSetTraitLines([]);
    }  



    const onBehaviorMouseHover = (item, index) => {
        let n = (index + 1) *3;
        try {
            for (let i = 0; i < behaviorLines.length; i++) {
                if([n - 3, n - 2, n - 1].indexOf(i) < 0) {
                    behaviorLines[i].remove();
                }
            }
        } catch(e){
        }

        onSetBehaviorLines(behaviorLines);
    } 

    const onBehaviorMouseOut = () => {
        removeBehaviorLines(behaviorLines);

        let arrBehaviors = [];
        createBehaviorLines(definitions, behaviorRefs, indicatorsRefs, arrBehaviors);
        onSetBehaviorLines(arrBehaviors);
    }


    const onTraitMouseHover = (item, index) => {
        let n = (index + 1) *3;
        try {
            for (let i = 0; i < traitLines.length; i++) {
                if([n - 3, n - 2, n - 1].indexOf(i) < 0) {
                    traitLines[i].remove();
                }
            }
        } catch(e){
        }
    } 

    const onTraitMouseOut = () => {
        removeTraitLines(traitLines);

        let traitsArr = [];

        createTraitLines(definitions, traitsRefs, indicatorsRefs, traitsArr);

        onSetTraitLines(traitsArr);
    }

    useEffect(() => {
        
        let behaviorsArr = [];
        createBehaviorLines(definitions, behaviorRefs, indicatorsRefs, behaviorsArr);
        onSetBehaviorLines(behaviorsArr);

        let traitsArr = [];
        createTraitLines(definitions, traitsRefs, indicatorsRefs, traitsArr);
        onSetTraitLines(traitsArr);

        const onRemoveAllLinesFunc = onRemoveAllLines;
       
        // const line5 = new LeaderLine(behaviorRef1.current, indicatorRef2.current, lineOptions2);
        // const line6 = new LeaderLine(behaviorRef2.current, indicatorRef2.current, lineOptions2);
        // const line7 = new LeaderLine(behaviorRef3.current, indicatorRef2.current, lineOptions2);
        // const line8 = new LeaderLine(behaviorRef4.current, indicatorRef2.current, lineOptions2);

        // const lineOptions3 = {
        //     color: '#686666',
        //     startPlugColor: '#686666',
        //     endPlugColor: '#686666',
        //     startPlug: '#686666',
        //     size: 1
        // };

        // const line9 = new LeaderLine(behaviorRef1.current, indicatorRef3.current, lineOptions3);
        // const line10 = new LeaderLine(behaviorRef2.current, indicatorRef3.current, lineOptions3);
        // const line11 = new LeaderLine(behaviorRef3.current, indicatorRef3.current, lineOptions3);
        // const line12 = new LeaderLine(behaviorRef4.current, indicatorRef3.current, lineOptions3);

        // // Drive
        // const lines13 = connectToPoints(traitsRef1, [indicatorRef1, indicatorRef2, indicatorRef3], {
        //     color: '#FB4D3D',
        //     startPlugColor: '#FB4D3D',
        //     endPlugColor: '#FB4D3D',
        //     startPlug: '#FB4D3D',
        //     size: 1
        // });

        // // Iniative
        // const lines14 = connectToPoints(traitsRef2, [indicatorRef1, indicatorRef2, indicatorRef3], {
        //     color: '#0095FF',
        //     startPlugColor: '#0095FF',
        //     endPlugColor: '#0095FF',
        //     startPlug: '#0095FF',
        //     size: 1
        // });

        // // Goal Achievement
        // const lines15 = connectToPoints(traitsRef3, [indicatorRef1, indicatorRef2, indicatorRef3], {
        //     color: '#9D64D2',
        //     startPlugColor: '#9D64D2',
        //     endPlugColor: '#9D64D2',
        //     startPlug: '#9D64D2',
        //     size: 1
        // });

        // // Persistence
        // const lines16 = connectToPoints(traitsRef4, [indicatorRef1, indicatorRef2, indicatorRef3], {
        //     color: '#d3ffce',
        //     startPlugColor: '#d3ffce',
        //     endPlugColor: '#d3ffce',
        //     startPlug: '#d3ffce',
        //     size: 1
        // });

        // // Competitiveness
        // const lines17 = connectToPoints(traitsRef5, [indicatorRef1, indicatorRef2, indicatorRef3], {
        //     color: '#b8775b',
        //     startPlugColor: '#b8775b',
        //     endPlugColor: '#b8775b',
        //     startPlug: '#b8775b',
        //     size: 1
        // });

        // // Perception
        // const lines18 = connectToPoints(traitsRef6, [indicatorRef1, indicatorRef2, indicatorRef3], {
        //     color: '#c90076',
        //     startPlugColor: '#c90076',
        //     endPlugColor: '#c90076',
        //     startPlug: '#c90076',
        //     size: 1
        // });

        // // Empathy
        // const lines19 = connectToPoints(traitsRef7, [indicatorRef1, indicatorRef2, indicatorRef3], {
        //     color: '#ceff00',
        //     startPlugColor: '#ceff00',
        //     endPlugColor: '#ceff00',
        //     startPlug: '#ceff00',
        //     size: 1
        // });


        // // Flexibility
        // const lines20 = connectToPoints(traitsRef8, [indicatorRef1, indicatorRef2, indicatorRef3], {
        //     color: '#3e8245',
        //     startPlugColor: '#3e8245',
        //     endPlugColor: '#3e8245',
        //     startPlug: '#3e8245',
        //     size: 1
        // });

        // const remmoveMultipleLines = (arr) => {
        //     for (let i = 0; i < arr.length; i++) {
        //         arr[i].remove();
        //     }
        // }

        return () => {
            onRemoveAllLinesFunc();
        };


        //     remmoveMultipleLines(lines13);
        //     remmoveMultipleLines(lines14);
        //     remmoveMultipleLines(lines15);
        //     remmoveMultipleLines(lines16);
        //     remmoveMultipleLines(lines17);
        //     remmoveMultipleLines(lines18);
        //     remmoveMultipleLines(lines19);
        //     remmoveMultipleLines(lines20);
        // };

    }, []);

    return (
        <Box mt="20px">
            <Grid templateColumns="1fr 1fr 1fr">
                <GridItem style={ { textAlign: "center" } }>
                    <Box>
                        <Heading size="md">Behavior Drivers</Heading>
                        <Heading size="md">(Common Factors)</Heading>
                        <Text color={COLORS.Primary} fontSize="14px" className="learn-more" onClick={() => behaviorsModal.onOpen()}>Learn more</Text>
                    </Box>
                    {definitions ? definitions.behaviors.map((b, i) => {
                        return (
                            <Box mt={i === 0 ? "30px" : "80px"} className="baloon" onMouseOver={() => onBehaviorMouseHover(b, i)} onMouseOut={() => onBehaviorMouseOut()} bgColor={"#fff"} borderRadius={"10px"} width={"200px"} ml="auto" mr="auto" height={"50px"} ref={behaviorRefs[i]}>
                                
                                <Tooltip label={(b.name ? BEHAVIORS_DESC[b.name.toLowerCase()] : null)}>
                                    <Box pt="10px">{b.name}</Box>
                                </Tooltip>
                            </Box>
                        )
                    }) : null}
                </GridItem>
                <GridItem style={ { textAlign: "center" } }>
                    <Box>
                        <Heading size="md">Indicators</Heading>
                        <Heading size="md">(Measures)</Heading>
                        <Text color={COLORS.Primary} fontSize="14px" className="learn-more" onClick={() => indicatorsModal.onOpen()}>Learn more</Text>
                    </Box>
                    {definitions ? definitions.indicators.map((t, i) => {
                        return (
                            <Box borderRadius={"50px"} 
                                width={t.description !== '' ? "200px" : "50px"} 
                                ml="auto" mr="auto" height={"40px"} 
                                mt={i === 0 ? "20px" : i === 1 ? "130px" : "200px"} 
                                pt="8px" r
                                ref={indicatorsRefs[i]}>
                                {t.indicator}{t.name} {t.description}
                            </Box>
                        )
                    }) : null}
                </GridItem>
                <GridItem style={ { textAlign: "center" } }>
                    <Box>
                        <Heading size="md">Traits</Heading>
                        <Heading size="md">(Unique Factors)</Heading>
                        <Text color={COLORS.Primary} fontSize="14px" className="learn-more" onClick={() => traitsModal.onOpen()}>Learn more</Text>
                    </Box>
                    {definitions ? definitions.traits.map((t, i) => {
                        return (
                            <Box 
                                bgColor={"#fff"} 
                                borderRadius={"150px"} 
                                width={"170px"} 
                                ml="auto" 
                                mr="auto" 
                                mt={i === 0 ? "20px" : "18px"} 
                                height={"40px"} 
                                ref={traitsRefs[i]}
                                className="baloon"
                                onMouseOver={() => onTraitMouseHover(t, i)}
                                onMouseOut={onTraitMouseOut}
                            >
                                <Tooltip label={(t.name ? TRAITS_DESC[t.name.toLowerCase()] : null)}>
                                    <Box pt="7px">{t.name}</Box>
                                </Tooltip>
                            </Box>
                        )
                    }) : null}

                    <BehaviorsModal 
                        isOpen={behaviorsModal.isOpen}
                        onClose={behaviorsModal.onClose}
                        behaviorDrivers={definitions.behaviors}
                    />
                    <IndicatorsModal 
                        isOpen={indicatorsModal.isOpen} 
                        onClose={indicatorsModal.onClose}
                        indicators={definitions.indicators}
                    />
                    <TraitsModal 
                        isOpen={traitsModal.isOpen} 
                        onClose={traitsModal.onClose}
                        traits={definitions.traits}
                    />
                </GridItem>
            </Grid>
        </Box>
    )
}

export default Arrows;