// Chakra imports
import { Box, Flex } from "@chakra-ui/react";
import PropTypes from "prop-types";
import React from "react";
import Footer from "components/footer/FooterAuth";
// Custom components
import { NavLink } from "react-router-dom";
// Assets
import './Default.css';

function AuthIllustration(props) {
  const { children, illustrationBackground } = props;

  const welcomePage = () => {
    window.location.replace(`${process.env.REACT_APP_WELCOME_REDIRECT}`);
  }

  // Chakra color mode
  return (
    <Box position='relative' h='max-content' backgroundColor="#F5FAFE">
      <Flex
        h={{
          sm: "initial",
          md: "unset",
          lg: "100vh",
          xl: "97vh",
        }}
        w='100%'
        maxW={{ md: "66%", lg: "1313px" }}
        mx='auto'
        pt={{ sm: "0px", md: "0px" }}
        px={{ lg: "30px", xl: "0px" }}
        ps={{ xl: "70px" }}
        justifyContent='start'
        direction='column'>
        <NavLink
          onClick={() => welcomePage()}
          to="#"
          style={() => ({
            width: "fit-content",
          })}>
        </NavLink>
        <Box style={ { marginTop: '100px', justifyContent: 'center', alignContent: 'center' } }>
          <img src={illustrationBackground} alt="" className="logo" height={"200px"} width={"180px"} style={ { marginLeft: 'auto', marginRight: 'auto' } } onClick={() => welcomePage()} />
        </Box>
        <Box mt="40px">
          {children}
        </Box>
        <Footer />
      </Flex>
    </Box>
  );
}
// PROPS

AuthIllustration.propTypes = {
  illustrationBackground: PropTypes.string,
  image: PropTypes.any,
};

export default AuthIllustration;
