import { createSlice } from '@reduxjs/toolkit';
import { getAuthenticatedClient, getAnonymousClient, getFileClient } from '../../api';
import { setUserAuthenticated, setOrganizationLogo } from '../authentication/slice';
import { loading, finishLoading } from '../loader/slice';

export const userSlice = createSlice({
  name: 'user',
  initialState: {
      userId: '',
      totalCount: 0,
      totalPages: 0,
      pageIndex: 0,
      pageSize: 8,
      username: '',
      lastName: '',
      firstName: '',
      email: '',
      organizationName : '',
      organizationDomain: '',
      role: 0,
      teamMembers: [],
      inactiveTeamMembers: [],
      subscriptionType: -1,
      subscriptionFrequency: 1,
      subscriptionName: '',
      mustPay: false,
      mustPayEnterprise: false,
      numberOfPendingRegistrations: 0,
      numberOfPendingEvaluations: 0,
      mustPerformEvaluationOnboarding: false,
      hasSkipEvaluationOnboarding: false,
      isInPaymentProcess: true,
      loadingTeamMembers: false
  },
  reducers: {
    setUserProfile: (state, action) => {
        let { 
            id,
            username,
            lastName,
            firstName,
            role,
            email,
            organizationName,
            organizationDomain,
            subscriptionType,
            subscriptionFrequency,
            subscriptionName,
            numberOfTeamMembers,
            numberOfEvaluations,
            numberOfEmployeeIQEvaluations,
            numberOfOrgIQEvaluations,
            numberOfCareerIqEvaluations,
            numberOfBoardIQEvaluations,
            numberOfPendingEvaluations,
            numberOfPendingRegistrations,
            numberOfCompletedEvaluations,
            numberOfExjDiagnosticEvaluations,
            mustChooseSubscription,
            mustPayEnterprise,
            mustPerformEvaluationOnboarding
        } = action.payload;


        state.userId = id;
        state.username = username;
        state.firstName = firstName;
        state.lastName = lastName;
        state.email = email;
        state.preferredLanguage = 'en-US';
        state.role = role;
        state.organizationName = organizationName;
        state.subscriptionType = subscriptionType;
        state.subscriptionFrequency = subscriptionFrequency;
        state.subscriptionName = subscriptionName;
        state.mustPay = mustChooseSubscription;
        state.organizationDomain = organizationDomain;
        state.mustPayEnterprise = mustPayEnterprise;
        state.mustPerformEvaluationOnboarding = mustPerformEvaluationOnboarding;

        state.numberOfTeamMembers = numberOfTeamMembers;
        state.numberOfEvaluations = numberOfEvaluations;
        state.numberOfEmployeeIQEvaluations = numberOfEmployeeIQEvaluations;
        state.numberOfOrgIQEvaluations = numberOfOrgIQEvaluations;
        state.numberOfCareerIqEvaluations = numberOfCareerIqEvaluations;
        state.numberOfBoardIQEvaluations = numberOfBoardIQEvaluations;
        state.numberOfPendingEvaluations = numberOfPendingEvaluations;
        state.numberOfPendingRegistrations = numberOfPendingRegistrations;
        state.numberOfCompletedEvaluations = numberOfCompletedEvaluations;
        state.numberOfExjDiagnosticEvaluations = numberOfExjDiagnosticEvaluations;

        localStorage.setItem('language', 'en-US');
    },
    setTeamMembers: (state, action) => {
        state.teamMembers = action.payload;
    },
    setInactiveTeamMembers: (state, action) => {
        state.inactiveTeamMembers = action.payload;
    },
    setSkipEvaluationOnboarding: (state, action) => {
        state.hasSkipEvaluationOnboarding = action.payload
    },
    setPaymentProcessState: (state, action) => {
        state.isInPaymentProcess = action.payload;
    },
    setLoadingTeamMembers: (state, action) => {
        state.loadingTeamMembers = action.payload;
    }
  }
});

export const { 
    setUserProfile,
    setTeamMembers,
    setInactiveTeamMembers,
    setSkipEvaluationOnboarding,
    setPaymentProcessState,
    setLoadingTeamMembers
 } = userSlice.actions;

export const loadUserProfileAsync = (userId, onNextAction) => async dispatch => {
    if(userId === null) {
        let userFromStorage = localStorage.getItem('user');
        if (userFromStorage !== null) {
            let user = JSON.parse(userFromStorage); 
        
            if (user !== null && user.email !== '' && user.email !== undefined && user.email !== null) {
                userId = user.id;
                dispatch(setUserAuthenticated(user.id));
            }
        } 
    }
    if(userId !== null) {
        dispatch(loading());
        const client = await getAuthenticatedClient(dispatch);
        client.get(`v1/users/${userId}`)
            .then(res => {
                let user = res.data;
                dispatch(setUserProfile(user));
                dispatch(finishLoading());
                dispatch(setOrganizationLogo(user.organizationLogo));
                if(onNextAction){
                    onNextAction(user);
                }

            })
            .catch((error) => {
                dispatch(finishLoading());
            });
    }
};

export const updateOrganizationLogoAsync = ({ file, onSuccess }) => async dispatch => {

    let userFromStorage = localStorage.getItem('user');
    if (userFromStorage !== null) {
        dispatch(loading());

        let user = JSON.parse(userFromStorage); 

        const formData = new FormData();
        formData.append('file', file);
    
        const client = await getFileClient(dispatch);
        client.post(`v1/organizations/${user.organizationId}/logo`, formData)
            .then(res => {
                let blobUrl = res.data;

                dispatch(setOrganizationLogo(blobUrl));
                onSuccess();
                dispatch(finishLoading());
            })
            .catch((error) => {
                dispatch(finishLoading());
            });
    } 
};


export const updateOrganizationInformationAsync = ({ organizationInformation, onSuccess, onError }) => async dispatch => {

    let userFromStorage = localStorage.getItem('user');
    if (userFromStorage !== null) {
        dispatch(loading());

        let user = JSON.parse(userFromStorage); 
    
        const client = await getAuthenticatedClient(dispatch);
        client.put(`v1/organizations/${user.organizationId}`, organizationInformation)
            .then(res => {
                onSuccess();
                dispatch(finishLoading());
            })
            .catch((error) => {
                onError();
                dispatch(finishLoading());
            });
    } 
};


export const getOrganizationLogoAsync = ({ domain, onSuccess }) => async dispatch => {

    dispatch(loading());

    const client = await getAnonymousClient(dispatch);
    client.get(`v1/organizations/${domain}/logo`)
        .then(res => {
            let blobUrl = res.data;

            dispatch(setOrganizationLogo(blobUrl));
            onSuccess(blobUrl);
            dispatch(finishLoading());
        })
        .catch((error) => {
            dispatch(finishLoading());
        });
};

export const listOrganizationUsersAsync = (status, onSuccess) => async dispatch => {

    let userFromStorage = localStorage.getItem('user');
    if (userFromStorage !== null) {
        let user = JSON.parse(userFromStorage); 
        dispatch(setLoadingTeamMembers(true));
        dispatch(loading());
        const client = await getAuthenticatedClient(dispatch);
        client.get(`v1/users?organizationId=${user.organizationId}&status=${status}`)
            .then(res => {
                let teamMembers = res.data.data;

                dispatch(setTeamMembers(teamMembers));
                onSuccess();
                dispatch(finishLoading());
                dispatch(setLoadingTeamMembers(false));
            })
            .catch((error) => {
                dispatch(finishLoading());
                dispatch(setLoadingTeamMembers(false));
            });
    } 
};


export const listInnactiveUsersAsync = (onSuccess) => async dispatch => {

    let userFromStorage = localStorage.getItem('user');
    if (userFromStorage !== null) {
        let user = JSON.parse(userFromStorage); 
        
        dispatch(loading());
        const client = await getAuthenticatedClient(dispatch);
        client.get(`v1/users?organizationId=${user.organizationId}&status=inactive`)
            .then(res => {
                let teamMembers = res.data.data;

                dispatch(setInactiveTeamMembers(teamMembers));
                onSuccess();
                dispatch(finishLoading());
            })
            .catch((error) => {
                dispatch(finishLoading());
            });
    } 
};


export const addNewTeamMemberAsync = ({ firstName, lastName, email, role, onSuccess, onError }) => async dispatch => {

    dispatch(loading());
    const client = await getAuthenticatedClient(dispatch);
    client.post(`v1/users/invite`, {
        firstName,
        lastName,
        email,
        role
    }).then(res => {
        const invitation = res.data;
        onSuccess(invitation);
        dispatch(finishLoading());
    })
    .catch((error) => {
        dispatch(finishLoading());
        if(onError && error.response.data && error.response.data.description) {
          onError(error.response.data.description);
        }
    });
};


export const addMultipleTeamMembersAsync = ({ invitees, role, onSuccess, onError }) => async dispatch => {

    dispatch(loading());
    const client = await getAuthenticatedClient(dispatch);
    client.post(`v1/users/invites`, {
        invitees,
        role
    }).then(res => {
        const invitation = res.data;
        onSuccess(invitation);
        dispatch(finishLoading());
    })
    .catch((error) => {
        dispatch(finishLoading());
        if(onError && error.response.data && error.response.data.description) {
          onError(error.response.data.description);
        }
    });
};

export const onUpdateUserProfile = ({ id, firstName, lastName, email, organizationName, onSuccess, onError }) => async dispatch => {

    dispatch(loading());
    const client = await getAuthenticatedClient(dispatch);
    client.put(`v1/users/${id}/profile`, {
        firstName,
        lastName,
        email,
        organizationName
    }).then(res => {
        onSuccess();
        dispatch(finishLoading());
    })
    .catch((error) => {
        dispatch(finishLoading());
        if(onError && error.response.data && error.response.data.description) {
          onError(error.response.data.description);
        }
    });
};


export const onDeleteTeamMemberAsync = ({ id, onSuccess, onError }) => async dispatch => {

    let userFromStorage = localStorage.getItem('user');

    if (userFromStorage !== null) {

        let user = JSON.parse(userFromStorage); 

        dispatch(loading());
        const client = await getAuthenticatedClient(dispatch);
        client.delete(`v1/organizations/${user.organizationId}/member/${id}`).then(res => {
            onSuccess();
            dispatch(finishLoading());
        })
        .catch((error) => {
            dispatch(finishLoading());
            if(onError && error.response.data && error.response.data.description) {
              onError(error.response.data.description);
            }
        });
    }
};




export default userSlice.reducer;
