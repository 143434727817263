import { createSlice } from '@reduxjs/toolkit';
import { getAnonymousClientForm } from '../../api';
import { loading, finishLoading } from '../loader/slice';

export const subscriptionsSlice = createSlice({
    name: 'subscriptions',
    initialState: {
      subscriptions: []
    },
    reducers: {
      setSubscriptions: (state, action) => {
        state.subscriptions = action.payload;
      }
    }
});

export const { 
  setSubscriptions 
} = subscriptionsSlice.actions;

export function loadSubscriptions(action) {
  const { onSuccess, onError } = action;
  
  return async function loadSubscriptionsAsync(dispatch, getState) {
      dispatch(loading());
      
      var client = await getAnonymousClientForm(dispatch);

      client.get(`v1/subscriptions`)
        .then(res => {
          let response = res.data;

          dispatch(setSubscriptions(response.data)) ;

          if(onSuccess){
            onSuccess();
          }

          dispatch(finishLoading());
      }).catch((error) => {
          dispatch(finishLoading());
          if(onError && error.response.data && error.response.data.description) {
            onError(error.response.data.description);
          }
      });
  }
}


export default subscriptionsSlice.reducer;
