import React from "react";
import Authenticated from "../authenticated";
import { connect } from "react-redux";
import './assessment.css';
import { useHistory } from "react-router-dom";
import OrganizationOnboardingView from "./views/OrganizationOnboardingView";

function Onboarding(props) {

  const history = useHistory();

  const onFinishOnboarding = (evaluation) => {
    history.push(`/app/assessment/${evaluation.id}`)
  }

  return (
    <Authenticated>
      <OrganizationOnboardingView 
        onFinishOnboarding={onFinishOnboarding}
      />
    </Authenticated>
  );
}


Onboarding.propTypes = {
};

Onboarding.defaultProps = {
}

function mapStateToProps(state) {

};

function mapDispatchToProps (dispatch) {
  return {
      dispatch
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Onboarding);