export const SURVEY_TYPE = { 
    OrgIq: 1,
    EmployeeIq: 2,
    BoardIq: 3,
    ExjDiagnostic: 4,
    CareerIQ: 5
}

export const SURVEY_TYPE_EXPLANATION = {
    1: 'By providing honest responses to this questionnaire, you will assist us in improving both the overall performance of your board and its working relationship with executive management. There are 96 questions in total. Please answer all of the questions. There is no time limit. Note that you should not think about your answers too deeply; try to follow your initial instincts regarding the questions.',
    2: 'By providing honest responses to this questionnaire, you will assist us in improving both the overall performance of your board and its working relationship with executive management. There are 96 questions in total. Please answer all of the questions. There is no time limit. Note that you should not think about your answers too deeply; try to follow your initial instincts regarding the questions.',
    3: 'By providing honest responses to this questionnaire, you will assist us in improving both the overall performance of your board and its working relationship with executive management. There are 96 questions in total. Please answer all of the questions. There is no time limit. Note that you should not think about your answers too deeply; try to follow your initial instincts regarding the questions.',
    4: 'By providing honest responses to this questionnaire, you will assist us in improving both the overall performance of your board and its working relationship with executive management. There are 96 questions in total. Please answer all of the questions. There is no time limit. Note that you should not think about your answers too deeply; try to follow your initial instincts regarding the questions.',
    5: 'By providing honest responses to this questionnaire, you will assist us in improving both the overall performance of your board and its working relationship with executive management. There are 96 questions in total. Please answer all of the questions. There is no time limit. Note that you should not think about your answers too deeply; try to follow your initial instincts regarding the questions.'
}

export const SURVEY_TYPE_SUMMARY = {
    1: 'Assessment of Relevant Organizational Aspects',
    2: 'Peer Assessment of Work-life Relevant Personality Aspects',
    3: 'Assessment of Corporate Governance Aspects',
    4: 'Assessment of Work-life Relevant Personality Aspects',
    5: 'Self-assessment of Work-life Relevant Personality Aspects'
}

export const SURVEY_TYPE_INTRO = [
    'N/A',
    `Org IQ: By providing honest responses to this questionnaire, you will assist us in uncovering the economic health of of your organization's vital functions . There are 96 questions in total. Please answer all of the questions. There is no time limit. Note that you should not think about your answers too deeply; try to follow your initial instincts regarding the questions.`,
    'Employee IQ: By providing honest responses to this questionnaire, you will assist us in uncovering the organizational style of an average employee of your organization. There are 96 questions in total. Please answer all of the questions. There is no time limit. Note that you should not think about your answers too deeply; try to follow your initial instincts regarding the questions.',
    'Board IQ: By providing honest responses to this questionnaire, you will assist us in improving both the overall performance of your board and its working relationship with executive management. There are 96 questions in total. Please answer all of the questions. There is no time limit. Note that you should not think about your answers too deeply; try to follow your initial instincts regarding the questions.',
    '',
    'Career IQ: Your candid answers to this questionnaire will help us match your specific needs and traits with the requirements of your desired jobs. The personal information will be coded and remain confidential, and the responses will be strictly confidential. The questions marked with an asterisk (*) are mandatory.'
]