// Chakra imports
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Button
  } from '@chakra-ui/react'
import React from "react";
  
export default function ConfirmationDialog(props) {

    const { 
        isOpen, 
        onClose, 
        onConfirm, 
        title, 
        description,
        confirmText,
        cancelText
    } = props;

    return (
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{title}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {description}
          </ModalBody>

          <ModalFooter>
            <Button onClick={onClose} mr={3}>
              {cancelText}
            </Button>
            <Button colorScheme='blue'  onClick={onConfirm}>{confirmText}</Button>
          </ModalFooter>
        </ModalContent>
    </Modal>
    )
}