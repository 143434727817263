/*!
  _   _  ___  ____  ___ ________  _   _   _   _ ___   
 | | | |/ _ \|  _ \|_ _|__  / _ \| \ | | | | | |_ _| 
 | |_| | | | | |_) || |  / / | | |  \| | | | | || | 
 |  _  | |_| |  _ < | | / /| |_| | |\  | | |_| || |
 |_| |_|\___/|_| \_\___/____\___/|_| \_|  \___/|___|
                                                                                                                                                                                                                                                                                                                                       
=========================================================
* Horizon UI - v1.1.0
=========================================================

* Product Page: https://www.horizon-ui.com/
* Copyright 2022 Horizon UI (https://www.horizon-ui.com/)

* Designed and Coded by Simmmple

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// Chakra imports
import { Box, Button, useToast, Heading, Text, Grid, GridItem, IconButton, Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, ModalFooter, Tooltip } from "@chakra-ui/react";
import Card from "components/card/Card";
import PropTypes from 'prop-types';
import { connect } from "react-redux";
import { getUserSurveys } from "features/userSurveys/slice";
import { useEffect, useState } from "react";
import PendingEvaluationBanner from "components/evaluations/pendingEvaluationBanner/PendingEvaluationBanner";
import { COLORS } from "constants/colors";
import modalImgCheck from '../../assets/img/modal/check.svg';
import { getIndividualMetrics } from "features/metrics/slice";
import { onDeleteTeamMemberAsync } from "features/user/slice";
import './MembersManagementMemberView.css';
import { IoTrashBin } from "react-icons/io5";
import { ResultPanel } from "components/results/ResultPanel";
import { createEvaluation } from "features/surveys/slice";
import { DownloadIcon } from "@chakra-ui/icons";
import { TailSpin } from "react-loader-spinner";
import { generateReport } from "features/reports/slice";
import { SURVEY_TYPE_DESCRIPTION } from "constants/surveyTypes.const";
import { SURVEY_TYPE } from "constants/evaluation.const";

function MembersManagementMemberView({ 
    member, 
    userSurveys, 
    onLoadUserSurveys, 
    onLoadUserMetrics,
    onReinviteUser,
    onDeleteTeamMember,
    onCreateNewEvaluation,
    onClose,
    employeeIQ,
    boardIQ,
    orgIQ,
    careerIQ,
    subscriptionType,
    role,
    userId,
    adminMode,
    isDownloading,
    onDownloadReport
 }) {

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [evaluationTabIndex, setEvaluationTabIndex] = useState(0);
    const [evaluationTypeIndex, setEvaluationTypeIndex] = useState(0);
    const evaluationTypes = [];

    const toast = useToast();

    if (employeeIQ !== null && employeeIQ.length > 0) {
        evaluationTypes.push(employeeIQ);
    }

    if (boardIQ !== null && boardIQ.length > 0) {
        evaluationTypes.push(boardIQ);
    }

    if (orgIQ !== null && orgIQ.length > 0) {
        evaluationTypes.push(orgIQ);
    }
    if (careerIQ !== null && careerIQ.length > 0) {
        evaluationTypes.push(careerIQ);
    }

    useEffect(() => {
        if(member && member.id !== ''){
            onLoadUserSurveys(member.id, 'Pending', () => { }, () => { });
            onLoadUserMetrics(member.id, () => { });
        }
    }, []);

    const onClickToManagePendingEvaluation = () => {

    }

    const onClickInvite = (id) => {

        onReinviteUser(id, () => {
            toast({
                title: 'Success',
                description: `Invitation sent.`,
                status: 'success',
                duration: 9000,
                isClosable: true
              });
              setIsModalOpen(true);
        }, (error) => {
            toast({
                title: 'Error',
                description: `Unable to resend the invitation.`,
                status: 'error',
                duration: 9000,
                isClosable: true
              });
              setIsModalOpen(true);
        })
    }

    const onClickToDeleteUser = () => {
        setIsDeleteModalOpen(true);
    }

    const onConfirmUserDeletion = () => {
        onDeleteTeamMember(member.id, () => {
            toast({
                title: 'Success',
                description: `${member.firstName} ${member.lastName} successfully deleted.`,
                status: 'success',
                duration: 9000,
                isClosable: true
              });
              setIsDeleteModalOpen(false);
              onClose();
        }, () => {
            toast({
                title: 'Error',
                description: `User could not bedeleted. Please contact support.`,
                status: 'error',
                duration: 9000,
                isClosable: true
              });
              setIsDeleteModalOpen(false);
        })
    }

    const surveyTypeMapping = {
        0: SURVEY_TYPE.EmployeeIq,
        1: SURVEY_TYPE.BoardIq,
        2: SURVEY_TYPE.OrgIq,
        3: SURVEY_TYPE.CareerIQ,
        4: SURVEY_TYPE.ExjDiagnostic
    }

    const onClickDownload = (reportOnly, evaluationTypeIndex, evaluationTabIndex) => {

        onDownloadReport(reportOnly, evaluationTypes[evaluationTypeIndex][evaluationTabIndex].evaluationId, (blob) => {
            if(reportOnly === false) { 
                // Create a URL for the Blob
                const url = window.URL.createObjectURL(blob);
                // Create a link element and trigger a download
                const link = document.createElement('a');
                link.href = url;
                link.download = `${SURVEY_TYPE_DESCRIPTION[surveyTypeMapping[evaluationTypeIndex]]}.pdf`;
                document.body.appendChild(link);
                
                link.click();
            
                // Clean up
                document.body.removeChild(link);
                window.URL.revokeObjectURL(url);
            }
        });
    }

    return (<Box pl="20px">
        {adminMode === true ? <Grid templateColumns={"50% 50%"} mt="15px" gap={0}>
            <Box mr="10px">
                <Box display={"flex"}>
                    <Heading size="lg">Team Members</Heading>
                    <Text ml="15px" color={COLORS.Primary} className="hover" mt="10px" onClick={() => onClose()} fontSize={"md"}>Return to Main Dashboard</Text>
                </Box>
                <Card height="136px" mt="15px">
                    <Grid templateColumns={"1fr 2fr 1fr"} ml="10px">
                        <GridItem>
                            <Box mt="20px">
                                <div style={{ width: "60px", height: "60px", textAlign: 'center', backgroundColor: "#0095FF1A", color: "#0095ff", borderRadius: "50%" }} >
                                    <Heading size="md" style={{ paddingTop: "16px" }}>
                                    {`${member.firstName.substring(0, 1)} ${member.lastName.substring(0, 1)}`}
                                    </Heading>
                                </div>
                            </Box>
                        </GridItem>
                        <GridItem>
                            <Box style={ { textWrap: "wrap"}} mt="30px" ml="10px">
                                <Box ml="10px">
                                    <Heading size="xs">{member.firstName} {member.lastName}</Heading>
                                    <Box style={ { overflowWrap: 'wrap', width: '200px' } }>
                                        <Text className="evaluation-text-description" 
                                        fontSize="xs"
                                        style={{ color: "#0000004D" }} 
                                        >
                                        {member.email}
                                        </Text>
                                    </Box>
                                </Box>
                            </Box>
                        </GridItem>
                        <GridItem>
                           <Box >
                                <IconButton 
                                    size='sm' 
                                    isRound={true} 
                                    onClick={onClickToDeleteUser}
                                    icon={<IoTrashBin />} 
                                    color="red" 
                                    bgColor={"#F6191933"} 
                                    mt="30px" 
                                    mr="40px" />
                            </Box>
                        </GridItem>
                    </Grid>
                </Card>
            </Box>
        </Grid> : null}
        {adminMode === true ? <Box mt="60px">
            <Heading>Pending Evaluation</Heading>
            <Grid templateColumns="1fr 1fr" columnGap={"15px"} mt="15px">
                    {userSurveys && userSurveys.length > 0 ? userSurveys.map((s, i) => {
                        return <Box id="peding-ev-ban">
                            <PendingEvaluationBanner
                                userSurveyResponseId={s.id}
                                type={s.surveyType}
                                date={s.createDate}
                                updateDate={s.updateDate}
                                status={s.status}
                                onClickToManagePendingEvaluation={ () => onClickToManagePendingEvaluation(s.surveyType)}
                                withButton={true}
                                buttonText="Invite"
                                onButtonClick={() => onClickInvite(s.id)}
                            />
                            </Box>
                }) : <Box>
                    <Text fontSize={"sm"}>No pending evaluations</Text>
                </Box>}
            </Grid>
        </Box> : null}
        <Box mt="60px">
            <Box>
                <Grid templateColumns={"1fr 1fr"}>
                    <GridItem>
                        <Heading>Results</Heading>
                    </GridItem>
                    {evaluationTypes.length > 0 && evaluationTypes[evaluationTypeIndex][evaluationTabIndex] ? <GridItem textAlign="right">
                        {/* download function */}
                        {isDownloading ? <Box float="right" mr="30px"><TailSpin
                            height="30"
                            width="30"
                            color="#4094E4"
                            ariaLabel="tail-spin-loading"
                            radius="1"
                            float="right"
                            wrapperStyle={{}}
                            wrapperClass=""
                            visible={true}
                            /> </Box>: <Tooltip label="Download Report">
                            <IconButton 
                                icon={<DownloadIcon />}
                                aria-label='Download'
                                mr="30px"
                                colorScheme="blue"
                                onClick={() => onClickDownload(false, evaluationTypeIndex, evaluationTabIndex)}
                            />
                        </Tooltip>}
                        {/* end download function */}
                    </GridItem> : null}
                </Grid>
            </Box>
            <ResultPanel 
                employeeIQ={employeeIQ}
                boardIQ={boardIQ}
                orgIQ={orgIQ}
                careerIQ={careerIQ}
                exjDiagnostic={null}
                subscriptionType={subscriptionType}
                userIdSelectedOnNavBar={null}
                isSelectingUserOnNavBar={false}
                hasSelectedAllInTheNavBar={() => false}
                onCreateNewEvaluation={onCreateNewEvaluation}
                onLoadIndividualMetrics={onLoadUserMetrics}
                userId={userId}
                role={role}
                showEvaluationsActionBar={false}
                onChangeEvaluationTypeIndex={(index) => setEvaluationTypeIndex(index)}
                onChangeEvaluationTabIndex={(index) => setEvaluationTabIndex(index)}
            />
        </Box>
        <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>
                    <img src={modalImgCheck} alt="" style={ { left: "45%", right: "45%", position: 'relative'}} />
                </ModalHeader>
                <ModalBody textAlign={"center"}>
                    <Heading size="sm">Invitation has been sent</Heading>
                    <Text mt="20px">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor</Text>
                </ModalBody>

                <ModalFooter justifyContent={"center"}>
                    <Button 
                        style={ { backgroundColor: COLORS.Primary, color: COLORS.PrimaryText } } 
                        mr={3} 
                        onClick={() => setIsModalOpen(false)}>
                        Close
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
        <Modal isOpen={isDeleteModalOpen} onClose={() => setIsDeleteModalOpen(false)}>
            <ModalOverlay />
            <ModalContent>
                <ModalBody textAlign={"center"}>
                    <Heading size="sm" mt="30px">Are you sure you want to remove “username”?</Heading>
                    <Text mt="30px">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor</Text>
                </ModalBody>

                <ModalFooter justifyContent={"center"}>
                    <Button 
                        style={ { backgroundColor: COLORS.Primary, color: COLORS.PrimaryText } } 
                        mr={3} 
                        onClick={() => setIsDeleteModalOpen(false)}>
                        Close
                    </Button>
                    <Button 
                        color={COLORS.Primary}
                        mr={3} 
                        onClick={onConfirmUserDeletion}>
                        Remove
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    </Box>
    );
}

MembersManagementMemberView.propTypes = {
    isDownloading: PropTypes.bool,
    onDownloadReport: PropTypes.func,
    onLoadUserSurveys: PropTypes.func,
    onLoadUserMetrics: PropTypes.func,
    onDeleteTeamMember: PropTypes.func
};

MembersManagementMemberView.defaultProps = {
    isDownloading: false,
    onDownloadReport: () => { },
    onLoadUserSurveys: () => { },
    onLoadUserMetrics: () => { },
    onDeleteTeamMember: () => { },
    onCreateNewEvaluation: () => { }
}
  
function mapStateToProps(state) {

    const { userSurveys } = state.userSurveys;

    const { employeeIQ, boardIQ, orgIQ, careerIQ } = state.metrics.individual;

    const { subscriptionType, role, userId } = state.user;

    const { isDownloading } = state.reports;

    return {
        userSurveys,
        employeeIQ, 
        boardIQ, 
        orgIQ, 
        careerIQ,
        subscriptionType,
        role,
        userId,
        isDownloading
    };
  };
  
function mapDispatchToProps (dispatch) {
    return {
        onLoadUserSurveys: (userId, status, onSuccess, onError ) => dispatch(getUserSurveys({ userId, status, onSuccess, onError })),
        onLoadUserMetrics: (userId, onSuccess) => dispatch(getIndividualMetrics({ userId, onSuccess })),
        onDeleteTeamMember: (id, onSuccess, onError) =>  dispatch(onDeleteTeamMemberAsync({ id, onSuccess, onError })),
        onCreateNewEvaluation: (surveyType, onSuccess, onError) => dispatch(createEvaluation(surveyType, onSuccess, onError)),
        onDownloadReport: (reportOnly, evaluationId, onSuccess) => dispatch(generateReport({ reportOnly, evaluationId, onSuccess })),
        dispatch
    }
  }
  
  export default connect(mapStateToProps, mapDispatchToProps)(MembersManagementMemberView);