/* eslint-disable */
import  { 
  Modal, 
  ModalOverlay, 
  ModalContent, 
  ModalBody, 
  FormControl, 
  ModalHeader,
  ModalCloseButton,
  FormLabel,
  Input,
  ModalFooter,
  Button
} from "@chakra-ui/react";

// Custom components
import React from "react";

export default function EditMember(props) {
  
    const initialRef = React.useRef(null);
    const finalRef = React.useRef(null);

    const [firstName, onChangeFirstName] = React.useState(props.firstName);
    const [lastName, onChangeLastName] = React.useState(props.lastName);
    const [email, onChangeEmail] = React.useState(props.email);

    const onSubmit = (e) => {
      e.preventDefault();

      props.onUpdateMember(firstName, lastName, email);
    }
  
    return (
        <Modal
          initialFocusRef={initialRef}
          finalFocusRef={finalRef}
          isOpen={props.isOpen}
          onClose={props.onClose}
        >
          <ModalOverlay />
          <ModalContent>
            <form onSubmit={onSubmit}>
              <ModalHeader>Update User</ModalHeader>
              <ModalCloseButton />
              <ModalBody pb={6}>
                    <FormControl>
                      <FormLabel>First name</FormLabel>
                      <Input value={firstName} onChange={(e) => onChangeFirstName(e.target.value)} required ref={initialRef} placeholder='First name' />
                    </FormControl>
        
                    <FormControl mt={4}>
                      <FormLabel>Last name</FormLabel>
                      <Input value={lastName} onChange={(e) => onChangeLastName(e.target.value)} required placeholder='Last name' />
                    </FormControl>

                    <FormControl mt={4}>
                      <FormLabel>E-mail</FormLabel>
                      <Input value={email} onChange={(e) => onChangeEmail(e.target.value)} required placeholder='E-mail' />
                    </FormControl>
                  </ModalBody>
        
                  <ModalFooter>
                    <Button colorScheme='blue' type="submit" mr={3}>
                      Save
                    </Button>
                    <Button onClick={props.onClose}>Cancel</Button>
                  </ModalFooter>
              </form>
          </ModalContent>
        </Modal>
    )
  }