/* eslint-disable */
/*!
  _   _  ___  ____  ___ ________  _   _   _   _ ___   
 | | | |/ _ \|  _ \|_ _|__  / _ \| \ | | | | | |_ _| 
 | |_| | | | | |_) || |  / / | | |  \| | | | | || | 
 |  _  | |_| |  _ < | | / /| |_| | |\  | | |_| || |
 |_| |_|\___/|_| \_\___/____\___/|_| \_|  \___/|___|
                                                                                                                                                                                                                                                                                                                                       
=========================================================
* Horizon UI - v1.1.0
=========================================================

* Product Page: https://www.horizon-ui.com/
* Copyright 2022 Horizon UI (https://www.horizon-ui.com/)

* Designed and Coded by Simmmple

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

import React, { useEffect } from "react";
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useHistory } from "react-router-dom";
// Chakra imports
import {
  Box,
  Flex,
  useColorModeValue,
  useToast,
} from "@chakra-ui/react";
// Custom components
import DefaultAuth from "layouts/auth/Default";
// Assets
import illustration from "assets/img/logo/logo.png";
import { loginWithSession } from "features/authentication/slice";

function Session(props) {

  const { onSignInWithSession, organizationLogo, location } = props;
  const logoImage = organizationLogo !== null && organizationLogo !== '' ? organizationLogo : illustration;

  const history = useHistory();

  const toast = useToast();

  useEffect(() => {

    const query = new URLSearchParams(location.search);
    const session = query.get('id');
    
    const onLogginSucceed = (url) => {
      window.location.replace(url);
    }

    const onLoginError = (msg) => {
      toast({
        title: 'Login Failed',
        description: msg,
        status: 'error',
        duration: 9000,
        isClosable: true
      })
      history.push('/auth/sign-in');
    }

    onSignInWithSession(session, onLogginSucceed, onLoginError);
  }, []);

  return (
   <DefaultAuth illustrationBackground={logoImage} image={logoImage}>
      
      <Flex
        maxW={{ base: "100%", md: "max-content" }}
        w='100%'
        mx={{ base: "auto", lg: "0px" }}
        me='auto'
        h='100%'
        alignItems='start'
        justifyContent='center'
        mb={{ base: "30px", md: "60px" }}
        px={{ base: "25px", md: "0px" }}
        flexDirection='column'>
        <Box me='auto'
          mt={{ base: "0px", xl: "30px", md: "60px" }}
        >
        </Box>
      </Flex>
    </DefaultAuth>
  );
}

Session.propTypes = {
  onSignInWithSession: PropTypes.func,
  organizationLogo: PropTypes.string
};

Session.defaultProps = {
  onSignInWithSession: () => {},
  organizationLogo: ''
}

function mapStateToProps(state) {
  const { authentication } = state;

  const { organizationLogo } = authentication;
  
  return {
    organizationLogo
   };
};

function mapDispatchToProps (dispatch) {
  return {
      onSignInWithSession: (session, onLogginSucceed, onLoginError) => dispatch(loginWithSession({ session, onLogginSucceed, onLoginError })),
      dispatch
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Session);