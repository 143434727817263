import { createSlice } from '@reduxjs/toolkit';
import { getAuthenticatedClient } from '../../api';
import { loading, finishLoading } from '../loader/slice';

export const paymentsSlice = createSlice({
    name: 'payments',
    initialState: {
      payments: [],
      isUpgradeModalOpen: false
    },
    reducers: {
      setPayment: (state, action) => {
        state.payments = action.payload;
      },
      setIsUpgradeModalOpen: (state, action) => {
        state.isUpgradeModalOpen = action.payload;
      },
    }
});

export const { 
  setPayment,
  setIsUpgradeModalOpen
} = paymentsSlice.actions;

export function createEnterprisePaymentSession(action) {
  const { onSuccess, onError } = action;
  
  return async function createEnterprisePaymentSessionasync(dispatch, getState) {
      dispatch(loading());
      
      var client = await getAuthenticatedClient(dispatch);

      client.post(`v1/payments/enterprise/customer`).then(res => {
          let response = res.data;

          if(onSuccess){
            onSuccess(response);
          }

          dispatch(finishLoading());
      }).catch((error) => {
          dispatch(finishLoading());
          if(onError && error.response.data && error.response.data.description) {
            onError(error.response.data.description);
          }
      });
  }
}

export function createPaymentSession(action) {
  const { subscriptionType, subscriptionFrequency, onSuccess, onError } = action;
  
  return async function createPaymentSessionAsync(dispatch, getState) {
      dispatch(loading());
      
      var client = await getAuthenticatedClient(dispatch);

      client.post(`v1/payments/checkout`, {
        subscriptionType,
        subscriptionFrequency
      }).then(res => {
          let response = res.data;

          if(onSuccess){
            onSuccess(response);
          }

          dispatch(finishLoading());
      }).catch((error) => {
          dispatch(finishLoading());
          if(onError && error.response.data && error.response.data.description) {
            onError(error.response.data.description);
          }
      });
  }
}


export function createPaymentOnboarding(action) {
  const { onSuccess, onError } = action;
  
  return async function createPaymentOnboardingnAsync(dispatch, getState) {
      dispatch(loading());
      
      var client = await getAuthenticatedClient(dispatch);

      client.post(`v1/payments/onboarding`).then(res => {
          let response = res.data;

          if(onSuccess){
            onSuccess(response);
          }
      }).catch((error) => {
          dispatch(finishLoading());
          if(onError && error.response.data && error.response.data.description) {
            onError(error.response.data.description);
          }
      });
  }
}

export default paymentsSlice.reducer;
