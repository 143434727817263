/* eslint-disable */
import  { 
  Box,
  Button,
  Text, 
  Heading,
  useToast,
  Grid,
  GridItem,
  FormControl,
  FormLabel,
  Input,
  InputRightElement,
  InputGroup
} from "@chakra-ui/react";
import _ from "lodash";
import './UpgradePlan.css';

import PropTypes from 'prop-types';
import { connect } from "react-redux";
// Custom components
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import Authenticated from "../authenticated";
import { COLORS } from "constants/colors";

import './Settings.css';
import { onUpdateUserProfile } from "features/user/slice";

function Settings(props) {
    const history = useHistory();

    const toast = useToast();
    
    const [firstName, setFirstName] = useState(props.firstName);
    const [lastName, setLastname] = useState(props.lastName);
    const [email, setEmail] = useState(props.email);
    const [organizationName, setOrganizationName] = useState(props.organizationName);

    useEffect(() => {
      setFirstName(props.firstName);
    }, [props.firstName]);

    useEffect(() => {
      setLastname(props.lastName);
    }, [props.lastName]);

    useEffect(() => {
      setEmail(props.email);
    }, [props.email]);

    useEffect(() => {
      setOrganizationName(props.organizationName);
    }, [props.organizationName]);

    const handleUpdate = (e) => {
      e.preventDefault();

      props.onUpdateTeamMember(props.userId, firstName, lastName, email, organizationName, () => {
        toast({
          description: 'User settings updated.',
          status: 'success',
          duration: 9000,
          isClosable: true
        });
      }, () => {
        toast({
          description: 'Unable to update User settings. Please contact support.',
          status: 'error',
          duration: 9000,
          isClosable: true
        });
      });
    }

    return (
        <Authenticated>
          <Box ml="20px" mr="30px" pb="400px" mt={8}> 
              <Box display={"flex"} ml="40px">
                <Heading size="md" pt="10px">Edit Profile</Heading>
                <Text fontSize="16px" ml="5px" pt="13px" color={COLORS.Primary}>My Profile</Text>
              </Box>
              <Box pr="100px" ml="40px">
                <form onSubmit={handleUpdate}>
                  <Grid gridColumns="1fr 1fr" gap={4} pr="50%" mt="20px">
                    <GridItem>
                      <FormControl position={"relative"}>
                        <FormLabel position={"absolute"} style={ { color: COLORS.Primary, zIndex: 9, marginLeft: '10px', fontSize: '10pt', marginTop: '2px' } }>First name</FormLabel>
                        <Input value={firstName} height={"60px"} onChange={(e) => setFirstName(e.target.value)} required bgColor={"#fff"} />
                      </FormControl>
                    </GridItem>
                    <GridItem>
                      <FormControl position={"relative"}>
                        <FormLabel position={"absolute"} style={ { color: COLORS.Primary, zIndex: 9, marginLeft: '10px', fontSize: '10pt', marginTop: '2px' } }>Last name</FormLabel>
                        <Input value={lastName} height={"60px"} onChange={(e) => setLastname(e.target.value)} required bgColor={"#fff"} />
                      </FormControl>
                    </GridItem>
                    <GridItem>
                      <FormControl position={"relative"}>
                        <FormLabel position={"absolute"} style={ { color: COLORS.Primary, zIndex: 9, marginLeft: '10px', fontSize: '10pt', marginTop: '2px' } }>E-mail</FormLabel>
                        <Input value={email} height={"60px"} onChange={(e) => setEmail(e.target.value)} required bgColor={"#fff"} />
                      </FormControl>
                    </GridItem>
                    <GridItem>
                      <FormControl position={"relative"}>
                        <FormLabel position={"absolute"} style={ { color: COLORS.Primary, zIndex: 9, marginLeft: '10px', fontSize: '10pt', marginTop: '2px' } }>Organization</FormLabel>
                        <Input value={organizationName} height={"60px"} onChange={(e) => setOrganizationName(e.target.value)} required bgColor={"#fff"}/>
                      </FormControl>
                    </GridItem>
                    <GridItem pos="relative">
                    <FormLabel position={"absolute"} style={ { color: COLORS.Primary, zIndex: 9, marginLeft: '10px', fontSize: '10pt', marginTop: '2px' } }>Password</FormLabel>
                      <InputGroup>
                        <Input value={"123456"} type="password" height={"60px"} 
                          required bgColor={"#fff"}
                          input
                          />
                          <InputRightElement>
                            <Text fontSize="14px" pt="14px" className="update-pwd" onClick={() => history.push('/admin/password')} mr="40px" color={COLORS.Primary}>CHANGE</Text>
                          </InputRightElement>
                      </InputGroup>
                    </GridItem>
                    <GridItem colSpan={2}>
                      <Button
                          w='140px'
                          mt="20px"
                          height={"50px"}
                          style={ { backgroundColor: COLORS.Primary, color: COLORS.PrimaryText } }
                          type="submit"
                          fontWeight='500'>
                          Save
                      </Button>
                    </GridItem>
                  </Grid>
                </form>
              </Box>
          </Box>
        </Authenticated>
    )
  }


Settings.propTypes = {
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  organizationName: PropTypes.string,
  email: PropTypes.string,
  role: PropTypes.number,
  onUpdateTeamMember: PropTypes.func
};

Settings.defaultProps = {
  firstName: 'First name',
  lastName: 'Last name',
  organizationName: 'Organization name',
  email: 'email',
  role: 0,
  onUpdateTeamMember: () => { }
}

function mapStateToProps(state) {

  const { 
    firstName, 
    lastName, 
    organizationName,
    email,
    role
  } = state.user;

  const { userId } = state.authentication;

  return {
    userId,
    firstName, 
    lastName, 
    role,
    organizationName,
    email
   };
};

function mapDispatchToProps (dispatch) {
  return {
      onUpdateTeamMember: (id, firstName, lastName, email, organizationName, onSuccess, onError) => dispatch(onUpdateUserProfile({ id, firstName, lastName, email, organizationName, onSuccess, onError })),
      dispatch
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Settings);