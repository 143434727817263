/* eslint-disable */
import  { 
    Box,
    Button,
    Text, 
    Heading,
    useToast,
    Grid,
    GridItem,
    FormControl,
    FormLabel,
    Input
  } from "@chakra-ui/react";
import _ from "lodash";
import './UpgradePlan.css';
import { connect } from "react-redux";
// Custom components
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import Authenticated from "../authenticated";
import { COLORS } from "constants/colors";
import './Settings.css';
import { recoverPassword } from "features/authentication/slice";
  
function Password(props) {
      const history = useHistory();
  
      const toast = useToast();
      
      const [currentPassword, setCurrentPassword] = useState(props.firstName);
      const [password, setPassword] = useState(props.firstName);
      const [confirm, setConfirm] = useState(props.lastName);
  
      const handleUpdate = (e) => {
        e.preventDefault();
        
        if(password === confirm) {
            props.onRecoverPassword(null, currentPassword, password, (redirectUrl) => {
                toast({
                  description: 'Password updated.',
                  status: 'success',
                  duration: 9000,
                  isClosable: true
                });
                history.push('/admin/profile');
              }, (msg) => {
                toast({
                  description: msg,
                  status: 'error',
                  duration: 9000,
                  isClosable: true
                })
              });
        } else {
            toast({
                description: `'New password' and 'Retype password' don't match.`,
                status: 'error',
                duration: 9000,
                isClosable: true
              })
        }
      }
  
      return (
          <Authenticated>
            <Box ml="20px" mr="30px" pb="400px" mt={8}> 
                <Box display={"flex"} ml="40px">
                  <Heading size="md" pt="10px">Change Password</Heading>
                  <Text fontSize="16px" ml="5px" pt="13px" color={COLORS.Primary}>Edit Profile</Text>
                </Box>
                <Box pr="100px" ml="40px">
                  <form onSubmit={handleUpdate}>
                    <Grid gridColumns="1fr" gap={4} pr="70%" mt="20px">
                      <GridItem>
                        <FormControl position={"relative"}>
                          <FormLabel position={"absolute"} style={ { color: COLORS.Primary, zIndex: 9, marginLeft: '10px', fontSize: '10pt', marginTop: '2px' } }>Current Password</FormLabel>
                          <Input value={currentPassword} type="password" height={"60px"} onChange={(e) => setCurrentPassword(e.target.value)} required bgColor={"#fff"} />
                        </FormControl>
                      </GridItem>
                      <GridItem>
                        <FormControl position={"relative"}>
                          <FormLabel position={"absolute"} style={ { color: COLORS.Primary, zIndex: 9, marginLeft: '10px', fontSize: '10pt', marginTop: '2px' } }>New Password</FormLabel>
                          <Input value={password} type="password" height={"60px"} onChange={(e) => setPassword(e.target.value)} required bgColor={"#fff"} />
                        </FormControl>
                      </GridItem>
                      <GridItem>
                        <FormControl position={"relative"}>
                          <FormLabel position={"absolute"} style={ { color: COLORS.Primary, zIndex: 9, marginLeft: '10px', fontSize: '10pt', marginTop: '2px' } }>Retype New Password</FormLabel>
                          <Input value={confirm} type="password" 
                            height={"60px"} onChange={(e) => setConfirm(e.target.value)} 
                            required 
                            bgColor={"#fff"} 
                            style={{ border: password !== confirm ? "1px solid red" : null } }
                        />
                        </FormControl>
                      </GridItem>
                      <GridItem>
                        <Button
                            w='140px'
                            mt="20px"
                            height={"50px"}
                            style={ { backgroundColor: COLORS.Primary, color: COLORS.PrimaryText } }
                            type="submit"
                            fontWeight='500'>
                            Save
                        </Button>
                        <Button
                            w='140px'
                            mt="20px"
                            ml="20px"
                            height={"50px"}
                            style={ { backgroundColor: COLORS.PrimaryText, color: COLORS.Primary } }
                            onClick={() => history.push('/admin/profile')}
                            fontWeight='500'>
                            Cancel
                        </Button>
                      </GridItem>
                    </Grid>
                  </form>
                </Box>
            </Box>
          </Authenticated>
      )
    }
  
  
  Password.propTypes = {
  };
  
  Password.defaultProps = {
  }
  
  function mapStateToProps(state) {
    return {
     };
  };
  
  function mapDispatchToProps (dispatch) {
    return {
        onRecoverPassword: (token, currentPassword, password, onSuccess, onError) => dispatch(recoverPassword({ token, currentPassword, password, onSuccess, onError })),
        dispatch
    }
  }
  
  export default connect(mapStateToProps, mapDispatchToProps)(Password);