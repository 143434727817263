// Chakra imports
import { Avatar, Box, SimpleGrid, Text, useColorModeValue } from "@chakra-ui/react";
import Card from "components/card/Card.js";
import React from "react";
import './Banner.css';
import { SUBSCRIPTION_TYPE } from "constants/subscriptionTypes.const";
import { USER_ROLE } from "constants/userRoles.const";

export default function Banner(props) {
  const { 
    banner, 
    avatar, 
    name, 
    job, 
    teamMembers, 
    surveyEvaluations, 
    organizationName,
    numberOfBoardIqEvaluations,
    numberOfEmployeeIqEvaluations,
    numberOfOrgIqEvaluations,
    subscriptionType,
    role
  } = props;
  // Chakra Color Mode
  const borderColor = useColorModeValue(
    "white !important",
    "#111C44 !important"
  );
  return (
    <Card mb={{ base: "0px", lg: "20px" }} className="banner-background" align='center'>
      <Box
        bg={`url(${banner})`}
        bgSize='cover'
        borderRadius='16px'
        h='131px'
        w='100%'
      />
      <Avatar
        mx='auto'
        src={avatar}
        h='87px'
        w='87px'
        mt='-43px'
        border='4px solid'
        borderColor={borderColor}
      />
      <Text fontWeight='bold' fontSize='xl' mt='10px'>
        {name}
      </Text>
      <Text fontSize='xl' mt='10px'>
        {organizationName}
      </Text>
      <Text fontSize='sm'>
        {job}
      </Text>
      {subscriptionType !== SUBSCRIPTION_TYPE.Enterprise || role === USER_ROLE.Admin ? <SimpleGrid columns={{sm: 1, md: 2, lg: 2}} mt={10}>
          <Box>
            <Text fontSize='2xl' fontWeight='700'>
              {teamMembers}
            </Text>
            <Text fontSize='sm' fontWeight='400'>
              Members
            </Text>
          </Box>
          <Box>
            <Text fontSize='2xl' fontWeight='700'>
              {surveyEvaluations}
            </Text>
            <Text fontSize='sm' fontWeight='400'>
              Evaluations
            </Text>
          </Box>
        </SimpleGrid> : null}
        {subscriptionType !== SUBSCRIPTION_TYPE.Enterprise ? <SimpleGrid columns={{sm: 1, md: 3, lg: 3}} mt={10}>
          <Box align='center'>
              <Text fontSize='1xl' fontWeight='700'>
                {numberOfEmployeeIqEvaluations}
              </Text>
              <Text fontSize='sm' fontWeight='400'>
                Employee IQ Evaluations
              </Text>
            </Box>
            <Box align='center'>
              <Text fontSize='1xl' fontWeight='700'>
                {numberOfBoardIqEvaluations}
              </Text>
              <Text fontSize='sm' fontWeight='400'>
                Board IQ Evaluations
              </Text>
            </Box>
            <Box align='center' direction='column'>
              <Text fontSize='1xl' fontWeight='700'>
                {numberOfOrgIqEvaluations}
              </Text>
              <Text fontSize='sm' fontWeight='400'>
                Org IQ Evaluations
              </Text>
            </Box>
            {/* <Box align='center' direction='column'>
              <Text fontSize='1xl' fontWeight='700'>
                {numberOfCareerIqEvaluations}
              </Text>
              <Text fontSize='sm' fontWeight='400'>
                Career IQ Evaluations
              </Text>
            </Box> */}
        </SimpleGrid> : null}
    </Card>
  );
}
