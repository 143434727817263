const { SURVEY_TYPE } = require("./evaluation.const");

const personalityTraits = {
  "Activity Level": {
    firstGrade: {
      title: "High",
      range: "7.00 - 10.00",
      content:
        "Exceptional drive and energy at work, exceeding expectations and inspiring others.",
      action: "",
    },
    secondGrade: {
      title: "Moderate",
      range: "4.00 - 6.99",
      content:
        "A consistent level of engagement, productivity, and determination, achieving meaningful outcomes.",
      action: "",
    },
    thirdGrade: {
      title: "Low",
      range: "0.00 - 3.99",
      content:
        "Struggle to maintain consistent motivation and energy level, and hesitation when faced with obstacles.",
      action: "",
    },
  },
  Initiative: {
    firstGrade: {
      title: "High",
      range: "7.00 - 10.00",
      content:
        "Extraordinary capacity for proactivity, self-guided action, and taking the lead in various situations.",
      action: "",
    },
    secondGrade: {
      title: "Moderate",
      range: "4.00 - 6.99",
      content:
        "A healthy balance of proactivity and responsiveness in their actions, without being explicitly directed.",
      action: "",
    },
    thirdGrade: {
      title: "Low",
      range: "0.00 - 3.99",
      content:
        "Struggle with taking proactive action and assuming personal responsibility without clear direction from others.",
      action: "",
    },
  },
  "Goal Achievement": {
    firstGrade: {
      title: "High",
      range: "7.00 - 10.00",
      content:
        "Exceptional proficiency in setting, pursuing, and successfully attaining clear, actionable, and measurable targets.",
      action: "",
    },
    secondGrade: {
      title: "Moderate",
      range: "4.00 - 6.99",
      content:
        "A solid capacity to establish realistic and attainable goals, with adjusting the strategies as needed to stay on course.",
      action: "",
    },
    thirdGrade: {
      title: "Low",
      range: "0.00 - 3.99",
      content:
        "Struggle with defining clear and measurable goals or developing actionable plans to accomplish them.",
      action: "",
    },
  },
  Persistence: {
    firstGrade: {
      title: "High",
      range: "7.00 - 10.00",
      content:
        "Extraordinary capacity to tenaciously pursue goals despite facing significant obstacles or setbacks.",
      action: "",
    },
    secondGrade: {
      title: "Moderate",
      range: "4.00 - 6.99",
      content:
        "A healthy balance of determination, resilience and long-term focus in pursuit of goals.",
      action: "",
    },
    thirdGrade: {
      title: "Low",
      range: "0.00 - 3.99",
      content:
        "A healthy balance of determination, resilience and long-term focus in pursuit of goals.",
      action: "",
    },
  },
  Competitiveness: {
    firstGrade: {
      title: "High",
      range: "7.00 - 10.00",
      content:
        "Exceptional determination to excel and outperform others, with a singular focus on winning.",
      action: "",
    },
    secondGrade: {
      title: "Moderate",
      range: "4.00 - 6.99",
      content:
        "A healthy balance of drive and ambition in pursuing success, while enjoying competition and striving to perform at their best.",
      action: "",
    },
    thirdGrade: {
      title: "Low",
      range: "0.00 - 3.99",
      content:
        "Struggle to harness the ambition effectively, feeling intimidated or overwhelmed by competition, and avoiding comparison with others.",
      action: "",
    },
  },
  Perception: {
    firstGrade: {
      title: "High",
      range: "7.00 - 10.00",
      content:
        "Extraordinary capacity to interpret and make sense of sensory information with remarkable clarity and depth.",
      action: "",
    },
    secondGrade: {
      title: "Moderate",
      range: "4.00 - 6.99",
      content:
        "A balanced sensitivity of the environment, while perceiving relevant cues and making informed judgments about the surroundings.",
      action: "",
    },
    thirdGrade: {
      title: "Low",
      range: "0.00 - 3.99",
      content:
        "Difficulties in accurately interpreting and making sense of sensory information from the environment, with struggles to filter out irrelevant stimuli.",
      action: "",
    },
  },
  Listening: {
    firstGrade: {
      title: "High",
      range: "7.00 - 10.00",
      content:
        "Exceptional capacity to receive, interpret, and understand spoken or auditory information with remarkable precision and depth.",
      action: "",
    },
    secondGrade: {
      title: "Moderate",
      range: "4.00 - 6.99",
      content:
        "A balanced engagement with speakers, demonstrating attentiveness, respect, and a genuine interest in understanding their message.",
      action: "",
    },
    thirdGrade: {
      title: "Low",
      range: "0.00 - 3.99",
      content:
        "Difficulties in maintaining interest, focus, or attention during conversations, leading to misunderstandings or misinterpretations of messages.",
      action: "",
    },
  },
  Flexibility: {
    firstGrade: {
      title: "High",
      range: "7.00 - 10.00",
      content:
        "Extraordinary ability and willingness to adapt, adjust, and respond effectively to changing circumstances, situations, or challenges.",
      action: "",
    },
    secondGrade: {
      title: "Moderate",
      range: "4.00 - 6.99",
      content:
        "A balanced resilience in the face of change, embracing opportunities for growth and learning while remaining grounded in principles and priorities.",
      action: "",
    },
    thirdGrade: {
      title: "Low",
      range: "0.00 - 3.99",
      content:
        "Struggle to adapt, adjust, and respond effectively to changing circumstances, situations, or challenges.",
      action: "",
    },
  },

  "Organizational Culture": {
    firstGrade: {
      title: "High",
      range: "7.00 - 10.00",
      content:
        "A vibrant and inclusive culture that fosters innovation, collaboration, and adaptability.",
      action: "",
    },
    secondGrade: {
      title: "Moderate",
      range: "4.00 - 6.99",
      content:
        "A healthy culture that promotes transparency, open communication, and respect for diverse perspectives.",
      action: "",
    },
    thirdGrade: {
      title: "Low",
      range: "0.00 - 3.99",
      content:
        "A culture characterized by silos, resistance to change, or lack of alignment with organizational values. Communication barriers and distrust may hinder collaboration and morale.",
      action: "",
    },
  },
  "Market Perception": {
    firstGrade: {
      title: "High",
      range: "7.00 - 10.00",
      content:
        "Strong brand reputation, market leadership position, and customer-centric.",
      action: "",
    },
    secondGrade: {
      title: "Moderate",
      range: "4.00 - 6.99",
      content:
        "Positive market perception with a solid reputation for quality products/services and customer satisfaction.",
      action: "",
    },
    thirdGrade: {
      title: "Low",
      range: "0.00 - 3.99",
      content:
        "Mixed or inconsistent market perception, potentially due to issues with product/service quality, customer service, or branding. May struggle to differentiate from competitors.",
      action: "",
    },
  },
  "Money Management": {
    firstGrade: {
      title: "High",
      range: "7.00 - 10.00",
      content:
        "Effective financial management practices, including prudent budgeting, cash flow optimization, and strategic investment decisions.",
      action: "",
    },
    secondGrade: {
      title: "Moderate",
      range: "4.00 - 6.99",
      content: "",
      action: "",
    },
    thirdGrade: {
      title: "Low",
      range: "0.00 - 3.99",
      content:
        "Sound financial management with clear budgetary controls and responsible spending. Maintains profitability and liquidity while investing in growth opportunities.",
      action: "",
    },
  },
  "Setting Goals": {
    firstGrade: {
      title: "High",
      range: "7.00 - 10.00",
      content:
        "Clear, ambitious, and achievable goals aligned with the organization's vision and strategic objectives.",
      action: "",
    },
    secondGrade: {
      title: "Moderate",
      range: "4.00 - 6.99",
      content:
        "Well-defined goals that are realistic, measurable, and relevant to organizational priorities. Regularly reviewed and adjusted to reflect changing circumstances.",
      action: "",
    },
    thirdGrade: {
      title: "Low",
      range: "0.00 - 3.99",
      content:
        "Vague or conflicting goals, lack of alignment with strategic objectives, or inconsistent goal-setting processes. May lead to confusion, inefficiency, or missed opportunities.",
      action: "",
    },
  },
  "Organizational Structure": {
    firstGrade: {
      title: "High",
      range: "7.00 - 10.00",
      content:
        "Agile and adaptive organizational structure that facilitates a healthy decentralized authority, and fluid communication channels.",
      action: "",
    },
    secondGrade: {
      title: "Moderate",
      range: "4.00 - 6.99",
      content:
        "Efficient organizational structure with clear roles, responsibilities, and reporting lines. Balances centralization and decentralization to support operational effectiveness.",
      action: "",
    },
    thirdGrade: {
      title: "Low",
      range: "0.00 - 3.99",
      content:
        "Rigid or outdated organizational structure that hampers communication, slows decision-making, or stifles creativity. May result in bureaucracy, inefficiency, or role ambiguity.",
      action: "",
    },
  },

  "Organization Members": {
    firstGrade: {
      title: "High",
      range: "7.00 - 10.00",
      content:
        "High-performing and engaged workforce characterized by strong morale, motivation, and alignment with organizational goals.",
      action: "",
    },
    secondGrade: {
      title: "Moderate",
      range: "4.00 - 6.99",
      content:
        "Committed and competent employees and management who demonstrate professionalism, teamwork, and a willingness to learn and grow.",
      action: "",
    },
    thirdGrade: {
      title: "Low",
      range: "0.00 - 3.99",
      content:
        "Issues with employee morale, motivation, performance, and turnover due to factors such as poor leadership, inadequate training, or job dissatisfaction.",
      action: "",
    },
  },
  "Reward System": {
    firstGrade: {
      title: "High",
      range: "7.00 - 10.00",
      content:
        "Comprehensive and equitable reward system that recognizes and rewards performance, innovation, and contributions to organizational success.",
      action: "",
    },
    secondGrade: {
      title: "Moderate",
      range: "4.00 - 6.99",
      content:
        "Fair and competitive compensation and benefits package aligned with industry standards and employee expectations.",
      action: "",
    },
    thirdGrade: {
      title: "Low",
      range: "0.00 - 3.99",
      content:
        "Inequitable or ineffective reward system that fails to motivate or retain top talent. May lead to dissatisfaction, demotivation, or talent drain.",
      action: "",
    },
  },
  "Information Flow": {
    firstGrade: {
      title: "High",
      range: "7.00 - 10.00",
      content:
        "Seamless and transparent information flow across all levels of the organization, enabling timely decision-making and collaboration.",
      action: "",
    },
    secondGrade: {
      title: "Moderate",
      range: "4.00 - 6.99",
      content:
        "Effective communication channels and processes ensure relevant information is shared efficiently and accurately. Encourages feedback and fosters a culture of open communication.",
      action: "",
    },
    thirdGrade: {
      title: "Low",
      range: "0.00 - 3.99",
      content:
        "Barriers to information flow, such as silos, communication breakdowns, or inadequate technology infrastructure. May lead to misunderstandings, inefficiencies, or missed opportunities.",
      action: "",
    },
  },

  Meetings: {
    firstGrade: {
      title: "High",
      range: "7.00 - 10.00",
      content:
        "Well-structured and productive meetings that are focused, efficient, and inclusive.",
      action: "",
    },
    secondGrade: {
      title: "Moderate",
      range: "4.00 - 6.99",
      content:
        "Regular and organized meetings that facilitate communication, alignment, and decision-making among stakeholders.",
      action: "",
    },
    thirdGrade: {
      title: "Low",
      range: "0.00 - 3.99",
      content:
        "Inefficient or disorganized meetings that lack clear agendas, objectives, or follow-up actions. Waste time and fail to achieve meaningful outcomes.",
      action: "",
    },
  },
  Accountability: {
    firstGrade: {
      title: "High",
      range: "7.00 - 10.00",
      content:
        "Strong culture of accountability where individuals take ownership of their actions and decisions.",
      action: "",
    },
    secondGrade: {
      title: "Moderate",
      range: "4.00 - 6.99",
      content:
        "Clear roles, responsibilities, and processes for holding individuals and teams accountable for their performance and conduct.",
      action: "",
    },
    thirdGrade: {
      title: "Low",
      range: "0.00 - 3.99",
      content:
        "Lack of clarity or enforcement mechanisms for accountability, leading to finger-pointing, blame shifting, or lack of consequences for misconduct.",
      action: "",
    },
  },
  "Standards of Conduct": {
    firstGrade: {
      title: "High",
      range: "7.00 - 10.00",
      content:
        "High ethical standards and integrity embedded in organizational culture and operations.",
      action: "",
    },
    secondGrade: {
      title: "Moderate",
      range: "4.00 - 6.99",
      content:
        "Established standards of conduct that promote honesty, integrity, and fairness in all business dealings.",
      action: "",
    },
    thirdGrade: {
      title: "Low",
      range: "0.00 - 3.99",
      content:
        "Instances of unethical behavior, conflicts of interest, or breaches of trust due to lax enforcement or inadequate adherence to ethical standards.",
      action: "",
    },
  },
  "Board Interaction": {
    firstGrade: {
      title: "High",
      range: "7.00 - 10.00",
      content:
        "Positive and constructive interactions among board members characterized by mutual respect, collaboration, and effective communication.",
      action: "",
    },
    secondGrade: {
      title: "Moderate",
      range: "4.00 - 6.99",
      content:
        "Open and respectful communication among board members, fostering a culture of trust and transparency.",
      action: "",
    },
    thirdGrade: {
      title: "Low",
      range: "0.00 - 3.99",
      content:
        "Tensions, conflicts, or lack of communication among board members, hindering effective governance and decision-making processes.",
      action: "",
    },
  },
  Composition: {
    firstGrade: {
      title: "High",
      range: "7.00 - 10.00",
      content:
        "Diverse and balanced composition of the board with complementary skills, expertise, and perspectives.",
      action: "",
    },
    secondGrade: {
      title: "Moderate",
      range: "4.00 - 6.99",
      content:
        "Board composition reflects a mix of skills, experience, and diversity that aligns with organizational needs and objectives.",
      action: "",
    },
    thirdGrade: {
      title: "Low",
      range: "0.00 - 3.99",
      content:
        "Lack of diversity or imbalance in board composition, potentially leading to blind spots, groupthink, or inadequate oversight.",
      action: "",
    },
  },
  "Board-Management Relations": {
    firstGrade: {
      title: "High",
      range: "7.00 - 10.00",
      content:
        "Collaborative and constructive relationship between the board and management, based on mutual respect, trust, and open communication.",
      action: "",
    },
    secondGrade: {
      title: "Moderate",
      range: "4.00 - 6.99",
      content:
        "Effective partnership between the board and management, with regular communication, alignment on strategic priorities and organizational goals.",
      action: "",
    },
    thirdGrade: {
      title: "Low",
      range: "0.00 - 3.99",
      content:
        "Friction or mistrust between the board and management, hindering effective decision-making, oversight, or execution of strategic initiatives.",
      action: "",
    },
  },
  "Board Motivation": {
    firstGrade: {
      title: "High",
      range: "7.00 - 10.00",
      content:
        "High level of engagement and commitment among board members, driven by a dedication to organizational success.",
      action: "",
    },
    secondGrade: {
      title: "Moderate",
      range: "4.00 - 6.99",
      content:
        "Board members are motivated and dedicated to fulfilling their fiduciary duties and responsibilities.",
      action: "",
    },
    thirdGrade: {
      title: "Low",
      range: "0.00 - 3.99",
      content:
        "Lack of motivation or disengagement among board members, potentially due to unclear expectations, limited involvement, or perceived lack of impact.",
      action: "",
    },
  },
  "Open-mindedeness": {
    firstGrade: {
      title: "High",
      range: "7.00 - 10.00",
      content:
        "Culture of receptivity to new ideas, perspectives, feedback, creativity, and continuous improvement.",
      action: "",
    },
    secondGrade: {
      title: "Moderate",
      range: "4.00 - 6.99",
      content:
        "Willingness to consider diverse viewpoints and explore alternative approaches to governance and decision-making.",
      action: "",
    },
    thirdGrade: {
      title: "Low",
      range: "0.00 - 3.99",
      content:
        "Closed-mindedness or resistance to change, potentially stifling innovation, and hindering effective governance processes. May lead to missed opportunities or stagnation.",
      action: "",
    },
  },
};

export const PersonalityTraitsLabels = {
  [SURVEY_TYPE.OrgIq]: personalityTraits,
  [SURVEY_TYPE.BoardIq]: personalityTraits,
  [SURVEY_TYPE.EmployeeIq]: personalityTraits,
};
