import { CheckIcon, CloseIcon, DeleteIcon, EditIcon } from "@chakra-ui/icons";
import { useEffect, useState } from "react";
import ConfirmQuestionDeletion from "./ConfirmQuestionDeletion";

const { 
  Textarea,
  NumberInputField, 
  NumberInput, 
  NumberInputStepper, 
  NumberDecrementStepper, 
  NumberIncrementStepper, 
  Box,
  FormControl,
  FormLabel,
  IconButton,
  Flex,
  Heading,
  Select
} = require("@chakra-ui/react");

function Question(props){
  
  const [title, setTitle] = useState(props.title);
  const [order, setOrder] = useState(props.order);
  const [trait, setTrait] = useState(props.trait);
  const [description, setDescription] = useState(props.description);
  const [behaviorDriver, setBehaviorDriver] = useState(props.behaviorDriver);
  const [mode, setMode] = useState("read");
  const [isConfirmDeleteOpen, setIsConfirmDeleteOpen] = useState(false);

  const behaviorDrivers = ['N/D', 'Action', 'Process', 'Strategy', 'People'];

  const { 
    onChangeQuestion,
    onRemoveQuestion
  } = props;

  useEffect(() => {
    setTitle(props.title);
  }, [props.title]);

  useEffect(() => {
    setOrder(props.order);
  }, [props.order]);

  useEffect(() => {
    setTrait(props.trait);
  }, [props.trait]);

  useEffect(() => {
    setDescription(props.description);
  }, [props.description]);
  
  useEffect(() => {
    setBehaviorDriver(props.behaviorDriver);
  }, [props.behaviorDriver]);

  const handleConfirm = () => {
    setMode("read");
    onChangeQuestion(title, description, parseInt(order), props.availableForFreePlan, behaviorDriver);
  }

  const handleDelete = () => {
    setIsConfirmDeleteOpen(true);
  }

  const handleDeleteConfirmation = () => {
    setMode("read");
    onRemoveQuestion();
  }

  return (
      <Box>
        <FormControl>
          <Flex>
            <Box sx={ { marginTop: 2 } }>
            {mode === "read" ? <Box> 
              <Heading size={"md"}>{title}</Heading>
              <Heading size={"sm"}>{description}</Heading>
              <Flex>
                <Box>Trait: {trait !== null && trait !== '' ? trait : 'N/D'} </Box>
                <Box ml={3}>Behavior driver: {behaviorDrivers[behaviorDriver]}</Box>
              </Flex>
            </Box> : <Heading size={"md"}>Editing Question</Heading>}
            </Box>
            {mode === "read" ? <Box>
              <IconButton sx={{ padding: 0 } } onClick={() => setMode(mode === "read" ? "write" : "read")}>
                <EditIcon />
              </IconButton>
            </Box> : <Box ml={2}>
              <Flex>
                <IconButton color="green" sx={{ padding: 0 } } onClick={handleConfirm}>
                  <CheckIcon />
                </IconButton>
                <IconButton ml={2} color="gray" sx={{ padding: 0 } } onClick={() => setMode("read")}>
                  <CloseIcon />
                </IconButton>
                <IconButton color="red" sx={{ padding: 0, marginLeft: 10 } } onClick={handleDelete}>
                  <DeleteIcon />
                </IconButton>
              </Flex>
            </Box>}
          </Flex>
        </FormControl>
        <FormControl mt={2}>
          <Flex>
            {mode === "write" ? <Textarea size='sm' 
                onChange={(e => setTitle(e.target.value))} 
                placeholder='Title' 
                defaultValue={title} 
                width={"50%"} /> : null}
            {mode === "write" ? <Textarea size='sm' 
                onChange={(e => setDescription(e.target.value))} 
                placeholder='Description' 
                defaultValue={description} 
                width={"50%"} /> : null}
            {mode === "write" ? <Box ml={5} width={"10%"}>
              <FormLabel mt={2}>Order</FormLabel>
              <NumberInput defaultValue={order} min={1} max={96}  >
              <NumberInputField onChange={(e => setOrder(e.target.value))} />
              <NumberInputStepper>
                <NumberIncrementStepper />
                <NumberDecrementStepper />
              </NumberInputStepper>
            </NumberInput>
            </Box> : null}
            {mode === "write" ? <Box ml={2} width={"15%"}>
              <FormControl>
                <FormLabel>Trait</FormLabel>
                {trait !== null && trait !== '' ? trait : 'N/D'}
              </FormControl>
            </Box> : null} 
            {mode === "write" ? <Box ml={2} width={"15%"}>
              <FormControl>
                <FormLabel>Behavior Change</FormLabel>
                <Select placeholder='Select value' value={behaviorDriver} onChange={(e) => setBehaviorDriver(e.target.value)}>
                  <option value={0}>None</option>
                  <option value={1}>Action</option>
                  <option value={2}>Process</option>
                  <option value={3}>Strategy</option>
                  <option value={4}>People</option>
                </Select>
              </FormControl>
            </Box> : null} 
          </Flex>
        </FormControl>
        <ConfirmQuestionDeletion 
          isOpen={isConfirmDeleteOpen} 
          onClose={() => setIsConfirmDeleteOpen(!isConfirmDeleteOpen)}
          onConfirm={() => handleDeleteConfirmation()}
        />
      </Box>
  )
}

export default Question;