import { SURVEY_TYPE } from "./evaluation.const";

export const RESULT_LABELS = {
    [SURVEY_TYPE.OrgIq]: {
        behviorDriversTitle: 'Behavior Drivers',
        behaviorDriversDesc: 'The general direction of your organization is characterized by different focus on four main factors: action, process, strategy, and people. The graph displays your scores for these factors on a scale of 0 to 10.',
        traitDerivedFromIndicatorsTitle: 'Organization Traits',
        traitDerivedFromIndicatorsDesc: 'Regardless of size, type, and dominant industry, all organizations commonly share 8 traits. The graph displays those traits and your scores on a scale from 0 to 10.',
        hamonizationIndexTitle: 'Harmonization Index',
        hamonizationIndexDesc: `The Harmonization Index indicates the alignment across various functions within your organization in achieving expected results, by adhering to standards, and thoughtful cultivation of human capital. The graph illustrates your organization's Harmonization Index on a scale of 0 to 100.`,
    },
    [SURVEY_TYPE.EmployeeIq]: {
        behviorDriversTitle: 'Behavior Drivers',
        behaviorDriversDesc: 'Your organizational behavior is characterized by different focus on four main factors: action, process, strategy, and people. The graph displays your scores for these factors on a scale of 0 to 10.',
        traitDerivedFromIndicatorsTitle: 'Personality Traits',
        traitDerivedFromIndicatorsDesc: 'Regardless of job position or work experience, individuals in any organization commonly share 8 traits. The graph displays those traits and your scores on a scale from 0 to 10.”',
        hamonizationIndexTitle: 'Harmonization Index',
        hamonizationIndexDesc: 'The Harmonization Index indicates your alignment with the organization in achieving expected results, adhering to standards, and fostering effective collaboration with colleagues. The graph illustrates your Harmonization Index on a scale of 0 to 100.',
    },
    [SURVEY_TYPE.BoardIq]: {
        behviorDriversTitle: 'Behavior Drivers',
        behaviorDriversDesc: 'Your corporate governance is characterized by different focus on four main factors: action, process, strategy, and people. The graph displays your scores for these factors on a scale of 0 to 10.',
        traitDerivedFromIndicatorsTitle: 'Corporate Governance Traits',
        traitDerivedFromIndicatorsDesc: 'Regardless of size, type, and dominant industry, all boards of directors commonly share 8 traits. The graph displays those traits and your scores on a scale from 0 to 10.”',
        hamonizationIndexTitle: 'Harmonization Index',
        hamonizationIndexDesc: `The Harmonization Index indicates a seamless steering of the organization through collaboration, strategic thinking, and effective governance. The graph illustrates your Board’s Harmonization Index on a scale of 0 to 100.`,
    },
    [SURVEY_TYPE.ExjDiagnostic]: {
        behviorDriversTitle: '',
        behaviorDriversDesc: '',
        traitDerivedFromIndicatorsTitle: '',
        traitDerivedFromIndicatorsDesc: '',
        hamonizationIndexTitle: '',
        hamonizationIndexDesc: '',
    },
    [SURVEY_TYPE.CareerIQ]: {
        behviorDriversTitle: '',
        behaviorDriversDesc: '',
        traitDerivedFromIndicatorsTitle: '',
        traitDerivedFromIndicatorsDesc: '',
        hamonizationIndexTitle: '',
        hamonizationIndexDesc: '',
    },
}