import { Box, Text } from '@chakra-ui/react';
import React from 'react';

function DataDriveDecisions(props){
    return <Box minH="100px" backgroundColor={"#fff"}>
            <Box p="40px" textAlign={"center"} color="red">
                <Text>We are now feeding our AI engine - soon, our clients will be able to use it as help in making qualified decisions based on the data in our WorkIQ ecosystem.</Text>
            </Box>
        </Box>
}


DataDriveDecisions.propTypes = {

};

DataDriveDecisions.defaultProps = {
    
}

export default DataDriveDecisions;