// Chakra imports
import {
    Box, Text
  } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import Authenticated from "views/admin/authenticated";

import './index.css';

function Cover({ location }) {
    
    const logo = require("../../assets/img/logo/logo.png");

    const [name, setName] = useState();
    const [date, setDate] = useState();

    useEffect(() => {
      const query = new URLSearchParams(location.search);
      setName(query.get('name'));
      setDate(query.get('date'));
  }, []);

    return (
      <Authenticated>
        <Box textAlign={"center"} mt="0px">
            <img src={logo} alt="" width="140px" style={ { marginBottom: 10, marginTop: "80px", marginLeft: "auto", marginRight: "auto" } }/>
        </Box>
        <Box textAlign={"center"} mt="120px" display={"flex"} justifyContent={"center"}>
          <Text fontSize="18px">Individual report for&nbsp;</Text>
          <Text fontSize={"18px"} fontWeight={"bold"}>{`${name}`}</Text>
        </Box>
        <Box textAlign="center" mt="100px">
          {date}
        </Box>
      </Authenticated>
    );
  }
  
  export default Cover;