// Chakra imports
import {
  Box,
  Button,
  SimpleGrid,
  Text,
  useColorModeValue,
  useDisclosure
} from "@chakra-ui/react";
// Custom components
import Card from "components/card/Card.js";
import { SUBSCRIPTION_TYPE } from "constants/subscriptionTypes.const";
import React from "react";
// Assets
import BoardIQ from "views/admin/evaluations/boardIQ";
import ExjDiagnostic from "views/admin/evaluations/exjDiagnostic";
import EmployeeIQ from "views/admin/evaluations/employeeIQ";
import OrgIQ from "views/admin/evaluations/orgIQ";
import { COLORS } from "constants/colors";
import { SURVEY_TYPE } from "constants/evaluation.const";

export default function Evaluation(props) {
  const {
    subscriptionType,
    onClickToManageEvaluation
  } = props;
  // Chakra Color Mode
  const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
  const employeeIqDialog = useDisclosure();
  const boardIqDialog = useDisclosure();
  const orgIqDialog = useDisclosure();
  const exjDiagnosticDialog = useDisclosure();

  const handleClickOpenEvaluation = (type) => {
    onClickToManageEvaluation(type);
  };

  return (
    <Box maxW="550px">
      <Card mb="20px" align="center" minH="355px">
        <Box>
          <SimpleGrid>
            <Box direction="column" pe="44px">
              <Text
                color={textColorPrimary}
                fontWeight="bold"
                textAlign="start"
                fontSize="xl"
                mt={"10px"}
              >
                There are three assessments in total.
              </Text>
            </Box>
            <Box>
              <Text textAlign={"left"} mt="10px">
                Thus, you may invite any member of your team to take any assessment. Just click on the assessment button and follow the on-screen instructions.
              </Text>
            </Box>
          </SimpleGrid>
          {subscriptionType !== SUBSCRIPTION_TYPE.Enterprise ? (
            <SimpleGrid
              columns={{ lg: 2, md: 2, sm: 1 }}
              mt={4}
              spacingY={"10px"}
              spacingX={"0px"}
            >
              <Box w="100%">
                <Button
                  height={"70px"}
                  width={"80%"}
                  colorScheme="blue"
                  onClick={() =>
                    handleClickOpenEvaluation(SURVEY_TYPE.EmployeeIq)
                  }
                  fontWeight="500"
                >
                  Employee IQ
                </Button>
                <EmployeeIQ
                  isOpen={employeeIqDialog.isOpen}
                  onClose={employeeIqDialog.onClose}
                />
              </Box>
              <Box>
                <Button
                  height={"70px"}
                  width={"80%"}
                  colorScheme="purple"
                  onClick={() => handleClickOpenEvaluation(SURVEY_TYPE.BoardIq)}
                  fontWeight="500"
                >
                  Board IQ
                </Button>
                <BoardIQ
                  isOpen={boardIqDialog.isOpen}
                  onClose={boardIqDialog.onClose}
                />
              </Box>
              <Box>
                <Button
                  height={"70px"}
                  width={"80%"}
                  colorScheme="teal"
                  onClick={() => handleClickOpenEvaluation(SURVEY_TYPE.OrgIq)}
                  fontWeight="500"
                >
                  Org IQ
                </Button>
                <OrgIQ
                  isOpen={orgIqDialog.isOpen}
                  onClose={orgIqDialog.onClose}
                />
              </Box>
              {/* <Box>
              Filip and Zvonko asked to remove CareerIQ on Nov 29/11/23
              <Button
                height={"70px"}
                width={"80%"}
                style={ { backgroundColor: COLORS.Primary, color: COLORS.PrimaryText } }
                onClick={() => handleClickOpenEvaluation(SUBSCRIPTION_TYPE.CareerIQ)}
                fontWeight='500'>
                Career IQ
              </Button>
              <CareerIQ
                isOpen={careerIqDialog.isOpen}
                onClose={careerIqDialog.onClose}
              />
            </Box> */}
            </SimpleGrid>
          ) : null}
          {subscriptionType === SUBSCRIPTION_TYPE.Enterprise ? (
            <Box w="100%" mt={10} style={{ textAlign: "center" }}>
              <Button
                height={"70px"}
                style={{
                  backgroundColor: COLORS.Primary,
                  color: COLORS.PrimaryText,
                }}
                onClick={() =>
                  handleClickOpenEvaluation(SURVEY_TYPE.ExjDiagnostic)
                }
                fontWeight="500"
              >
                Exj Diagnostic
              </Button>
              <ExjDiagnostic
                isOpen={exjDiagnosticDialog.isOpen}
                onClose={exjDiagnosticDialog.onClose}
              />
            </Box>
          ) : null}
        </Box>
      </Card>
    </Box>
  );
}
