import { createSlice } from '@reduxjs/toolkit';

export const arrowsSlice = createSlice({
  name: 'arrows',
  initialState: {
    behaviorLines: [],
    traitLines: []
  },
  reducers: {
    setBehaviorLines: (state, action) => {
        state.behaviorLines = action.payload;
    },
    setTraitLines: (state, action) => {
      state.traitLines = action.payload;
    },
    cleanLines: (state, action) => {
      for (let i = 0; i < state.traitLines.length; i++) {
        try {
          state.traitLines[i].remove();
        } catch { }
      }

      for (let i = 0; i < state.behaviorLines.length; i++) {
        try {
          state.behaviorLines[i].remove();
        } catch{ }
      }

      state.traitLines = [];
      state.behaviorLines = [];
    }
  }
});

export const { 
    setBehaviorLines,
    setTraitLines,
    cleanLines
 } = arrowsSlice.actions;

export default arrowsSlice.reducer;
