// Chakra imports
import { Box, Text, Heading } from "@chakra-ui/react";
import Card from "components/card/Card.js";
import React from "react";
import { useHistory } from "react-router-dom";

import { COLORS } from "constants/colors";

import employeeIqImg from '../../../../assets/img/surveys/EmployeeIQ.svg';
import careerIQImg from '../../../../assets/img/surveys/CareerIQ.svg';
import orgIqImg from '../../../../assets/img/surveys/OrgIQ.svg';
import boardIqImg from '../../../../assets/img/surveys/BoardIQ.svg';
import { SURVEY_TYPE } from "constants/evaluation.const";

import './PendingEvaluationBanner.css';
import { SURVEY_TYPE_DESCRIPTION } from "constants/surveyTypes.const";
import { SURVEY_TYPE_SUMMARY } from "constants/evaluation.const";

export default function PendingEvaluationBanner(props) {
  const { 
    userSurveyResponseId, 
    type
  } = props;

  const history = useHistory();

  const survey_types_imgs = {
    [SURVEY_TYPE.EmployeeIq]: employeeIqImg,
    [SURVEY_TYPE.BoardIq]: boardIqImg,
    [SURVEY_TYPE.OrgIq]: orgIqImg,
    [SURVEY_TYPE.CareerIQ]: careerIQImg,
    [SURVEY_TYPE.ExjDiagnostic]: careerIQImg,
   };

   const handleClickEvaluation = () => {
      history.push(`/app/assessment/${userSurveyResponseId}`);
   }

  return (
    <Box maxW="md">
      <Card mb={{ base: "0px", lg: "20px" }} minH="110px">
            <Box display={"flex"}>
              <Box h={"100%"} className="evaluation-text-image" onClick={handleClickEvaluation}>
                <img style={ { marginTop: '10%' } } alt="" src={survey_types_imgs[type]} width={"60px"}/>
              </Box>
              <Box>
                <Box ml="20px">
                  <Heading size="sm">{SURVEY_TYPE_DESCRIPTION[type]}</Heading>
                  <Box style={ { overflowWrap: 'break-word', width: '250px' } }>
                    <Text className="evaluation-text-description" 
                      style={{ color: COLORS.Primary }} 
                      size="md"
                      onClick={handleClickEvaluation}
                    >
                      {SURVEY_TYPE_SUMMARY[type]}
                    </Text>
                  </Box>
                </Box>
              </Box>
            </Box>
            {/* <Box>
              {status === STATUS.IN_PROGRESS ? `You have an ongoing ${type} Evaluation` : `You have a pending ${type} Evaluation.`}
            </Box>
            <Box style={ { color: 'green' } }>
              {status === STATUS.IN_PROGRESS ? `Last update ${moment(updateDate).format('MMM DD YYYY HH:mm')}` : null}
            </Box> */}
          
          </Card>
    </Box>
   
  );
}
