/* eslint-disable */
/*!
  _   _  ___  ____  ___ ________  _   _   _   _ ___   
 | | | |/ _ \|  _ \|_ _|__  / _ \| \ | | | | | |_ _| 
 | |_| | | | | |_) || |  / / | | |  \| | | | | || | 
 |  _  | |_| |  _ < | | / /| |_| | |\  | | |_| || |
 |_| |_|\___/|_| \_\___/____\___/|_| \_|  \___/|___|
                                                                                                                                                                                                                                                                                                                                       
=========================================================
* Horizon UI - v1.1.0
=========================================================

* Product Page: https://www.horizon-ui.com/
* Copyright 2022 Horizon UI (https://www.horizon-ui.com/)

* Designed and Coded by Simmmple

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

import React, { useEffect } from "react";
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { NavLink } from "react-router-dom";
// Chakra imports
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  Text,
  useColorModeValue,
  useToast,
  useDisclosure
} from "@chakra-ui/react";
// Custom components
import { HSeparator } from "components/separator/Separator";
import DefaultAuth from "layouts/auth/Default";
// Assets
import illustration from "assets/img/logo/logo.png";
import { FcGoogle } from "react-icons/fc";
import { MdOutlineRemoveRedEye } from "react-icons/md";
import { RiEyeCloseLine } from "react-icons/ri";
import { login } from "features/authentication/slice";
import { getOrganizationLogoAsync } from "features/user/slice";
import { forgotPassword } from "features/authentication/slice";
import { recoverPassword } from "features/authentication/slice";

function RecoverPassword(props) {

  const { onRecoverPassword, organizationLogo, location, onGetCompanyLogo } = props;

  // Chakra color mode
  const textColor = useColorModeValue("navy.700", "white");
  const textColorSecondary = "gray.400";
  const textColorDetails = useColorModeValue("navy.700", "secondaryGray.600");
  const brandStars = useColorModeValue("brand.500", "brand.400");
  const [show, setShow] = React.useState(false);
  const handleClick = () => setShow(!show);

  const [showConfirm, setShowConfirm] = React.useState(false);
  const handleClickConfirm = () => setShowConfirm(!showConfirm);

  const [password, setPassword] = React.useState('');
  const [confirmPassword, setConfirmPassword] = React.useState('');

  const toast = useToast();

  var splitUrl = window.location.hostname.split('.');
  var subdomain = window.location.hostname.split('.')[0];
  const logoImage = organizationLogo !== null && organizationLogo !== '' ? organizationLogo : illustration;

  useEffect(() => {
    if (splitUrl.length > 2 && subdomain !== 'www') {
      onGetCompanyLogo(subdomain, () => { });
    }
  }, []);

  const handlePasswordRecovery = (event) => {
    event.preventDefault();

    const tokenId = new URLSearchParams(location.search).get("id");

    if (tokenId === null || tokenId === '') {
      toast({
        title: `Password Recovery`,
        description: `Password forgot token not valid`,
        status: 'error',
        duration: 9000,
        isClosable: true
      });
      return;
    }

    if(password !== confirmPassword){
      toast({
        title: `Password validation`,
        description: `Password and confirm password don't match`,
        status: 'error',
        duration: 9000,
        isClosable: true
      });
      return;
    }

    if(password !== '') {

      onRecoverPassword(tokenId, null, password, (redirectUrl) => {
        toast({
          title: 'Success',
          description: 'Email successfully recovered!',
          status: 'success',
          duration: 9000,
          isClosable: true
        });
        onClose();
        window.location.replace(redirectUrl);
      }, (msg) => {
        toast({
          title: 'Forgot Password request Failed',
          description: msg,
          status: 'error',
          duration: 9000,
          isClosable: true
        })
      })
    }
  }


  return (
   <DefaultAuth illustrationBackground={logoImage} image={logoImage}>
      
      <Flex
        maxW={{ base: "100%", md: "max-content" }}
        w='100%'
        mx={{ base: "auto", lg: "0px" }}
        me='auto'
        h='100%'
        alignItems='start'
        justifyContent='center'
        mb={{ base: "30px", md: "60px" }}
        px={{ base: "25px", md: "0px" }}
        flexDirection='column'>
        <Box me='auto'
          mt={{ base: "0px", xl: "30px", md: "60px" }}
        >
          <Heading color={textColor} fontSize='36px' mb='10px'>
            Recover Password
          </Heading>
          <Text
            mb='36px'
            ms='4px'
            color={textColorSecondary}
            fontWeight='400'
            fontSize='md'>
            Enter your new password
          </Text>
        </Box>
        <Flex
          zIndex='2'
          direction='column'
          w={{ base: "100%", md: "420px" }}
          maxW='100%'
          background='transparent'
          borderRadius='15px'
          mx={{ base: "auto", lg: "unset" }}
          me='auto'
          mb={{ base: "20px", md: "auto" }}>
          <form onSubmit={handlePasswordRecovery}>
            <FormControl>
              <FormLabel
                ms='4px'
                fontSize='sm'
                fontWeight='500'
                color={textColor}
                display='flex'>
                Password<Text color={brandStars}>*</Text>
              </FormLabel>
              <InputGroup size='md'>
                <Input
                  isRequired={true}
                  fontSize='sm'
                  placeholder='Min. 8 characters'
                  mb='24px'
                  size='lg'
                  type={show ? "text" : "password"}
                  variant='auth'
                  errorBorderColor='red.300'
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
                <InputRightElement display='flex' alignItems='center' mt='4px'>
                  <Icon
                    color={textColorSecondary}
                    _hover={{ cursor: "pointer" }}
                    as={show ? RiEyeCloseLine : MdOutlineRemoveRedEye}
                    onClick={handleClick}
                  />
                </InputRightElement>
              </InputGroup>
              <FormLabel
                ms='4px'
                fontSize='sm'
                fontWeight='500'
                color={textColor}
                display='flex'>
                Confirm Password<Text color={brandStars}>*</Text>
              </FormLabel>
              <InputGroup size='md'>
                <Input
                  isRequired={true}
                  fontSize='sm'
                  placeholder='Min. 8 characters'
                  mb='24px'
                  size='lg'
                  type={showConfirm ? "text" : "password"}
                  variant='auth'
                  errorBorderColor='red.300'
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                />
                <InputRightElement display='flex' alignItems='center' mt='4px'>
                  <Icon
                    color={textColorSecondary}
                    _hover={{ cursor: "pointer" }}
                    as={show ? RiEyeCloseLine : MdOutlineRemoveRedEye}
                    onClick={handleClickConfirm}
                  />
                </InputRightElement>
              </InputGroup>
              <Button
                fontSize='sm'
                colorScheme="green"
                fontWeight='500'
                w='100%'
                h='50'
                mb='24px'
                type="submit">
                Sign In
              </Button>
            </FormControl>
          </form>
          <Flex
            flexDirection='column'
            justifyContent='center'
            alignItems='start'
            maxW='100%'
            mt='0px'>
            <Text color={textColorDetails} fontWeight='400' fontSize='14px'>
              Not registered yet?
              <NavLink to='/auth/sign-up'>
                <Text
                  color={"green"}
                  as='span'
                  ms='5px'
                  fontWeight='500'>
                  Create an Account
                </Text>
              </NavLink>
            </Text>
          </Flex>
        </Flex>
      </Flex>
    </DefaultAuth>
  );
}

RecoverPassword.propTypes = {
  organizationLogo: PropTypes.string,
  onSignIn: PropTypes.func,
  onGetCompanyLogo: PropTypes.func,
  onRecoverPassword: PropTypes.func
};

RecoverPassword.defaultProps = {
  organizationLogo: null,
  onSignIn: () => { },
  onGetCompanyLogo: () => { },
  onRecoverPassword: () => { }
}

function mapStateToProps(state) {
  const { authentication } = state;

  const { organizationLogo } = authentication;
  
  return {
    organizationLogo
   };
};

function mapDispatchToProps (dispatch) {
  return {
      onRecoverPassword: (token, currentPassword, password, onSuccess, onError) => dispatch(recoverPassword({ token, currentPassword, password, onSuccess, onError })),
      onGetCompanyLogo: (domain, onSuccess ) => dispatch(getOrganizationLogoAsync({ domain, onSuccess })),
      dispatch
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(RecoverPassword);