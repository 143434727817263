// Chakra imports
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalFooter,
    ModalBody,
    Button,
    Box,
    Text,
    Heading
  } from '@chakra-ui/react'
import Card from 'components/card/Card';
import { COLORS } from 'constants/colors';
import React from "react";
  
export default function IndicatorsModal(props) {
    const { 
        isOpen, 
        onClose, 
        indicators
    } = props;

    return (
      <Modal size='xl' isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalBody>
            <Box textAlign={"center"} fontSize={"14px"} mt="20px">
                <Text>
                    The indicators are simple statements related to everyday work activities. Below are several out of 96 indicators.
                </Text>
            </Box>
            {indicators.map((a, i) => {
                return <Box mt="20px">
                <Card pr="80px" boxShadow="0px 18px 40px rgba(112, 144, 176, 0.12)" h="100px">
                    <Box display={"flex"}>
                        <Box>
                            <Box width="60px">
                                <div style={{ width: "59px", height: "59px", textAlign: 'center', backgroundColor: "#0095FF1A", color: "#0095ff", borderRadius: "50%" }} >
                                    <Heading size="md" style={{ paddingTop: "16px" }}>
                                        {a.name}
                                    </Heading>
                                </div>
                            </Box>
                        </Box>
                        <Box width={"400px"}>
                            <Text ml="15px" mt={a.description.length <= 30 ? "15px" : "5px"}>{a.description}</Text>
                        </Box>
                    </Box>
                </Card>
            </Box>
            })}
          </ModalBody>
          <ModalFooter>
            <Button 
                style={ { backgroundColor: COLORS.Primary, color: COLORS.PrimaryText } } 
                ml="auto"
                mr="auto"
                height={"60px"}
                width={"150px"}
                onClick={onClose}>Close</Button>
          </ModalFooter>
        </ModalContent>
    </Modal>
    )
}