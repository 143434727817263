export const SUBSCRIPTION_TYPE = {
    Undetermined: -1,
    Free: 0,
    Individual: 1,
    Team: 2,
    Enterprise: 3
}

export const SUBSCRIPTION_FREQUENCY = {
    Monthly: 1,
    Yearly: 2
}

export const SUBSCRIPTION_FREQUENCY_DESC = {
    [SUBSCRIPTION_FREQUENCY.Monthly]: 'month',
    [SUBSCRIPTION_FREQUENCY.Yearly]: 'year'
}