/*!
  _   _  ___  ____  ___ ________  _   _   _   _ ___   
 | | | |/ _ \|  _ \|_ _|__  / _ \| \ | | | | | |_ _| 
 | |_| | | | | |_) || |  / / | | |  \| | | | | || | 
 |  _  | |_| |  _ < | | / /| |_| | |\  | | |_| || |
 |_| |_|\___/|_| \_\___/____\___/|_| \_|  \___/|___|
                                                                                                                                                                                                                                                                                                                                       
=========================================================
* Horizon UI - v1.1.0
=========================================================

* Product Page: https://www.horizon-ui.com/
* Copyright 2022 Horizon UI (https://www.horizon-ui.com/)

* Designed and Coded by Simmmple

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// Chakra imports
import { Box } from "@chakra-ui/react";
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import React, { useEffect, useState } from "react";
import { listOrganizationUsersAsync, addNewTeamMemberAsync, onDeleteTeamMemberAsync } from "features/user/slice";
import { onUpdateUserProfile } from "features/user/slice";
import { resendUserInvitation } from "features/userSurveys/slice";
import { getOrganizationUserResponseSummary } from "features/metrics/slice";
import MembersManagementMemberView from "./MembersManagementMemberView";
import MembersManagementDefaultView from "./MembersManagementDefaultView";

function MembersManagement(props) {
  const viewTypes = {
    Default: 1,
    Member: 2
  };

  const [viewType, setViewType] = useState(viewTypes.Default);
  const [viewingMember, setViewingMember] = useState(null);
  const { 
    onLoadOrganizationUserResponseSummary,
    userReponseSummary,
    onReinviteUser,
    onVisualizeUser
  } = props;

  const query = new URLSearchParams(props.location.search);
  const surveyType = query.get('surveyType');

  useEffect(() => {
    onLoadOrganizationUserResponseSummary();
  }, [])

  const onClickTeamMember = (member) => {
    onVisualizeUser(true);
    setViewingMember(member);
    setViewType(viewTypes.Member);
    window.scrollTo(0, 0);
  }

  const onClickCloseTeamMember = () => {
    onVisualizeUser(false);
    setViewType(viewTypes.Default);
  }

  return (
      <Box>
        {viewType === viewTypes.Default ? <MembersManagementDefaultView 
          onClickTeamMember={onClickTeamMember}
          userReponseSummary={userReponseSummary}
          surveyType={surveyType}
          showExplanation={props.showExplanation}
          /> : <MembersManagementMemberView 
            member={viewingMember} 
            onClose={onClickCloseTeamMember} 
            onReinviteUser={onReinviteUser}
            adminMode={true}
        />}
      </Box>
  );
}


MembersManagement.propTypes = {
  subscriptions: PropTypes.array,
  organizationName: PropTypes.string,
  onLoadSubscriptions: PropTypes.func,
  onCreateTeamMembers: PropTypes.func,
  onUpdateTeamMember: PropTypes.func,
  onReinviteUser: PropTypes.func,
  onLoadOrganizationUserResponseSummary: PropTypes.func,
  showExplanation: PropTypes.bool
};

MembersManagement.defaultProps = {
  subscriptions: [],
  organizationName: '',
  onLoadSubscriptions: () => {},
  onCreateTeamMembers: () => {},
  onUpdateTeamMember: () => {},
  onReinviteUser: () => {},
  onLoadOrganizationUserResponseSummary: () => {},
  showExplanation: false
}

function mapStateToProps(state) {

  const { organizationName } = state.user;

  const { userReponseSummary } = state.metrics;
  
  return {
    organizationName,
    userReponseSummary
   };
};

function mapDispatchToProps (dispatch) {
  return {
      onLoadTeamMembers: () => dispatch(listOrganizationUsersAsync(null, () => {})),
      onLoadOrganizationUserResponseSummary: (onSuccess) => dispatch(getOrganizationUserResponseSummary(onSuccess)),
      onCreateTeamMembers: (firstName, lastName, email, onSuccess, onError) => dispatch(addNewTeamMemberAsync({ firstName, lastName, email, onSuccess, onError })),
      onDeleteTeamMember: (id, onSuccess, onError) =>  dispatch(onDeleteTeamMemberAsync({ id, onSuccess, onError })),
      onUpdateTeamMember: (id, firstName, lastName, email, onSuccess, onError) => dispatch(onUpdateUserProfile({ id, firstName, lastName, email, organizationName: '', onSuccess, onError })),
      onReinviteUser: (userSurveyResponseId, onSuccess, onError) => dispatch(resendUserInvitation({ userSurveyResponseId, onSuccess, onError })),
      dispatch
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(MembersManagement);