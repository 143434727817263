/*!
  _   _  ___  ____  ___ ________  _   _   _   _ ___   
 | | | |/ _ \|  _ \|_ _|__  / _ \| \ | | | | | |_ _| 
 | |_| | | | | |_) || |  / / | | |  \| | | | | || | 
 |  _  | |_| |  _ < | | / /| |_| | |\  | | |_| || |
 |_| |_|\___/|_| \_\___/____\___/|_| \_|  \___/|___|
                                                                                                                                                                                                                                                                                                                                       
=========================================================
* Horizon UI - v1.1.0
=========================================================

* Product Page: https://www.horizon-ui.com/
* Copyright 2022 Horizon UI (https://www.horizon-ui.com/)

* Designed and Coded by Simmmple

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// Chakra imports
import { Box } from "@chakra-ui/react";
import React, { useEffect } from "react";
import PropTypes from 'prop-types';
import Authenticated from "views/admin/authenticated";
import { connect } from "react-redux";
import { setTraitLines } from "features/arrows/slice";
import { setBehaviorLines } from "features/arrows/slice";
import { cleanLines } from "features/arrows/slice";
import { getDefinitionMetrics } from "features/metrics/slice";
import Arrows from "views/admin/definitions/components/Arrows";

function DefinitionsReport({ definitions, behaviorLines, traitLines, onSetBehaviorLines, onLoadDefinitions, onSetTraitLines, onRemoveAllLines }) {

  const indexes = { EmployeeIQ: 0, BoardIQ: 1, OrgIQ: 2, CareerIQ: 3 };

  const tabIndex = indexes.EmployeeIQ;

  useEffect(() => {
    onLoadDefinitions(() => { });
  }, []);

  const getDefinitions = (def) => {
    return tabIndex === indexes.EmployeeIQ 
      ? def.employeeIQ
      : tabIndex === indexes.BoardIQ 
        ? def.boardIQ
        : tabIndex === indexes.OrgIQ 
          ? def.orgIQ
          : tabIndex === indexes.CareerIQ 
            ? def.careerIQ
            : null;
  }

  return (
    <Authenticated>
          <Box>
            {definitions ? <Arrows 
              definitions={getDefinitions(definitions)} 
              onSetBehaviorLines={onSetBehaviorLines}
              onSetTraitLines={onSetTraitLines}
              traitLines={traitLines}
              behaviorLines={behaviorLines}
              onRemoveAllLines={onRemoveAllLines}
              /> : null}
          </Box>
    </Authenticated>
  );
}

  
DefinitionsReport.propTypes = {
  definitions: PropTypes.object,
  onLoadDefinitions: PropTypes.func,
  onSetTraitLines: PropTypes.func,
  onSetBehaviorLines: PropTypes.func,
  onRemoveAllLines: PropTypes.func
};

DefinitionsReport.defaultProps = {
  onLoadDefinitions: () => { },
  onSetTraitLines: () => { },
  onSetBehaviorLines: () => { },
  onRemoveAllLines: () => { }
}

function mapStateToProps(state) {
  const { behaviorLines, traitLines } = state.arrows;
  const { definitions } = state.metrics;

  return {
    definitions,
    behaviorLines, 
    traitLines
   };
};

function mapDispatchToProps (dispatch) {
  return {
      onLoadDefinitions: (onSuccess) => dispatch(getDefinitionMetrics({ onSuccess })),
      onSetTraitLines: (traitLines) => dispatch(setTraitLines(traitLines)),
      onSetBehaviorLines: (behaviorLines) => dispatch(setBehaviorLines(behaviorLines)),
      onRemoveAllLines: () => dispatch(cleanLines()),
      dispatch
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DefinitionsReport);